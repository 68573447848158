import React, { useState } from 'react';
import { AuthService } from '../../../services/AuthService/auth.service';
import { DefaultValueBinder, DefaultFieldBinderFactory } from '@offerpad/concrete/lib/model';
import { ValidationResult } from '@offerpad/concrete/lib/validation/Validator';
import * as binder from './binder';
import Alert from '../../UIComponents/Alert';
import { Button } from 'floorplan/components/v2/Button';
import Fields from './fields';
import { FormControl } from 'floorplan/components/v2/Form';
import { useHistory } from "react-router-dom";
import { useAppInsights } from '../../../context/AppInsightsContext';
import { path, RouteName } from '../../../routing/Routes';
import { useNotifications } from '../../../context/notifications/NotificationsContext';
import { notify } from '../../../context/notifications/NotificationsReducer';

const ChangePassword = () => {
    const { dispatch: notificationsDispatch } = useNotifications();
    const history = useHistory();
    const appInsights = useAppInsights();

    const [feedbackMessage, setFeedbackMessage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState<Record<string, string>>({})

    const valueBinder = new DefaultValueBinder();
    const fieldBinderFactory = new DefaultFieldBinderFactory(valueBinder);
    const form = binder.create(fieldBinderFactory);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const field = form.fields[e.target.name];
        const value = e.target.value;
        const result: ValidationResult = field.validator.validate(value);
        setErrors({ ...errors, [e.target.name]: result.message })
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const model = form.bind(e.target as HTMLFormElement);
        if (model.valid) {
            setIsSubmitting(true);
            AuthService.resetPassword({
                email: JSON.parse(AuthService.getUserInfo()).email,
                oldPassword: model.fields[Fields.OldPassword].value,
                newPassword: model.fields[Fields.NewPassword].value
            })
                .then((response) => {
                    if (response.status === 200) {
                        const data = response.data
                        if (data && data.error_description) {
                            throw new Error(data.error_description);
                        }
                        notify(notificationsDispatch, { 'message': `Your password was successfully updated. Please login.`, 'type': 'success' });
                        AuthService.logout();
                        history.push(path(RouteName.Login));
                    } else {
                        // Deals with any status that's not 200
                        if (response.data && response.data.error_description) {
                            throw new Error(response.data.error_description);
                        }
                        throw new Error('Error description unavailable');
                    }
                })
                .catch((error) => {
                    appInsights.trackTrace({ message: error.response.data });
                    if (error.response.data === 'User not found or invalid password') {
                        setFeedbackMessage(`The current password you entered doesn't match our records.`);
                    } else {
                        setFeedbackMessage("There was an error while processing your request; please try again later.");
                    }
                }).finally(() => setIsSubmitting(false));
        } else {
            let errors = {};
            for (let field in model.fields) {
                const validation = model.fields[field].validation;
                errors[field] = validation.message;
            }
            setErrors(errors);
        }
    }

    return (
        <div className="c-authform">
            <form onSubmit={(e) => onSubmit(e)}>
                <h2 className="c-authform__title">Change password</h2>
                <p className="c-authform__subtitle">Let&rsquo;s get you a new password.</p>
                {feedbackMessage && <Alert description={feedbackMessage} />}
                <fieldset>
                    <legend></legend>
                    <FormControl
                        onChange={(e) => onChange(e)}
                        label="Current password"
                        id={Fields.OldPassword}
                        name={Fields.OldPassword}
                        errorText={errors[Fields.OldPassword]}
                        hasError={!!errors[Fields.OldPassword]}
                        showErrorIcon={!!errors[Fields.OldPassword]}
                        type='password'
                        placeholder="Current password"
                    />
                    <FormControl
                        onChange={(e) => onChange(e)}
                        label="New password"
                        id={Fields.NewPassword}
                        name={Fields.NewPassword}
                        errorText={errors[Fields.NewPassword]}
                        hasError={!!errors[Fields.NewPassword]}
                        showErrorIcon={!!errors[Fields.NewPassword]}
                        type='password'
                        placeholder='New password'
                    />
                </fieldset>
                <p className="c-authform__password-rules">
                    Passwords must be at least six characters long and include digits, lower and upper case letters and
                    special characters.
                </p>

                <Button
                    id="change-password-submit" fullWidth primary type='submit'
                >
                    {isSubmitting ? 'Changing Password...' : 'Update my password'}
                </Button>
            </form>
        </div>);
}

export default ChangePassword;