import apiClient from '../../clients/apiClient'
import { getAuthHeaders } from '../AuthService/auth.service';
const apiManagementUrl = process.env.RAZZLE_API_MANAGEMENT_URL;
import { Profile } from './models/Profile';

const customerAuthEndpoint = () => {
    apiClient.defaults.baseURL = apiManagementUrl + '/api/customer-auth/v2/';
    return apiClient;
};

export const getInfo  = () : Promise<Profile> => {
    return new Promise(function (resolve, reject) {
        customerAuthEndpoint()
            .get('/info', getAuthHeaders())
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem('user.info', JSON.stringify(response.data));
                    resolve(response.data);
                }
            }, reject);
    });
}

export const submitFeedback = (data) => customerAuthEndpoint().post('/feedback', data)

export const updateProfile = ({ email, firstName, lastName, phoneNumber, agent, agentId, brokerageName }) =>
    customerAuthEndpoint().post(
        '/profile',
        {
            email,
            firstName,
            lastName,
            phoneNumber,
            agent,
            agentId,
            brokerageName,
        },
        getAuthHeaders()
    )
