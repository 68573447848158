import React from 'react';
import Navbar from '../../UIComponents/Navbar/Navbar';
import { useSidebar } from '../../../context/sidebar/SidebarContext';
import { displaySidebar } from '../../../context/sidebar/SidebarReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddressDropdown from '../../UIComponents/AddressDropdown';
import UserAvatar from '../../Controls/UserAvatar';
import cx from 'classnames';
import './TopNavbar.scss'

type TopNavbarProps = {
    noSidebar?: Boolean
    addressDropdown?: Boolean
    userAvatar?: Boolean
    largeLogo?: Boolean
}

const TopNavbar: React.FC<TopNavbarProps> = ({ noSidebar, addressDropdown = false, userAvatar = false, largeLogo = false }) => {
    const { state: sidebar, dispatch: sidebarDispatch } = useSidebar();

    return <div className='c-topnavbar'>
        <Navbar position="relative" transparent showNavbar={false} >
            <div className="navbar-wrapper">
                {noSidebar &&
                    <a id="top-nav-bar-large-logo-anchor" className="navbar-brand" href="/">
                        <img src={require('@assets/team-card-logo--offerpad.svg')} className={cx('img-fluid', { 'large-logo': largeLogo })} />
                    </a>
                }
                {!noSidebar &&
                    <>
                        <FontAwesomeIcon
                            id="top-nav-bar-show-sidebar-icon"
                            icon="bars"
                            className="fa-lg menu-icon"
                            onClick={() => displaySidebar(sidebarDispatch, !sidebar.showSidebar)}
                        />
                        <a id="top-nav-bar-large-logo-anchor-bis" className="navbar-brand" href="/">
                            <img src={require('@assets/op-logo.svg')} className={cx('img-fluid', 'pl-3', 'pr-3', { 'large-logo': largeLogo })} width="160" />
                        </a>
                    </>
                }
                <div className="navbar-toolbar">
                    {userAvatar && <div className="user-avatar-wrapper">
                        <UserAvatar size="md" />
                    </div>}
                    {addressDropdown && <AddressDropdown />}
                </div>
            </div>
        </Navbar >
    </div>
}

export default TopNavbar;