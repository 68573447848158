import * as RequestService from '../../services/RequestService/request.service';
import { RequestSummary } from '../../services/RequestService/types';

export enum Actions {
    REQUEST_BEGIN = 'requestBegin',
    GET_HOMES_COMPLETE = 'loadAllComplete',
    REQUEST_ERROR = 'requestError'
}
export type Action = { type: Actions; data?: any };
export type State = {
    requests: Array<RequestSummary>,
    loading: boolean,
    error: boolean,
    loaded: boolean
};
export type Dispatch = (action: Action) => void;
export const initialState: State = {
    requests: [],
    loading: false,
    error: false,
    loaded: false
};

export const requestReducer = (state: State, action: Action) => {
    switch (action.type) {
        case Actions.REQUEST_BEGIN:
            return { ...state, loading: true };
        case Actions.GET_HOMES_COMPLETE:
            return {
                ...state,
                loading: false,
                requests: action.data,
                error: false,
                loaded: true
            };
        case Actions.REQUEST_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                loaded: true
            };
        default:
            return state;
    }
};

export const getHomes = async (dispatch: Dispatch) => {
    dispatch({ type: Actions.REQUEST_BEGIN });
    try {
        const requests = await RequestService.loadAll();
        dispatch({ type: Actions.GET_HOMES_COMPLETE, data: requests.data });
    } catch {
        dispatch({ type: Actions.REQUEST_ERROR });
    }
};

export const preOfferSteps = [
    'NewRequest',
    'OfferInProgress',
    'PresentOffer',
    'OfferReview',
    'RenewalRequest',
    'DiscoveryRequired',
    'PreliminaryOfferSent'
];
