import apiClient from '../../clients/apiClient';
import { getAuthHeaders } from '../AuthService/auth.service';
import { DispositionOffer, DispositionSummary } from './types';

const apiManagementUrl = process.env.RAZZLE_API_MANAGEMENT_URL;
const dispositionoffersEndpoint = () => {
    apiClient.defaults.baseURL = `${apiManagementUrl}/api/customer/v2/dispositionoffers/`;
    return apiClient;
};

export const getDisposition = (offerId: string) => dispositionoffersEndpoint().get(`${offerId}/portal-get`, getAuthHeaders());
export const getDispositions = () => dispositionoffersEndpoint().get<Array<DispositionSummary>>('portal-get-all', getAuthHeaders());
export const counterOffer = (offerId: string, offer: DispositionOffer) => dispositionoffersEndpoint().put(`${offerId}/portal-counter`, offer, getAuthHeaders());
export const acceptOffer = (offerId: string) => dispositionoffersEndpoint().post(`${offerId}/accept`, null, getAuthHeaders());
export const declineOffer = (offerId: string, message: string) => dispositionoffersEndpoint().post(`${offerId}/decline`, {
    DeclinedNotes: message
}, getAuthHeaders());
