import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import cx from 'classnames';
import styles from './PropertyTile.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Property } from '../../../services/RequestService/types'
import { getStreetView } from '../../../services/MapsService/maps.service';
import { DispositionSummary } from '../../../services/DispositionService/types';

export enum Products {
    FLEX = 'FLEX',
    EXPRESS = 'EXPRESS'
}

export type buyer = {
    firstName: string;
    lastName: string;
    link: string;
    status: string;
}

type PropertyTileProps = {
    link: string;
    property: Property;
    status?: string;
    disabled?: boolean;
    active?: boolean;
    imageUrl?: string;
    buyers?: Array<buyer>;
    product?: Products;
}

const PropertyTile: React.FC<PropertyTileProps> = (props) => {
    const {
        link,
        imageUrl,
        property,
        disabled = false,
        active = true,
        buyers = [],
        status,
        product = null,
    } = props;

    const [streetView, setStreetView] = useState<string>(null);
    const [showBuyers, setShowBuyers] = useState<boolean>(false);
    useEffect(() => {
        if (!imageUrl) {
            getStreetView(property.normalizedAddress).then((streetView) => setStreetView(streetView));
        }
    }, [property.normalizedAddress, imageUrl])

    const backGroundImage = imageUrl || streetView || require('@assets/stub-house.svg');

    if (showBuyers && buyers?.length > 1) {
        return <div className={styles['select-buyer']}>
            <div className={styles['title']}>
                Select a Buyer
                <FontAwesomeIcon className={styles.icons}
                    icon={['fas', 'times']} onClick={() => setShowBuyers(false)} />
            </div>
            {buyers.map(buyer => <Link
                className={styles['buyer']}
                to={buyer.link}
            >
                <div className={styles['bubble']} />
                <div className={styles['buyer-info']}>
                    <div>
                        {`${buyer.firstName} ${buyer.lastName}`}
                    </div>
                    <span>Status:</span>{` ${buyer.status}`}
                </div>
            </Link>)}
        </div>
    }

    return <Link
        to={buyers?.length > 0 ? '#' : link}
        onClick={(e) => {
            if (disabled) {
                e.preventDefault();
                return;
            }
            if (buyers?.length > 0) {
                setShowBuyers(true);
            }
        }}
        data-qa="`home-list-item-${home.acquisitionId}`"
        className={cx(styles[disabled ? 'c-homelist__disabled' : 'c-homelist__link'])}
    >
        <div
            className={cx(
                styles['c-homelist__home'], 
                styles[disabled ? 'c-homelist__disabled' : null]
                )}
        >
            <div
                className={styles['c-homelist__image']}
                style={{
                    backgroundImage: `url("${backGroundImage}")`,
                    backgroundSize: imageUrl || streetView
                        ? 'cover'
                        : 'contain',
                    filter: `brightness(${disabled ? '50%' : '100%'})`,
                }}
            >
                {
                    status &&
                    product !== Products.FLEX &&
                    <div className={cx(!active || !status ? styles['no-status'] : styles['status'])}>
                        {status}
                    </div>
                }
            </div>
            {
                !active &&
                <div className={styles['c-homelist__not-active']}>
                    <FontAwesomeIcon icon={['far', 'exclamation-triangle']} />
                    Request Not Active
                </div>
            }
            <div className={styles['c-homelist__details']}>
                <span className={styles['c-homelist__address']}>
                    {property.streetAddress.toLowerCase()}
                </span>
                <span className={styles['c-homelist__locality']}>
                    {`${property.city.toLowerCase()}, ${property.state}, ${property.zipCode}`}
                </span>
            </div>
            {
                product &&
                <div className={cx(styles['product'], styles[product === Products.FLEX ? 'flex' : 'express'])}>
                    {product === Products.FLEX ? 'FLEX' : 'EXPRESS'}
                </div>
            }

        </div>
    </Link>
}
export const dispositionToProperty = (disposition: DispositionSummary): Property => {
    return {
        fullAddress: disposition.propertyAddress,
        normalizedAddress: disposition.propertyAddress,
        streetAddress: disposition.propertyAddress,
        city: disposition.propertyCity,
        state: disposition.propertyState,
        zipCode: disposition.propertyZipcode,
        latitude: null,
        longitude: null,
        timeZoneId: null,
    }
}

export default PropertyTile;