import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import DropdownPaper from '../UIComponents/DropdownPaper';
import TokenService from '../../services/token.service';
import { AuthService } from '../../services/AuthService/auth.service';
import { getInfo } from '../../context/user/UserReducer';
import { useUser } from '../../context/user/UserContext';
import './UserAvatar.scss';
import { path, RouteName } from '../../routing/Routes';

type UserAvatarProps = {
    size?: string,
    clickable?: boolean
}

const UserAvatar: React.FC<UserAvatarProps> = ({ size, clickable = true }) => {
    const { state: user, dispatch: userDispatch } = useUser();
    const isLoggedIn = TokenService.getToken();

    useEffect(() => {
        if (isLoggedIn && user.loginToken !== isLoggedIn) {
            getInfo(userDispatch, isLoggedIn);
        }
    }, [isLoggedIn]);

    const logout = () => {
        AuthService.logout();
        // we want to use window.location.href instead of history.push since we want to app the refetched
        // this causes all the context values to be reset to there initial values
        window.location.href = (path(RouteName.Login));
    }

    const avatarForeground = clickable ? 'ec7625' : 'ffffff';
    const avatarBackground = clickable ? 'ffffff' : '475D78';


    const avatarUrl = isLoggedIn ?
        `https://ui-avatars.com/api/?bold=true&color=${avatarForeground}&background=${avatarBackground}&format=svg&name=${user.firstName}+${user.lastName}` : ''

    if (!isLoggedIn) {
        return null;
    }

    if (!clickable) {
        return <div className={`user-avatar ${size || 'sm'}-size`}>
            <div className="dropdown-toggle no-caret">
                <img src={avatarUrl} className="avatar no-clickable" />
            </div>
        </div>
    }

    return <div className={`user-avatar ${size || 'sm'}-size`}>
        <DropdownPaper position="left"
            title={
                <div className="dropdown-toggle no-caret" data-toggle="dropdown">
                    <img src={avatarUrl} className="avatar" />
                </div>
            }>
            <Link id="user-avatar-change-password" to={path(RouteName.ChangePassword)} className="dropdown-item">Change Password</Link>
            <Link id="user-avatar-profile" to={path(RouteName.Profile)} className="dropdown-item">Account Settings</Link>
            <a id="user-avatar-2933" className="dropdown-item" onClick={logout}>Logout</a>
        </DropdownPaper>
    </div>
}

export default UserAvatar;