import React, { useState } from 'react';
import { Wizard, WizardStep, useStepController } from './Wizard/Wizard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOffer } from '../../../context/offer/OfferContext';
import { ConnectionType } from '../../../services/FormsService/models/ConnectionType';
import { FormType } from '../../../services/FormsService/models/FormType';
import * as FormService from '../../../services/FormsService/forms.service';
import { FormRadioGrid, FormCheck } from 'floorplan/components/Form';
import { FormControl } from 'floorplan/components/v2/Form';
import { Button } from 'floorplan/components/v2/Button';
import TokenService from '../../../services/token.service';
import cx from 'classnames';

import './SurveyContent.scss';

const UtilitiesFormContent = (props) => {
    const { state: { offerState } } = useOffer();
    const stepController = useStepController();
    const [formSucceeded, updateFormSucceeded] = useState(false);
    const [formFailed, updateFormFailed] = useState(false);
    const [submitting, updateSubmitting] = useState(false);

    const solarPanels = offerState.request?.solarPanels;
    const solarCompanyName = offerState.request?.leasedSolarCompany;
    const solarPanelsLeased = 'Leased (Monthly or quarterly lease payment to a solar company, but the panels are owned by the company)';
    const solarPanelsFinanced = 'Financed (Regular payments are made to a solar or finance company, but the panels are owned by homeowners)';
    const solarPanelsOwned = 'Owned (No payments are made and solar panels are owned outright)';
    const mailDirectlyAtHome = 'Directly to home (mailbox in front of home, mail slot)';
    const mailAtCentralArea = 'Central area / cluster mailboxes in community';
    const mailAtPostOffice = 'Must pick up mail at the post office';

    const initSolarPanelsOwnership = (solarPanels) => {
        switch (solarPanels) {
            case 'Leased':
                return solarPanelsLeased;
            case 'Financed':
                return solarPanelsFinanced;
            case 'Owned':
                return solarPanelsOwned;
            default:
                return '';
        }
    }

    const [form, updateForm] = useState({
        primaryServicesElectric: '',
        solarPanelsInstalled: solarPanels === 'None' ? 'No' : 'Yes',
        solarPanelsOwnership: initSolarPanelsOwnership(solarPanels),
        solarCompanyName: solarCompanyName || '',
        solarFinancingCompanyName: '',
        solarServicingCompanyName: '',
        homeHasGasService: '',
        gasCompanyName: '',
        gasCompanyNameNotSure: false,
        septicSystemPresent: '',
        septicCompanyName: '',
        septicCompanyNameNotSure: false,
        sewerProvider: '',
        sewerProviderNotSure: false,
        waterWellPresent: offerState.request?.hasWell ? 'Yes' : 'No',
        waterCompany: '',
        waterCompanyNotSure: false,
        waterWellShared: offerState.request?.isWellShared ? 'Yes' : 'No',
        garbageCollectionServicePresent: '',
        garbageCompany: '',
        garbageCompanyNotSure: false,
        garbageDisposalMoreInfo: '',
        garbageDay: '',
        garbageDayNotSure: false,
        recyclingServicesPresent: '',
        recyclingCompany: '',
        recyclingCompanySameAsGarbage: false,
        recyclingCompanyNotSure: false,
        recyclingDay: '',
        recyclingDayNotSure: false,
        mailDeliveredWhere: '',
        mailboxLocation: '',
        mailboxLocationNotSure: false,
        mailboxNumber: '',
        mailboxNumberNotSure: false,
        assignedParkingPresent: '',
        parkingNumber: '',
        parkingNumberNotSure: false,
        subdivisionName: '',
        notInSubdivision: false,
        subdivisionNameNotSure: false,
        subdivisionGated: offerState.request?.inGatedCommunity ? 'Yes' : 'No',
        gateCode: '',
        gateCodeNotSure: false,
        storagePresent: '',
        storageNumber: '',
        storageNumberNotSure: false,
        finalComments: ''
    });

    const [formErrors, updateFormErrors] = useState({
        primaryServicesElectric: false,
        solarPanelsInstalled: false,
        solarPanelsOwnership: false,
        solarCompanyName: false,
        solarFinancingCompanyName: false,
        solarServicingCompanyName: false,
        homeHasGasService: false,
        gasCompanyName: false,
        gasCompanyNameNotSure: false,
        septicSystemPresent: false,
        septicCompanyName: false,
        septicCompanyNameNotSure: false,
        sewerProvider: false,
        sewerProviderNotSure: false,
        waterWellPresent: false,
        waterCompany: false,
        waterCompanyNotSure: false,
        waterWellShared: false,
        garbageCollectionServicePresent: false,
        garbageCompany: false,
        garbageCompanyNotSure: false,
        garbageDay: false,
        garbageDayNotSure: false,
        recyclingServicesPresent: false,
        recyclingCompany: false,
        recyclingCompanyNotSure: false,
        recyclingDay: false,
        recyclingDayNotSure: false,
        mailDeliveredWhere: false,
        mailboxLocation: false,
        mailboxLocationNotSure: false,
        mailboxNumber: false,
        mailboxNumberNotSure: false,
        assignedParkingPresent: false,
        parkingNumber: false,
        parkingNumberNotSure: false,
        subdivisionName: false,
        notInSubdivision: false,
        subdivisionNameNotSure: false,
        subdivisionGated: false,
        gateCode: false,
        gateCodeNotSure: false,
        storagePresent: false,
        storageNumber: false,
        storageNumberNotSure: false,
    });

    const updateField = (field, value) => {
        updateFormErrors({ ...formErrors, [field]: false });
        if (value === true && field.endsWith('NotSure')) {
            /* When a 'not sure' is checked, reset the corresponding text input to empty */
            updateForm({ ...form, [field]: value, [field.split('NotSure')[0]]: '' });
        }
        else {
            updateForm({ ...form, [field]: value });
        }
    }

    const updateFields = (fields, values) => {
        const newForm = form;
        fields.forEach((field, index) => {
            newForm[field] = values[index];
            updateFormErrors({ ...formErrors, [field]: false });
        });
        updateForm(newForm);
    }

    const validate = (field) => {
        if (form[field] || form[field] === 0) {
            stepController.incrementStep();
        }
        else {
            updateFormErrors({ ...formErrors, [field]: true });
        }
    }

    const validateAtLeastOne = (fields) => {
        fields.forEach(field => {
            if (form[field] || form[field] === 0) {
                stepController.incrementStep();
                return;
            }
        });
        fields.forEach(field => {
            updateFormErrors({ ...formErrors, [field]: true });
        });
    }

    const navigateAway = () => {
        const loggedIn = TokenService.getToken();
        if (loggedIn) {
            window.location.href= `${process.env.RAZZLE_CONNECT_URL}/sell/overview?transactionId=${props.acquisitionId}`
        } else {
            window.location.href = `https://www.offerpad.com/`;
        }
    };

    const submitForm = () => {
        if (submitting) {
            return;
        }
        updateSubmitting(true);
        FormService.submitForm({
            connectionId: props.acquisitionId,
            connectionType: ConnectionType.HelixEntity,
            formType: FormType.UtilitiesForm,
            items: Object.keys(form).map((key) => {
                return {
                    name: key,
                    value: form[key],
                };
            }),
        })
            .then(
                () => {
                    updateFormSucceeded(true);
                },
                () => {
                    updateFormFailed(true);
                })
            .catch(() => {
                updateFormFailed(true);
            })
            .finally(() => {
                updateSubmitting(false);
                stepController.incrementStep();
            });
    };

    const NavigationButtons = () => {
        return (
            <div className={cx({ 'btn-wrapper': true, 'd-none': submitting })} >
                {
                    (!formSucceeded && !formFailed && stepController.currentStep > 0) ?
                        <button
                            id="utilities-form-back"
                            className="btn-survey-previous"
                            onClick={stepController.decrementStep}
                        >
                            <FontAwesomeIcon icon={['fas', 'long-arrow-left']} />
                            {' Back'}
                        </button>
                        :
                        <button id="utilities-form-close" className="btn-survey-previous" onClick={navigateAway}>
                            <FontAwesomeIcon icon={['fas', 'times']} />
                            {' Close'}
                        </button>
                }
            </div>
        )
    };

    return (
        <div>
            <NavigationButtons />
            <Wizard stepController={stepController} >
                {/* 
                Initial section
                */}
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-main">
                            <div className="pt-4 pb-4 center-on-mobile">
                                <img src={require('@assets/op-logo.svg')} className="logo" />
                            </div>
                            <h3 className="d-md-block smaller">
                                Please take 5 minutes to tell us about the services for your home!
                            </h3>
                            <div className="btn-wrapper pt-4">
                                <Button id="utilities-form-start" large onClick={stepController.incrementStep}>
                                    Start
                                </Button>
                            </div>
                        </div>
                    </div>
                </WizardStep>

                {/* 
                Core section
                */}
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-main">
                            <h3 className="d-md-block smaller">
                                Primary Services | Electric
                            </h3>
                            <div className="highlight">
                                Who provides electricity to the property?
                            </div>
                            <div className="u-spacer-m" />
                            <div>
                                <FormControl
                                    compact
                                    id='primaryServicesElectric'
                                    name='primaryServicesElectric'
                                    value={form.primaryServicesElectric}
                                    hasError={formErrors.primaryServicesElectric}
                                    errorText={formErrors.primaryServicesElectric ? 'Please make a selection' : ''}
                                    onChange={e => updateField('primaryServicesElectric', e.target.value)}
                                    placeholder='Please enter the company name'
                                />
                                <div className="btn-wrapper pt-5">
                                    <Button
                                        id="utilities-form-primaryServicesElectric"
                                        large
                                        disabled={!form.primaryServicesElectric}
                                        onClick={() => validate('primaryServicesElectric')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-main">
                            <h3 className="d-md-block smaller">
                                Primary Services | Solar
                            </h3>
                            <div className="highlight">
                                Does the home have solar panels installed?
                            </div>
                            <div className="u-spacer-m" />
                            <div>
                                <FormControl
                                    compact
                                    id='solarPanelsInstalled'
                                    className='simple-radios'
                                    name='solarPanelsInstalled'
                                    value={form.solarPanelsInstalled}
                                    hasError={formErrors.solarPanelsInstalled}
                                    errorText={formErrors.solarPanelsInstalled ? 'Please make a selection' : ''}
                                    onChange={e => updateField('solarPanelsInstalled', e.target.value)}
                                >
                                    <FormRadioGrid
                                        columns={1}
                                        bp='desktop'
                                    >
                                        <FormRadioGrid.Option
                                            id='solarPanelsInstalled1'
                                            key='solarPanelsInstalled1'
                                            label='Yes'
                                            value='Yes'
                                            checked={form.solarPanelsInstalled === 'Yes'}
                                        />
                                        <FormRadioGrid.Option
                                            id='solarPanelsInstalled2'
                                            key='solarPanelsInstalled2'
                                            label='No'
                                            value='No'
                                            checked={form.solarPanelsInstalled === 'No'}
                                        />
                                    </FormRadioGrid>
                                </FormControl>
                                <div className="btn-wrapper pt-2">
                                    <Button
                                        id="utilities-form-solarPanelsInstalled"
                                        large
                                        disabled={!form.solarPanelsInstalled}
                                        onClick={() => validate('solarPanelsInstalled')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </div>
                </WizardStep>
                {
                    form.solarPanelsInstalled === 'Yes' &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    Primary Services | Solar
                                </h3>
                                <div className="highlight">
                                    Tell us more about the ownership of your solar panels
                                </div>
                                <div className="u-spacer-m" />
                                <div>
                                    <FormControl
                                        compact
                                        id='solarPanelsOwnership'
                                        className='simple-radios'
                                        name='solarPanelsOwnership'
                                        value={form.solarPanelsOwnership}
                                        hasError={formErrors.solarPanelsOwnership}
                                        errorText={formErrors.solarPanelsOwnership ? 'Please make a selection' : ''}
                                        onChange={e => updateField('solarPanelsOwnership', e.target.value)}
                                    >
                                        <FormRadioGrid
                                            columns={1}
                                            bp='desktop'
                                        >
                                            <FormRadioGrid.Option
                                                id='solarPanelsOwnership1'
                                                key='solarPanelsOwnership1'
                                                label={solarPanelsLeased}
                                                value={solarPanelsLeased}
                                                checked={form.solarPanelsOwnership === solarPanelsLeased}
                                            />
                                            <FormRadioGrid.Option
                                                id='solarPanelsOwnership2'
                                                key='solarPanelsOwnership2'
                                                label={solarPanelsFinanced}
                                                value={solarPanelsFinanced}
                                                checked={form.solarPanelsOwnership === solarPanelsFinanced}
                                            />
                                            <FormRadioGrid.Option
                                                id='solarPanelsOwnership3'
                                                key='solarPanelsOwnership3'
                                                label={solarPanelsOwned}
                                                value={solarPanelsOwned}
                                                checked={form.solarPanelsOwnership === solarPanelsOwned}
                                            />
                                        </FormRadioGrid>
                                    </FormControl>
                                    <div className="btn-wrapper pt-2">
                                        <Button
                                            id="utilities-form-solarPanelsOwnership"
                                            large
                                            disabled={!form.solarPanelsOwnership}
                                            onClick={() => validate('solarPanelsOwnership')}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                {
                    form.solarPanelsInstalled === 'Yes' &&
                    form.solarPanelsOwnership === solarPanelsLeased &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    Primary Services | Solar (Leased)
                                </h3>
                                <div className="highlight">
                                    Name of solar company
                                </div>
                                <div className="u-spacer-m" />
                                <div>
                                    <FormControl
                                        compact
                                        id='solarCompanyName'
                                        name='solarCompanyName'
                                        value={form.solarCompanyName}
                                        hasError={formErrors.solarCompanyName}
                                        errorText={formErrors.solarCompanyName ? 'Please make a selection' : ''}
                                        onChange={e => updateField('solarCompanyName', e.target.value)}
                                        placeholder='Please enter the company name'
                                    />
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="utilities-form-solarCompanyName"
                                            large
                                            disabled={!form.solarCompanyName}
                                            onClick={() => validate('solarCompanyName')}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                {
                    form.solarPanelsInstalled === 'Yes' &&
                    form.solarPanelsOwnership === solarPanelsFinanced &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    Primary Services | Solar (Financed)
                                </h3>
                                <div className="highlight">
                                    Name of company to which payments are made
                                </div>
                                <div className="u-spacer-m" />
                                <div>
                                    <FormControl
                                        compact
                                        id='solarFinancingCompanyName'
                                        name='solarFinancingCompanyName'
                                        value={form.solarFinancingCompanyName}
                                        hasError={formErrors.solarFinancingCompanyName}
                                        errorText={formErrors.solarFinancingCompanyName ? 'Please make a selection' : ''}
                                        onChange={e => updateField('solarFinancingCompanyName', e.target.value)}
                                        placeholder='Please enter the company name'
                                    />
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="utilities-form-solarFinancingCompanyName"
                                            large
                                            disabled={!form.solarFinancingCompanyName}
                                            onClick={() => validate('solarFinancingCompanyName')}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                {
                    form.solarPanelsInstalled === 'Yes' &&
                    form.solarPanelsOwnership === solarPanelsOwned &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    Primary Services | Solar (Owned)
                                </h3>
                                <div className="highlight">
                                    If your owned solar panels are serviced by a company, please provide the company name below.
                                </div>
                                <div className="u-spacer-m" />
                                <div>
                                    <FormControl
                                        compact
                                        id='solarServicingCompanyName'
                                        name='solarServicingCompanyName'
                                        value={form.solarServicingCompanyName}
                                        hasError={formErrors.solarServicingCompanyName}
                                        errorText={formErrors.solarServicingCompanyName ? 'Please make a selection' : ''}
                                        onChange={e => updateField('solarServicingCompanyName', e.target.value)}
                                        placeholder='Please enter the company name'
                                    />
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="utilities-form-solarServicingCompanyName"
                                            large
                                            disabled={!form.solarServicingCompanyName}
                                            onClick={() => validate('solarServicingCompanyName')}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                {
                    form.solarPanelsInstalled === 'Yes' &&
                    form.solarPanelsOwnership === solarPanelsOwned &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    Primary Services | Solar (Owned)
                                </h3>
                                <div>
                                    <p>
                                        That's great.  It will be helpful if you could provide us with any ownership documents
                                        you might have regarding your solar panels.  These will help us and any future buyer of
                                        the home.  If you have these, you can upload them to our Customer Portal at a later time.
                                    </p>
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="utilities-form-solarOwnedInfoSlideNext"
                                            large
                                            onClick={stepController.incrementStep}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-main">
                            <h3 className="d-md-block smaller">
                                Primary Services | Gas
                            </h3>
                            <div className="highlight">
                                Does the home have gas service?
                            </div>
                            <div className="u-spacer-m" />
                            <div>
                                <FormControl
                                    compact
                                    id='homeHasGasService'
                                    className='simple-radios'
                                    name='homeHasGasService'
                                    value={form.homeHasGasService}
                                    hasError={formErrors.homeHasGasService}
                                    errorText={formErrors.homeHasGasService ? 'Please make a selection' : ''}
                                    onChange={e => updateField('homeHasGasService', e.target.value)}
                                >
                                    <FormRadioGrid
                                        columns={1}
                                        bp='desktop'
                                    >
                                        <FormRadioGrid.Option
                                            id='homeHasGasService1'
                                            key='homeHasGasService1'
                                            label='Yes'
                                            value='Yes'
                                            checked={form.homeHasGasService === 'Yes'}
                                        />
                                        <FormRadioGrid.Option
                                            id='homeHasGasService2'
                                            key='homeHasGasService2'
                                            label='No'
                                            value='No'
                                            checked={form.homeHasGasService === 'No'}
                                        />
                                    </FormRadioGrid>
                                </FormControl>
                                <div className="btn-wrapper pt-2">
                                    <Button
                                        id="utilities-form-homeHasGasService"
                                        large
                                        disabled={!form.homeHasGasService}
                                        onClick={() => validate('homeHasGasService')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                {
                    form.homeHasGasService === 'Yes' &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    Primary Services | Gas
                                </h3>
                                <div className="highlight">
                                    What is the name of the gas provider?
                                </div>
                                <div className="u-spacer-m" />
                                <div>
                                    <FormControl
                                        compact
                                        id='gasCompanyName'
                                        name='gasCompanyName'
                                        value={form.gasCompanyName}
                                        hasError={formErrors.gasCompanyName}
                                        errorText={formErrors.gasCompanyName ? 'Please make a selection' : ''}
                                        onChange={e => updateField('gasCompanyName', e.target.value)}
                                        placeholder='Name of gas company'
                                        disabled={form.gasCompanyNameNotSure}
                                    />
                                    <FormControl
                                        compact
                                        id='gasCompanyNameNotSure'
                                        name='gasCompanyNameNotSure'
                                        value={form.gasCompanyName}
                                        isChecklist={true}
                                    >
                                        <FormCheck
                                            id='gasCompanyNameCheckbox'
                                            name='gasCompanyNameCheckbox'
                                            checked={form.gasCompanyNameNotSure}
                                            onChange={e => updateField('gasCompanyNameNotSure', e.target.checked)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            I'm not sure
                                        </FormCheck>
                                    </FormControl>
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="utilities-form-gasCompanyName"
                                            large
                                            disabled={!form.gasCompanyName && !form.gasCompanyNameNotSure}
                                            onClick={() => validateAtLeastOne(['gasCompanyName', 'gasCompanyNameNotSure'])}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-main">
                            <h3 className="d-md-block smaller">
                                Water and Sewer | Septic
                            </h3>
                            <div className="highlight">
                                Does the home have a septic system?
                            </div>
                            <div className="u-spacer-m" />
                            <div>
                                <FormControl
                                    compact
                                    id='septicSystemPresent'
                                    className='simple-radios'
                                    name='septicSystemPresent'
                                    value={form.septicSystemPresent}
                                    hasError={formErrors.septicSystemPresent}
                                    errorText={formErrors.septicSystemPresent ? 'Please make a selection' : ''}
                                    onChange={e => updateField('septicSystemPresent', e.target.value)}
                                >
                                    <FormRadioGrid
                                        columns={1}
                                        bp='desktop'
                                    >
                                        <FormRadioGrid.Option
                                            id='septicSystemPresent1'
                                            key='septicSystemPresent1'
                                            label='Yes'
                                            value='Yes'
                                            checked={form.septicSystemPresent === 'Yes'}
                                        />
                                        <FormRadioGrid.Option
                                            id='septicSystemPresent2'
                                            key='septicSystemPresent2'
                                            label='No'
                                            value='No'
                                            checked={form.septicSystemPresent === 'No'}
                                        />
                                        <FormRadioGrid.Option
                                            id='septicSystemPresent3'
                                            key='septicSystemPresent3'
                                            label={`I'm not sure`}
                                            value={`I'm not sure`}
                                            checked={form.septicSystemPresent === `I'm not sure`}
                                        />
                                    </FormRadioGrid>
                                </FormControl>
                                <div className="btn-wrapper pt-2">
                                    <Button
                                        id="utilities-form-septicSystemPresent"
                                        large
                                        disabled={!form.septicSystemPresent}
                                        onClick={() => validate('septicSystemPresent')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                {
                    form.septicSystemPresent === 'Yes' &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    Water and Sewer | Septic
                                </h3>
                                <div className="highlight">
                                    Who is the company that services the septic system?
                                </div>
                                <div className="u-spacer-m" />
                                <div>
                                    <FormControl
                                        compact
                                        id='septicCompanyName'
                                        name='septicCompanyName'
                                        value={form.septicCompanyName}
                                        hasError={formErrors.septicCompanyName}
                                        errorText={formErrors.septicCompanyName ? 'Please make a selection' : ''}
                                        onChange={e => updateField('septicCompanyName', e.target.value)}
                                        placeholder='Enter Service Provider Name'
                                        disabled={form.septicCompanyNameNotSure}
                                    />
                                    <FormControl
                                        compact
                                        id='septicCompanyNameNotSure'
                                        name='septicCompanyNameNotSure'
                                        value={form.septicCompanyName}
                                        isChecklist={true}
                                    >
                                        <FormCheck
                                            id='septicCompanyNameCheckbox'
                                            name='septicCompanyNameCheckbox'
                                            checked={form.septicCompanyNameNotSure}
                                            onChange={e => updateField('septicCompanyNameNotSure', e.target.checked)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            I'm not sure
                                        </FormCheck>
                                    </FormControl>
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="utilities-form-septicCompanyName"
                                            large
                                            disabled={!form.septicCompanyName && !form.septicCompanyNameNotSure}
                                            onClick={() => validateAtLeastOne(['septicCompanyName', 'septicCompanyNameNotSure'])}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                {
                    form.septicSystemPresent === 'No' &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    Water and Sewer | Sewer
                                </h3>
                                <div className="highlight">
                                    Who is the sewer provider to the home?
                                </div>
                                <div className="u-spacer-m" />
                                <div>
                                    <FormControl
                                        compact
                                        id='sewerProvider'
                                        name='sewerProvider'
                                        value={form.sewerProvider}
                                        hasError={formErrors.sewerProvider}
                                        errorText={formErrors.sewerProvider ? 'Please make a selection' : ''}
                                        onChange={e => updateField('sewerProvider', e.target.value)}
                                        placeholder='Name of sewer provider'
                                        disabled={form.sewerProviderNotSure}
                                    />
                                    <FormControl
                                        compact
                                        id='sewerProviderNotSure'
                                        name='sewerProviderNotSure'
                                        value={form.sewerProvider}
                                        isChecklist={true}
                                    >
                                        <FormCheck
                                            id='sewerProviderCheckbox'
                                            name='sewerProviderCheckbox'
                                            checked={form.sewerProviderNotSure}
                                            onChange={e => updateField('sewerProviderNotSure', e.target.checked)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            I'm not sure
                                        </FormCheck>
                                    </FormControl>
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="utilities-form-sewerProvider"
                                            large
                                            disabled={!form.sewerProvider && !form.sewerProviderNotSure}
                                            onClick={() => validateAtLeastOne(['sewerProvider', 'sewerProviderNotSure'])}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-main">
                            <h3 className="d-md-block smaller">
                                Water and Sewer | Water
                            </h3>
                            <div className="highlight">
                                Does the home have a well?
                            </div>
                            <div className="u-spacer-m" />
                            <div>
                                <FormControl
                                    compact
                                    id='waterWellPresent'
                                    className='simple-radios'
                                    name='waterWellPresent'
                                    value={form.waterWellPresent}
                                    hasError={formErrors.waterWellPresent}
                                    errorText={formErrors.waterWellPresent ? 'Please make a selection' : ''}
                                    onChange={e => updateField('waterWellPresent', e.target.value)}
                                >
                                    <FormRadioGrid
                                        columns={1}
                                        bp='desktop'
                                    >
                                        <FormRadioGrid.Option
                                            id='waterWellPresent1'
                                            key='waterWellPresent1'
                                            label='Yes'
                                            value='Yes'
                                            checked={form.waterWellPresent === 'Yes'}
                                        />
                                        <FormRadioGrid.Option
                                            id='waterWellPresent2'
                                            key='waterWellPresent2'
                                            label='No'
                                            value='No'
                                            checked={form.waterWellPresent === 'No'}
                                        />
                                        <FormRadioGrid.Option
                                            id='waterWellPresent3'
                                            key='waterWellPresent3'
                                            label={`I'm not sure`}
                                            value={`I'm not sure`}
                                            checked={form.waterWellPresent === `I'm not sure`}
                                        />
                                    </FormRadioGrid>
                                </FormControl>
                                <div className="btn-wrapper pt-2">
                                    <Button
                                        id="utilities-form-waterWellPresent"
                                        large
                                        disabled={!form.waterWellPresent}
                                        onClick={() => validate('waterWellPresent')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                {
                    form.waterWellPresent === 'No' &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    Water and Sewer | Water
                                </h3>
                                <div className="highlight">
                                    Who provides water to the property?
                                </div>
                                <div className="u-spacer-m" />
                                <div>
                                    <FormControl
                                        compact
                                        id='waterCompany'
                                        name='waterCompany'
                                        value={form.waterCompany}
                                        hasError={formErrors.waterCompany}
                                        errorText={formErrors.waterCompany ? 'Please make a selection' : ''}
                                        onChange={e => updateField('waterCompany', e.target.value)}
                                        placeholder='Enter name of water company'
                                        disabled={form.waterCompanyNotSure}
                                    />
                                    <FormControl
                                        compact
                                        id='waterCompanyNotSure'
                                        name='waterCompanyNotSure'
                                        value={form.waterCompany}
                                        isChecklist={true}
                                    >
                                        <FormCheck
                                            id='waterCompanyCheckbox'
                                            name='waterCompanyCheckbox'
                                            checked={form.waterCompanyNotSure}
                                            onChange={e => updateField('waterCompanyNotSure', e.target.checked)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            I'm not sure
                                        </FormCheck>
                                    </FormControl>
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="utilities-form-waterCompany"
                                            large
                                            disabled={!form.waterCompany && !form.waterCompanyNotSure}
                                            onClick={() => validateAtLeastOne(['waterCompany', 'waterCompanyNotSure'])}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                {
                    form.waterWellPresent === 'Yes' &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    Water and Sewer | Water
                                </h3>
                                <div className="highlight">
                                    Is the well shared?
                                </div>
                                <div className="u-spacer-m" />
                                <div>
                                    <FormControl
                                        compact
                                        id='waterWellShared'
                                        className='simple-radios'
                                        name='waterWellShared'
                                        value={form.waterWellShared}
                                        hasError={formErrors.waterWellShared}
                                        errorText={formErrors.waterWellShared ? 'Please make a selection' : ''}
                                        onChange={e => updateField('waterWellShared', e.target.value)}
                                    >
                                        <FormRadioGrid
                                            columns={1}
                                            bp='desktop'
                                        >
                                            <FormRadioGrid.Option
                                                id='waterWellShared1'
                                                key='waterWellShared1'
                                                label='Yes'
                                                value='Yes'
                                                checked={form.waterWellShared === 'Yes'}
                                            />
                                            <FormRadioGrid.Option
                                                id='waterWellShared2'
                                                key='waterWellShared2'
                                                label='No'
                                                value='No'
                                                checked={form.waterWellShared === 'No'}
                                            />
                                        </FormRadioGrid>
                                    </FormControl>
                                    <div className="btn-wrapper pt-2">
                                        <Button
                                            id="utilities-form-waterWellShared"
                                            large
                                            disabled={!form.waterWellShared}
                                            onClick={() => validate('waterWellShared')}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                {
                    form.waterWellPresent === 'Yes' &&
                    form.waterWellShared === 'Yes' &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    Water and Sewer | Water (Shared Well)
                                </h3>
                                <div>
                                    <p>
                                        Thanks for the info.  We will need a copy of the shared well agreement.  Your Transaction Manager will request this from you at a later time.
                                    </p>
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="utilities-form-well-agreement-info"
                                            large
                                            onClick={stepController.incrementStep}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-main">
                            <h3 className="d-md-block smaller">
                                Garbage &amp; Recycling | Garbage
                            </h3>
                            <div className="highlight">
                                Does the property have garbage collection services?
                            </div>
                            <div className="u-spacer-m" />
                            <div>
                                <FormControl
                                    compact
                                    id='garbageCollectionServicePresent'
                                    className='simple-radios'
                                    name='garbageCollectionServicePresent'
                                    value={form.garbageCollectionServicePresent}
                                    hasError={formErrors.garbageCollectionServicePresent}
                                    errorText={formErrors.garbageCollectionServicePresent ? 'Please make a selection' : ''}
                                    onChange={e => updateField('garbageCollectionServicePresent', e.target.value)}
                                >
                                    <FormRadioGrid
                                        columns={1}
                                        bp='desktop'
                                    >
                                        <FormRadioGrid.Option
                                            id='garbageCollectionServicePresent1'
                                            key='garbageCollectionServicePresent1'
                                            label='Yes'
                                            value='Yes'
                                            checked={form.garbageCollectionServicePresent === 'Yes'}
                                        />
                                        <FormRadioGrid.Option
                                            id='garbageCollectionServicePresent2'
                                            key='garbageCollectionServicePresent2'
                                            label='No'
                                            value='No'
                                            checked={form.garbageCollectionServicePresent === 'No'}
                                        />
                                    </FormRadioGrid>
                                </FormControl>
                                <div className="btn-wrapper pt-2">
                                    <Button
                                        id="utilities-form-garbageCollectionServicePresent"
                                        large
                                        disabled={!form.garbageCollectionServicePresent}
                                        onClick={() => validate('garbageCollectionServicePresent')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                {
                    form.garbageCollectionServicePresent === 'Yes' &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    Garbage &amp; Recycling | Garbage
                                </h3>
                                <div className="highlight">
                                    Who provides garbage service to the home?
                                </div>
                                <div className="u-spacer-m" />
                                <div>
                                    <FormControl
                                        compact
                                        id='garbageCompany'
                                        name='garbageCompany'
                                        value={form.garbageCompany}
                                        hasError={formErrors.garbageCompany}
                                        errorText={formErrors.garbageCompany ? 'Please make a selection' : ''}
                                        onChange={e => updateField('garbageCompany', e.target.value)}
                                        placeholder='Enter Service Provider Name'
                                        disabled={form.garbageCompanyNotSure}
                                    />
                                    <FormControl
                                        compact
                                        id='garbageCompanyNotSure'
                                        name='garbageCompanyNotSure'
                                        value={form.garbageCompany}
                                        isChecklist={true}
                                    >
                                        <FormCheck
                                            id='garbageCompanyCheckbox'
                                            name='garbageCompanyCheckbox'
                                            checked={form.garbageCompanyNotSure}
                                            onChange={e => updateField('garbageCompanyNotSure', e.target.checked)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            I'm not sure
                                        </FormCheck>
                                    </FormControl>
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="utilities-form-garbageCompany"
                                            large
                                            disabled={!form.garbageCompany && !form.garbageCompanyNotSure}
                                            onClick={() => validateAtLeastOne(['garbageCompany', 'garbageCompanyNotSure'])}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                {
                    form.garbageCollectionServicePresent === 'Yes' &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    Garbage &amp; Recycling | Garbage
                                </h3>
                                <div className="highlight">
                                    What day is the garbage collected on?
                                </div>
                                <div className="u-spacer-m" />
                                <div>
                                    <FormControl
                                        compact
                                        id='garbageDay'
                                        name='garbageDay'
                                        value={form.garbageDay}
                                        hasError={formErrors.garbageDay}
                                        errorText={formErrors.garbageDay ? 'Please make a selection' : ''}
                                        onChange={e => updateField('garbageDay', e.target.value)}
                                        placeholder='Select an option'
                                        disabled={form.garbageDayNotSure}
                                    >
                                        <select>
                                            <option hidden value='' label="Select an option" >
                                                Select an option
                                            </option>
                                            <option label="Monday" value="Monday" >
                                                Monday
                                            </option>
                                            <option label="Tuesday" value="Tuesday" >
                                                Tuesday
                                            </option>
                                            <option label="Wednesday" value="Wednesday" >
                                                Wednesday
                                            </option>
                                            <option label="Thursday" value="Thursday" >
                                                Thursday
                                            </option>
                                            <option label="Friday" value="Friday" >
                                                Friday
                                            </option>
                                            <option label="Saturday" value="Saturday" >
                                                Saturday
                                            </option>
                                            <option label="Sunday" value="Sunday" >
                                                Sunday
                                            </option>
                                        </select>
                                    </FormControl>
                                    <FormControl
                                        compact
                                        id='garbageDayNotSure'
                                        name='garbageDayNotSure'
                                        value={form.garbageDay}
                                        isChecklist={true}
                                    >
                                        <FormCheck
                                            id='garbageDayCheckbox'
                                            name='garbageDayCheckbox'
                                            checked={form.garbageDayNotSure}
                                            onChange={e => updateField('garbageDayNotSure', e.target.checked)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            I'm not sure
                                        </FormCheck>
                                    </FormControl>
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="utilities-form-garbageDay"
                                            large
                                            disabled={!form.garbageDay && !form.garbageDayNotSure}
                                            onClick={() => validateAtLeastOne(['garbageDay', 'garbageDayNotSure'])}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                {
                    form.garbageCollectionServicePresent === 'No' &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    Garbage &amp; Recycling | Garbage
                                </h3>
                                <div className="highlight">
                                    Please tell us more about the disposal of your garbage
                                </div>
                                <div className="u-spacer-m" />
                                <div>
                                    <FormControl
                                        compact
                                        id='garbageDisposalMoreInfo'
                                        name='garbageDisposalMoreInfo'
                                        placeholder="Provide a brief overview of the garbage disposal process for your area"
                                        value={form.garbageDisposalMoreInfo}
                                        onChange={(e) =>
                                            updateForm({
                                                ...form, garbageDisposalMoreInfo: e.target.value
                                            })}
                                    >
                                        <textarea
                                            maxLength={200}
                                            rows={4}
                                        ></textarea>
                                    </FormControl>
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="utilities-form-garbageDisposalMoreInfo"
                                            large
                                            onClick={stepController.incrementStep}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-main">
                            <h3 className="d-md-block smaller">
                                Garbage &amp; Recycling | Recycling
                            </h3>
                            <div className="highlight">
                                Does the home have recycling services?
                            </div>
                            <div className="u-spacer-m" />
                            <div>
                                <FormControl
                                    compact
                                    id='recyclingServicesPresent'
                                    className='simple-radios'
                                    name='recyclingServicesPresent'
                                    value={form.recyclingServicesPresent}
                                    hasError={formErrors.recyclingServicesPresent}
                                    errorText={formErrors.recyclingServicesPresent ? 'Please make a selection' : ''}
                                    onChange={e => updateField('recyclingServicesPresent', e.target.value)}
                                >
                                    <FormRadioGrid
                                        columns={1}
                                        bp='desktop'
                                    >
                                        <FormRadioGrid.Option
                                            id='recyclingServicesPresent1'
                                            key='recyclingServicesPresent1'
                                            label='Yes'
                                            value='Yes'
                                            checked={form.recyclingServicesPresent === 'Yes'}
                                        />
                                        <FormRadioGrid.Option
                                            id='recyclingServicesPresent2'
                                            key='recyclingServicesPresent2'
                                            label='No'
                                            value='No'
                                            checked={form.recyclingServicesPresent === 'No'}
                                        />
                                        <FormRadioGrid.Option
                                            id='recyclingServicesPresent3'
                                            key='recyclingServicesPresent3'
                                            label={`I'm not sure`}
                                            value={`I'm not sure`}
                                            checked={form.recyclingServicesPresent === `I'm not sure`}
                                        />
                                    </FormRadioGrid>
                                </FormControl>
                                <div className="btn-wrapper pt-2">
                                    <Button
                                        id="utilities-form-recyclingServicesPresent"
                                        large
                                        disabled={!form.recyclingServicesPresent}
                                        onClick={() => validate('recyclingServicesPresent')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                {
                    form.recyclingServicesPresent === 'Yes' &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    Garbage &amp; Recycling | Recycling
                                </h3>
                                <div className="highlight">
                                    Who provides recycling services to the home?
                                </div>
                                <div className="u-spacer-m" />
                                <div>
                                    <FormControl
                                        compact
                                        id='recyclingCompany'
                                        name='recyclingCompany'
                                        value={form.recyclingCompany}
                                        hasError={formErrors.recyclingCompany}
                                        errorText={formErrors.recyclingCompany ? 'Please make a selection' : ''}
                                        onChange={e => updateField('recyclingCompany', e.target.value)}
                                        placeholder='Enter name of the recycling service provider'
                                        disabled={form.recyclingCompanySameAsGarbage || form.recyclingCompanyNotSure}
                                    />
                                    <FormControl
                                        compact
                                        id='recyclingCompanyCheckboxes'
                                        name='recyclingCompanyCheckboxes'
                                        value={form.recyclingCompany}
                                        isChecklist={true}
                                    >
                                        {
                                            form.garbageCompany !== '' &&
                                            <FormCheck
                                                id='recyclingCompanyCheckbox1'
                                                name='recyclingCompanyCheckbox1'
                                                checked={form.recyclingCompanySameAsGarbage}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        updateFields(['recyclingCompany', 'recyclingCompanySameAsGarbage'], [form.garbageCompany, true])
                                                    }
                                                    else {
                                                        updateField('recyclingCompanySameAsGarbage', false);
                                                    }
                                                }}
                                                style={{ cursor: 'pointer' }}
                                                disabled={form.recyclingCompanyNotSure}
                                            >
                                                Same as garbage service provider
                                            </FormCheck>
                                        }
                                        <FormCheck
                                            id='recyclingCompanyCheckbox2'
                                            name='recyclingCompanyCheckbox2'
                                            checked={form.recyclingCompanyNotSure}
                                            onChange={e => updateField('recyclingCompanyNotSure', e.target.checked)}
                                            style={{ cursor: 'pointer' }}
                                            disabled={form.recyclingCompanySameAsGarbage}
                                        >
                                            I'm not sure
                                        </FormCheck>
                                    </FormControl>
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="utilities-form-recyclingCompany"
                                            large
                                            disabled={!form.recyclingCompany && !form.recyclingCompanyNotSure}
                                            onClick={() => validateAtLeastOne(['recyclingCompany', 'recyclingCompanySameAsGarbage', 'recyclingCompanyNotSure'])}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                {
                    form.recyclingServicesPresent === 'Yes' &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    Garbage &amp; Recycling | Recycling
                                </h3>
                                <div className="highlight">
                                    What day is recycling collected on?
                                </div>
                                <div className="u-spacer-m" />
                                <div>
                                    <FormControl
                                        compact
                                        id='recyclingDay'
                                        name='recyclingDay'
                                        value={form.recyclingDay}
                                        hasError={formErrors.recyclingDay}
                                        errorText={formErrors.recyclingDay ? 'Please make a selection' : ''}
                                        onChange={e => updateField('recyclingDay', e.target.value)}
                                        placeholder='Select an option'
                                        disabled={form.recyclingDayNotSure}
                                    >
                                        <select>
                                            <option hidden value='' label="Select an option" >
                                                Select an option
                                            </option>
                                            <option label="Monday" value="Monday" >
                                                Monday
                                            </option>
                                            <option label="Tuesday" value="Tuesday" >
                                                Tuesday
                                            </option>
                                            <option label="Wednesday" value="Wednesday" >
                                                Wednesday
                                            </option>
                                            <option label="Thursday" value="Thursday" >
                                                Thursday
                                            </option>
                                            <option label="Friday" value="Friday" >
                                                Friday
                                            </option>
                                            <option label="Saturday" value="Saturday" >
                                                Saturday
                                            </option>
                                            <option label="Sunday" value="Sunday" >
                                                Sunday
                                            </option>
                                        </select>
                                    </FormControl>
                                    <FormControl
                                        compact
                                        id='recyclingDayNotSure'
                                        name='recyclingDayNotSure'
                                        value={form.recyclingDay}
                                        isChecklist={true}
                                    >
                                        <FormCheck
                                            id='recyclingDayCheckbox'
                                            name='recyclingDayCheckbox'
                                            checked={form.recyclingDayNotSure}
                                            onChange={e => updateField('recyclingDayNotSure', e.target.checked)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            I'm not sure
                                        </FormCheck>
                                    </FormControl>
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="utilities-form-recyclingDay"
                                            large
                                            disabled={!form.recyclingDay && !form.recyclingDayNotSure}
                                            onClick={() => validateAtLeastOne(['recyclingDay', 'recyclingDayNotSure'])}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-main">
                            <h3 className="d-md-block smaller">
                                General | Mail services
                            </h3>
                            <div className="highlight">
                                Where is the mail delivered?
                            </div>
                            <div className="u-spacer-m" />
                            <div>
                                <FormControl
                                    compact
                                    id='mailDeliveredWhere'
                                    className='simple-radios'
                                    name='mailDeliveredWhere'
                                    value={form.mailDeliveredWhere}
                                    hasError={formErrors.mailDeliveredWhere}
                                    errorText={formErrors.mailDeliveredWhere ? 'Please make a selection' : ''}
                                    onChange={e => updateField('mailDeliveredWhere', e.target.value)}
                                >
                                    <FormRadioGrid
                                        columns={1}
                                        bp='desktop'
                                    >
                                        <FormRadioGrid.Option
                                            id='mailDeliveredWhere1'
                                            key='mailDeliveredWhere1'
                                            label={mailDirectlyAtHome}
                                            value={mailDirectlyAtHome}
                                            checked={form.mailDeliveredWhere === mailDirectlyAtHome}
                                        />
                                        <FormRadioGrid.Option
                                            id='mailDeliveredWhere2'
                                            key='mailDeliveredWhere2'
                                            label={mailAtCentralArea}
                                            value={mailAtCentralArea}
                                            checked={form.mailDeliveredWhere === mailAtCentralArea}
                                        />
                                        <FormRadioGrid.Option
                                            id='mailDeliveredWhere3'
                                            key='mailDeliveredWhere3'
                                            label={mailAtPostOffice}
                                            value={mailAtPostOffice}
                                            checked={form.mailDeliveredWhere === mailAtPostOffice}
                                        />
                                    </FormRadioGrid>
                                </FormControl>
                                <div className="btn-wrapper pt-2">
                                    <Button
                                        id="utilities-form-mailDeliveredWhere"
                                        large
                                        disabled={!form.mailDeliveredWhere}
                                        onClick={() => validate('mailDeliveredWhere')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                {
                    form.mailDeliveredWhere === mailAtCentralArea &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    General | Mail services
                                </h3>
                                <div>
                                    <div className="highlight">
                                        Where are the community's mailboxes located?
                                    </div>
                                    <div className="u-spacer-m" />
                                    <FormControl
                                        compact
                                        id='mailboxLocation'
                                        name='mailboxLocation'
                                        value={form.mailboxLocation}
                                        hasError={formErrors.mailboxLocation}
                                        errorText={formErrors.mailboxLocation ? 'Please make a selection' : ''}
                                        onChange={e => updateField('mailboxLocation', e.target.value)}
                                        placeholder='Location of mailbox'
                                        style={{ marginBottom: 0 }}
                                        disabled={form.mailboxLocationNotSure}
                                    />
                                    <FormControl
                                        compact
                                        id='mailboxLocationNotSure'
                                        name='mailboxLocationNotSure'
                                        value={form.mailboxLocation}
                                        isChecklist={true}
                                    >
                                        <FormCheck
                                            id='mailboxLocationCheckbox'
                                            name='mailboxLocationCheckbox'
                                            checked={form.mailboxLocationNotSure}
                                            onChange={e => updateField('mailboxLocationNotSure', e.target.checked)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            I'm not sure
                                        </FormCheck>
                                    </FormControl>
                                    <div className="u-spacer-l" />
                                    <div className="highlight">
                                        What is the mailbox number?
                                    </div>
                                    <div className="u-spacer-m" />
                                    <FormControl
                                        compact
                                        id='mailboxNumber'
                                        name='mailboxNumber'
                                        value={form.mailboxNumber}
                                        hasError={formErrors.mailboxNumber}
                                        errorText={formErrors.mailboxNumber ? 'Please make a selection' : ''}
                                        onChange={e => updateField('mailboxNumber', e.target.value)}
                                        placeholder='Location of mailbox'
                                        style={{ marginBottom: 0 }}
                                        disabled={form.mailboxNumberNotSure}
                                    />
                                    <FormControl
                                        compact
                                        id='mailboxNumberNotSure'
                                        name='mailboxNumberNotSure'
                                        value={form.mailboxNumber}
                                        isChecklist={true}
                                    >
                                        <FormCheck
                                            id='mailboxNumberCheckbox'
                                            name='mailboxNumberCheckbox'
                                            checked={form.mailboxNumberNotSure}
                                            onChange={e => updateField('mailboxNumberNotSure', e.target.checked)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            I'm not sure
                                        </FormCheck>
                                    </FormControl>
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="utilities-form-mailboxLocation"
                                            large
                                            disabled={(!form.mailboxLocation && !form.mailboxLocationNotSure) ||
                                                (!form.mailboxNumber && !form.mailboxNumberNotSure)}
                                            onClick={() => {
                                                if ((form.mailboxLocation || form.mailboxLocationNotSure) &&
                                                    (form.mailboxNumber || form.mailboxNumberNotSure)) {
                                                    stepController.incrementStep();
                                                }
                                            }}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-main">
                            <h3 className="d-md-block smaller">
                                General | Parking
                            </h3>
                            <div className="highlight">
                                Do you have assigned parking in your community?
                            </div>
                            <div className="u-spacer-m" />
                            <div>
                                <FormControl
                                    compact
                                    id='assignedParkingPresent'
                                    className='simple-radios'
                                    name='assignedParkingPresent'
                                    value={form.assignedParkingPresent}
                                    hasError={formErrors.assignedParkingPresent}
                                    errorText={formErrors.assignedParkingPresent ? 'Please make a selection' : ''}
                                    onChange={e => updateField('assignedParkingPresent', e.target.value)}
                                >
                                    <FormRadioGrid
                                        columns={1}
                                        bp='desktop'
                                    >
                                        <FormRadioGrid.Option
                                            id='assignedParkingPresent1'
                                            key='assignedParkingPresent1'
                                            label='Yes'
                                            value='Yes'
                                            checked={form.assignedParkingPresent === 'Yes'}
                                        />
                                        <FormRadioGrid.Option
                                            id='assignedParkingPresent2'
                                            key='assignedParkingPresent2'
                                            label='No'
                                            value='No'
                                            checked={form.assignedParkingPresent === 'No'}
                                        />
                                        <FormRadioGrid.Option
                                            id='assignedParkingPresent3'
                                            key='assignedParkingPresent3'
                                            label={`I'm not sure`}
                                            value={`I'm not sure`}
                                            checked={form.assignedParkingPresent === `I'm not sure`}
                                        />
                                    </FormRadioGrid>
                                </FormControl>
                                <div className="btn-wrapper pt-2">
                                    <Button
                                        id="utilities-form-assignedParkingPresent"
                                        large
                                        disabled={!form.assignedParkingPresent}
                                        onClick={() => validate('assignedParkingPresent')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                {
                    form.assignedParkingPresent === 'Yes' &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    General | Parking
                                </h3>
                                <div className="highlight">
                                    What is the assigned parking number(s) for your home?
                                </div>
                                <div>
                                    <FormControl
                                        compact
                                        id='parkingNumber'
                                        name='parkingNumber'
                                        value={form.parkingNumber}
                                        hasError={formErrors.parkingNumber}
                                        errorText={formErrors.parkingNumber ? 'Please make a selection' : ''}
                                        onChange={e => updateField('parkingNumber', e.target.value)}
                                        placeholder='Enter assigned parking'
                                        disabled={form.parkingNumberNotSure}
                                    />
                                    <FormControl
                                        compact
                                        id='parkingNumberNotSure'
                                        name='parkingNumberNotSure'
                                        value={form.parkingNumber}
                                        isChecklist={true}
                                    >
                                        <FormCheck
                                            id='parkingNumberCheckbox'
                                            name='parkingNumberCheckbox'
                                            checked={form.parkingNumberNotSure}
                                            onChange={e => updateField('parkingNumberNotSure', e.target.checked)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            I'm not sure
                                        </FormCheck>
                                    </FormControl>
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="utilities-form-parkingNumber"
                                            large
                                            disabled={!form.parkingNumber && !form.parkingNumberNotSure}
                                            onClick={() => validateAtLeastOne(['parkingNumber', 'parkingNumberNotSure'])}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-main">
                            <h3 className="d-md-block smaller">
                                General | Subdivision
                            </h3>
                            <div className="highlight">
                                What is the name of the subdivision?
                            </div>
                            <div className="u-spacer-m" />
                            <div>
                                <FormControl
                                    compact
                                    id='subdivisionName'
                                    name='subdivisionName'
                                    value={form.subdivisionName}
                                    hasError={formErrors.subdivisionName}
                                    errorText={formErrors.subdivisionName ? 'Please make a selection' : ''}
                                    onChange={e => updateField('subdivisionName', e.target.value)}
                                    placeholder='Enter subdivision name'
                                    disabled={form.notInSubdivision || form.subdivisionNameNotSure}
                                />
                                <FormControl
                                    compact
                                    id='subdivisionNameCheckboxes'
                                    name='subdivisionNameCheckboxes'
                                    value={form.subdivisionName}
                                    isChecklist={true}
                                >
                                    <FormCheck
                                        id='subdivisionName1'
                                        name='subdivisionName1'
                                        checked={form.notInSubdivision}
                                        onChange={e => updateFields(['notInSubdivision', 'subdivisionName'], [e.target.checked, ''])}
                                        style={{ cursor: 'pointer' }}
                                        disabled={form.subdivisionNameNotSure}
                                    >
                                        The home is not in a subdivision
                                    </FormCheck>
                                    <FormCheck
                                        id='subdivisionNameCheckbox2'
                                        name='subdivisionNameCheckbox2'
                                        checked={form.subdivisionNameNotSure}
                                        onChange={e => updateField('subdivisionNameNotSure', e.target.checked)}
                                        style={{ cursor: 'pointer' }}
                                        disabled={form.notInSubdivision}
                                    >
                                        I'm not sure of the name of my subdivision
                                    </FormCheck>
                                </FormControl>
                                <div className="btn-wrapper pt-5">
                                    <Button
                                        id="utilities-form-subdivisionName"
                                        large
                                        disabled={!form.subdivisionName && !form.notInSubdivision && !form.subdivisionNameNotSure}
                                        onClick={() => validateAtLeastOne(['subdivisionName', 'notInSubdivision', 'subdivisionNameNotSure'])}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                {
                    (form.subdivisionName !== '' || form.subdivisionNameNotSure === true) &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    General | Access
                                </h3>
                                <div className="highlight">
                                    Is the subdivision gated?
                                </div>
                                <div className="u-spacer-m" />
                                <div>
                                    <FormControl
                                        compact
                                        id='subdivisionGated'
                                        className='simple-radios'
                                        name='subdivisionGated'
                                        value={form.subdivisionGated}
                                        hasError={formErrors.subdivisionGated}
                                        errorText={formErrors.subdivisionGated ? 'Please make a selection' : ''}
                                        onChange={e => updateField('subdivisionGated', e.target.value)}
                                    >
                                        <FormRadioGrid
                                            columns={1}
                                            bp='desktop'
                                        >
                                            <FormRadioGrid.Option
                                                id='subdivisionGated1'
                                                key='subdivisionGated1'
                                                label='Yes'
                                                value='Yes'
                                                checked={form.subdivisionGated === 'Yes'}
                                            />
                                            <FormRadioGrid.Option
                                                id='subdivisionGated2'
                                                key='subdivisionGated2'
                                                label='No'
                                                value='No'
                                                checked={form.subdivisionGated === 'No'}
                                            />
                                        </FormRadioGrid>
                                    </FormControl>
                                    <div className="btn-wrapper pt-2">
                                        <Button
                                            id="utilities-form-subdivisionGated"
                                            large
                                            disabled={!form.subdivisionGated}
                                            onClick={() => validate('subdivisionGated')}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                {
                    form.subdivisionGated === 'Yes' &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    General | Access
                                </h3>
                                <div className="highlight">
                                    What is the gate code?
                                </div>
                                <div className="u-spacer-m" />
                                <div>
                                    <FormControl
                                        compact
                                        id='gateCode'
                                        name='gateCode'
                                        value={form.gateCode}
                                        hasError={formErrors.gateCode}
                                        errorText={formErrors.gateCode ? 'Please make a selection' : ''}
                                        onChange={e => updateField('gateCode', e.target.value)}
                                        placeholder='Enter gate code'
                                        disabled={form.gateCodeNotSure}
                                    />
                                    <FormControl
                                        compact
                                        id='gateCodeNotSure'
                                        name='gateCodeNotSure'
                                        value={form.gateCode}
                                        isChecklist={true}
                                    >
                                        <FormCheck
                                            id='gateCodeCheckbox'
                                            name='gateCodeCheckbox'
                                            checked={form.gateCodeNotSure}
                                            onChange={e => updateField('gateCodeNotSure', e.target.checked)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            I'm not sure
                                        </FormCheck>
                                    </FormControl>
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="utilities-form-gateCode"
                                            large
                                            disabled={!form.gateCode && !form.gateCodeNotSure}
                                            onClick={() => validateAtLeastOne(['gateCode', 'gateCodeNotSure'])}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                {
                    offerState.propertySpec?.propertyType !== 'SFD' &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    General | Storage
                                </h3>
                                <div className="highlight">
                                    Does the property have assigned storage?
                                </div>
                                <div className="u-spacer-m" />
                                <div>
                                    <FormControl
                                        compact
                                        id='storagePresent'
                                        className='simple-radios'
                                        name='storagePresent'
                                        value={form.storagePresent}
                                        hasError={formErrors.storagePresent}
                                        errorText={formErrors.storagePresent ? 'Please make a selection' : ''}
                                        onChange={e => updateField('storagePresent', e.target.value)}
                                    >
                                        <FormRadioGrid
                                            columns={1}
                                            bp='desktop'
                                        >
                                            <FormRadioGrid.Option
                                                id='storagePresent1'
                                                key='storagePresent1'
                                                label='Yes'
                                                value='Yes'
                                                checked={form.storagePresent === 'Yes'}
                                            />
                                            <FormRadioGrid.Option
                                                id='storagePresent2'
                                                key='storagePresent2'
                                                label='No'
                                                value='No'
                                                checked={form.storagePresent === 'No'}
                                            />
                                            <FormRadioGrid.Option
                                                id='storagePresent3'
                                                key='storagePresent3'
                                                label={`I'm not sure`}
                                                value={`I'm not sure`}
                                                checked={form.storagePresent === `I'm not sure`}
                                            />
                                        </FormRadioGrid>
                                    </FormControl>
                                    <div className="btn-wrapper pt-2">
                                        <Button
                                            id="utilities-form-storagePresent"
                                            large
                                            disabled={!form.storagePresent}
                                            onClick={() => validate('storagePresent')}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                {
                    offerState.propertySpec?.propertyType !== 'SFD' &&
                    form.storagePresent === 'Yes' &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-main">
                                <h3 className="d-md-block smaller">
                                    General | Storage
                                </h3>
                                <div className="highlight">
                                    What is the assigned storage number?
                                </div>
                                <div className="u-spacer-m" />
                                <div>
                                    <FormControl
                                        compact
                                        id='storageNumber'
                                        name='storageNumber'
                                        value={form.storageNumber}
                                        hasError={formErrors.storageNumber}
                                        errorText={formErrors.storageNumber ? 'Please make a selection' : ''}
                                        onChange={e => updateField('storageNumber', e.target.value)}
                                        placeholder='Enter storage number'
                                        disabled={form.storageNumberNotSure}
                                    />
                                    <FormControl
                                        compact
                                        id='storageNumberNotSure'
                                        name='storageNumberNotSure'
                                        value={form.gateCode}
                                        isChecklist={true}
                                    >
                                        <FormCheck
                                            id='storageNumberCheckbox'
                                            name='storageNumberCheckbox'
                                            checked={form.storageNumberNotSure}
                                            onChange={e => updateField('storageNumberNotSure', e.target.checked)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            I'm not sure
                                        </FormCheck>
                                    </FormControl>
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="utilities-form-storageNumber"
                                            large
                                            disabled={!form.storageNumber && !form.storageNumberNotSure}
                                            onClick={() => validateAtLeastOne(['storageNumber', 'storageNumberNotSure'])}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }

                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-main">
                            <h3 className="d-md-block smaller">
                                Additional comments
                            </h3>
                            <div className="highlight">
                                Please enter any additional information you would like to share with us regarding the services at your home.
                            </div>
                            <div className="u-spacer-m" />
                            <div>
                                <FormControl
                                    compact
                                    id='finalComments'
                                    name='finalComments'
                                    value={form.finalComments}
                                    onChange={e => updateField('finalComments', e.target.value)}
                                    placeholder='Tell us more'
                                >
                                    <textarea
                                        name="finalComments"
                                        rows={4}
                                    ></textarea>
                                </FormControl>
                                <div className="btn-wrapper pt-5">
                                    <Button
                                        id="utilities-submit-btn"
                                        large
                                        onClick={submitForm}
                                        disabled={submitting}
                                    >
                                        {submitting ? 'Submitting...' : 'Submit'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>

                {/* 
                Feedback after form submission
                */}
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-main">
                            {
                                formSucceeded &&
                                <>
                                    <h3 className="d-md-block smaller">
                                        Information Successfully Submitted
                                    </h3>
                                    <div className="highlight">
                                        Thanks for taking the time to provide this information to our team - you're awesome!
                                    </div>
                                </>
                            }
                            {
                                formFailed &&
                                <>
                                    <h3 className="d-md-block smaller">
                                        Submission Failed
                                    </h3>
                                    <div className="highlight">
                                        We encountered an issue while submitting your information. Please try again later.
                                    </div>
                                </>
                            }
                            <div className="u-spacer-m" />
                            <div className="d-flex justify-content-left">
                                <img src={require('@assets/illustrations/survey_finished_icon.svg')} />
                            </div>
                        </div>
                    </div>
                </WizardStep>
            </Wizard>
        </div >
    );
}

export default UtilitiesFormContent;
