import { AxiosInstance } from 'axios';
import { setup } from 'axios-cache-adapter'
import FullStory from './fullStory';
import AuthService from '../services/AuthService/auth.service';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import TokenService from '../services/token.service';
import { path, RouteName } from '../routing/Routes';

const apiClient = setup({
    baseURL: '',
    timeout: 30e3,
    headers: {
        'Content-Type': 'application/json',
    },
    cache: {
        maxAge: process.env.NODE_ENV === 'production' ? 12e4 : 10e3,
        exclude: {
            filter: (url) => {
                return !/^\/(offers|requests)\/.+$/.test(url.url);
            },
        },
        invalidate: async (config, request) => {
            if (request.clearCacheEntry) {
                //@ts-ignore
                await config.store.removeItem(config.uuid)
            }
        }
    },
})

apiClient.interceptors.response.use(null, (error) => {
    const response = error.response;
    if (response) {
        const httpErrorCode = response.status;
        const config = response.config;
        switch (httpErrorCode) {
            case 404:
                if (
                    config &&
                    config.data &&
                    config.data.includes('username') &&
                    config.data.includes('password')
                ) {
                    break;
                }
                break;
            default:
                FullStory.event('HTTP Error', {
                    error_str: JSON.stringify(error),
                    response_str: JSON.stringify(response),
                });
        }
    }
    return Promise.reject(error);
});

// Function that will be called to refresh authorization
const refreshAuthLogic = async (failedRequest) => {
    const refreshed = await AuthService.refreshToken();
    if (refreshed == true) {
        failedRequest.response.config.headers['Authorization'] = `Bearer ${TokenService.getToken()}`;
    }
    else {
        AuthService.logout();
        window.location.href = (path(RouteName.Login));
    }
    return Promise.resolve();
};

// // Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(apiClient, refreshAuthLogic, { statusCodes: [401] });


export default apiClient as AxiosInstance;