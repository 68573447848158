import styles from './Documents.module.scss';
import React, { useEffect, useState } from "react";
import { useOffer } from '../../context/offer/OfferContext';
import { loadOffer } from '../../context/offer/OfferReducer';
import { useParams, useHistory } from 'react-router-dom';
import PageLayout from '../Controls/PageLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Heading from '@offerpad/floorplan/dist/typography/Heading';
import useScreen from '@offerpad/floorplan/dist/hooks/useScreen';
import { useDocuments } from '../../context/documents/DocumentsContext';
import { getDocuments, addDocumentBlob, deleteDocumentBlob } from '../../context/documents/DocumentsReducer';
import { contractUrl } from '../../services/ClientRequest/clientRequest.service';
import { notify } from '../../context/notifications/NotificationsReducer';
import { useNotifications } from '../../context/notifications/NotificationsContext';
import TokenService from '../../services/token.service';
import { formatDate } from '../../components/Controls/resources/datetime';
import { useUser } from '../../context/user/UserContext';
import { useUpload, FileService, uploadFile, Upload, deleteUpload } from '@offerpad/concrete.react/lib/File'
import { Document } from '../../services/DocumentsService/types'
import { formatDocumentName, columns, sortDocuments, createInspectionDocument, createOfferStateDocuments, getAllMessages } from '../Controls/resources/documentUtil'
import ProgressBar from '../Controls/Dashboard/ProgressBar/ProgressBar';
import getProgressInfoSeller from '../Controls/resources/progressUtilSeller';
import DocumentCard, { DocumentCardProps } from '../Controls/DocumentCard';

const fileService = new FileService(process.env.RAZZLE_API_MANAGEMENT_URL);

const Documents = () => {
    const { state: { offerState }, dispatch: offerDispatch } = useOffer();
    const { state: { documents, loaded }, dispatch: documentsDispath } = useDocuments();
    const { keyType, offerKey } = useParams<{ keyType: string, offerKey: string }>();
    const history = useHistory();
    const visualTrackerInfo = getProgressInfoSeller(offerState);

    useEffect(() => {
        if (offerState.offerKey != offerKey || offerState.keyType != keyType || !offerState.thereIsOffer) {
            loadOffer(offerDispatch, offerKey, keyType, history);
        }
    }, [keyType, offerKey])

    const isLoggedIn = TokenService.getToken();
    useEffect(() => {
        if (offerState.thereIsOffer && isLoggedIn) {
            getDocuments(documentsDispath, offerState.offerKey, "Offer", "SellerDocuments")
        }
    }, [offerState.thereIsOffer, isLoggedIn])

    if (!offerState.thereIsOffer) {
        return null;
    }

    if (documents.length === 0 && !loaded) {
        return null;
    }

    const offerStateDocuments = offerState.documents.filter(doc => doc.contractId).map(doc => createOfferStateDocuments(
        doc.id + documents.length, doc.name, doc.contractId, doc.completedDateTime,
    ))

    if (offerState.inspectionDocumentUrl) {
        offerStateDocuments.push(createInspectionDocument(offerState.inspectionDocumentUrl, offerState.inspectionSubmittedDateTime, documents.length + 1))
    }

    return (
        <PageLayout
            subHeaderText={`Welcome back, ${offerState.firstName}`}
            headerDescription={visualTrackerInfo.sentenceTop}
            streetAddress={offerState.streetAddress}
            headerText="Documents"
            listedByAgent={offerState.listedByAgent}
            centerColumn={
                <>
                    {
                        visualTrackerInfo.workflowStep &&
                        <ProgressBar show={!offerState.isLost} workflowStep={visualTrackerInfo.workflowStep} currentProgressBar={visualTrackerInfo.progressBar} progressPercentage={visualTrackerInfo.progressPercentage} />
                    }
                    <DocumentsTable documents={[...documents, ...offerStateDocuments]} />
                </>
            }
        />
    )
}

export const DocumentsTable = ({ documents }: { documents: Array<Document> }) => {
    const screen = useScreen();
    const isTablet = screen.width < 991
    const { state: uploads } = useUpload();
    const [showSortDropDown, setShowSortDropdown] = useState<boolean>(false);
    const [sortColumn, setSortColumn] = useState<number>(null);
    const { state: user } = useUser();
    
    const updateSortColumn = (index) => {
        if (sortColumn === index) {
            setSortColumn(null)
        } else {
            setSortColumn(index)
        }
    }

    if (documents.length === 0) {
        return <h3>There are no documents associated to this offer</h3>
    }

    const { state: { offerState } } = useOffer();
    const { dispatch: uploadDispatch } = useUpload();
    const { state: { deleting }, dispatch: documentsDispath } = useDocuments();
    const { dispatch: notificationsDispatch } = useNotifications();
    const allowedFileTypes = ['doc', 'pdf', 'docx'];

    return <div className={styles.table}>
        {!isTablet && <header className={styles.header}>
            {
                columns.map((column, index) => {
                    return <Heading size={5} color="#636a71" key={column}>
                        {column}
                        <FontAwesomeIcon
                            id="documents-update-sort-column"
                            className={index === sortColumn ? styles.sortIcon__selected : styles.sortIcon}
                            icon={['fas', 'sort']}
                            key={index}
                            onClick={() => updateSortColumn(index)} />
                    </Heading>
                })
            }
            <div />
        </header>}
        {isTablet &&
            <div style={{ textAlign: 'right' }}>
                <FontAwesomeIcon id="documents-sort-dropdown-9651" className={styles.sortSizeUpIcon} icon={['far', 'sort-size-up']} onClick={() => setShowSortDropdown(!showSortDropDown)} />
                <ul className={styles.sortDropDown} style={showSortDropDown ? {} : { display: "none" }}>
                    {
                        columns.map((column, index) => {
                            return <li
                                id={`documents-sort-${column}`}
                                className={index === sortColumn ? styles.sortDropDown_selected : ''}
                                key={column}
                                onClick={() => {
                                    updateSortColumn(index);
                                    setShowSortDropdown(false);
                                }}>
                                {column}
                            </li>
                        })
                    }
                </ul>
            </div>}
        <div className={styles.instructions}>
            <div>
                <div>
                    <span>File Types Allowed:</span> .doc, .docx, .pdf
                </div>
                <div>
                    <span>File Size Limit:</span> 5 MB
                </div>
            </div>
        </div>
        <div>
            {(sortColumn !== null ? sortDocuments(sortColumn, documents) : documents).map((doc: Document) => {
                const token = TokenService.getToken();
                const upload = uploads.find(upload => upload.type === `document-${doc.id}`);
                const onFileInputChange = async (e) => {
                    const file = e.target.files[0];
                    const fileType = file.name.split('.').pop();

                    if (!allowedFileTypes.includes(fileType)) {
                        return notify(notificationsDispatch, { message: `${fileType} files are not allowed`, type: 'danger' })
                    }
                    await uploadFile(uploadDispatch, file, doc.connectionId, fileService, `document-${doc.id}`, onFileUploaded);
                }

                const onFileUploaded = async (upload: Upload) => {
                    await addDocumentBlob(documentsDispath, doc.id, upload.blobUrl, upload.file.type, upload.file.size);
                    // still true as of 7/11/2022
                    // adding a documentBlob doesn't return a newly created blob with id, so I need to refecth all of documents in order to have an id to delete with
                    // could remove this if endpoint gets updated to return created document
                    getDocuments(documentsDispath, offerState.offerKey, "Offer", "SellerDocuments")
                }

                const deleteBlob = async () => {
                    if (doc.documentBlobs.length < 1) {
                        return;
                    }
                    await deleteDocumentBlob(documentsDispath, doc.id, doc.documentBlobs[0].id);
                    if (upload) {
                        deleteUpload(uploadDispatch, upload.uuid);
                    }
                }

                const uploadedBy = doc.documentBlobs.length > 0 && doc.documentBlobs[0].createdBy ? (doc.documentBlobs[0].createdBy === user.email ? "You" : 'Offerpad') : 'N/A'

                let props: DocumentCardProps = {
                    name: formatDocumentName(doc.type),
                    assignedDate: doc.createdDateTime ? formatDate(doc.createdDateTime, undefined, undefined, 'MM/DD/YY') : 'N/A',
                    uploadDate: doc.documentBlobs.length > 0 ? formatDate(doc.documentBlobs[0].createdDateTime, undefined, undefined, 'MM/DD/YY') : 'N/A',
                    url: doc.documentBlobs.length > 0 ? doc.documentBlobs[0].blobUrl : null,
                    uploadedBy: uploadedBy,
                    onFileInputChange: onFileInputChange,
                    uploadProgress: upload?.progress,
                    allowDelete: uploadedBy !== "Offerpad" && doc.status !== "Approved",
                    onDelete: deleteBlob,
                    deleting: deleting,
                    onCancelUpload: fileService.cancel,
                    allowMessage: false,
                    onSubmit: (message) => addDocumentBlob(documentsDispath, doc.id, upload.blobUrl, upload.file.type, upload.file.size, message),
                    messages: getAllMessages(user, doc),
                    showMessageHistory: false
                }

                if (doc.group === 'OfferStateDocuments') {
                    props = {
                        name: formatDocumentName(doc.type),
                        assignedDate: 'N/A',
                        uploadDate: doc.createdDateTime ? formatDate(doc.createdDateTime, undefined, undefined, 'MM/DD/YY') : 'N/A',
                        url: contractUrl(doc.connectionId, token),
                        uploadedBy: 'Offerpad',
                    }
                }

                return <DocumentCard {...props} key={`${doc.id}-${doc.modifiedDateTime}`} />
            })}
        </div>
    </div>
}

export default Documents;
