import React from "react";
import moment from "moment";
import { Modal } from 'floorplan/components/v2/Modal'
import { Button } from 'floorplan/components/Button'
import { closeModal } from '@offerpad/concrete.react/lib/Modal';
import { useModal } from '@offerpad/concrete.react/lib/Modal';
import { DocumentMessage } from "src/services/DocumentsService/types";
import styles from './MessageHistoryModal.module.scss';

const opHouseIcon = require('src/assets/icons/op-house-icon.svg');

export type MessageHistoryModalProps = {
    messages: DocumentMessage[];
}

const MessageHistoryModal: React.FC<MessageHistoryModalProps> = (props) => {
    const { messages } = props;
    const { state: { isOpen }, dispatch: modalDispatch } = useModal();

    return (
        <Modal
            open={isOpen}
            onClose={() => closeModal(modalDispatch)}
            mobileBottom
            hasFooter
        >
            <Modal.Header
                title={'View Message History'}
                onClose={() => closeModal(modalDispatch)}
                border
            />
            <Modal.Content>
                <div data-testid="message-history-content">
                    {messages.map((message) => {
                        return <div key={`${message.createdDateTime}`} className={styles.messageContainer}>
                            <p>{message.messageContent}</p>
                            <div className={styles.messageBottom}>
                                <p>{moment(message.createdDateTime).format("MM/DD/YY, h:m A")}</p>
                                <div className={styles.createdBySection}>
                                    {
                                        message.createdBy === 'Offerpad' &&
                                        <img className={styles.createdByIcon} src={opHouseIcon} alt='icon' />
                                    }
                                    <p>{message.createdBy}</p>
                                </div>
                            </div>
                        </div>;
                    })}
                </div>
            </Modal.Content>
            <Modal.Footer border >
                <Button
                    id="message-history-close-button"
                    data-qa="message-history-close-button"
                    type='submit'
                    large
                    onClick={() => closeModal(modalDispatch)}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default MessageHistoryModal;