import React, { useState, useRef, useEffect } from 'react';
import './DropdownPaper.scss'

type DropdownPaperProps = {
    direction?: string,
    icon?: string,
    position: string,
    hideArrow?: boolean,
    tag?: string,
    title: React.ReactNode;
    children: React.ReactNode;
}


const DropdownPaper: React.FC<DropdownPaperProps> = (props) => {
    const { title, direction = 'down', children, position } = props;
    const [open, setOpen] = useState<boolean>(false);

    const dropdownRef = useRef(null);
    const handleOutsideClick = event => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpen(false);
        }
    };
    useEffect(() => {
        document.addEventListener("click", handleOutsideClick, false);
        return () => {
            document.removeEventListener("click", handleOutsideClick, false);
        };
    }, []);

    const toggleDropdown = () => setOpen(!open);

    const directionAttribute = () => {
        let baseDirection = '';
        if (direction === 'up') {
            baseDirection = 'top-start';
        } else if (direction === 'down') {
            baseDirection = 'bottom-start';
        }
        return baseDirection;
    }

    return <li id="dropdown-paper-toggle" className={`dropdown ${open ? 'show' : ''}`} aria-haspopup="true" aria-expanded={open} onClick={toggleDropdown} ref={dropdownRef}>
        {title}
        <ul
            className={`dropdown-menu ${open ? 'show' : ''} ${position === 'right' ? 'dropdown-menu-right' : ''}`}
            x-placement={directionAttribute()}
        >
            {children}
        </ul>
    </li>
}

export default DropdownPaper;