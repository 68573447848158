import React from 'react';
import './DashboardStatusCard.scss';
import { Button } from 'floorplan/components/v2/Button';
export interface DashboardStatusCardProps {
    title: string;
    value?: string;
    status?: string;
    link?: string;
    linkTitle?: string;
    linkClick?: Function;
}

const DashboardStatusCard = ({ title, value, status, link, linkTitle, linkClick }: DashboardStatusCardProps) => {
    const style = {
        fontSize: '1.3125rem',
        fontWeight: 700,
    };
    return (
        <div className={`op-card dashboard-status-card ${status ? status : ''}`}>
            <h4 className="op-card__small-title truncate">{title}</h4>
            {value && <p style={{ ...style, color: '#333333' }}> {value} </p>}
            {!value && link && <Button ghost style={{ padding: '0', color: '#4c77a9' }} id="dashboard-status-card-anchor-link" href={link} target="blank" rel="noreferrer noopener">{linkTitle}</Button>}
            {value && link && <Button ghost style={{ padding: '0', color: '#4c77a9' }} id="dashboard-status-card-anchor-title" href={link} target="blank" rel="noreferrer noopener" >{linkTitle}</Button>}
            {linkClick && <Button ghost style={{ padding: '0', color: '#4c77a9' }} id="dashboard-status-card-title" onClick={() => linkClick()}>
                {linkTitle}
            </Button>}
        </div>
    );
}

export default DashboardStatusCard;
