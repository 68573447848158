import React  from 'react';

const Circle = function (radius: number, color: string, state: number, setState) {
    const style = {
        width: 2 * radius + 'rem',
        height: 2 * radius + 'rem',
        backgroundColor: color,
        borderRadius: radius + 'rem',
    };
    return <div id="slider-control-set-state" style={style} onClick={() => setState(state)}></div>;
};

interface ISliderControl {
    states: number;
    radius: number;
    color: string;
    selectedColor: string;
    currentState: number;
    setState;
    width: string;
}

const SliderControl = (props: ISliderControl) => {
    const { states, radius, color, selectedColor, currentState, setState, width } = props;
    const circles = [];
    for (let i = 0; i < states; ++i) {
        circles.push(Circle(radius, i === currentState ? selectedColor : color, i, setState));
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'space-around', alignContent: 'center', width: width }}>
                {circles}
            </div>
        </div>
    );
};

export default SliderControl;
