import React from 'react';

type ContentProps = {
    toggleSidebar: React.MouseEventHandler<HTMLDivElement>
}

const Content: React.FC<ContentProps> = ({ children, toggleSidebar }) => {
    return <div id="content-toggle-side-bar" className="content" onClick={toggleSidebar}>
        {children}
    </div>
}

export default Content