import React, { useEffect } from 'react'
import PageLayout from '../../Controls/PageLayout'
import { useDisposition } from '../../../context/disposition/DispositionContext'
import { useParams } from 'react-router-dom';
import styles from './BuyersTodos.module.scss'

const BuyersTodos = () => {
    const { state: disposition, getDisposition } = useDisposition();
    const { offerKey } = useParams<{ offerKey: string }>();

    useEffect(() => {
        if (disposition.dispositionOffer === null || disposition.dispositionOffer?.id?.toString() !== offerKey) {
            getDisposition(offerKey)
        }
    }, [offerKey])

    const { dispositionOffer } = disposition;


    if (dispositionOffer === null) {
        return <></>
    }

    return <PageLayout
        streetAddress={'Street Address'}
        headerText="Buyers To-Dos"
        subHeaderText={``}
        listedByAgent={false}
        centerColumn={
            <div className={styles.buyers_todos}>
                Buyers To-Dos
            </div>
        }
    />
}

export default BuyersTodos;