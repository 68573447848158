import React from 'react';
import { titleContactImage } from './resources/titleContacts';
import classes from './TeamMember.module.scss';
import { Button } from 'floorplan/components/v2/Button';
import { useOffer } from 'src/context/offer/OfferContext';

const offerpadLogo = require('../../assets/team-card-logo--offerpad.svg');
const firstAmericanLogo = require('../../assets/first-american-logo.png');
const bellhopLogo = require('../../assets/bellhop.svg');
const statewideTitleLogo = require('../../assets/statewide-title-logo.png');
const hankinPackLogo = require('../../assets/hankin-pack-logo.png');
const dircksMovingLogo = require('../../assets/dircks-moving-logistics-logo.png');
const mcMichaelAndGrayLogo = require('../../assets/mc-michael-and-gray-logo.png');

const getCompanyName = (teamMember) => {
    let company = teamMember.companyName;
    if (!company && (teamMember.email ?? '').toLowerCase().endsWith('@offerpad.com')) {
        company = 'offerpad';
    }
    return company;
}

const createPhoneLink = (phone) => 'tel:' + phone;

const formatPhone = (phone) => {
    phone = '' + phone;
    phone = phone.replace(/[^\d]/g, '');

    if (phone.length == 10) {
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
    return phone;
}

const TeamMember = (props) => {
    const { state: { offerState }, dispatch: offerDispatch } = useOffer();

    let { teamMember: member, offerpadNumber: opPhone } = props;
    const company = getCompanyName(member);
    const photoAlt = `Photo of ${member.fullName}`;
    const mailto = 'mailto:' + member.email;

    const className = [classes['c-teammember'], classes['op-card']];
    if (company === 'offerpad') {
        className.push(classes['c-teammember--op']);
    }
    if (member.role === 'Transaction Manager') {
        className.push(classes['c-teammember--main']);
    }

    const companyLogo = (() => {
        switch (company) {
            case 'offerpad':
                return offerpadLogo;
            case 'First American Title':
                opPhone = false;
                if (offerState.market === 'Indianapolis') {
                    member.phoneNumber = 8886507007;
                }
                else {
                    member.phoneNumber = 8134406515;
                }
                return firstAmericanLogo;
            case 'Dircks':
                return dircksMovingLogo;
            case 'Bellhop':
            case 'Bellhops':
                opPhone = false;
                member.fullName = 'Bellhop';
                return bellhopLogo;
            case 'Hankin & Pack PLLC':
                return hankinPackLogo;
            case 'McMichael & Gray, PC':
                return mcMichaelAndGrayLogo;
            case 'Statewide Title Services':
                return statewideTitleLogo;
        }
        return null;
    })();

    const avatarUrl = member.imageURL || titleContactImage(member) || `https://ui-avatars.com/api/?bold=true&color=ffffff&background=1b6775&format=svg&name=${member.fullName}`;
    const displayLogo = companyLogo ? <img src={companyLogo} className={classes['c-teammember__logo']} alt='company logo' /> : <div><br />{company}</div>;


    const emailStyle = () => {
        const width = window.innerWidth;
        let size = ((1.25 - 0.9) / (1920 - 375)) * (width - 375) + 0.9;
        if (size > 1.25) size = 1.25;
        if (size < 0.9) size = 0.9;
        return { fontSize: `${size}rem` };
    }
    const fullNameStyle = () => {
        const width = window.innerWidth;
        let length = Math.sqrt(width * window.innerHeight) / (width < 1450 ? 1 : 10);
        length /= member.fullName.length;
        if (length > 2) length = 2;
        return { fontSize: `${length}rem` };
    }
    const scheduleCall = member.phoneNumber && member.schedulingLink && company === 'offerpad';

    let displaySchedulingLink = true;

    const testSchedulingLink = () => {
        if (!displaySchedulingLink) {
            // TODO: Write code to test schedulingLink before displaying;
            // set the value of displaySchedulingLink
            displaySchedulingLink = true;
        }
        return displaySchedulingLink;
    }

    return (
        <div className={className.join(' ')}>
            <div className={classes['c-teammember__content']}>
                <div className={classes['c-teammember__images']}>
                    <div>
                        <img src={avatarUrl} alt={photoAlt} className={classes['c-teammember__photo']} />
                    </div>
                    {displayLogo}
                    {member.role === 'Transaction Manager' && <div className={classes['c-teammember__mainbadge']}>Your Main Contact</div>}
                </div>
                <div className={classes['c-teammember__info']}>

                    {member.role && <div className={classes['c-teammember__role']}>{member.role}</div>}

                    {member.fullName && <h4 className={classes['op-card__title']} style={fullNameStyle()}>{member.fullName}</h4>}

                    {member.email && <a id={`team-member-${member.role}`} href={mailto} className={classes['c-teammember__email']} style={emailStyle()}>{member.email}</a>}

                    {member.description && <p className={classes['c-teammember__desc']}>{member.description}</p>}

                    {member.phoneNumber &&
                        <div className={classes['c-teammember__phone']}>
                            <strong>{opPhone ? 'Direct/Text: ' : 'Direct: '}</strong>
                            <a id={`team-member-phone-${member.role}`} href={createPhoneLink(member.phoneNumber)}>{formatPhone(member.phoneNumber)}</a>
                            {member.phoneExtension && <span>({member.phoneExtension})</span>}

                        </div>
                    }
                    {opPhone &&
                        <div className={classes['c-teammember__phone']}>
                            <strong>Office: </strong>
                            {formatPhone(opPhone)}
                        </div>
                    }
                </div>
            </div>
            {scheduleCall &&
                <div className={classes['c-teammember__contact']}>
                    {testSchedulingLink() &&
                        <Button
                            id="team-member-contact-link"
                            fullWidth={true}
                            secondary
                            href={member.schedulingLink}
                            target="_blank"
                        >Schedule a call with {member.firstName}</Button>
                    }
                    {!testSchedulingLink() &&
                        <div>
                            {
                                // TODO: Write code to handle testSchedulingLink failure
                            }
                        </div>
                    }
                </div>
            }
        </div>);
}
export default TeamMember;
