import React from 'react';
import './CompletedTaskCard.scss';
import UserAvatar from './UserAvatar';

const CompletedTaskCard = (item) => {
    const { task } = item;

    return (
        <div className="c-taskcard c-taskcard--completed op-card">
            <h4 className="c-taskcard__title"><UserAvatar size="sm" clickable={false} />{task.title}</h4>
            {
                task.completionDate &&
                <div className="c-taskcard__date" >
                    You completed this task on {task.completionDate}
                </div>
            }
        </div>);
}

export default CompletedTaskCard;