import React, { useReducer, createContext, useContext } from 'react';
import { DocumentsReducer, initialState, State, Dispatch } from './DocumentsReducer';

const DocumentsContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

const DocumentsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(DocumentsReducer, initialState);
    const value = { state, dispatch };

    return <DocumentsContext.Provider value={value}>{children}</DocumentsContext.Provider>;
};

const useDocuments = () => {
    const context = useContext(DocumentsContext);
    if (context === undefined) {
        throw new Error('useDocuments must be used within an DocumentsProvider');
    }
    return context;
};

export { useDocuments, DocumentsProvider, DocumentsContext };
