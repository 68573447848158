import { Document } from '../../services/DocumentsService/types';
import * as DocumentService from '../../services/DocumentsService/documents.service';

export enum Actions {
    LOAD_DOCUMENTS = 'loadDocuments',
    LOAD_DOCUMENTS_COMPLETE = 'loadDocumentsComplete',
    DOCUMENT_BLOBS_LOADING = 'documentBlobsLoading',
    DOCUMENT_BLOBS_DELETE = 'documentBlobsDeleting',
    ADD_DOCUMENT_BLOBS_COMPLETE = 'addDocumentBlobsComplete',
    DELETE_DOCUMENT_BLOB_COMPLETE = 'deleteDocumentBlobComplete',
    ERROR = 'error',
}
export type Action = { type: Actions; data?: any };
export type State = {
    documents: Array<Document>;
    loading: boolean;
    error: boolean;
    errorMessage: string;
    loaded: boolean;
    deleting: boolean,
};
export type Dispatch = (action: Action) => void;

export const initialState: State = {
    documents: [],
    loading: false,
    error: false,
    errorMessage: '',
    loaded: false,
    deleting: false,
};

export const DocumentsReducer = (state: State, action: Action) => {
    let document, documents;
    switch (action.type) {
        case Actions.LOAD_DOCUMENTS:
            return { ...state, loading: true };
        case Actions.LOAD_DOCUMENTS_COMPLETE:
            return { ...state, loading: false, error: false, loaded: true, documents: action.data };
        case Actions.DOCUMENT_BLOBS_LOADING:
            return { ...state, loading: true };
        case Actions.DOCUMENT_BLOBS_DELETE:
            return { ...state, deleting: true };
        case Actions.ADD_DOCUMENT_BLOBS_COMPLETE:
            document = state.documents.find((doc) => doc.id === action.data.documentId);
            document = { ...document, documentBlobs: [...document.documentBlobs, ...action.data.blobs], status: 'PendingApproval' };
            documents = state.documents.map((el) => (el.id === action.data.documentId ? document : el));
            return { ...state, loading: false, error: false, documents: documents };
        case Actions.DELETE_DOCUMENT_BLOB_COMPLETE:
            document = state.documents.find((doc) => doc.id === action.data.documentId);
            document.documentBlobs = document.documentBlobs.filter((blob) => blob.id !== action.data.blobId);
            documents = state.documents.map((el) => (el.id === action.data.documentId ? document : el));
            return { ...state, loading: false, error: false, documents: documents, deleting: false };
        case Actions.ERROR:
            const message = action.data.message;
            return { ...state, error: true, loaded: true, errorMessage: message };
        default:
            return state;
    }
};

export const getDocuments = async (dispatch: Dispatch, connectionId: string, connectionType: string, group: string) => {
    dispatch({ type: Actions.LOAD_DOCUMENTS });
    try {
        const documents = await (await DocumentService.getDocumentsByGroup(connectionId, connectionType, group)).data;
        dispatch({ type: Actions.LOAD_DOCUMENTS_COMPLETE, data: documents ? documents : [] });
    } catch (error) {
        dispatch({ type: Actions.ERROR, data: { message: error } });
    }
};

export const addDocumentBlob = async (
    dispatch: Dispatch,
    documentId,
    blobUrl,
    mimeType,
    size,
    annotation?,
    createdBy?,
    category = 'SellerDocuments',
    uploadType = 'Customer'
) => {
    dispatch({ type: Actions.DOCUMENT_BLOBS_LOADING });
    try {
        const blobs = [
            {
                blobUrl: blobUrl,
                size: size,
                category: category,
                mimeType: mimeType,
                uploadType: uploadType,
                annotation: annotation,
                createdBy: createdBy,
            },
        ];
        await DocumentService.addDocumentBlobs(documentId, blobs);
        dispatch({ type: Actions.ADD_DOCUMENT_BLOBS_COMPLETE, data: { blobs: blobs, documentId: documentId } });
    } catch (error) {
        dispatch({ type: Actions.ERROR, data: { message: error } });
    }
};

export const deleteDocumentBlob = async (dispatch: Dispatch, documentId, blobId) => {
    dispatch({ type: Actions.DOCUMENT_BLOBS_DELETE });
    try {
        await DocumentService.deleteDocumentBlob(blobId);
        dispatch({ type: Actions.DELETE_DOCUMENT_BLOB_COMPLETE, data: { blobId: blobId, documentId: documentId } });
    } catch (error) {
        dispatch({ type: Actions.ERROR, data: { message: error } });
    }
};
