import React, { useReducer, createContext, useContext } from 'react';
import { userReducer, initialState, State, Dispatch } from './UserReducer';

const UserContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

type UserProviderProps = { children: React.ReactNode };

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(userReducer, initialState);
    const value = { state, dispatch };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUser = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within an userProvider');
    }
    return context;
};

export { useUser, UserProvider, UserContext };
