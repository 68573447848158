import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as ClientRequest from '../../../services/ClientRequest/clientRequest.service';
import { useOffer } from "../../../context/offer/OfferContext";
import disabledDates from "../../Controls/resources/disabledDatesUtil";
import { useNotifications } from "../../../context/notifications/NotificationsContext";
import { notify } from '../../../context/notifications/NotificationsReducer';
import { closeModal } from '@offerpad/concrete.react/lib/Modal';
import { useModal } from '@offerpad/concrete.react/lib/Modal';
import { Modal } from 'floorplan/components/v2/Modal'
import { Button } from 'floorplan/components/Button'
import './ChangeCOEModal.scss';

const ChangeCOEModal: React.FC = () => {
    const { state: { offerState } } = useOffer();
    const { dispatch: notificationsDispatch } = useNotifications();
    const { state: { isOpen }, dispatch: modalDispatch } = useModal();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [proposedCOEDate, setProposedCOEDate] = useState<Date>(null);

    const isWeekday = (date) => {
        const day = date.getDay(date);
        return day !== 0 && day !== 6;
    };

    const submitRequest = () => {
        setSubmitting(true);
        ClientRequest.coeDateChange({
            changeDate: proposedCOEDate,
            acquisitionId: offerState.acquisitionId,
        })
            .then(() => {
                notify(notificationsDispatch, {
                    message: `Success! Your request to change the Close of Escrow date has been submitted!`,
                    type: 'success',
                });
                closeModal(modalDispatch);
            })
            .catch(() => {
                notify(notificationsDispatch, {
                    message: `Oops! There was an issue requesting a change for the Close of Escrow date. Please try again later.`,
                    type: 'danger',
                });
            })
            .finally(() => {
                setSubmitting(false);
            });
    }
    return (
        <Modal
            open={isOpen}
            onClose={() => closeModal(modalDispatch)}
            mobileBottom
            hasFooter
        >
            <Modal.Header
                title={'New Close of Escrow Date'}
                onClose={() => closeModal(modalDispatch)}
                border={false}
            />
            <Modal.Content>
                <div data-testid="change-coe-content">
                    <div>
                        <DatePicker
                            inline
                            selected={proposedCOEDate || ''}
                            onChange={(date) => setProposedCOEDate(date)}
                            formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
                            excludeDates={disabledDates(offerState.dateTimePicker)}
                            filterDate={isWeekday}
                            minDate={new Date(offerState.calendarStartDate)}
                            maxDate={new Date(offerState.calendarEndDate)}
                        />
                    </div>
                </div>
            </Modal.Content>
            <Modal.Footer>
                <Button
                    id="dashboard-status-send-coe-button"
                    data-qa="dashboard-status-send-coe-button"
                    type='submit'
                    large
                    disabled={submitting || !proposedCOEDate}
                    onClick={submitRequest}
                >
                    {submitting ? 'Sending...' : 'Send Request'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ChangeCOEModal;