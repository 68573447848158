import React, { useEffect } from 'react';
import { useOffer } from '../../context/offer/OfferContext';
import { loadOffer } from '../../context/offer/OfferReducer';
import { useParams, useHistory } from 'react-router-dom';
import PageLayout from '../Controls/PageLayout';
import DashboardStatus from '../Controls/Dashboard/DashboardStatus';
import './Dashboard.scss';
import ProgressBar from '../Controls/Dashboard/ProgressBar/ProgressBar';
import DashboardWhatsNext from '../Controls/Dashboard/DashboardWhatsNext/DashboardWhatsNext';
import DashboardTodos from '../Controls/Dashboard/DashboardTodos/DashboardTodos';
import { useDisposition } from '../../context/disposition/DispositionContext';
import getProgressInfoBuyer from '../Controls/resources/progressUtilBuyer';
import { stateCodes } from '../helpers/stateNames';
import WithdrawnRejectedCard from '../Controls/Dashboard/WithdrawnRejectedCard/WithdrawnRejectedCard';
import { DispositionStatus } from '../../services/DispositionService/types';
import { MessageAlert, AgentRepresentationProgramTierMax } from "src/services/DispositionService/constants";
import Spinner from '@offerpad/floorplan/dist/components/Spinner';
import { Alert } from 'floorplan/components/Alert';

const Dashboard = () => {
    const isBuyer = window.location.pathname.includes('/buyers/');

    return isBuyer ? <BuyerDashboard /> : <SellerDashboard />;
};

const BuyerDashboard = () => {
    const { state: disposition, getDisposition } = useDisposition();
    const { offerKey } = useParams<{ offerKey: string }>();
    let visualTrackerInfo = getProgressInfoBuyer(disposition?.dispositionOffer);
    useEffect(() => {
        if (disposition.dispositionOffer === null || disposition.dispositionOffer?.id?.toString() !== offerKey) {
            getDisposition(offerKey);
        }
    }, [offerKey])

    if (disposition === null || disposition.dispositionOffer === null) {
        return <></>;
    }

    const { dispositionOffer } = disposition;
    let submitMessageAlert = MessageAlert;
    let showMessageAlert = dispositionOffer.agentRepresentationProgramTier === AgentRepresentationProgramTierMax;
    return (
        <PageLayout
            streetAddress={'streetAddress'}
            headerText="Overview"
            subHeaderText={`Welcome back, ${dispositionOffer.agentFirstName}`}
            headerDescription={''}
            listedByAgent={true}
            centerColumn={
                <div className="dashboard" data-testid="buyer-dashboard">
                    <div className="header-data-fields">
                        <DataField header="Property Address">{`${dispositionOffer.propertyAddress}, ${dispositionOffer.propertyCity}, ${stateCodes[dispositionOffer.propertyState]} ${dispositionOffer.propertyZipcode}`}</DataField>
                        <DataField header="Buyers Name">
                            {`${dispositionOffer.buyerFirstName} ${dispositionOffer.buyerLastName}`}
                        </DataField>
                    </div>
                    {
                        dispositionOffer.status === DispositionStatus.DECLINED || dispositionOffer.status === DispositionStatus.WITHDRAWN ?
                            <WithdrawnRejectedCard />
                            :
                            <>
                                {
                                    visualTrackerInfo.workflowStep &&
                                    <ProgressBar workflowStep={visualTrackerInfo.workflowStep} currentProgressBar={visualTrackerInfo.progressBar} progressPercentage={visualTrackerInfo.progressPercentage} />

                                }
                                {showMessageAlert ?
                                    <Alert kind="warning">
                                        <b>Message from Offerpad:</b><hr />
                                        {submitMessageAlert}
                                    </Alert> : null}
                                <div className="grid-layout">
                                    <DashboardStatus className="grid-status" offer={dispositionOffer} isBuyer offerDispatch={null} />
                                    <div className="grid-todos">
                                        <DashboardTodos />
                                    </div>
                                    <div className="grid-whatsnext">
                                        <DashboardWhatsNext whatsNextHeading={''} whatsNextCopy={visualTrackerInfo.whatsNextCopy} faqs={null} />
                                    </div>
                                    <div className="grid-calendar">{/* <DashboardCalendar offer={null} /> */}</div>
                                </div>
                            </>
                    }
                </div>
            }
        />
    );
};

const SellerDashboard = () => {
    const {
        state: { offerState },
        dispatch: offerDispatch,
    } = useOffer();
    const { keyType, offerKey } = useParams<{ keyType: string; offerKey: string }>();
    const history = useHistory();

    useEffect(() => {
        loadOffer(offerDispatch, offerKey, keyType, history, false, 5, false);
    }, [offerKey]);

    if (!offerState?.requestKey) {
        return (
            <div className='spinner'>
                <Spinner />
            </div>
        );
    }
    else {
        if (offerState?.acquisitionId) {
            window.location.href = `${process.env.RAZZLE_CONNECT_URL}/sell/overview?transactionId=${offerState.acquisitionId}`;
        } else {
            window.location.href = `${process.env.RAZZLE_CONNECT_URL}/sell/dashboard`;
        }
    }
};

export default Dashboard;

const DataField = (props) => {
    const classes = ['data-field']
    if (props.notMobile) {
        classes.push('mobile-hidden')
    }
    return (
        <div className={classes.join(' ')}>
            <div className="data-field-name">{props.header}</div>
            <div className="data-field-value">{props.children}</div>
        </div>
    );
};
