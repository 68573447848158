import React, { useState, useRef, useEffect } from 'react';
import OfferRound from './OfferRound';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DispositionOffer, DispositionStatus } from '../../../services/DispositionService/types';
import chunk from 'lodash/chunk';
import last from 'lodash/last';
import styles from './OfferHistory.module.scss';
import NumberFormat from 'react-number-format';
import { useDisposition } from '../../../context/disposition/DispositionContext';
import OfferHistoryFooter from './OfferHistoryFooter';

type OfferHistoryProps = {
    isCompact?: boolean;
};

const OfferHistory: React.FC<OfferHistoryProps> = (props) => {
    const { isCompact } = props;
    return isCompact ? <Compact /> : <Extended />;
};

export const convertOffersToRounds = (offers: Array<DispositionOffer>): Array<Array<DispositionOffer>> => {
    let sortedOffers = offers.sort((a, b) => a.createdDatetime < b.createdDatetime ? -1 : (a.createdDatetime > b.createdDatetime ? 1 : 0));
    const offersToRounds = sortedOffers.slice(0, 2);
    for (let i = 1; i < sortedOffers.length - 1; i++) {
        offersToRounds.push(...sortedOffers.slice(i, i + 2));
    }
    return chunk(offersToRounds, 2);
};

const Compact: React.FC = () => {
    const [counter, setCounter] = useState<boolean>(false);
    const { state: disposition } = useDisposition();
    const { portalDispositionOfferDetails, listPrice } = disposition.dispositionOffer;
    const rounds = convertOffersToRounds(portalDispositionOfferDetails);
    const [activeRound, setActiveRound] = useState<number>(Math.max(rounds.length - 1, 0));
    const lastRound = last(rounds);

    return (
        <div className={styles.offer_history_compact}>
            <div className={styles.tabs}>
                {rounds.map((_, index) => (
                    <div
                        className={index === activeRound && !counter ? styles.active_tab : ''}
                        onClick={() => {
                            setActiveRound(index);
                            setCounter(false);
                        }}
                        key={index}
                    >
                        Round {index + 1}
                    </div>
                ))}
                {counter && lastRound.length > 1 &&  (
                    <div className={styles.active_tab} key={rounds.length + 1}>
                        Round {rounds.length + 1}
                    </div>
                )}
            </div>
            <div className={styles.rounds_compact}>
                {rowLabels(listPrice)}
                {counter && lastRound.length > 1? (
                    <OfferRound
                        isCompact
                        offers={[lastRound[1]]}
                        showCounterInputs={true}
                        round={rounds.length + 1}
                        isNew={true}
                        highestAndBestCounter={disposition.dispositionOffer.status === DispositionStatus.NEED_HIGHEST_BEST}
                    />
                ) : (
                    <OfferRound
                        isCompact
                        offers={rounds[activeRound] || []}
                        showCounterInputs={activeRound === rounds.length - 1 && counter}
                        round={activeRound}
                        isNew={rounds.length - 1 === activeRound}
                        highestAndBestCounter={disposition.dispositionOffer.status === DispositionStatus.NEED_HIGHEST_BEST}
                    />
                )}
            </div>
            <OfferHistoryFooter
                onCounter={() => setCounter(true)}
                onCancel={() => setCounter(false)}
                isCounter={counter}
            />
        </div>
    );
};

const Extended: React.FC = () => {
    const [counter, setCounter] = useState<boolean>(false);
    const { state: disposition } = useDisposition();
    const scrollRef = useRef(null);
    const { portalDispositionOfferDetails, listPrice, status } = disposition.dispositionOffer;
    const rounds = convertOffersToRounds(portalDispositionOfferDetails);
    const lastRound = last(rounds);
    const scrollAllTheWayRight = () => {
        // jsdom does not support scrollTo so we need to check if it exists
        scrollRef.current?.scrollTo && scrollRef.current?.scrollTo({
            top: scrollRef.current?.scrollTop,
            left: scrollRef.current?.scrollWidth - scrollRef.current?.clientWidth,
            behavior: 'smooth',
        });
    };
    // scroll all the way right on load
    useEffect(scrollAllTheWayRight, []);
    // scroll right on counter
    useEffect(() => {
        if (counter) {
            scrollAllTheWayRight();
        }
    }, [counter]);

    const scrollLeft = () => {
        scrollRef.current?.scrollTo({
            top: scrollRef.current?.scrollTop,
            left: scrollRef.current?.scrollLeft - 500,
            behavior: 'smooth',
        });
    };

    const scrollRight = () => {
        scrollRef.current?.scrollTo({
            top: scrollRef.current?.scrollTop,
            left: scrollRef.current?.scrollLeft + 500,
            behavior: 'smooth',
        });
    };

    return (
        <div className={styles.offer_history_extended}>
            <FontAwesomeIcon
                icon={['fas', 'chevron-left']}
                className={styles.left_chevron}
                onClick={() => scrollLeft()}
            />
            <FontAwesomeIcon
                icon={['fas', 'chevron-right']}
                className={styles.right_chevron}
                onClick={() => scrollRight()}
            />
            <div className={styles.rounds_extended} ref={scrollRef}>
                {rowLabels(listPrice, true)}
                <hr />
                {rounds.map((round, index) => (
                    <OfferRound
                        offers={round}
                        round={index + 1}
                        showCounterInputs={counter}
                        highestAndBestCounter={disposition.dispositionOffer.status === DispositionStatus.NEED_HIGHEST_BEST}
                        isNew={ (rounds.length === index + 1 && !counter) || (counter && lastRound.length <= 1)}
                        key={index}
                    />
                ))}
                {counter && lastRound.length > 1 && (
                    <OfferRound
                        offers={[lastRound[1]]}
                        round={rounds.length + 1}
                        showCounterInputs={counter}
                        highestAndBestCounter={disposition.dispositionOffer.status === DispositionStatus.NEED_HIGHEST_BEST}
                        isNew={true}
                        key={rounds.length}
                    />
                )}
            </div>
            <OfferHistoryFooter
                onCounter={() => setCounter(true)}
                onCancel={() => setCounter(false)}
                isCounter={counter}
            />
        </div>
    );
};

const  rowLabels = (listPrice: number, extended: boolean = false) => {
    return (
        <div className={styles.offer_details}>
            {extended && <div></div>}
            <div className={styles.list_price}>
                <div>List price</div>
                <NumberFormat thousandSeparator={true} prefix={'$'} value={listPrice} displayType={'text'} />
            </div>
            <div>Closing Date</div>
            <div>Offer Price</div>
            <div>Requested Concessions</div>
            <div>Earnest Money</div>
            <div>Message (optional)</div>
        </div>
    );
}

export default OfferHistory;
