import { formatDate } from './datetime';
import { CurrentTasks, CompletedTasks, InProgressTasks } from './content/checklist';
import { stateNames } from '../../helpers/stateNames';

export default function buildCheckList(offerState, disclosureInfo, documents, dashboard:boolean = false) {
    const sellUrl = process.env.RAZZLE_SELL_URL;
    const currentTasks = new CurrentTasks(offerState);
    const inProgressTasks = new InProgressTasks(offerState);
    const completedTasks = new CompletedTasks(offerState);
    const { propertyDisclosure, sellersForDisclosure, marketRequiresDisclosures } = disclosureInfo || {};

    // const offerState = state.offerState;
    const checkList = {
        current: [],
        inProgress: [],
        completed: [],
    };

    if (!offerState.thereIsOffer) {
        return null;
    }

    const timeZoneId = offerState.timeZoneId;
    const movingCompany = offerState.movingCompany;
    const titleCompany = offerState.titleCompany;

    // 16513 - Checklist Item 1: Upload photos uploadPhotos
    if (offerState.keyType !== 'interest' && offerState.uploadPhotos) {
        checkList.current.push({
            ...currentTasks.uploadPhotos(),
            showCompletionCriteria: offerState.uploadPhotosCompletionCriteria,
            buttonUrl: offerState.pathUploadPhotos,
        });
    }

    // Request offer
    if (offerState.keyType === 'interest' || offerState.isLost) {
        checkList.current.push({
            ...currentTasks.requestOffer(offerState),
            buttonUrl: sellUrl,
        });
        return checkList;
    }

    // 16511 - Checklist Item 2: Review your offer
    if (offerState.hasToReviewOffer) {
        checkList.current.push({
            ...currentTasks.reviewOffer(),
            buttonUrl: `${sellUrl}/offer/${offerState.offerKey}`,
        });
    } else if (offerState.accepted) {
        checkList.completed.push({
            ...completedTasks.reviewOffer(),
            completionDate: formatDate(offerState.acceptedDateTime, timeZoneId),
        });
    }

    // 16514 - Checklist Item 3: Sign the contract
    if (offerState.needsToSignContract) {
        checkList.current.push({ ...currentTasks.signContract() });
    } else if (offerState.contractSigned) {
        checkList.completed.push({
            ...completedTasks.signContract(),
            completionDate: formatDate(offerState.contractSignedDateTime, timeZoneId),
        });
    }

    /*
        TODO(Story 26621):
            Uncomment the following block of code once Helix adds the ability
            to see the two new fields that were added to the Utilities form, namely
            solarServicingCompanyName and finalComments
    */

    // Utilities form
    if (offerState.contractSigned && offerState.showUtilitiesForm) {
        let utilitiesForm = offerState.utilitiesForm;
        if (!!utilitiesForm) {
            checkList.completed.push(
                completedTasks.utilitiesFormCompleted(formatDate(utilitiesForm.modifiedDateTime, timeZoneId))
            );
        } else {
            checkList.current.push(currentTasks.utilitiesFormNeeded(offerState.utilitiesFormLink));
        }
    }

    // 16526 - Checklist Item 13: Contact title about an issue
    if (titleCompany && offerState.titleWaitingOnSeller && offerState.sellerInformationSheetReceived) {
        checkList.current.push({
            ...currentTasks.contactTitle(titleCompany),
            buttonUrl: dashboard ? offerState.pathChecklist : offerState.pathYourTeam,
        });
    }

    // 16515 - Checklist Item 4: [TitleCompany] needs information from you
    if (offerState.isTimeToContactTitle) {
        if (offerState.hasNotContactedTitle) {
            checkList.current.push({
                ...currentTasks.titleNeedsInfo(titleCompany),
                buttonUrl: dashboard ? offerState.pathChecklist : offerState.pathYourTeam,
            });
        } else if (offerState.hasContactedTitle) {
            checkList.completed.push(completedTasks.titleNeedsInfo());
        }
    }

    // Seller disclosures
    if (marketRequiresDisclosures && !!propertyDisclosure) {
        const totalSellers = sellersForDisclosure.length;
        const sellersThatSigned = sellersForDisclosure.filter((x) => x.signed).length;
        const propertyDisclosureCompleted =
            propertyDisclosure.status === 'Completed' && !!propertyDisclosure.completedDateTime;
        if (propertyDisclosureCompleted) {
            checkList.completed.push({
                ...completedTasks.disclosuresFormCompleted(),
                completionDate: formatDate(propertyDisclosure.completedDateTime, timeZoneId),
            });
        } else if (propertyDisclosure.sentEmbedded && totalSellers > 0) {
            /* can sign through Portal */
            if (sellersThatSigned === 0) {
                checkList.current.push(
                    currentTasks.signSellerDisclosuresEmbedded(stateNames[offerState.state], sellersForDisclosure)
                );
            } else if (sellersThatSigned < totalSellers) {
                checkList.inProgress.push(
                    inProgressTasks.signSellerDisclosuresEmbedded(stateNames[offerState.state], sellersForDisclosure)
                );
            } else if (sellersThatSigned === totalSellers) {
                checkList.inProgress.push(inProgressTasks.disclosuresWaitingBuyerSignature());
            }
        } else if (!propertyDisclosure.sentEmbedded) {
            /* disclosures sent via email */
            checkList.current.push(currentTasks.signSellerDisclosuresEmail());
        }
    }

    // 16520 - Checklist Item 6: Look out for an email from the moving company
    if (offerState.emailFromMovingCompanyExpected) {
        checkList.current.push(currentTasks.checkMovingCompanyEmail());
    }

    // 16518 - Checklist Item 7: Schedule the home inspection
    if (offerState.needsToScheduleHomeInspection) {
        let owner = ['South Charlotte', 'Charlotte', 'Raleigh'].includes(offerState.market)
            ? offerState.closingConsultant
            : offerState.homeMarketingConsultant;
        const checkListItem = currentTasks.scheduleHomeInspection(owner);
        if (!!checkListItem) {
            checkList.current.push({
                ...checkListItem,
                buttonUrl: offerState.pathYourTeam,
            });
        }
    } else if (offerState.homeInspectionScheduled) {
        checkList.completed.push({
            ...completedTasks.scheduleHomeInspection(),
            completionDate: formatDate(offerState.homeInspectionCreatedDateTime, timeZoneId),
        });
    }

    // 16522 - Checklist Item 8: Sign Post Possession Addendum
    if (offerState.needsTosignPostPossessionAddendum) {
        checkList.current.push({ ...currentTasks.signPostPossessionAddendum() });
    } else if (offerState.postPossessionAddendumSigned) {
        checkList.completed.push({
            ...completedTasks.signPostPossessionAddendum(),
            completionDate: formatDate(offerState.postPossessionContractCompletedDateTime, timeZoneId),
        });
    }

    // 22125 - Checklist Item: Sign Extended Stay Addendum
    if (offerState.needsTosignExtendedStayAddendum) {
        checkList.current.push({ ...currentTasks.signExtendedStayAddendum() });
    } else if (offerState.extendedStayAddendumSigned) {
        checkList.completed.push({
            ...completedTasks.signExtendedStayAddendum(),
            completionDate: formatDate(offerState.extendedStayContractCompletedDateTime, timeZoneId),
        });
    }

    // 22125 - Checklist Item: Sign Extended Stay Cancellation Addendum
    if (offerState.needsTosignExtendedStayCancellationAddendum) {
        checkList.current.push({ ...currentTasks.signExtendedStayCancellationAddendum() });
    } else if (offerState.extendedStayCancellationAddendumSigned) {
        checkList.completed.push({
            ...completedTasks.signExtendedStayCancellationAddendum(),
            completionDate: formatDate(offerState.extendedStayCancellationContractCompletedDateTime, timeZoneId),
        });
    }

    // 22125 - Checklist Item: Sign Extended Stay COE Change Addendum
    if (offerState.needsTosignExtendedStayCOEChangeAddendum) {
        checkList.current.push({ ...currentTasks.signExtendedStayCOEChangeAddendum() });
    } else if (offerState.extendedStayCOEChangeAddendumSigned) {
        checkList.completed.push({
            ...completedTasks.signExtendedStayCOEChangeAddendum(),
            completionDate: formatDate(offerState.extendedStayCOEChangeContractCompletedDateTime, timeZoneId),
        });
    }

    // 22125 - Checklist Item: Sign Extended Stay COE Change Cancellation Addendum
    if (offerState.needsTosignExtendedStayCancellationCOEChangeAddendum) {
        checkList.current.push({ ...currentTasks.signExtendedStayCancellationCOEChangeAddendum() });
    } else if (offerState.extendedStayCancellationCOEChangeAddendumSigned) {
        checkList.completed.push({
            ...completedTasks.signExtendedStayCancellationCOEChangeAddendum(),
            completionDate: formatDate(
                offerState.extendedStayCancellationCOEChangeContractCompletedDateTime,
                timeZoneId
            ),
        });
    }

    // 16524 - Checklist Item 10: Schedule inspection review with TM
    // Contract shouldn't exist yet
    if (offerState.needsToScheduleInspectionReview) {
        checkList.current.push({
            ...currentTasks.scheduleInspectionReview(offerState.closingConsultantFullName),
            buttonUrl: offerState.closingConsultantLink,
        });
    }

    // 19630 - Post inspection survey
    let postInspectionSurvey = offerState.postInspectionSurvey;
    if (!!postInspectionSurvey === false && offerState.postInspectionSurveyReady) {
        checkList.current.push({
            ...currentTasks.completePostInspectionSurvey(),
            buttonUrl: offerState.postInspectionSurveyLink,
        });
    } else if (!!postInspectionSurvey === true) {
        const completionDate = formatDate(postInspectionSurvey.modifiedDateTime, timeZoneId);
        checkList.completed.push({
            ...completedTasks.postInspectionSurveyCompleted(completionDate),
            completionDate: completionDate,
        });
    }

    // 16525 - Checklist Item 11: Review and sign credit
    if (offerState.creditAddendumSent) {
        checkList.current.push(currentTasks.reviewAndSignCredit(offerState.contactEmail));
    } else if (offerState.creditAddendumCompleted) {
        checkList.completed.push(completedTasks.reviewAndSignCredit());
    }

    // 16603 - Checklist Item 12: Review and sign credit and repair addendum
    if (offerState.creditAndRepairAddendumSent) {
        checkList.current.push(currentTasks.reviewAndSignCreditAndRepairAddendum(offerState.contactEmail));
    } else if (offerState.creditAndRepairAddendumCompleted) {
        checkList.completed.push({
            ...completedTasks.reviewAndSignCreditAndRepairAddendum(),
            completionDate: formatDate(offerState.creditAndRepairAddendumCompletedDateTime, timeZoneId),
        });
    }

    // 16621 - Checklist Item 14: Contact [Moving Partner]
    if (offerState.needsToContactMovingCompany) {
        checkList.current.push({
            ...currentTasks.contactMovingCompany(movingCompany),
            buttonUrl: dashboard ? offerState.checkList : offerState.pathYourTeam,
        });
    }

    // 17053 - New Status Tracker Item: Checklist Item Sign COE Addendum
    if (offerState.needsToSignCloseOfEscrowAddendum) {
        checkList.current.push(
            currentTasks.signCOEAddendum(
                formatDate(offerState.closeOfEscrowAddedndumSentSignByDateTime, timeZoneId, true)
            )
        );
    } else if (offerState.closeOfEscrowAddendumSigned) {
        checkList.completed.push(
            completedTasks.signCOEAddendum(
                formatDate(offerState.closeOfEscrowAddedndumCompletedDateTime, timeZoneId, true)
            )
        );
    }

    // 16527 - Checklist Item 15: Schedule closing appointment with title
    if (offerState.needsToScheduleSigningAppointment) {
        checkList.current.push(currentTasks.scheduleClosingAppointment(titleCompany));
    } else if (offerState.signingAppointmentScheduled) {
        checkList.completed.push({
            ...completedTasks.scheduleClosingAppointment(titleCompany),
            completionDate: formatDate(offerState.signingApptDateTime, timeZoneId),
        });
    }

    // 18912 - exit survey
    if (offerState.isAcquired && offerState.currentStep === 'Closed') {
        let exitSurvey = offerState.exitSurvey;
        if (!!exitSurvey) {
            checkList.completed.push(
                completedTasks.closedSurveyCompleted(formatDate(exitSurvey.modifiedDateTime, timeZoneId))
            );
        } else {
            checkList.current.push(currentTasks.checkCompletedClosingSurvey(offerState.closedSurveyUrl));
        }
    }

    const completedStatuses = ['PendingApproval', 'Uploaded', 'Approved'];
    const pendingUploadStatuses = ['PendingUpload', 'Denied'];

    if (documents) {
        documents
            .filter((document) => pendingUploadStatuses.includes(document.status))
            .forEach((document) => checkList.current.push(currentTasks.uploadDocument(document)));

        documents
            .filter((document) => completedStatuses.includes(document.status))
            .forEach((document) => checkList.completed.push(completedTasks.uploadDocument(document)));
    }
    return checkList;
}
