import React, { useReducer, createContext, useContext, ReactNode } from 'react';
import { offerReducer, initialState, State, Dispatch } from './OfferReducer';

const OfferContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

type OfferProviderProps = { children: ReactNode };

const OfferProvider: React.FC<OfferProviderProps> = ({ children }) => {
	const [state, dispatch] = useReducer(offerReducer, initialState);;
	const value = { state, dispatch };
	return <OfferContext.Provider value={value}>{children}</OfferContext.Provider>;
};

const useOffer = () => {
	const context = useContext(OfferContext);
	if (context === undefined) {
		throw new Error('useOffer must be used within an OfferProvider');
	}
	return context;
};

export { useOffer, OfferProvider, OfferContext };
