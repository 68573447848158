import React, { useEffect, useMemo } from 'react';
import PageLayout from '../Controls/PageLayout';
import { useOffer } from '../../context/offer/OfferContext';
import { loadOffer } from '../../context/offer/OfferReducer';
import { useParams, useHistory } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import getCalendarEvents from '../Controls/resources/calendarUtil';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import Agenda from '../Controls/Agenda';
import ProgressBar from '../Controls/Dashboard/ProgressBar/ProgressBar';
import getProgressInfoSeller from '../Controls/resources/progressUtilSeller';
import { useModal, openModal } from '@offerpad/concrete.react/lib/Modal';
import { Modals } from '../Controls/ModalController/ModalController';
import './Calendar.scss';
import '@assets/sass/offerpad/paper/plugins/_plugin-fullcalendar.scss';

const Calendar = () => {
    const { state: { offerState }, dispatch: offerDispatch } = useOffer();
    const { keyType, offerKey } = useParams<{ keyType: string, offerKey: string }>();
    const history = useHistory();
    const visualTrackerInfo = getProgressInfoSeller(offerState);

    useEffect(() => {
        if (offerState.offerKey !== offerKey || offerState.keyType !== keyType || !offerState.thereIsOffer) {
            loadOffer(offerDispatch, offerKey, keyType, history);
        }
    }, [keyType, offerKey]);

    const events = getCalendarEvents(offerState);
    const MemoizedFullCalendar = useMemo(() => FullCalendarMemo(events), [offerState.acquisitionId]);

    if (!offerState.thereIsOffer) {
        return null;
    }

    return (
        <PageLayout
            streetAddress={offerState.streetAddress}
            headerDescription={visualTrackerInfo.sentenceTop}
            headerText='View calendar events'
            subHeaderText={`Welcome back, ${offerState.firstName}`}
            listedByAgent={offerState.listedByAgent}
            centerColumn={(
                <>
                    {
                        visualTrackerInfo.workflowStep
                    && (
                        <ProgressBar show={!offerState.isLost} workflowStep={visualTrackerInfo.workflowStep}
                            currentProgressBar={visualTrackerInfo.progressBar} progressPercentage={visualTrackerInfo.progressPercentage}
                        />
                    )
                    }
                    {MemoizedFullCalendar}
                    <Agenda events={events} timeZoneId={offerState.timeZoneId} />
                </>
            )}
        />
    );
};

const FullCalendarMemo = events => {
    const { dispatch: modalDispatch } = useModal();

    const handleDatesRender = () => {
        const temp = document.getElementsByClassName('fc-header-toolbar')[0];
        const title = temp.getElementsByClassName('fc-center')[0] as HTMLElement;
        const titleParts = title.innerText.split(' ');
        const month = titleParts[0].trim() === '-' ? moment().format('MMMM') : titleParts[0];
        title.innerHTML = `${month}`;
    };

    const handleEventClick = info => {
        const {modalContent} = info.event.extendedProps;
        if (isEmpty(modalContent)) {
            return;
        }
        const eventStart = moment(info.event.start);
        const now = moment();
        if (
            now.year() > eventStart.year()
            || (now.year() === eventStart.year() && now.month() > eventStart.month())
            || (now.year() === eventStart.year() && now.month() === eventStart.month() && now.date() > eventStart.date())
        ) {
            return;
        }
        openModal(modalDispatch, Modals.CALENDAR_EVENT_MODAL, { modalContent });
    };

    const isEventInCurrentMonth = info => {
        const monthStart = moment(info.view.currentStart);
        const monthEnd = moment(info.view.currentEnd);
        return moment(info.event.start).isBetween(monthStart, monthEnd);
    };

    const eventRender = info => {
        if (isEventInCurrentMonth(info)) {
            let eventStyle = '';
            if (!isEmpty(info.event.extendedProps.modalContent)) {
                eventStyle = 'style="cursor: pointer !important;"';
                info.el.firstChild.innerHTML = `<div class='calendar-event-enabled' ${eventStyle}>${info.event.extendedProps.eventHtml}</div>`;
            } else {
                info.el.firstChild.innerHTML = `<div class='calendar-event-green' ${eventStyle}>${info.event.extendedProps.eventHtml}</div>`;
            }
        } else {
            info.el.firstChild.innerHTML = `<div class='calendar-event-disabled'>${info.event.extendedProps.eventHtml}</div>`;
        }
    };

    return (
        <div className='c-calendar'>
            <FullCalendar
                showNonCurrentDates
                defaultView='dayGridMonth'
                header={{ left: 'prev', center: 'title', right: 'next' }}
                themeSystem=''
                eventLimit={1}
                eventLimitText={number => `${number}`}
                displayEventTime={false}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin]}
                weekends
                events={events}
                eventClick={handleEventClick}
                datesRender={handleDatesRender}
                eventRender={eventRender}
            />
        </div>
    );
};

export default Calendar;
