
export const isAgent = (offerState) => {
    return (offerState && offerState.listedByAgent) || false;
};

/**
 *
 * @param {string} agent agent text
 * @param {string} user user text
 */
export const agentUser = (agent = '', user = '', offerState) => {
    return isAgent(offerState) ? agent : user;
};

export const theMy = (offerState) => {
    return isAgent(offerState) ? 'the' : 'my';
};

export const wooCongrat = (offerState) => {
    return isAgent(offerState) ? "Woo-hoo, it's happening!" : "Congratulations! It's happening!";
};

const buildValue = (a, b, c = null) => {
    return { step: a, homePictureBadgeText: b, action: c };
}

export const getStatusObjectNotExpired = (offerState, currentStatus) => {
    const signingApptDateTime = offerState.signingApptDateTime;
    const clearedToClose = offerState.clearedToClose;
    const escrowCloseDate = offerState.escrowCloseDate;
    const offerKey = offerState.offerKey;
    const sellUrl = process.env.RAZZLE_SELL_URL;

    switch (offerState.currentStep) {
        // 16450 - Transaction Tracker: Workflow New Request
        case 'NewRequest':
        case 'OfferInProgress':
        case 'PresentOffer':
        case 'OfferReview':
            return buildValue(currentStatus.newOffer(), 'Working on Offer');
        // 16942 - Transaction Tracker: Offer Renewal
        case 'RenewalRequest':
            return buildValue(currentStatus.renewalRequest(), 'Working on Offer');
        // 16437 - Transaction Tracker: Workflow OfferSent
        case 'OfferSent':
            return buildValue(currentStatus.offerSent(`${sellUrl}/offer/${offerKey}`), 'Offer Sent');
        // 16780: Transaction Tracker: Workflow Contract In Progress
        case 'ContractInProgress':
            return buildValue(currentStatus.contractInProgress(), 'Offer Sent');
        // 16453 - Transaction Tracker: ContractSent
        case 'ContractSent':
            return buildValue(currentStatus.contractSent(), 'Contract Sent');
        // 16434 - Transaction Tracker: Workflow ContractSigned
        case 'ContractSigned':
            return buildValue(currentStatus.contractSigned(), 'Under Contract');
        // 16435 - Transaction Tracker: Workflow EscrowOpened
        case 'EscrowOpened':
            if (offerState.offerExtended) {
                return buildValue(currentStatus.escrowOpened(), 'Under Contract');
            }
            break;
        case 'InspectionOnHold':
            // 16904 - Transaction Tracker: Inspection on Hold
            return buildValue(currentStatus.inspectionOnHold(), 'Under Contract');
        case 'InspectionScheduled':
            // 16904 - Transaction Tracker: Inspection on Hold
            if (offerState.inspectionOnHold) {
                return buildValue(currentStatus.inspectionOnHold(), 'Under Contract');
            }
            // 16477 - Transaction Tracker: Workflow InspectionScheduled
            if (offerState.inspectionScheduled) {
                return buildValue(currentStatus.inspectionScheduled_scheduled(), 'Inspection Scheduled');
            }
            // 16454 - Transaction Tracker: Workflow Inspection Appt Done
            if (!offerState.inspectionSubmittedDateTime) {
                return buildValue(currentStatus.waitingForInspectionResults(), 'Reviewing Inspection');
            }
            return buildValue(currentStatus.waitingForInspectionResults(), 'Reviewing Inspection');
        case 'InspectionCompleted':
        case 'OkInspection':
            // 16496 - Transaction Tracker: Workflow Inspection Results Ready (Credit only Needed)
            if (offerState.creditAddendum) {
                if (offerState.creditAddendumAmountIsNaN) {
                    break;
                }
                return buildValue(currentStatus.inspectionResults_creditOnly(offerState.creditAddendumAmount), 'Credit Request Sent');
            }
            // 16495 - Transaction Tracker: Workflow Inspection Results Ready (C & R Needed)
            if (offerState.creditAndRepairAddendum) {
                if (offerState.creditAndRepairAddendumAmountIsNaN) {
                    break;
                }
                return buildValue(currentStatus.inspectionResults_creditAndRepair(offerState.creditAndRepairAddendumAmount), 'Credit and Repair Request Sent');
            }
            // 16478 - Transaction Tracker: Workflow InspectionCompleted (Official)
            if (!!offerState.officialInspectionCompleted) {
                return buildValue(currentStatus.inspectionCompleted(), 'Reviewing Inspection');
            }
            return buildValue(currentStatus.waitingForInspectionResults(), 'Reviewing Inspection');
        case 'PurchaseInProgress':
        case 'WalkThroughScheduled':
            const creditAndRepairSkipped = offerState.creditAndRepairSkipped;
            if (clearedToClose && signingApptDateTime) {
                return buildValue(currentStatus.purchaseInProgressC(), 'Cleared to Close');
            }
            if (clearedToClose && !signingApptDateTime) {
                return buildValue(currentStatus.purchaseInProgressB(), 'Cleared to Close');
            }
            if (!clearedToClose && signingApptDateTime) {
                return buildValue(currentStatus.purchaseInProgressA(), 'Prepare for Closing');
            }
            if (!clearedToClose && !signingApptDateTime && creditAndRepairSkipped && escrowCloseDate) {
                return buildValue(currentStatus.purchaseInProgressD(), 'Prepare for Closing');
            }
            if (!clearedToClose && !signingApptDateTime && !creditAndRepairSkipped && escrowCloseDate) {
                return buildValue(currentStatus.purchaseInProgressE(), 'Prepare for Closing');
            }
            break;
        case 'WalkThroughCompleted':
            return buildValue(currentStatus.walkThroughCompleted(), 'Prepare for Closing');
        case 'Closed':
            // 16501 - Transaction Tracker: Closed (No extended stay)
            // 16502 - Transaction Tracker: Closed (Yes extended stay)
            if (offerState.isAcquired) {
                if (!offerState.postPossessionAddendum) {
                    return buildValue(currentStatus.closed_noExtendedStay(), 'Sold to Offerpad');
                }
                if (offerState.postPossessionMoveOutDate) {
                    return buildValue(currentStatus.closed_yesExtendedStay(), 'Sold to Offerpad');
                }
            }
    }
    return buildValue(currentStatus.fallBackStatus(), null);
}
