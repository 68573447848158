import { FieldBinderFactory } from '@offerpad/concrete/lib/model';
import FormBinder from '@offerpad/concrete/lib/model/FormBinder';
import Validator from '@offerpad/concrete/lib/validation/Validator';
import * as rules from '@offerpad/concrete/lib/validation/rules';
import Fields from './fields';

export function create(binders: FieldBinderFactory): FormBinder {
    return new FormBinder({
        [Fields.Email]: binders.string(
            Validator.create([
                rules.required('The email field is required'),
                rules.email('The email field must be a valid email')
            ])
        ),
        [Fields.Password]: binders.string(
            Validator.create([
                rules.required('The password field is required')
            ])
        )
    });
}
