import React from "react";
import '../../../assets/sass/offerpad/inputs.scss';

type CheckboxProps = {
    disabled?: boolean,
    inline?: boolean,
    onChange: Function;
    children: React.ReactNode;
    className?: string;
    checked?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
    const { disabled = false, inline = false, onChange, children, className = '', checked = false} = props;
    const cbId = Math.random().toString(16).slice(2);
    return <div className={`op-checkbox  form-check ${disabled ? 'disabled' : ''} ${inline ? 'form-check-inline' : ''} ${className}`}>
        <label className="form-check-label">
            <input id={cbId} className="form-check-input" type="checkbox" disabled={disabled} onChange={(e) => onChange(e)} checked={checked} />
            <span className="form-check-sign"></span>
            {inline ? '&nbsp;' : ''}
            {children}
        </label>
    </div>
}

export default Checkbox;