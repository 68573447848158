import React, { useReducer, createContext, useContext } from 'react';
import { requestReducer, initialState, State, Dispatch } from './RequestsReducer';

const RequestsContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

type RequestsProviderProps = { children: React.ReactNode };

const RequestsProvider: React.FC<RequestsProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(requestReducer, initialState);
    const value = { state, dispatch };

    return <RequestsContext.Provider value={value}>{children}</RequestsContext.Provider>;
};

const useRequest = () => {
    const context = useContext(RequestsContext);
    if (context === undefined) {
        throw new Error('useRequest must be used within an RequestProvider');
    }
    return context;
};

export { useRequest, RequestsProvider, RequestsContext };
