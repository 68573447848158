import React, { useEffect } from 'react';
import styles from './AddressDropdown.module.scss';
import { useRequest } from '../../../context/request/RequestsContext';
import { useOffer } from '../../../context/offer/OfferContext';
import { getHomes, preOfferSteps } from '../../../context/request/RequestsReducer';
import { useHistory, useParams } from 'react-router';


const AddressDropdown = () => {
    const { state: { requests }, dispatch: requestsDispath } = useRequest();
    const { state: { offerState } } = useOffer();
    const { keyType } = useParams<{ keyType: string, offerKey: string }>();

    const history = useHistory();

    useEffect(() => {
        if (requests.length === 0) {
            getHomes(requestsDispath);
        }
    }, [])

    if (keyType === 'interest' && requests.length > 0) {
        history.push(`/home`);
    }

    if (!offerState.thereIsOffer) {
        return null;
    }

    // filter out addresses that aren't suppose to be visible
    const addresses = requests.filter(request => request && !(
        request.isLost ||
        request.currentStep === 'ApprovedCancellation' ||
        (!request.accepted && request.currentStep === 'Closed') ||
        request.isConcierge === true) &&
        request.property.streetAddress?.toLowerCase() != offerState.streetAddress?.toLowerCase());

    const onChange = (e) => {
        const address = e.target.value;
        if (address === offerState.streetAddress?.toLowerCase()) {
            return;
        }
        const request = requests.find(request => request.property.streetAddress?.toLowerCase() === address);

        if (request) {
            const keyType = preOfferSteps.includes(request.currentStep) ? 'request' : 'offer';
            history.push(`/${keyType}/${request.key || request.request.key}/dashboard`)
        }
    }

    return (
        <label className={`${styles.wrap} ${addresses.length === 0 ? styles.wrap_hide_after : ''}`} onChange={(e) => onChange(e)}>
            <select className={styles.select} disabled={addresses.length === 0}>
                <option key={offerState.streetAddress?.toLowerCase()}>
                    {offerState.streetAddress}
                </option>
                {addresses.map(address =>
                    <option key={address.property.streetAddress?.toLowerCase()} value={address.property.streetAddress?.toLowerCase()}>
                        {address.property.streetAddress}
                    </option>)}
            </select>
        </label>
    )
}

export default AddressDropdown;
