export default (key, keyType, isConcierge = false) => {
    let sidebarLinks = [];

    const overview = {
        name: 'Overview',
        icon: ['fas', 'tachometer'],
        path: `/${keyType}/${key}/dashboard`,
    };
    sidebarLinks.push(overview);

    const todo = {
        name: 'To-Dos',
        icon: ['fas', 'clipboard-list-check'],
        path: `/${keyType}/${key}/checklist`,
    };

    if (!isConcierge) {
        sidebarLinks.push(todo);
    }

    const calendar = {
        name: 'Calendar',
        icon: ['fas', 'calendar-alt'],
        path: `/${keyType}/${key}/calendar`,
    };
    sidebarLinks.push(calendar);

    const team = {
        name: 'Your Team',
        icon: ['fas', 'users-class'],
        path: `/${keyType}/${key}/your-team`,
    };
    sidebarLinks.push(team);

    /* Allow user to upload photos only before the offer was extended */
    if (keyType === 'request') {
        const upload = {
            name: 'Photos',
            icon: ['fas', 'images'],
            path: `/${keyType}/${key}/upload-photos`,
        };
        sidebarLinks.push(upload);
    }

    const documents = {
        name: 'Documents',
        icon: ['fas', 'file'],
        path: `/${keyType}/${key}/documents`,
    };

    if (!isConcierge) {
        sidebarLinks.push(documents);
    }
    
    return sidebarLinks;
};
