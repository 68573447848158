import './Agenda.scss';
import { formatDate } from './resources/datetime';
import React, { useState } from "react";
import CollapseItem from '../Controls/CollapseItem';
import isEmpty from 'lodash/isEmpty';

type AgendaProps = {
    events: Array<any>
    timeZoneId: string;
}

const Agenda: React.FC<AgendaProps> = (props) => {
    const { events, timeZoneId } = props;
    const [eventShown, setEventShown] = useState<string>()
    
    return <div className="card agenda">
        <h5>Today is {formatDate(new Date().toString(), timeZoneId)}</h5>
        <div id="accordion" role="tablist" aria-multiselectable="true" className="card-collapse accordion">
            {
                events.map(event => {
                    const isAbsoluteDate = (event.title === 'Close of Escrow' || event.title === "Move Out Deadline");
                    return <CollapseItem
                        key={event.title}
                        title={event.title}
                        date={formatDate(event.start, timeZoneId, isAbsoluteDate)}
                        hasContent={!isEmpty(event.extendedProps.modalContent)}
                        show={event.title === eventShown}
                        toggle={(title) => setEventShown(title)}
                    >
                        {event.extendedProps.modalContent && <div >
                            <div className="c-agenteventcard">
                                <div className="c-agenteventcard__middle">
                                    <img
                                        className="c-agenteventcard__image"
                                        src={event.extendedProps.modalContent.illustrationPath}
                                    />
                                    {event.extendedProps.modalContent.description && <p
                                        style={{ textAlign: 'left', padding: '1rem' }}
                                        className="c-agenteventcard__desc"
                                        dangerouslySetInnerHTML={{ __html: event.extendedProps.modalContent.description }}
                                    />}
                                </div>
                            </div>
                        </div>}
                    </CollapseItem>
                })
            }
        </div>
    </div>
}

export default Agenda;