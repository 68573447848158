import React from 'react';
import Datetime from 'react-datetime';
import './DateTimepicker.scss';

interface DateTimePickerProps {
    onChange: (any) => void;
    blockedDates: any;
    className?: string;
    value: Date;
}

const DateTimePicker: React.FC<DateTimePickerProps> = props => {
    const { onChange, blockedDates, className, value } = props;

    const renderDay = (props, currentDate) => {
        return (
            <td {...props}>
                <span>{currentDate.date()}</span>
            </td>
        );
    };

    return (
        <Datetime
            value={value}
            dateFormat={'MMM, ddd D'}
            onChange={onChange}
            timeFormat={false}
            isValidDate={blockedDates}
            renderDay={renderDay}
            className={className}
            inputProps={{
                onKeyDown: (e) => e.preventDefault()
            }}
        />
    );
};

export default DateTimePicker;
