import { clear } from 'console';
import get from 'lodash/get';
import moment from 'moment-timezone';

const storeContractSignedDate = (ref) => {
    const event = ref.contracts.find((x) => x.category === 'PurchaseContract' && x.completedDateTime);
    ref.contractSignedDate = get(event, 'completedDateTime', null);
};

const storeEscrowCloseDate = (ref) => {
    const tempContracts = ref.contracts
        .filter((x) => x.closeDateTime && x.completedDateTime)
        .sort((a, b) => -moment(a.completedDateTime).diff(moment(b.completedDateTime), 'seconds'));
    ref.escrowCloseDate = tempContracts.length > 0 ? tempContracts[0].closeDateTime : null;
};

const storeRequestSubmittedDate = (ref) => {
    const event = ref.events.find((x) => x.name === 'Request Submitted' && x.date);
    ref.requestSubmittedDate = get(event, 'date', false);
};

const storeOfferExtendedDate = (ref) => {
    const event = ref.events.find((x) => x.name === 'Offer Extended' && x.date);
    ref.offerExtendedDate = get(event, 'date', null);
};

const storePostPossessionAddendum = (ref) => {
    const postPossessionAddendum = ref.contracts
        .filter(
            (x) =>
                ref.postPossessionAddenda &&
                ref.postPossessionAddenda.includes(x.category) &&
                (x.status === 'Completed' || x.status === 'Sent')
        )
        .sort((a, b) => moment(a.postPossessionDate).diff(moment(b.postPossessionDate), 'seconds'));

    ref.postPossessionAddendum = postPossessionAddendum === [] ? null : postPossessionAddendum[0];
};

const storeExtendedStayAddendum = (ref) => {
    const extendedStayAddendum = ref.contracts
        .filter((x) => x.category === 'ExtendedStayAddendum' && (x.status === 'Completed' || x.status === 'Sent'))
        .sort((a, b) => moment(a.postPossessionDate).diff(moment(b.postPossessionDate), 'seconds'));

    ref.extendedStayAddendum = extendedStayAddendum === [] ? null : extendedStayAddendum[0];
};

const storeExtendedStayCancellationAddendum = (ref) => {
    const extendedStayCancellationAddendum = ref.contracts
        .filter(
            (x) =>
                x.category === 'ExtendedStayCancellationAddendum' && (x.status === 'Completed' || x.status === 'Sent')
        )
        .sort((a, b) => moment(a.postPossessionDate).diff(moment(b.postPossessionDate), 'seconds'));

    ref.extendedStayCancellationAddendum =
        extendedStayCancellationAddendum === [] ? null : extendedStayCancellationAddendum[0];
};

const storeExtendedStayCOEChangeAddendum = (ref) => {
    const extendedStayCOEChangeAddendum = ref.contracts
        .filter(
            (x) => x.category === 'ExtendedStayCOEChangeAddendum' && (x.status === 'Completed' || x.status === 'Sent')
        )
        .sort((a, b) => moment(a.postPossessionDate).diff(moment(b.postPossessionDate), 'seconds'));

    ref.extendedStayCOEChangeAddendum = extendedStayCOEChangeAddendum === [] ? null : extendedStayCOEChangeAddendum[0];
};

const storeExtendedStayCancellationCOEChangeAddendum = (ref) => {
    const extendedStayCancellationCOEChangeAddendum = ref.contracts
        .filter(
            (x) =>
                x.category === 'ExtendedStayCancellationCOEChangeAddendum' &&
                (x.status === 'Completed' || x.status === 'Sent')
        )
        .sort((a, b) => moment(a.postPossessionDate).diff(moment(b.postPossessionDate), 'seconds'));

    ref.extendedStayCancellationCOEChangeAddendum =
        extendedStayCancellationCOEChangeAddendum === [] ? null : extendedStayCancellationCOEChangeAddendum[0];
};

const storeSolutionAdvisorGeneric = (ref, offer) => {
    let tempMarket = get(offer, 'market');
    tempMarket = tempMarket ? tempMarket : 'Phoenix';
    let imageUrl = null;
    try {
        imageUrl = require(`../../../assets/markets/${tempMarket?.toLowerCase().replace(' ', '_')}_team.jpg`);
    } catch {
        imageUrl = `https://ui-avatars.com/api/?bold=true&color=ffffff&background=1b6775&format=svg&name=O%20P`;
    }
    ref.solutionsAdvisorGeneric = {
        market: tempMarket,
        email: `${tempMarket?.toLowerCase()}@offerpad.com`,
        fullName: `${tempMarket} Solutions Team`,
        imageURL: imageUrl,
        phoneNumber: '844.388.4539',
    };
};

const cleanupMovingCompany = (offer) => {
    let returnValue = get(offer, 'movingCompanyContact');
    const movingCompanyName = get(offer, 'movingCompany.companyName');

    if (
        (!offer.isUnderContract && offer.currentStep !== 'Closed') ||
        (movingCompanyName &&
            (movingCompanyName === 'Customer Declined' || movingCompanyName === 'Customer Not Eligible'))
    ) {
        returnValue = null;
    } else if (!returnValue && offer.freeMove) {
        returnValue = {
            fullName: 'Bellhop',
            companyName: 'Bellhop',
            email: 'offerpad@bellhop.com',
            phoneNumber: '888-296-1874',
        };
    }

    if (returnValue && returnValue.companyName) {
        returnValue.imageURL = require(`../../../assets/movers/${returnValue.companyName.toLowerCase()}_image.jpg`);
    }
    return returnValue;
};

const storeValues = (ref, offer) => {
    const offerTime = new Date(offer.createdDateTime).getTime();
    ref.offerViewed =
        offerTime > 0 &&
        get(offer, 'entityEvents', [])
            .filter((x) => x.name === 'Purchase Offer Viewed')
            .some((x) => new Date(x.createdDateTime).getTime() > offerTime);
    ref.inspectionScheduledDateTime = get(offer, 'inspection.scheduledDateTime');
    ref.inspectionSubmittedDateTime = get(offer, 'inspection.submittedDateTime');
    ref.inspectionDocumentUrl = get(offer, 'inspection.documentUrl');
    ref.keyType = offer.keyType;
    ref.offerKey = offer.key;
    ref.requestKey = offer.request ? offer.request.key : null;
    ref.images = get(offer, 'images', []);
    ref.contracts = get(offer, 'contracts', []);
    ref.currentStep = get(offer, 'currentStep');
    ref.entityEvents = get(offer, 'entityEvents', []);
    ref.listedByAgent = get(offer, 'listedByAgent');
    ref.titleCompany = get(offer, 'titleContact.companyName', 'the title company');
    ref.timeZoneId = get(offer, 'property.timeZoneId', 'America/Phoenix');
    ref.homeMarketingConsultant = get(offer, 'homeMarketingConsultant');
    ref.closingConsultant = get(offer, 'closingConsultant');
    ref.closingConsultantLink = get(offer, 'closingConsultant.schedulingLink', pagePath(ref, 'your-team'));
    ref.closingConsultantFullName = get(offer, 'closingConsultant.fullName', 'your Transaction manager');
    ref.teamPageLink = pagePath(ref, 'your-team');
    ref.postInspectionSurveyLink = pagePath(ref, 'survey/post-inspection');
    ref.checklistPageLink = pagePath(ref, 'checklist');
    const contactEmail = get(offer, 'contact.email', null);
    ref.acquisitionId = get(offer, 'acquisitionId');
    ref.contactEmail = contactEmail ? `<strong>${contactEmail}</strong>` : `your registered email`;
    ref.expired = get(offer, 'expired', false);
    ref.expirationDateTime = adjustToUTC(get(offer, 'expirationDateTime'));
    ref.signingApptDateTime = get(offer, 'signingApptDateTime.utcDateTime', null);
    ref.presentOfferApptDateTime = get(offer, 'presentOfferApptDateTime');
    ref.virtualWalkThroughScheduledDateTime = get(offer, 'request.virtualWalkThroughScheduledDateTime');
    ref.clearedToClose = get(offer, 'clearedToClose', false);
    ref.isAcquired = get(offer, 'isAcquired', false);
    ref.market = get(offer, 'market');
    ref.moveDate = get(offer, 'moveDate');
    ref.freeMove = get(offer, 'freeMove');
    ref.titleCompanyCapitalized = get(offer, 'titleContact.companyName', 'The title company');
    ref.events = get(offer, 'events', []);
    storeContractSignedDate(ref);
    storeEscrowCloseDate(ref);
    storeRequestSubmittedDate(ref);
    storeOfferExtendedDate(ref);
    ref.noPostPossessionMoveOutDate = moment(ref.escrowCloseDate).subtract(1, 'days').toString();
    ref.walkthroughScheduledDate = get(offer, 'walkThrough.scheduledDateTime', null);
    ref.walkthroughCompletedDate = get(offer, 'walkThrough.completedDateTime', null);
    storePostPossessionAddendum(ref);
    ref.postPossessionMoveOutDate = get(offer, 'postPossessionDate', null);
    storeExtendedStayAddendum(ref);
    storeExtendedStayCancellationAddendum(ref);
    storeExtendedStayCOEChangeAddendum(ref);
    storeExtendedStayCancellationCOEChangeAddendum(ref);
    ref.propertyImages = get(offer, 'propertyImages', []);
    ref.request = get(offer, 'request', {});
    ref.accepted = get(offer, 'accepted');
    ref.acceptedDateTime = get(offer, 'acceptedDateTime');
    ref.titleClosingDate = get(offer, 'titleClosingDate', null);
    ref.titleWaitingOnSeller = get(offer, 'titleWaitingOnSeller');
    ref.sellerInformationSheetReceived = get(offer, 'sellerInformationSheetReceived');
    ref.movingCompanyNeedsAdditionalInfoFromSeller = get(offer, 'movingCompanyNeedsAdditionalInfoFromSeller');
    ref.normalizedAddress = get(offer, 'property.normalizedAddress', '');
    ref.streetAddress = get(offer, 'property.streetAddress', null);
    ref.city = get(offer, 'property.city', '');
    ref.state = get(offer, 'property.state', '');
    ref.zipCode = get(offer, 'property.zipCode', '');
    ref.cityStateZipCode = [ref.city, ref.state, ref.zipCode].join(', ');
    ref.firstName = get(offer, 'firstName', 'valued Offerpad customer');
    ref.lastName = get(offer, 'lastName', '');
    ref.offerpadContactPhoneNumber = get(offer, 'offerpadContactPhoneNumber');
    ref.closingConsultant = get(offer, 'closingConsultant');
    ref.homeOwnerConsultant = get(offer, 'homeOwnerConsultant');
    ref.conciergeAgent = get(offer, 'conciergeAgent');
    ref.isConcierge = get(offer, 'isConcierge')
    storeSolutionAdvisorGeneric(ref, offer);
    ref.postInspectionSurvey = get(offer, 'postInspectionSurvey');
    ref.utilitiesForm = get(offer, 'utilitiesForm');
    ref.utilitiesFormLink = pagePath(ref, 'form/utilities');
    ref.exitSurvey = get(offer, 'exitSurvey');
    ref.titleContact =
        !offer.isAcquired && !(offer.isUnderContract && offer.events.find((x) => x.name === 'Escrow Opened' && x.date))
            ? null
            : get(offer, 'titleContact');

    ref.movingCompanyContact = cleanupMovingCompany(offer);
    ref.titleContactFullName = get(offer, 'titleContact.fullName', 'the title company');
    ref.titleContactPhoneNumber = get(offer, 'titleContact.phoneNumber');

    ref.creditAddendum = get(offer, 'contracts', []).find((x) => x.category === 'CreditAddendum');
    ref.creditAddendumSent = get(offer, 'contracts', []).find(
        (x) => x.category === 'CreditAddendum' && (x.status === 'Sent' || x.status === 'Delivered')
    );
    ref.creditAddendumCompleted = get(offer, 'contracts', []).find(
        (x) => x.category === 'CreditAddendum' && x.status === 'Completed'
    );

    if (ref.creditAddendumCompleted === undefined) {
        ref.creditAddendumCompleted = false;
    }

    ref.creditAddendumAmount = get(ref.creditAddendumSent, 'additionalCreditToBuyer');
    ref.creditAddendumCompletedDateTime = get(ref.creditAddendum, 'completedDateTime', null);

    ref.creditAndRepairAddendum = get(offer, 'contracts', []).find((x) => x.category === 'CreditAndRepairAddendum');
    ref.creditAndRepairAddendumSent = get(offer, 'contracts', []).find(
        (x) => x.category === 'CreditAndRepairAddendum' && (x.status === 'Sent' || x.status === 'Delivered')
    );
    ref.creditAndRepairAddendumCompleted = get(offer, 'contracts', []).find(
        (x) => x.category === 'CreditAndRepairAddendum' && x.status === 'Completed'
    );
    ref.creditAndRepairAddendumAmount = get(ref.creditAndRepairAddendumSent, 'additionalCreditToBuyer');
    ref.creditAndRepairAddendumCompletedDateTime = get(ref.creditAndRepairAddendum, 'completedDateTime');

    ref.imageRequestStatus = get(offer, 'request.imageRequestStatus');
    ref.propertyDisclosure = get(offer, 'contracts', []).find((x) => x.category === 'PropertyDisclosure');
    ref.closedSurveyUrl = pagePath(ref, 'survey/closed');
    ref.price = get(offer, 'price');
    ref.isLost = get(offer, 'isLost');
    ref.dateTimePicker = get(offer, 'dateTimePicker');
    ref.presentOfferApptDateTime = get(offer, 'presentOfferApptDateTime');
    ref.calendarEndDate = get(offer, 'calendarEndDate')
    ref.calendarStartDate = get(offer, 'calendarStartDate')

    let movingCompany = get(offer, 'movingCompany');
    ref.movingCompany = movingCompany ? movingCompany.replace('Bellhops', 'Bellhop') : movingCompany; //Replace exists until Bellhop is being returned correctly.
    ref.acquisitionId = get(offer, 'acquisitionId');
    ref.propertySpec = get(offer, 'propertySpec');
};

// PREDICATES
const storePredicates = (ref, offer) => {
    storeChecklistPredicates(ref, offer);
    storeStatustrackerPredicates(ref);
};

const storeChecklistPredicates = (ref, offer) => {
    ref.offerExtended = ref.keyType === 'offer';

    ref.uploadPhotos = ref.propertyImages.length === 0 && !ref.offerExtended;

    ref.uploadPhotosCompletionCriteria = ref.imageRequestStatus === 'MustUpload' && ref.propertyImages.length === 0;

    ref.uploadPhotosCompleted = ref.propertyImages.length !== 0 && ref.imageRequestStatus === 'MustUpload';

    ref.hasToReviewOffer = (ref.currentStep === 'OfferSent' || ref.currentStep === 'ContractInProgress') && !ref.expired;

    ref.titleNeedsInformation = ref.titleCompany && ref.titleWaitingOnSeller && ref.sellerInformationSheetReceived;

    ref.itsTimeToContactTitle = ref.offerExtended && !ref.preTitleCurrentSteps.includes(ref.currentStep);

    ref.donotContactTitleJustYet = ref.offerExtended && ref.preTitleCurrentSteps.includes(ref.currentStep);

    ref.underContract =
        ref.currentStep !== 'ContractSigned' &&
        ref.preTitleCurrentSteps &&
        !ref.preTitleCurrentSteps.includes(ref.currentStep);

    ref.isTimeToContactTitle = ref.offerExtended && !ref.preTitleCurrentSteps.includes(ref.currentStep);
    ref.hasNotContactedTitle = ref.titleCompany && !ref.sellerInformationSheetReceived;
    ref.hasContactedTitle = ref.titleCompany && ref.sellerInformationSheetReceived;

    ref.propertyDisclosureRequired =
        ref.propertyDisclosure !== undefined && ref.propertyDisclosure.status !== 'Completed';
    ref.propertyDisclosureCompleted =
        ref.propertyDisclosure !== undefined &&
        ref.propertyDisclosure.status === 'Completed' &&
        !!ref.propertyDisclosure.completedDateTime;

    ref.homeInspectionScheduled = !!ref.inspectionScheduledDateTime && ref.currentStep !== 'InspectionOnHold';

    ref.homeInspectionCreatedDateTime = get(
        ref.entityEvents.find((x) => x.name === 'Inspection Scheduled'),
        'createdDateTime'
    );

    const postPossessionContract = ref.contracts.find((x) => ref.postPossessionAddenda.includes(x.category));

    ref.needsTosignPostPossessionAddendum =
        postPossessionContract &&
        (postPossessionContract.status === 'Sent' || postPossessionContract.status === 'Delivered');

    ref.postPossessionAddendumSigned = postPossessionContract && postPossessionContract.status === 'Completed';

    ref.postPossessionContractCompletedDateTime = get(postPossessionContract, 'completedDateTime');

    const extendedStayContract = ref.contracts.find((x) => x.category === 'ExtendedStayAddendum');

    ref.needsTosignExtendedStayAddendum =
        extendedStayContract && (extendedStayContract.status === 'Sent' || extendedStayContract.status === 'Delivered');

    ref.extendedStayAddendumSigned = extendedStayContract && extendedStayContract.status === 'Completed';

    ref.extendedStayContractCompletedDateTime = get(extendedStayContract, 'completedDateTime');

    const extendedStayCancellationContract = ref.contracts.find(
        (x) => x.category === 'ExtendedStayCancellationAddendum'
    );

    ref.needsTosignExtendedStayCancellationAddendum =
        extendedStayCancellationContract &&
        (extendedStayCancellationContract.status === 'Sent' || extendedStayCancellationContract.status === 'Delivered');

    ref.extendedStayCancellationAddendumSigned =
        extendedStayCancellationContract && extendedStayCancellationContract.status === 'Completed';

    ref.extendedStayCancellationContractCompletedDateTime = get(extendedStayCancellationContract, 'completedDateTime');

    const extendedStayCOEChangeAddendum = ref.contracts.find((x) => x.category === 'ExtendedStayCOEChangeAddendum');

    ref.needsTosignExtendedStayCOEChangeAddendum =
        extendedStayCOEChangeAddendum &&
        (extendedStayCOEChangeAddendum.status === 'Sent' || extendedStayCOEChangeAddendum.status === 'Delivered');

    ref.extendedStayCOEChangeAddendumSigned =
        extendedStayCOEChangeAddendum && extendedStayCOEChangeAddendum.status === 'Completed';

    ref.extendedStayCOEChangeContractCompletedDateTime = get(extendedStayCOEChangeAddendum, 'completedDateTime');

    // Extended Stay COE Change Cancellation
    const extendedStayCancellationCOEChangeAddendum = ref.contracts.find(
        (x) => x.category === 'ExtendedStayCancellationCOEChangeAddendum'
    );

    ref.needsTosignExtendedStayCancellationCOEChangeAddendum =
        extendedStayCancellationCOEChangeAddendum &&
        (extendedStayCancellationCOEChangeAddendum.status === 'Sent' ||
            extendedStayCancellationCOEChangeAddendum.status === 'Delivered');

    ref.extendedStayCancellationCOEChangeAddendumSigned =
        extendedStayCancellationCOEChangeAddendum && extendedStayCancellationCOEChangeAddendum.status === 'Completed';

    ref.extendedStayCancellationCOEChangeContractCompletedDateTime = get(
        extendedStayCancellationCOEChangeAddendum,
        'completedDateTime'
    );

    ref.closeOfEscrowAddedndumSent = get(offer, 'contracts', []).find(
        (x) => x.category === 'CloseOfEscrowAddendum' && x.status === 'Sent'
    );

    ref.closeOfEscrowAddedndumSentSignByDateTime = get(ref.closeOfEscrowAddedndumSent, 'signByDateTime');

    ref.closeOfEscrowAddedndumCompleted = get(offer, 'contracts', []).find(
        (x) => x.category === 'CloseOfEscrowAddendum' && x.status === 'Completed'
    );

    ref.closeOfEscrowAddedndumCompletedDateTime = get(ref.closeOfEscrowAddedndumCompleted, 'completedDateTime');

    ref.needsToSignCloseOfEscrowAddendum =
        ref.offerExtended && ref.closeOfEscrowAddedndumSent && ref.closeOfEscrowAddedndumSentSignByDateTime;

    ref.closeOfEscrowAddendumSigned =
        ref.offerExtended && ref.closeOfEscrowAddedndumCompleted && !!ref.closeOfEscrowAddedndumCompletedDateTime;

    const titleCompanyPresent = ref.titleCompany !== 'the title company';

    ref.signingAppointmentScheduled = titleCompanyPresent && ref.titleClosingDate && ref.signingApptDateTime;

    let pointer = ref.contracts.find((x) => x.category === 'PurchaseContract' && x.status !== 'Completed');
    ref.needsToSignContract = ref.currentStep === 'ContractSent' && pointer !== undefined;

    pointer = ref.contracts.find((x) => x.category === 'PurchaseContract' && x.status === 'Completed');
    ref.contractSigned = pointer !== undefined;

    ref.contractSignedDateTime = pointer ? pointer.completedDateTime : null;

    pointer = ['InspectionScheduled', 'InspectionCompleted', 'EscrowOpened'].includes(ref.currentStep);
    pointer = pointer && ref.movingCompany === null;
    ref.emailFromMovingCompanyExpected = ref.freeMove && pointer && !ref.moveDate;

    pointer = ref.entityEvents.find((x) => x.name === 'Escrow Opened');
    ref.needsToScheduleHomeInspection =
        ref.offerExtended && pointer && (!ref.inspectionScheduledDateTime || ref.currentStep === 'InspectionOnHold');

    pointer = ref.entityEvents.find((x) => x.name === 'Credit And Repair Draft Ready');
    ref.needsToScheduleInspectionReview =
        pointer && ref.creditAndRepairAddendum && ref.creditAndRepairAddendum.status === 'Draft';

    pointer = ['Bellhop', 'Dircks'].includes(ref.movingCompany);
    ref.needsToContactMovingCompany = ref.offerExtended && pointer && ref.movingCompanyNeedsAdditionalInfoFromSeller;

    pointer = ref.events.find((x) => 'cleared to close from title' === (x.name ?? '').toLowerCase());
    ref.needsToScheduleSigningAppointment = titleCompanyPresent && pointer && !ref.titleClosingDate;

    ref.sellerDisclosuresRequired = ref.markets.includes(ref.market);
};

const undefinedToFalse = (x) => (x === false || x === undefined ? false : true);

const storeStatustrackerPredicates = (ref) => {
    ref.offerInProgress = [
        'NewRequest',
        'OfferInProgress',
        'PresentOffer',
        'OfferReview',
        'RenewalRequest',
        'PendingConciergeConsideration',
    ].includes(ref.currentStep);
    ref.offerSent = ref.currentStep === 'OfferSent';
    ref.offerExpired = ref.offerExtended && ref.expired;
    ref.inspectionOnHold = undefinedToFalse(
        !ref.inspectionScheduledDateTime && ref.entityEvents.find((x) => x.name === 'Inspection on Hold')
    );

    ref.inspectionScheduled =
        ref.offerExtended && !ref.inspectionSubmittedDateTime && !!ref.inspectionScheduledDateTime;
    ref.creditAddendumAmountIsNaN = Number.isNaN(ref.creditAddendumAmount);
    ref.creditAndRepairAddendumAmountIsNaN = Number.isNaN(ref.creditAndRepairAddendumAmount);
    ref.officialInspectionCompleted = ref.entityEvents.find((x) => x.name === 'UC-Schedule Results Review Email');
    ref.creditAndRepairSkipped = ref.entityEvents.find((x) => 'Credit And Repair Skipped' === x.name) !== undefined;
};

const storeDocuments = (ref) => {
    let index = 1;

    ref.documents = ref.contracts
        .filter((x) => x.status === 'Completed' && !!x.completedDateTime && !!ref.documentName[x.category])
        .map((x) => {
            return {
                id: index++,
                name: ref.documentName[x.category],
                status: x.status,
                completedDateTime: x.completedDateTime,
                contractId: x.contractId,
            };
        });
};

const adjustToUTC = (time) => {
    let utc = moment.utc(time || '');
    return utc ? utc.toISOString() : time;
};

const pagePath = (ref, page) => `/${ref.keyType}/${ref.offerKey || ref.requestKey}/${page}`;

const resetOfferState = (ref, offer) => {
    storeValues(ref, offer);
    storePredicates(ref, offer);
    storeDocuments(ref);
    ref.thereIsOffer = true;

    const evt = ref.entityEvents.find((evt) => evt.name === 'UC-Inspection Completed Email');
    if (evt && evt.createdDateTime) {
        ref.postInspectionSurveyReady =
            !!(ref.homeOwnerConsultant || ref.conciergeAgent) &&
            !!ref.closingConsultant &&
            onTimeForPISurvey(evt.createdDateTime, offer?.property?.timeZoneId || 'America/Phoenix');
    }

    ref.exitSurveyReady = !!ref.closingConsultant && !!ref.titleContact;
    ref.pathCalendar = pagePath(ref, 'calendar');
    ref.pathUploadPhotos = pagePath(ref, 'upload-photos');
    ref.pathYourTeam = pagePath(ref, 'your-team');
    ref.pathDocuments = pagePath(ref, 'documents');
    ref.pathChecklist = pagePath(ref, 'checklist');
};

const onTimeForPISurvey = (dateTime: string, timeZoneId: string) => {
    const end = moment(dateTime).add(30, 'days').toDate().getTime();
    const now = moment.tz(timeZoneId).utc(true).toDate().getTime();
    return now < end;
};

export default resetOfferState;
