import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './FormGroupInput.scss';

type FormGroupInputProps = {
    label: string;
    name: string;
    onChange: Function;
    disabled?: boolean;
    error?: string;
    placeholder?: string;
    onKeyUp?: Function;
    required?: boolean;
    type?: string;
    value?: string;
    fontSize?: string;
}

const FormGroupInput: React.FC<FormGroupInputProps> = (props) => {
    const {
        error,
        label,
        name,
        disabled = false,
        onChange,
        required = false,
        type = 'text',
        value = null,
        placeholder = null,
        onKeyUp = null,
        fontSize = null,
    } = props;

    const [touched, setTouched] = useState<boolean>(false);
    const [focusLost, setFocusLost] = useState<boolean>(false);
    const hasSuccess = touched && !error;
    const others = {};

    if (placeholder) {
        others['placeholder'] = placeholder;
    }
    if (onKeyUp) {
        others['onKeyUp'] = onKeyUp;
    }

    let style = {}

    if (fontSize) {
        style['fontSize'] = fontSize;
    }

    {/* See: https://help.fullstory.com/hc/en-us/articles/360020623574-How-do-I-protect-my-users-privacy-in-FullStory-#fs-consent */ }
    if (type === 'password') {
        others['data-secret'] = "b2b446cc5a9544ca0cc0f9353";
    }
    if (value) {
        others['value'] = value;
    }
    
    const classes = ['form-group', 'op-formgroup', label && 'has-label', hasSuccess && 'has-success']
        .filter(cls => cls).join(' ');
    const inputClasses = ['input-container', focusLost && error && 'error'].filter(cls => cls).join(' ');

    return <div className={classes} >
        {label &&
            <label style={style}>
                {label}
                {required && <span className="text-danger">*</span>}
            </label>
        }
        <div className={inputClasses} >
            {/* See: https://help.fullstory.com/hc/en-us/articles/360020623574-How-do-I-protect-my-users-privacy-in-FullStory-#fs-consent */}
            <input
                className={`${!touched ? 'not-touched' : ''} ${type === 'password' ? 'fs-exclude' : ''}`}
                required={required}
                name={name}
                disabled={disabled}
                type={type}
                autoComplete={type}
                onChange={(e) =>  onChange(e)}
                onKeyUp={() => setTouched(true)}
                onBlur={() => setFocusLost(true)}
                aria-describedby="addon-right addon-left"
                style={{ border: 'none', flex: '1' }}
                {...others}
            />
            {error && focusLost && <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className='input-container__icon' />}
        </div>
        {error && focusLost &&
            <div className='new-invalid-feedback'>
                {error}
            </div>
        }
    </div>
}

export default FormGroupInput;