import React from 'react';
import ViewHeader from './ViewHeader';
import AppFooter from '../Views/Layout/AppFooter';
import './PageLayout.scss';

type PageLayoutProps = {
    streetAddress: string;
    headerText: string;
    subHeaderText?: string;
    headerDescription?: string;
    listedByAgent: boolean;
    leftColumn?: React.ReactNode;
    leftColumnHeader?: React.ReactNode;
    rightColumn?: React.ReactNode;
    rightColumnHeader?: React.ReactNode;
    centerColumn?: React.ReactNode;
    centerColumnHeader?: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = (props) => {

    const { streetAddress, headerText, headerDescription, subHeaderText, listedByAgent,
        leftColumn, leftColumnHeader, rightColumn, rightColumnHeader, centerColumn, centerColumnHeader } = props;

    return (
        <div className='row'>
            <div className="col-md-12 mid-section">
                {streetAddress &&
                    <ViewHeader
                        address={streetAddress}
                        header={headerText}
                        description={headerDescription}
                        subheader={subHeaderText}
                    />
                }
                <div className="row">
                    {leftColumn &&
                        <div className="col-lg-8">
                            {leftColumnHeader && <h3 className="c-viewsubhead" >{leftColumnHeader}</h3>}
                            {leftColumn}
                        </div>
                    }

                    {rightColumn &&
                        <div className="col-lg-4">
                            {rightColumnHeader && <h3 className="c-viewsubhead" >{rightColumnHeader}</h3>}
                            {rightColumn}
                        </div>
                    }

                    {centerColumn &&
                        <div className="col-lg-12">
                            {centerColumnHeader && <h3 className="c-viewsubhead" >{centerColumnHeader}</h3>}
                            {centerColumn}
                        </div>
                    }
                </div>
            </div>
            <AppFooter pageStyle />
        </div>
    )
}

export default PageLayout;