import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Wizard, WizardStep, useStepController } from './Wizard/Wizard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOffer } from '../../../context/offer/OfferContext';
import { ConnectionType } from '../../../services/FormsService/models/ConnectionType';
import { FormType } from '../../../services/FormsService/models/FormType';
import * as FormService from '../../../services/FormsService/forms.service';
import { FormRadioGrid } from 'floorplan/components/Form';
import { FormControl } from 'floorplan/components/v2/Form';
import { Button } from 'floorplan/components/v2/Button';
import { Rating, RatingView } from 'react-simple-star-rating';
import { RadioNps } from '../../UIComponents/RadioNps/RadioNps';
import TokenService from '../../../services/token.service';
import Checkbox from "../../UIComponents/Inputs/Checkbox";
import cx from 'classnames';

import './SurveyContent.scss';

const ExitSurveyContent = (props) => {
    const { state: { offerState }, dispatch: offerDispatch } = useOffer();
    const stepController = useStepController();
    const history = useHistory();
    const [formSucceeded, updateFormSucceeded] = useState(false);
    const [formFailed, updateFormFailed] = useState(false);
    const [submitting, updateSubmitting] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    let listedByAgent = offerState.listedByAgent;
    const movingCompanyName = props.movingCompany?.companyName.replace('Bellhops', 'Bellhop');

    const [form, updateForm] = useState({
        transactionManagerFullName: props.transactionManager.fullName,
        transactionManagerKnowledgeable: '',
        transactionManagerResponsiveness: '',
        transactionManagerFriendliness: '',
        transactionManagerStarRating: null,
        transactionManagerComments: '',
        movingCompanyName: movingCompanyName,
        movingCompanyStarRating: null,
        movingCompanyComments: '',
        movingCompanyUnused: isChecked,
        titleCompanyName: props.titleCompany.companyName,
        titleCompanyStarRating: null,
        titleCompanyComments: '',
        offerpadStarRating: null,
        offerpadRating: null,
        offerpadShareComments: '',
        offerpadMotivator: null,
        offerpadComments: '',
        offerpadRecommendations: ''
    });

    const [formErrors, updateFormErrors] = useState({
        transactionManagerKnowledgeable: false,
        transactionManagerResponsiveness: false,
        transactionManagerFriendliness: false,
        transactionManagerStarRating: false,
        movingCompanyStarRating: false,
        movingCompanyUnused: false,
        titleCompanyStarRating: false,
        offerpadStarRating: false,
        offerpadRating: false,
        offerpadMotivator: false,
        offerpadShareComments: false,
        offerpadComments: false,
        offerpadRecommendations: false
    });

    const updateField = (field, value) => {
        updateForm({ ...form, [field]: value });
        updateFormErrors({ ...formErrors, [field]: false });
    }

    const validate = (field) => {
        if (form[field] || form[field] === 0) {
            stepController.incrementStep();
        }
        else {
            if(field == 'movingCompanyStarRating' && form.movingCompanyUnused) {
                stepController.incrementStep();
            } else {
                updateFormErrors({ ...formErrors, [field]: true });
            }
        }
    }

    const friendliness = [
        { label: '- Very friendly', value: 5 },
        { label: '- Friendly', value: 4 },
        { label: '- Somewhat friendly', value: 3 },
        { label: '- Not always friendly', value: 2 },
        { label: '- Not friendly at all', value: 1 },
    ];

    const motivators = [
        { label: 'It was a fast sell', value: 1 },
        { label: 'Price', value: 2 },
        {
            label: listedByAgent
                ? 'They were able to skip the normal home-selling headaches'
                : 'I was able to skip the normal home-selling headaches',
            value: 3,
        },
        {
            label: listedByAgent
                ? 'Someone they trust told them it was a better way'
                : 'Someone I trust told me it was a better way',
            value: 4,
        },
    ];

    const navigateAway = () => {
        const loggedIn = TokenService.getToken();
        if (loggedIn) {
            window.location.href = `${process.env.RAZZLE_CONNECT_URL}/sell/overview?transactionId=${props.acquisitionId}`
        } else {
            window.location.href = `https://www.offerpad.com/`;
        }
    };

    const submitForm = () => {
        if (submitting) {
            return;
        }
        updateSubmitting(true);
        FormService.submitForm({
            connectionId: props.acquisitionId,
            connectionType: ConnectionType.HelixEntity,
            formType: FormType.ClosedSurvey,
            items: Object.keys(form).map((key) => {
                return {
                    name: key,
                    value: form[key],
                };
            }),
        })
            .then(
                () => {
                    updateFormSucceeded(true);
                },
                () => {
                    updateFormFailed(true);
                })
            .catch(() => {
                updateFormFailed(true);
            })
            .finally(() => {
                updateSubmitting(false);
                stepController.incrementStep();
            });
    };

    const NavigationButtons = () => {
        return (
            <div className={cx({ 'btn-wrapper': true, 'd-none': submitting })} >
                {
                    (!formSucceeded && !formFailed && stepController.currentStep > 0) ?
                        <button
                            id="exit-survey-content-previous"
                            className="btn-survey-previous"
                            onClick={stepController.decrementStep}
                        >
                            <FontAwesomeIcon icon={['fas', 'long-arrow-left']} />
                            {' Back'}
                        </button>
                        :
                        <button id="exit-survey-content-close" className="btn-survey-previous" onClick={navigateAway}>
                            <FontAwesomeIcon icon={['fas', 'times']} />
                            {' Close'}
                        </button>
                }
            </div>
        )
    };

    const noFreeLocalMove = (e) => {
        setIsChecked(e.target.checked);
        updateField('movingCompanyUnused', e.target.checked);
    }

    useEffect(() => {
        if(form.movingCompanyUnused && form.movingCompanyStarRating > 0) {
            updateField('movingCompanyStarRating', 0);   
        }
    }, [form.movingCompanyUnused]);

    useEffect(() => {
        if(form.movingCompanyStarRating > 0  && form.movingCompanyUnused) {
            setIsChecked(false);
            updateField('movingCompanyUnused', false);
        }
    }, [form.movingCompanyStarRating])

    return (
        <div>
            <NavigationButtons />
            <Wizard stepController={stepController} >
                {/* 
                Initial section
                */}
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-main">
                            <div className="pt-4 pb-4">
                                <img src={require('@assets/op-logo.svg')} className="logo" />
                            </div>
                            {
                                listedByAgent ?
                                    <h3 className="d-none d-md-block smaller">
                                        Congratulations, your seller has sold their home to Offerpad!
                                    </h3> :
                                    <h3 className="d-none d-md-block smaller">
                                        Congratulations, you have sold your home to Offerpad!
                                    </h3>
                            }
                            <div className="highlight">
                                Your opinion matters to us! Thanks for taking the time to provide your valuable feedback.
                            </div>
                            <div className="btn-wrapper pt-4">
                                <Button id="exit-survey-start" large onClick={stepController.incrementStep}>
                                    Start the 5-minute survey!
                                </Button>
                            </div>
                        </div>
                    </div>
                </WizardStep>

                {/* 
                Transaction Manager section
                */}
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar">
                            <div className="text-center">
                                <img src={props.transactionManager.imageURL} alt="" className="rep large" />
                            </div>
                        </div>
                        <div className="modal-main">
                            <p>This is your Transaction Manager</p>
                            <h3>{props.transactionManager.fullName}</h3>
                            <div className="highlight">
                                First, let's find how your Transaction Manager, {props.transactionManager.fullName}, took care of you! Was {props.transactionManager.firstName} knowledgeable about the Offerpad process and able to answer all of your questions?
                            </div>
                            <div>
                                <FormControl
                                    id='transactionManagerKnowledgeable'
                                    className='simple-radios'
                                    name='transactionManagerKnowledgeable'
                                    value={form.transactionManagerKnowledgeable}
                                    hasError={formErrors.transactionManagerKnowledgeable}
                                    errorText={formErrors.transactionManagerKnowledgeable ? 'Please make a selection' : ''}
                                    onChange={e => updateField('transactionManagerKnowledgeable', e.target.value)}
                                >
                                    <FormRadioGrid
                                        columns={1}
                                        bp='desktop'
                                    >
                                        <FormRadioGrid.Option
                                            id='transactionManagerKnowledgeable1'
                                            key='transactionManagerKnowledgeable1'
                                            label='Yes'
                                            value='Yes'
                                            checked={form.transactionManagerKnowledgeable === 'Yes'}
                                        />
                                        <FormRadioGrid.Option
                                            id='transactionManagerKnowledgeable2'
                                            key='transactionManagerKnowledgeable2'
                                            label='Partially'
                                            value='Partially'
                                            checked={form.transactionManagerKnowledgeable === 'Partially'}
                                        />
                                        <FormRadioGrid.Option
                                            id='transactionManagerKnowledgeable3'
                                            key='transactionManagerKnowledgeable3'
                                            label='Not Knowledgeable'
                                            value='Not Knowledgeable'
                                            checked={form.transactionManagerKnowledgeable === 'Not Knowledgeable'}
                                        />
                                        <FormRadioGrid.Option
                                            id='transactionManagerKnowledgeable4'
                                            key='transactionManagerKnowledgeable4'
                                            label='Not applicable'
                                            value='Not applicable'
                                            checked={form.transactionManagerKnowledgeable === 'Not applicable'}
                                        />
                                    </FormRadioGrid>
                                </FormControl>
                                <div className="btn-wrapper pt-2">
                                    <Button
                                        id="exit-survey-transaction-knowledgeable-btn"
                                        large
                                        disabled={!form.transactionManagerKnowledgeable}
                                        onClick={() => validate('transactionManagerKnowledgeable')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar">
                            <div className="text-center">
                                <img src={props.transactionManager.imageURL} alt="" className="rep large" />
                            </div>
                        </div>
                        <div className="modal-main">
                            <p>Correspondence with</p>
                            <h3>{props.transactionManager.fullName}</h3>
                            <div className="highlight">
                                Was{' '}
                                {props.transactionManager.fullName}{' '}
                                quick to respond to any messages, emails, or texts?
                            </div>
                            <div>
                                <FormControl
                                    id='transactionManagerResponsiveness'
                                    className='simple-radios'
                                    name='transactionManagerResponsiveness'
                                    value={form.transactionManagerResponsiveness}
                                    hasError={formErrors.transactionManagerResponsiveness}
                                    errorText={formErrors.transactionManagerResponsiveness ? 'Please make a selection' : ''}
                                    onChange={e => updateField('transactionManagerResponsiveness', e.target.value)}
                                >
                                    <FormRadioGrid
                                        columns={1}
                                        bp='desktop'
                                    >
                                        <FormRadioGrid.Option
                                            id='transactionManagerResponsiveness1'
                                            key='transactionManagerResponsiveness1'
                                            label='Very responsive'
                                            value='Very responsive'
                                            checked={form.transactionManagerResponsiveness === 'Very responsive'}
                                        />
                                        <FormRadioGrid.Option
                                            id='transactionManagerResponsiveness2'
                                            key='transactionManagerResponsiveness2'
                                            label='Mostly responsive'
                                            value='Mostly responsive'
                                            checked={form.transactionManagerResponsiveness === 'Mostly responsive'}
                                        />
                                        <FormRadioGrid.Option
                                            id='transactionManagerResponsiveness3'
                                            key='transactionManagerResponsiveness3'
                                            label='Not responsive at all'
                                            value='Not responsive at all'
                                            checked={form.transactionManagerResponsiveness === 'Not responsive at all'}
                                        />
                                        <FormRadioGrid.Option
                                            id='transactionManagerResponsiveness4'
                                            key='transactionManagerResponsiveness4'
                                            label='Not applicable'
                                            value='Not applicable'
                                            checked={form.transactionManagerResponsiveness === 'Not applicable'}
                                        />
                                    </FormRadioGrid>
                                </FormControl>
                                <div className="btn-wrapper">
                                    <Button
                                        id="exit-survey-transaction-responsiveness-btn"
                                        large
                                        disabled={!form.transactionManagerResponsiveness}
                                        onClick={() => validate('transactionManagerResponsiveness')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar">
                            <div className="text-center">
                                <img src={props.transactionManager.imageURL} alt="" className="rep large" />
                            </div>
                        </div>
                        <div className="modal-main">
                            <p>Friendliness of</p>
                            <h3>{props.transactionManager.fullName}</h3>
                            <div className="highlight">
                                Friendliness is important to Offerpad. Was{' '}
                                {props.transactionManager.firstName}{' '}
                                friendly?
                            </div>
                            <div>
                                <FormControl
                                    id='transactionManagerFriendliness'
                                    className='simple-radios'
                                    name='transactionManagerFriendliness'
                                    value={form.transactionManagerFriendliness}
                                    hasError={formErrors.transactionManagerFriendliness}
                                    errorText={formErrors.transactionManagerFriendliness ? 'Please make a selection' : ''}
                                    onChange={e => updateField('transactionManagerFriendliness', e.target.value)}
                                >
                                    <select
                                        className="op-select-dropdown"
                                        style={{ width: '250px' }}
                                        placeholder="Select your rating"
                                    >
                                        <option hidden value='' label="Select your rating">
                                            Select your rating
                                        </option>
                                        {
                                            friendliness.map(x =>
                                                <option value={x.value} label={`${x.value} ${x.label}`} key={x.value}>
                                                    {`${x.value} ${x.label}`}
                                                </option>
                                            )
                                        }
                                    </select>
                                </FormControl>
                                <div className="btn-wrapper pt-5">
                                    <Button
                                        id="exit-survey-transaction-friendliness-btn"
                                        large
                                        disabled={!form.transactionManagerFriendliness}
                                        onClick={() => validate('transactionManagerFriendliness')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar">
                            <div className="text-center">
                                <img src={props.transactionManager.imageURL} alt="" className="rep large" />
                            </div>
                        </div>
                        <div className="modal-main">
                            <p>Your rating for</p>
                            <h3>{props.transactionManager.fullName}</h3>
                            <div className="highlight">
                                Please give {props.transactionManager.firstName} a star rating.
                            </div>
                            <div>
                                <Rating
                                    ratingValue={form.transactionManagerStarRating || 0}
                                    onClick={rating => updateField('transactionManagerStarRating', rating)}
                                    size={50}
                                />
                                <div className="btn-wrapper pt-5">
                                    <Button
                                        id="exit-survey-transaction-star-rating-btn"
                                        large
                                        disabled={!form.transactionManagerStarRating}
                                        onClick={() => validate('transactionManagerStarRating')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar">
                            <div className="text-center">
                                <img src={props.transactionManager.imageURL} alt="" className="rep large" />
                                <h3>{props.transactionManager.fullName}</h3>
                                <div className="mx-auto">
                                    <div>Your rating:</div>
                                    <RatingView
                                        stars={form.transactionManagerStarRating || 0}
                                        ratingValue={form.transactionManagerStarRating || 0}
                                        size={30}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-main">
                            {
                                form.transactionManagerStarRating <= 3 ?
                                    <div className="highlight">
                                        Hmm, looks like {props.transactionManager.firstName} could have done better. Would you
                                        like to tell us more? (optional)
                                    </div> :
                                    <div className="highlight">
                                        Wow! We love {props.transactionManager.firstName} too! Was there anything they did that
                                        exceeded your expectations? (optional)
                                    </div>
                            }
                            <div>
                                <FormControl
                                    id='transactionManagerComments'
                                    name='transactionManagerComments'
                                    placeholder="Tell us more"
                                    value={form.transactionManagerComments}
                                    onChange={(e) =>
                                        updateForm({
                                            ...form, transactionManagerComments: e.target.value
                                        })}
                                >
                                    <textarea
                                        name="transactionManagerComments"
                                        rows={4}
                                    ></textarea>
                                </FormControl>
                                <div className="btn-wrapper pt-4">
                                    <Button
                                        id="exit-survey-transaction-comments-btn"
                                        large
                                        onClick={stepController.incrementStep}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>

                {/* 
                Moving company section
                */}
                {
                    props.movingCompany &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-sidebar">
                                <div className="text-center">
                                    <img src={props.movingCompany.imageURL} alt="" className="rep large" />
                                </div>
                            </div>
                            <div className="modal-main">
                                <p>Your rating for</p>
                                <h3>{movingCompanyName}</h3>
                                <div className="highlight">
                                    How would you rate your experience with the moving company{' '}
                                    {movingCompanyName}?
                                </div>
                                <div>
                                    <Rating
                                        ratingValue={form.movingCompanyStarRating || 0}
                                        onClick={rating => updateField('movingCompanyStarRating', rating)}
                                        size={50}
                                    />
                                    <div className="pt-3">
                                        <Checkbox
                                            checked={isChecked}
                                            onChange={(e) => noFreeLocalMove(e)}
                                            data-qa="register-acceptterms"
                                        >
                                            I did not use the free local move
                                        </Checkbox>
                                    </div>
                                    <div className="btn-wrapper pt-3">
                                        <Button
                                            id="exit-survey-moving-star-rating-btn"
                                            large
                                            disabled={!form.movingCompanyStarRating && !isChecked}
                                            onClick={() => validate('movingCompanyStarRating')}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }
                {
                    props.movingCompany && form.movingCompanyStarRating && 
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-sidebar">
                                <div className="text-center">
                                    <img src={props.movingCompany.imageURL} alt="" className="rep large" />
                                    <h3>{movingCompanyName}</h3>
                                </div>
                            </div>
                            <div className="modal-main">
                                <div className="highlight">
                                    Would you like to tell us more about your experience with{' '}
                                    {movingCompanyName}? (optional)
                                </div>
                                <div>
                                    <FormControl
                                        id='movingCompanyComments'
                                        name='movingCompanyComments'
                                        placeholder="Tell us more"
                                        value={form.movingCompanyComments}
                                        onChange={(e) =>
                                            updateForm({
                                                ...form, movingCompanyComments: e.target.value
                                            })}
                                    >
                                        <textarea
                                            name="movingCompanyComments"
                                            rows={4}
                                        ></textarea>
                                    </FormControl>
                                    <div className="btn-wrapper pt-4">
                                        <Button
                                            id="exit-survey-moving-comments-btn"
                                            large
                                            onClick={stepController.incrementStep}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }

                {/* 
                Title company section
                */}
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar">
                            <div className="text-center">
                                <img src={props.titleCompany.imageURL} alt="" className="reptitle" />
                            </div>
                        </div>
                        <div className="modal-main">
                            <p>Your rating for</p>
                            <h3>{props.titleCompany.companyName}</h3>
                            <div className="highlight">
                                How would you rate your experience with the title company{' '}
                                {props.titleCompany.companyName}?
                            </div>
                            <div>
                                <Rating
                                    ratingValue={form.titleCompanyStarRating || 0}
                                    onClick={rating => updateField('titleCompanyStarRating', rating)}
                                    size={50}
                                />
                                <div className="btn-wrapper pt-5">
                                    <Button
                                        id="exit-survey-title-star-rating-btn"
                                        large
                                        disabled={!form.titleCompanyStarRating}
                                        onClick={() => validate('titleCompanyStarRating')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar">
                            <div className="text-center">
                                <img src={props.titleCompany.imageURL} alt="" className="reptitle" />
                            </div>
                        </div>
                        <div className="modal-main">
                            <div className="highlight">
                                Would you like to tell us more about your experience with{' '}
                                {props.titleCompany.companyName}? (optional)
                            </div>
                            <div>
                                <FormControl
                                    id='titleCompanyComments'
                                    name='titleCompanyComments'
                                    placeholder="Tell us more"
                                    value={form.titleCompanyComments}
                                    onChange={(e) =>
                                        updateForm({
                                            ...form, titleCompanyComments: e.target.value
                                        })}
                                >
                                    <textarea
                                        name="titleCompanyComments"
                                        rows={4}
                                    ></textarea>
                                </FormControl>
                                <div className="btn-wrapper pt-4">
                                    <Button
                                        id="exit-survey-title-comments-btn"
                                        large
                                        onClick={stepController.incrementStep}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>

                {/* 
                Offerpad overall section 
                */}
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar pt-4">
                            <div className="text-center">
                                <img src={require('@assets/op-logo.svg')} className="logo" />
                            </div>
                        </div>
                        <div className="pt-3 pl-3 pr-3 d-flex flex-column">
                            <h3>The Selling Process</h3>
                            <div className="highlight">How do you feel about the overall Offerpad selling process?</div>
                            <div>
                                <Rating
                                    ratingValue={form.offerpadStarRating || 0}
                                    onClick={rating => updateField('offerpadStarRating', rating)}
                                    size={50}
                                />
                                <div className="btn-wrapper pt-5">
                                    <Button
                                        id="exit-survey-offerpad-star-rating-btn"
                                        large
                                        disabled={!form.offerpadStarRating}
                                        onClick={() => validate('offerpadStarRating')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar pt-4">
                            <div className="text-center">
                                <img src={require('@assets/op-logo.svg')} className="logo" />
                                {
                                    (form.offerpadRating || form.offerpadRating === 0) &&
                                    <div className="mx-auto">
                                        <div className="pt-4">Your rating:</div>
                                        <span className="rating-number">{form.offerpadRating}</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="pt-3 pl-3 pr-3 d-flex flex-column">
                            <h2>Your Overall Experience</h2>
                            <div className="highlight">
                                Based on your entire experience, how likely are you to recommend Offerpad to friends and
                                family?
                            </div>
                            <div className="pt-2">
                                <RadioNps
                                    id="offerpadRating"
                                    name="offerpadRating"
                                    options={[
                                        'grey',
                                        'grey',
                                        'grey',
                                        'grey',
                                        'grey',
                                        'grey',
                                        'grey',
                                        'grey',
                                        'grey',
                                        'grey',
                                        'grey',
                                    ]}
                                    minLabel="Not likely"
                                    maxLabel="Very likely"
                                    selectedOption={form.offerpadRating}
                                    onChange={value => updateField('offerpadRating', value)}
                                />
                            </div>
                            <div className="pt-2 align-self-center">
                                <div className="btn-wrapper pt-5">
                                    <Button
                                        id="exit-survey-nps-btn"
                                        large
                                        disabled={form.offerpadRating === null}
                                        onClick={() => validate('offerpadRating')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar pt-4">
                            <div className="text-center">
                                <img src={require('@assets/op-logo.svg')} className="logo" />
                            </div>
                        </div>
                        <div className="modal-main">
                            {
                                listedByAgent ?
                                    <div className="highlight">
                                        In your opinion, what was your seller's key motivator in selling to offerpad?
                                    </div> :
                                    <div className="highlight">
                                        What was your key motivator in selling to offerpad?
                                    </div>
                            }
                            <div>
                                <FormControl
                                    id='offerpadMotivator'
                                    className='simple-radios'
                                    name='offerpadMotivator'
                                    value={form.offerpadMotivator}
                                    hasError={formErrors.offerpadMotivator}
                                    errorText={formErrors.offerpadMotivator ? 'Please make a selection' : ''}
                                    onChange={e => updateField('offerpadMotivator', e.target.value)}
                                >
                                    <FormRadioGrid
                                        columns={1}
                                        bp='desktop'
                                    >
                                        {
                                            motivators.map(x =>
                                                <FormRadioGrid.Option
                                                    id={`offerpadMotivator${x.value}`}
                                                    key={`offerpadMotivator${x.value}`}
                                                    label={x.label}
                                                    value={x.label}
                                                    checked={form.offerpadMotivator === x.label}
                                                />
                                            )
                                        }
                                    </FormRadioGrid>
                                </FormControl>
                                <div className="btn-wrapper pt-5">
                                    <Button
                                        id="exit-survey-motivator-btn"
                                        large
                                        disabled={!form.offerpadMotivator}
                                        onClick={() => validate('offerpadMotivator')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep >
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar pt-4">
                            <div className="text-center">
                                <img src={require('@assets/op-logo.svg')} className="logo" />
                            </div>
                        </div>
                        <div className="modal-main">
                            <div className="highlight">
                                Would you be willing to share a quick review with us (and others) about your experience with Offerpad?
                            </div>
                            <div>
                                <FormControl
                                    id='offerpadShareComments'
                                    className='simple-radios'
                                    name='offerpadShareComments'
                                    value={form.offerpadShareComments}
                                    hasError={formErrors.offerpadShareComments}
                                    errorText={formErrors.offerpadShareComments ? 'Please make a selection' : ''}
                                    onChange={e => updateField('offerpadShareComments', e.target.value)}
                                >
                                    <FormRadioGrid
                                        columns={1}
                                        bp='desktop'
                                    >
                                        <FormRadioGrid.Option
                                            id='offerpadShareComments-yes'
                                            key='offerpadShareComments-yes'
                                            label='Yes'
                                            value='Yes'
                                            checked={form.offerpadShareComments === 'Yes'}
                                        />
                                        <FormRadioGrid.Option
                                            id='offerpadShareComments-no'
                                            key='offerpadShareComments-no'
                                            label='No'
                                            value='No'
                                            checked={form.offerpadShareComments === 'No'}
                                        />
                                    </FormRadioGrid>
                                </FormControl>
                                {
                                    form.offerpadShareComments === 'Yes' &&
                                    <div className="pt-2">
                                        If we use the information you provide, we will only use your first name and first letter
                                        of your last name, along with your city. (Max 1000 characters)
                                    </div>
                                }
                                <div>
                                    {
                                        form.offerpadShareComments === 'Yes' &&
                                        <FormControl
                                            id='offerpadComments'
                                            name='offerpadComments'
                                            placeholder="Tell us more"
                                            value={form.offerpadComments}
                                            onChange={(e) =>
                                                updateForm({
                                                    ...form, offerpadComments: e.target.value
                                                })}
                                        >
                                            <textarea
                                                id="offerpadComments"
                                                v-form="form.offerpadComments"
                                                name="offerpadComments"
                                                placeholder="Quick review"
                                                rows={4}
                                            ></textarea>
                                        </FormControl>
                                    }
                                    <div className="btn-wrapper pt-4">
                                        <Button
                                            id="exit-survey-offerpad-comments-btn"
                                            large
                                            disabled={!form.offerpadShareComments}
                                            onClick={() => validate('offerpadShareComments')}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar pt-4">
                            <div className="text-center">
                                <img src={require('@assets/op-logo.svg')} className="logo" />
                            </div>
                        </div>
                        <div className="modal-main">
                            <div className="highlight">
                                Do you have any recommendations that would have helped make the experience better? (For
                                internal use only)
                            </div>
                            <div>
                                <FormControl
                                    id='offerpadRecommendations'
                                    name='offerpadRecommendations'
                                    placeholder="Your recommendations"
                                    value={form.offerpadRecommendations}
                                    onChange={(e) =>
                                        updateForm({
                                            ...form, offerpadRecommendations: e.target.value
                                        })}
                                >
                                    <textarea
                                        id="offerpadRecommendations"
                                        v-form="form.offerpadRecommendations"
                                        name="offerpadRecommendations"
                                        placeholder="Your recommendations"
                                        rows={4}
                                    ></textarea>
                                </FormControl>
                                <div className="btn-wrapper pt-4">
                                    <Button
                                        id="exit-survey-offerpad-recommendations-btn"
                                        large
                                        onClick={submitForm}
                                        disabled={submitting}
                                    >
                                        {submitting ? 'Submitting...' : 'Submit survey'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>

                {/* 
                Feedback after form submission
                */}
                <WizardStep>
                    <div className="p-2">
                        <div className="survey-end">
                            {
                                formSucceeded &&
                                <h3>
                                    Thank you for taking our mini-survey. Your feedback is valuable and appreciated!
                                </h3>
                            }
                            {
                                formFailed &&
                                <h3>
                                    We encountered an issue while submitting your survey. Please try again later.
                                </h3>
                            }
                            <div className="d-flex justify-content-center">
                                <img src={require('@assets/illustrations/survey_finished_icon.svg')} />
                            </div>
                        </div>
                    </div>
                </WizardStep>
            </Wizard>
        </div>
    );
}

export default ExitSurveyContent;
