import React, { useReducer, createContext, useContext } from 'react';
import { notificationsReducer, initialState, State, Dispatch } from './NotificationsReducer';

const NotificationsContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

type NotificationsProviderProps = { children: React.ReactNode };

const NotificationsProvider: React.FC<NotificationsProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(notificationsReducer, initialState);
    const value = { state, dispatch };

    return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

const useNotifications = () => {
    const context = useContext(NotificationsContext);
    if (context === undefined) {
        throw new Error('useNotifications must be used within an NotificationsProvider');
    }
    return context;
};

export { useNotifications, NotificationsContext, NotificationsProvider };
