import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { DefaultValueBinder, DefaultFieldBinderFactory } from '@offerpad/concrete/lib/model';
import { ValidationResult } from '@offerpad/concrete/lib/validation/Validator';
import * as binder from './binder';
import { useAppInsights } from '../../../context/AppInsightsContext';
import Alert from '../../UIComponents/Alert';
import { Button } from 'floorplan/components/v2/Button';
import { FormControl } from 'floorplan/components/v2/Form';
import { AuthService } from '../../../services/AuthService/auth.service';
import { useNotifications } from '../../../context/notifications/NotificationsContext';
import { notify } from '../../../context/notifications/NotificationsReducer';
import { RouteName, path } from '../../../routing/Routes';

const ForgotPassword = () => {
    const history = useHistory();
    const appInsights = useAppInsights();

    const valueBinder = new DefaultValueBinder();
    const fieldBinderFactory = new DefaultFieldBinderFactory(valueBinder);
    const form = binder.create(fieldBinderFactory);

    const [errors, setErrors] = useState<Record<string, string>>({});
    const [forgotPassError, setForgotPassError] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');

    const { dispatch: notificationsDispatch } = useNotifications();

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const field = form.fields[e.target.name];
        const value = e.target.value;
        setEmail(value);
        const result: ValidationResult = field.validator.validate(value);
        setErrors({ ...errors, [e.target.name]: result.message });
    };

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const model = form.bind(e.target as HTMLFormElement);
        if (model.valid) {
            const email = model.fields['Email'].value;
            setIsSubmitting(true);
            AuthService.forgotPassword(email)
                .then(() => {
                    setIsSubmitting(false);
                    notify(notificationsDispatch, {
                        message:
                            'If that email exists a message will be sent to your account with a link to reset your password.',
                        type: 'success',
                    });
                    history.push(path(RouteName.Login));
                })
                .catch((error) => {
                    if (error.response.data == 'User not found') {
                        setIsSubmitting(false);
                        notify(notificationsDispatch, {
                            message:
                                'If that email exists a message will be sent to your account with a link to reset your password.',
                            type: 'success',
                        });
                        history.push(path(RouteName.Login));
                    } else {
                        let alertMessage = `There was an error while processing your request; please try again later.`;
                        appInsights.trackTrace({ message: error.message });
                        setIsSubmitting(false);
                        setForgotPassError(alertMessage);
                    }
                });
        } else {
            let errors = {};
            const validation = model.fields['Email'].validation;
            errors['Email'] = validation.message;
            setErrors(errors);
        }
    };

    return (
        <div className="c-authform">
            <form onSubmit={(e) => onSubmit(e)}>
                <h2 className="c-authform__title">It's okay!</h2>
                <p className="c-authform__subtitle">We can help you reset your password.</p>
                {forgotPassError && <Alert description={forgotPassError} />}
                <fieldset>
                    <legend></legend>
                    <FormControl
                        onChange={(e) => onChange(e)}
                        label={'Email'}
                        name={'Email'}
                        value={email}
                        errorText={errors['Email']}
                        hasError={!!errors['Email']}
                        showErrorIcon={!!errors['Email']}
                        type="email"
                    />
                </fieldset>
                <Button id="forgot-password-submit" fullWidth primary type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Resetting your password...' : 'Reset my password'}
                </Button>
            </form>
        </div>
    );
};

export default ForgotPassword;
