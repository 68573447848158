import * as OfferService from '../../services/OfferService/offer.service';
import * as FormService from '../../services/FormsService/forms.service';
import * as RequestService from '../../services/RequestService/request.service';
import { FormType } from '../../services/FormsService/models/FormType'
import { History } from 'history';
import resetOfferState from './tools';
import OfferState from './types';
import { openModal } from '@offerpad/concrete.react/lib/Modal';
import { Modals } from '../../components/Controls/ModalController/ModalController';

export enum Actions {
    GET_OFFER_COMPLETE = 'offer/getComplete',
    GET_OFFER_FAIL = 'offer/getFail',
    GET_OFFER = 'offer/get',
    RENEWAL = 'offer/renewal'
}

export type Action = { type: Actions; data?: any };

export type State = {
    offerState: OfferState;
    loading: boolean;
};

export type Dispatch = (action: Action) => void;

export const initialState: State = {
    offerState: new OfferState(),
    loading: false,
};

export const offerReducer = (state: State, action: Action) => {
    switch (action.type) {
        case Actions.GET_OFFER:
            return { ...state, loading: true }
        case Actions.GET_OFFER_COMPLETE:
            let offerState = new OfferState();
            resetOfferState(offerState, action.data);
            if (offerState.contractSigned) {
                storeUtilitiesFormFlag(offerState);
            }
            return { offerState, loading: false }
        case Actions.GET_OFFER_FAIL:
            return { ...state, loading: false }
        case Actions.RENEWAL:
            state.offerState.currentStep = 'RenewalRequest';
            state.offerState.expired = false;
            state.offerState.offerExpired = false;
            return { ...state };
        default:
            return state;
    }
};

const storeUtilitiesFormFlag = async (ref) => ref.showUtilitiesForm = (await RequestService.getUtilitiesFormFlag(ref.market)).data;

const timer = ms => new Promise(res => setTimeout(res, ms));

export const loadOffer = async (
    dispatch: Dispatch,
    offerKey: string,
    keyType: string,
    history: History,
    noCache: boolean = false,
    maxAttempts: number = 1,
    redirectOnFail: boolean = true
) => {

    dispatch({ type: Actions.GET_OFFER });
    let offer = null;

    if (keyType === 'interest') {
        offer = {
            offerKey: '0',
            keyType: 'interest',
            currentStep: 'Interest',
            thereIsOffer: true,
            property: {
                streetAddress: 'Property Address - TBD',
            }
        }
        dispatch({ type: Actions.GET_OFFER_COMPLETE, data: offer });
        return;
    }

    for (let attempt = 1; attempt <= maxAttempts && !offer; attempt++) {
        try {
            offer = await OfferService.current(offerKey, keyType, noCache);

            if (keyType === 'request' && offer.key) {
                const pathParts = window.location.pathname.split('/');
                history.push(`/offer/${offer.key}/${pathParts[pathParts.length - 1]}`);
            }

            const postInspectionSurveyResponse = await FormService.getFrom(offer.acquisitionId, FormType.PostInspectionSurvey);
            const postInspectionSurvey = await postInspectionSurveyResponse.data;
            const exitSurveyResponse = await FormService.getFrom(offer.acquisitionId, FormType.ClosedSurvey);
            const exitSurvey = await exitSurveyResponse.data;
            const utilitiesFormResponse = await FormService.getFrom(offer.acquisitionId, FormType.UtilitiesForm);
            const utilitiesForm = await utilitiesFormResponse.data;
            dispatch({
                type: Actions.GET_OFFER_COMPLETE, data: {
                    keyType: keyType,
                    postInspectionSurvey: postInspectionSurvey,
                    exitSurvey: exitSurvey,
                    utilitiesForm: utilitiesForm,
                    ...offer,
                }
            });
        } catch (error) {
            if (attempt < maxAttempts) {
                await timer(2000);
            }
            else {
                dispatch({ type: Actions.GET_OFFER_FAIL });
                if (redirectOnFail) {
                    history.push(history.location.pathname + '\\*');
                }
            }
        }
    }

}

export function renewMyOffer(offerKey: string, dispatch: Dispatch, modalDispatch: any) {

    RequestService.renewOffer(offerKey)
        .then((response) => {
            if (response.status === 200) {
                dispatch({ type: Actions.RENEWAL });
                openModal(modalDispatch, Modals.OFFER_RENEWAL_MODAL, { success: true });
            } else {
                openModal(modalDispatch, Modals.OFFER_RENEWAL_MODAL, { success: false });
            }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
            openModal(modalDispatch, Modals.OFFER_RENEWAL_MODAL, { success: false });
        })
}
