import React from 'react';
import AppFooter from './AppFooter';
import TopNavbar from './TopNavbar';
import './HomeLayout.scss';

type HomeLayoutLayoutProps = {
    children: React.ReactNode;
}

const HomeLayout: React.FC<HomeLayoutLayoutProps> = ({ children }) => {
    return <div className='c-homepage'>
        <div className="c-homepage__navbar-wrapper">
            <TopNavbar noSidebar userAvatar largeLogo />
        </div>
        <div className="c-homepage__content">
            {children}
        </div>
        <AppFooter darkBackground />
    </div>
}

export default HomeLayout;
