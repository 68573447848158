import React, { createContext, useContext } from "react";
import appInsights from '../clients/appInsights';

const AppInsightsContext = createContext(appInsights);

type AppInsightsProps = { children: React.ReactNode };

const AppInsightsProvider: React.FC<AppInsightsProps> = ({ children }) => {
    return <AppInsightsContext.Provider value={appInsights}>{children}</AppInsightsContext.Provider>;
};

const useAppInsights = () => {
    const context = useContext(AppInsightsContext);
    if (context === undefined) {
        throw new Error('useAppInsights must be used within an AppInsightsProvider');
    }
    return context;
};

export { useAppInsights, AppInsightsProvider };