import OfferState from "src/context/offer/types";

const defaultTeamObject = {
    offerpadNumber: null,
    teamMembers: null,
};

const TeamMemberInfo = {
    TRANSACTION_MANAGER: { ROLE: 'Transaction Manager', FIELD: 'closingConsultant', OWNER: 'ClosingConsultant' },
    SOLUTION_EXPERT: { ROLE: 'Solution Expert', FIELD: 'conciergeAgent', OWNER: 'ConciergeAgent' },
    SOLUTION_ADVISOR: { ROLE: 'Solution Advisor', FIELD: 'homeOwnerConsultant', OWNER: 'HomeownerConsultant' },
    SOLUTION_ADVISOR_GENERIC: { ROLE: 'Solution Advisor', FIELD: 'solutionsAdvisorGeneric' },
    SOLUTION_EXPERT_GENERIC: { ROLE: 'Solution Expert', FIELD: 'solutionsAdvisorGeneric' },
    IN_HOME_OFFERPAD_REPRESENTATIVE: {
        ROLE: 'In-Home Offerpad Representative',
        FIELD: 'homeMarketingConsultant',
        OWNER: 'HomeMarketingConsultant',
    },
    TITLE_CONTACT: { ROLE: 'Title Contact', FIELD: 'titleContact' },
    MOVING_COMPANY_CONTACT: { ROLE: 'Moving Company Contact', FIELD: 'movingCompanyContact' },
};

export const TeamMemberKeys = Object.fromEntries(
    Object.keys(TeamMemberInfo).map((x) => {
        return [x, { NAME: x, ...TeamMemberInfo[x] }];
    })
);

const buildDescription = (teamMember, teamMemberKey, listedByAgent) => {
    const subject = listedByAgent ? "your client's" : 'your';

    switch (teamMemberKey.NAME) {
        case TeamMemberKeys.TRANSACTION_MANAGER.NAME:
            return `${teamMember.firstName} is your main contact at Offerpad and will typically be the person to contact with questions and for details about your transaction.`;

        case TeamMemberKeys.SOLUTION_ADVISOR.NAME:
        case TeamMemberKeys.SOLUTION_EXPERT.NAME:
            return `${teamMember.firstName} is your initial contact at
            Offerpad and is here to help you understand all of your options when it comes to selling
            ${subject} home to Offerpad.`;

        case TeamMemberKeys.IN_HOME_OFFERPAD_REPRESENTATIVE.NAME:
            return `${teamMember.fullName} is the company
    representative that visits ${subject} home in person. ${teamMember.firstName} is a great resource
    for questions about the Offerpad sales process!`;

        case TeamMemberKeys.TITLE_CONTACT.NAME:
            return `${teamMember.firstName} is part of the team at ${teamMember.companyName} who handles the earnest
    account and acts as an intermediary between all parties to record the necessary legal
    documents.`;

        case TeamMemberKeys.MOVING_COMPANY_CONTACT.NAME:
            teamMember.companyName = teamMember.companyName.replace('Bellhops', 'Bellhop');
            return `${teamMember.companyName} is our preferred partner for your free
     local move. Offerpad has picked ${teamMember.companyName} because of their commitment to an
     amazing customer experience. Feel free to reach out to ${teamMember.companyName}
     with any questions about ${subject} move.`;

        case TeamMemberKeys.SOLUTION_ADVISOR_GENERIC.NAME:
            return `When we send your offer, Offerpad will assign you a dedicated Solution Advisor. In the meantime,
            feel free to connect with any of our friendly and helpful advisors serving ${teamMember.market}.`;
        case TeamMemberKeys.SOLUTION_EXPERT_GENERIC.NAME:
            return `Offerpad will assign you a dedicated Solution Expert. In the meantime,
                feel free to connect with any of our friendly and helpful experts serving ${teamMember.market}.`;
    }

    return '***MISSING DESCRIPTION***';
};

export const buildTeamMember = (teamMemberKey, offerState) => {
    const listedByAgent = offerState.listedByAgent;
    let teamMember = offerState[teamMemberKey.FIELD];
    if (teamMember) {
        teamMember.email = teamMember.email.replace('bellhops', 'bellhop');
        return {
            key: teamMemberKey.NAME,
            role: teamMemberKey.ROLE,
            owner: teamMemberKey.OWNER,
            description: buildDescription(teamMember, teamMemberKey, listedByAgent),
            ...teamMember,
        };
    }

    return null;
};

function getTeamMembers(offerState) {
    const offerKey = offerState.offerKey;
    const expired = offerState.expired;

    let teamMembers = [];

    teamMembers.push(buildTeamMember(TeamMemberKeys.TRANSACTION_MANAGER, offerState));

    if (offerKey && !expired && !offerState.isConcierge) {
        teamMembers.push(
            buildTeamMember(TeamMemberKeys.SOLUTION_ADVISOR, offerState) ||
            buildTeamMember(TeamMemberKeys.SOLUTION_EXPERT, offerState) ||
            buildTeamMember(TeamMemberKeys.SOLUTION_ADVISOR_GENERIC, offerState)
        );
    } else if (offerState.isConcierge) {
        teamMembers.push(buildTeamMember(TeamMemberKeys.SOLUTION_EXPERT, offerState) ||
            buildTeamMember(TeamMemberKeys.SOLUTION_EXPERT_GENERIC, offerState))
    } else {
        teamMembers.push(buildTeamMember(TeamMemberKeys.SOLUTION_ADVISOR_GENERIC, offerState));
    }

    teamMembers.push(buildTeamMember(TeamMemberKeys.IN_HOME_OFFERPAD_REPRESENTATIVE, offerState));
    teamMembers.push(buildTeamMember(TeamMemberKeys.TITLE_CONTACT, offerState));
    teamMembers.push(buildTeamMember(TeamMemberKeys.MOVING_COMPANY_CONTACT, offerState));

    teamMembers = teamMembers.filter((x) => x !== null);
    return teamMembers;
}

export default function getTeamObject(offerState) {
    if (!offerState.thereIsOffer) {
        return { ...defaultTeamObject };
    }
    return {
        offerpadNumber: offerState.offerpadContactPhoneNumber,
        teamMembers: getTeamMembers(offerState),
    };
}

export function getFirstTeamMember(offerState: OfferState) {
    const teamMembers = getTeamMembers(offerState);
    if (teamMembers.length === 0) {
        return null;
    }
    const underContract = offerState.underContract;
    if (offerState.isConcierge) {
        return teamMembers.find((x) => x.role === 'Solution Expert');
    }

    if (underContract) {
        return (
            teamMembers.find((x) => x.role === 'Transaction Manager') ||
            teamMembers.find((x) => x.role === 'Solution Advisor') ||
            teamMembers.find((x) => x.role === 'Solution Expert')
        );
    } else {
        return (
            teamMembers.find((x) => x.role === 'Solution Advisor') ||
            teamMembers.find((x) => x.role === 'Solution Expert')
        );
    }
}