
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import AuthService from '../../../services/AuthService/auth.service';
import Button from '../../UIComponents/Button';
import { path, RouteName } from '../../../routing/Routes';
import { useNotifications } from '../../../context/notifications/NotificationsContext';
import { useUser } from '../../../context/user/UserContext';
import { notify } from '../../../context/notifications/NotificationsReducer';
import { useAppInsights } from '../../../context/AppInsightsContext';
import "./ResendEmailVerification.scss";

const ResendEmailVerification = () => {
    const { state: user, dispatch: userDispatch } = useUser();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const history = useHistory();
    const { dispatch: notificationsDispatch } = useNotifications();
    const appInsights = useAppInsights();

    if (user['EmailConfirmed'] === 'True' && user['emailConfirmed'] === 'True') {
        history.push(path(RouteName.Home));
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await AuthService.sendVerificationEmail();
            if (response && response.status === 200) {
                notify(notificationsDispatch, { 'message': 'We sent you another verification email.  Please click on the included link to verify your account.', 'type': 'success' });
            } else {
                const data = response.data;
                throw new Error(data ? data.error : 'There is no error information');
            }
        } catch (error) {
            appInsights.trackTrace({ message: error.message });
            notify(notificationsDispatch, { 'message': 'There was an issue sending your verification email.  Please try again later.', 'type': 'danger' });
        }
        finally {
            setIsSubmitting(false);
        }
    }

    return (
        <div className="c-authform">
            <form onSubmit={(e) => onSubmit(e)}>
                <h2 className="c-authform__title">Email is not Verified</h2>
                <p className="c-authform__subtitle">It's Ok, we can try and resend the email verification</p>
                <Button
                    nativeType="submit"
                    round
                    block
                    className="c-authform__submit"
                    data-qa="login-submit"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Resending email verification...' : 'Resend email verification'}
                </Button>
            </form>
        </div>);
}

export default ResendEmailVerification;
