import React from "react";
import { Modal } from 'floorplan/components/v2/Modal'
import { Button } from 'floorplan/components/Button'
import { closeModal } from '@offerpad/concrete.react/lib/Modal';
import { useModal } from '@offerpad/concrete.react/lib/Modal';

export type OfferRenewalModalProps = {
    success: boolean;
}

const OfferRenewalModal: React.FC<OfferRenewalModalProps> = (props) => {
    const { success } = props;
    const { state: { isOpen }, dispatch: modalDispatch } = useModal();

    return (
        <Modal
            open={isOpen}
            onClose={() => closeModal(modalDispatch)}
            mobileBottom
            hasFooter
        >
            <Modal.Header
                title={'Offer Renewal'}
                onClose={() => closeModal(modalDispatch)}
                border={false}
            />
            <Modal.Content>
                <div data-testid="offer-renewal-feedback">
                    {
                        success ?
                            'Renewal request on its way!'
                            :
                            'There was a problem with your request.  Please try again later.'
                    }
                </div>
            </Modal.Content>
            <Modal.Footer>
                <Button
                    id="dashboard-status-close-renewal-button"
                    data-qa="dashboard-status-close-renewal-button"
                    type='submit'
                    large
                    onClick={() => closeModal(modalDispatch)}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default OfferRenewalModal;