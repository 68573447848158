import apiClient from '../../clients/apiClient'
import { getAuthHeaders } from '../AuthService/auth.service';
const apiManagementUrl = process.env.RAZZLE_API_MANAGEMENT_URL;

const customerEndpoint = () => {
    apiClient.defaults.baseURL = apiManagementUrl + '/api/customer/v2/';
    return apiClient;
};

export const getAcquisitionContracts = (acquisitionId) => customerEndpoint().get(`acquisitions/${acquisitionId}/contracts`, getAuthHeaders())
export const getContractIncludeSignatures = (contractId) => customerEndpoint().get(`contracts/${contractId}/include-signatures`, getAuthHeaders())
export const getContractEmbeddedSignUrl = (signatureId) => customerEndpoint().get(`contracts/embedded-sign-url/${signatureId}`, getAuthHeaders())