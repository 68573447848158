import apiClient from '../../clients/apiClient'
import * as Request from '../RequestService/request.service';
const apiManagementUrl = process.env.RAZZLE_API_MANAGEMENT_URL;
import { AxiosResponse } from 'axios';
import { Offer } from './types';
const customerEndpoint = () => {
    apiClient.defaults.baseURL = apiManagementUrl + '/api/customer/v2/';
    return apiClient;
};


export const loadDetails = (key: string): Promise<AxiosResponse<Offer>> => customerEndpoint().get(`/offers/${key}?doNotLog=true`)


export const current = (key: string, keyType: string, noCache: boolean = false): Promise<Offer> => {
    return new Promise(function (resolve, reject) {
        let promise = null;
        if (keyType === 'request') {
            promise = Request.loadDetails(key, noCache);
        } else {
            promise = loadDetails(key);
        }
        promise.then((response) => {
            resolve(response.data);
        }, reject);
    });
}
