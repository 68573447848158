import React, { useEffect, useContext } from 'react';
import Content from './Content';
import sidebarLinksSell from '../../Controls/resources/sidebarLinksSell';
import sidebarLinksBuy from '../../Controls/resources/sidebarLinksBuy';
import sidebarLinksBuyAgent from '../../Controls/resources/sidebarLinksBuyAgent';
import TopNavbar from './TopNavbar';
import { useParams } from 'react-router-dom';
import './DashboardLayout.scss';
import SideBar from '../../UIComponents/Sidebar/SideBar';
import { useSidebar } from '../../../context/sidebar/SidebarContext';
import { useOffer } from '../../../context/offer/OfferContext';
import { displaySidebar } from '../../../context/sidebar/SidebarReducer';
import { useLocation } from 'react-router';
import LabContext from '@offerpad/concrete.react/lib/Lab/internal/LabContext';
import { useUser } from '../../../context/user/UserContext';
import { useDisposition } from '../../../context/disposition/DispositionContext';

const mapActiveLinks = function (activeLink: string) {
    switch (activeLink) {
        case 'upload-photos':
            return 'photos';
        case 'checklist':
            return 'to-dos';
    }
    return activeLink;
};

const DashboardLayout = ({ children }) => {
    const buyUrl = process.env.RAZZLE_BUY_URL;
    const { state: sidebar, dispatch: sidebarDispatch } = useSidebar();
    const { state: { offerState } } = useOffer();
    const { state: disposition } = useDisposition();
    const location = useLocation();
    const labContext = useContext(LabContext);
    const { state: user } = useUser();
    const { keyType, offerKey } = useParams<{ keyType: string; offerKey: string }>();
    const sellLinks = sidebarLinksSell(offerKey, keyType, offerState.isConcierge);
    const buyLinks = sidebarLinksBuy(buyUrl, user.agent === 'True');
    const buyLinksAgent = sidebarLinksBuyAgent(offerKey);
    
    const toggleSidebar = () => {
        if (sidebar.showSidebar) {
            displaySidebar(sidebarDispatch, false);
            const element = document.getElementsByClassName('wrapper')[0] as HTMLElement;
            element.style.overflow = 'scroll';
        }
    };

    useEffect(() => {
        toggleSidebar();
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div
                id="dashboard-toggle-side-bar"
                className={sidebar.showSidebar ? 'sidebarOpen' : ''}
                onClick={toggleSidebar}
            ></div>
            <div className={`wrapper ${sidebar.showSidebar ? 'noScroll' : ''}`}>
                <SideBar sidebarLinksSell={sellLinks} sidebarLinksBuy={buyLinks} sidebarLinksBuyAgent={buyLinksAgent}/>
                <div id="main-panel" className="main-panel">
                    <div className="header">
                        <TopNavbar />
                    </div>
                    <Content toggleSidebar={toggleSidebar}>{children}</Content>
                </div>
            </div>
        </>
    );
};

const isAfterUnderContract = (step: string) =>
    [
        'ContractSigned',
        'EscrowOpened',
        'InspectionOnHold',
        'InspectionScheduled',
        'InspectionCompleted',
        'OkInspection',
        'PurchaseInProgress',
        'WalkThroughScheduled',
        'WalkThroughCompleted',
        'Closed',
    ].includes(step);

export default DashboardLayout;
