export type DocumentBlob = {
    id: number
    blobUrl: string,
    annotation: string,
    size: number,
    mimeType: string,
    uploadedType: string
    createdDateTime: Date
    createdBy: string
}

export type Document = {
    id: number,
    type: string,
    status: string,
    group: string,
    connectionId: string,
    connectionType: string,
    createdDateTime: Date,
    createdBy: string,
    modifiedDateTime: Date,
    modifiedBy: string,
    documentBlobs: Array<DocumentBlob>
    documentNotes: Array<DocumentNotes>
}

export type DocumentNotes = {
    createdBy: string,
    createdDateTime: Date,
    documentId: number,
    documentStatus: string,
    id: number,
    modifiedBy: string,
    modifiedDateTime: Date,
    note: string
}

export type DocumentMessage = {
    messageContent: string,
    createdDateTime: Date,
    createdBy: string
}

export enum DocumentStatus {
    PendingUpload = "PendingUpload",
    Uploaded = "Uploaded",
    PendingApproval = "PendingApproval",
    Denied = "Denied",
    Approved = "Approved"
}