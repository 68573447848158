import apiClient from '../../clients/apiClient'
import { getAuthHeaders } from '../AuthService/auth.service';
const apiManagementUrl = process.env.RAZZLE_API_MANAGEMENT_URL;

const infrastructureEndpoint = () => {
    apiClient.defaults.baseURL = apiManagementUrl + '/infrastructure/v1/';
    return apiClient;
};

export const getDocuments = (connectionId, connectionType) => infrastructureEndpoint().get(`documents/${connectionId}/${connectionType}`, getAuthHeaders());
export const getDocumentsByGroup = (connectionId, connectionType, group) => infrastructureEndpoint().get(`documents/${connectionId}/${connectionType}/${group}`, getAuthHeaders());
export const addDocumentBlobs = (documentId, blobs) => infrastructureEndpoint().post(`documents/add-blobs/${documentId}`, blobs, getAuthHeaders())
export const deleteDocumentBlob = (blobId) => infrastructureEndpoint().delete(`documents/remove-blob/${blobId}`, getAuthHeaders())