import apiClient from '../../clients/apiClient'
import { Form } from './models/Form';

const BASE_PATH = process.env.RAZZLE_API_MANAGEMENT_URL;
const formEndpoint = () => {
    apiClient.defaults.baseURL = BASE_PATH
    return apiClient;
};

export const submitForm = (form: Form) => {
    return formEndpoint()
        .post('api/forms/v1', form);
}

export const getFrom = (acquisitionId: number, formType: string) => {
    return formEndpoint()
        .get(`api/forms/v1/${acquisitionId}/${formType}`);
}

