import { FieldBinderFactory } from '@offerpad/concrete/lib/model';
import FormBinder from '@offerpad/concrete/lib/model/FormBinder';
import Validator from '@offerpad/concrete/lib/validation/Validator';
import * as rules from '@offerpad/concrete/lib/validation/rules';

export function create(binders: FieldBinderFactory): FormBinder {
    return new FormBinder({
        ['Email']: binders.string(
            Validator.create([
                rules.required('The email field is required'),
                rules.email('The email field must be a valid email')
            ])
        ),
        
    });
}
