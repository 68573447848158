import React from "react";

type ButtonProps = {
    block?: boolean,
    children: React.ReactNode;
    className?: string,
    disabled?: boolean,
    icon?: boolean,
    label?: string,
    labelRight?: string,
    link?: boolean,
    nativeType?: "button" | "submit" | "reset",
    outline?: boolean,
    round?: boolean,
    size?: string,
    social?: boolean,
    type?: string,
    wide?: boolean,
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button: React.FC<ButtonProps> = (props) => {
    const {
        block,
        children,
        className,
        disabled = false,
        icon,
        label,
        labelRight,
        link,
        nativeType = 'button',
        onClick = () => { },
        outline,
        round,
        size,
        social,
        type = 'default',
        wide } = props;

    const classes = [
        'btn',
        `btn-${type}`,
        block && 'btn-block',
        className,
        icon && 'btn-icon',
        link && 'btn-link',
        outline && `btn-outline-${type}`,
        round && 'btn-round',
        size ? `btn-${size}` : '',
        social && 'btn-social',
        wide && 'btn-wd',
    ].filter(css => css).join(' ');

    return <button id="portal-button-8291" type={nativeType} className={classes} disabled={disabled} onClick={onClick}>
        {label && <span className="btn-label">
            {label}
        </span>}
        {children}
        {labelRight && <span className="btn-label btn-label-right" >
            {labelRight}
        </span>}
    </button>
}

export default Button;