import React, { useReducer, createContext, useContext } from 'react';
import { DispositionOffer, DispositionSummary } from '../../services/DispositionService/types';
import {
    DispositionReducer,
    initialState,
    State,
    setNewCounterOffer,
    getDisposition,
    counterOffer,
    acceptOffer,
    declineOffer,
    getDispositions,
    Actions,
} from './DispositionReducer';

type DispositionContext = {
    state: State;
    getDisposition: (offerId: string | number) => Promise<void>;
    getDispositions: () => Promise<Array<DispositionSummary>>;
    counterOffer: (offerId: string | number, offer: DispositionOffer) => Promise<boolean>;
    acceptOffer: (offerId: string | number) => Promise<boolean>;
    declineOffer: (offerId: string | number, message?: string) => Promise<boolean>;
    setNewCounterOffer: (offer: Partial<DispositionOffer>) => void;
    clearError: () => void;
}

const DispositionContext = createContext<DispositionContext | undefined>(undefined);

const DispositionProvider = ({ children }) => {
    const [state, dispatch] = useReducer(DispositionReducer, initialState);
    const value = {
        state,
        getDisposition: (offerId) => getDisposition(dispatch, offerId),
        counterOffer: (offerId, offer) => counterOffer(dispatch, offerId, offer),
        acceptOffer: (offerId) => acceptOffer(dispatch, offerId),
        declineOffer: (offerId, message = "") => declineOffer(dispatch, offerId, message),
        setNewCounterOffer: (offer: Partial<DispositionOffer>) => setNewCounterOffer(dispatch, offer),
        clearError: () => dispatch({ type: Actions.CLEAR_ERROR }),
        getDispositions: () => getDispositions(dispatch)
    };

    return <DispositionContext.Provider value={value}>{children}</DispositionContext.Provider>;
};

const useDisposition = () => {
    const context = useContext(DispositionContext);
    if (context === undefined) {
        throw new Error('useDisposition must be used within an DispositionProvider');
    }
    return context;
};

export { useDisposition, DispositionProvider, DispositionContext };