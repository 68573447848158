import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './SurveyPage.scss'
import SurveyModal from './SurveyModal/SurveyModal';
import { useOffer } from '../../../context/offer/OfferContext';
import { loadOffer } from '../../../context/offer/OfferReducer';

import Spinner from 'floorplan/components/Spinner';
import TokenService from '../../../services/token.service';
import UtilitiesFormContent from './UtilitiesFormContent';

const UtilitiesFormPage = () => {
    const history = useHistory();
    const { state: { offerState, loading }, dispatch: offerDispatch } = useOffer();
    const { offerKey } = useParams<{ offerKey: string }>();

    const wasFormAlreadyCompleted = () => {
        return !!offerState.utilitiesForm;
    };

    let formAlreadyCompleted = wasFormAlreadyCompleted();
    let loggedIn = TokenService.getToken();

    useEffect(() => {
        loadOffer(offerDispatch, offerKey, 'offer', history);
    }, [offerKey]);

    if (loading) {
        return <div className="c-homelist__listings spinner-container">
            <Spinner />
        </div>
    }

    return (
        !!offerState.acquisitionId &&
        <SurveyModal>
            {
                !!formAlreadyCompleted ?
                    <div className="p-4">
                        <div className="survey-not-available">
                            <h5>
                                It looks like you already completed this form.  If more information is needed, we will let you know!
                            </h5>
                            <p>
                                You can navigate back to{' '}
                                {
                                    loggedIn ?
                                        <a id="utilities-form-link-5854" href={`${process.env.RAZZLE_CONNECT_URL}/sell/overview?transactionId=${offerState.acquisitionId}`}>your Dashboard</a> :
                                        <a id="utilities-form-anchor-5854" href="https://offerpad.com" rel="noopener">the Offerpad main page</a>
                                }.
                            </p>
                        </div>
                    </div>
                    :
                    <UtilitiesFormContent
                        acquisitionId={offerState.acquisitionId}
                    />
            }
        </SurveyModal>
    );
}

export default UtilitiesFormPage;