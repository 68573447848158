import { getAuthHeaders } from '../AuthService/auth.service';
import apiClient from '../../clients/apiClient'

const apiManagementUrl = process.env.RAZZLE_API_MANAGEMENT_URL;
const customerEndpoint = () => {
    apiClient.defaults.baseURL = apiManagementUrl + '/api/customer/v2/';
    return apiClient;
};
export const contractUrl = (contractId, token) => {
    return apiManagementUrl + `/api/customer/v2/contracts/${contractId}/document?token=${token}`;
};

export const coeDateChange = (payload) => customerEndpoint().post('/requests/changecoe', payload, getAuthHeaders());
export const sendMessage = (payload) => customerEndpoint().post('/requests/message', payload, getAuthHeaders());
export const getDocument = (contractId, token) => customerEndpoint().get(contractUrl(contractId, token), { responseType: 'blob' });
