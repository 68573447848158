import React from 'react';
import AppFooter from './AppFooter';
import TopNavbar from './TopNavbar';
import './AuthLayout.scss';

type AuthLayoutLayoutProps = {
    children: React.ReactNode;
};

const AuthLayout: React.FC<AuthLayoutLayoutProps> = ({ children }) => (
    <div className='c-authpage'>
        <TopNavbar noSidebar userAvatar />
        <div className='c-authpage__content'>
            {children}
        </div>
        <AppFooter />
    </div>
);

export default AuthLayout;
