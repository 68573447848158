import TokenService from '../token.service';
import apiClient from '../../clients/apiClient'
import jwt_decode from 'jwt-decode';
import qs from 'qs';

const apiManagementUrl = process.env.RAZZLE_API_MANAGEMENT_URL;
const CLIENT_ID = 'OPTwirl';
const customerAuthEndpoint = () => {
    apiClient.defaults.baseURL = apiManagementUrl + '/api/customer-auth/v2/';
    return apiClient;
};

const toExpiresInDate = (expiresIn) => {
    let expiresInDate = new Date();
    /*get exact time of expiration token*/
    expiresInDate.setSeconds(expiresInDate.getSeconds() + expiresIn);
    return expiresInDate;
};

const parseToken = async (response) => {
    try {
        if (response.status === 200) {
            const { access_token, refresh_token, expires_in } = response.data;

            if (access_token) {
                TokenService.saveToken(access_token);
                TokenService.saveRefreshToken(refresh_token);
                TokenService.saveExpiresIn(toExpiresInDate(expires_in));
                const decodedAccessToken = jwt_decode(access_token);
                localStorage.setItem('user.info', JSON.stringify(decodedAccessToken));
                return true;
            }
        }
    } catch (err) {
        return err;
    }
    return false;
};

export const getAuthHeaders = () => {
    return {
        headers: {
            Authorization: `Bearer ${TokenService.getToken()}`,
        },
    };
};

export const AuthService = {
    getUserInfo: () => localStorage.getItem('user.info'),
    logout: () => {
        TokenService.clearAll();
        localStorage.removeItem('user.info');
        document.cookie = 'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    },
    login: async (username: string, password: string) => {
        try {
            let response = await customerAuthEndpoint().post(
                '/token',
                qs.stringify({
                    username,
                    password,
                    client_id: CLIENT_ID,
                    grant_type: 'password',
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );

            return await parseToken(response);
        } catch (err) {
            return err;
        }
    },
    refreshToken: async () => {
        try {
            let response = await customerAuthEndpoint().post(
                '/token',
                qs.stringify({
                    refresh_token: TokenService.getRefreshToken(),
                    client_id: CLIENT_ID,
                    grant_type: 'refresh_token',
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );
            return await parseToken(response);
        } catch (err) {
            return err;
        }
    },
    register: async ({ firstName, lastName, email, password, agent, agentId, phoneNumber }) : Promise<Response> => {
        return customerAuthEndpoint()
                .post('/register', {
                    firstName,
                    lastName,
                    email,
                    password,
                    agent,
                    agentId,
                    phoneNumber,
                    sendPhoneNumberConfirmation: false,
                    sendEmailConfirmation: true,
                    confirmEmailSuccessUrl: `${window.location.protocol}//${window.location.host}/verify-email?preloadEmail=${email}`,
                    confirmEmailFailureUrl: `${window.location.protocol}//${window.location.host}/verify-email?message=Error verifying email`,
                })
    }, forgotPassword: (email) =>
        customerAuthEndpoint().post('/forgot-password', {
            email,
            linkUrl: `${window.location.protocol}//${window.location.host}/reset-password`,
        }),
    resetPassword: (data) => customerAuthEndpoint().post('/reset-password', data),
    sendVerificationEmail: () =>
        customerAuthEndpoint().post(
            '/send-email-confirmation',
            {
                successUrl: `${window.location.protocol}//${window.location.host}/verify-email`,
                failureUrl: `${window.location.protocol}//${window.location.host}/verify-email?message=Error verifying email`,
            },
            getAuthHeaders()
        ),
    sendVerificationText: () => customerAuthEndpoint().post('/send-phone-number-confirmation', null, getAuthHeaders()),
    verifyPhone: (code) => customerAuthEndpoint().post('/confirm-phone-number', { code }, getAuthHeaders()),
}

export default AuthService;