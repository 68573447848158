import React from 'react'
import './Navbar.scss'

type NavbarProps = {
    showNavbar: boolean,
    navbarMenuClasses?: [string, Object],
    transparent: boolean,
    position: string,
    type?: string,
    children: React.ReactNode;
}

const Navbar: React.FC<NavbarProps> = (props) => {
    const { type = 'absolute', position, showNavbar, transparent, children } = props;
    const classes = () => {
        let color = `bg-${type}`;
        let navPosition = `navbar-${position}`;
        return [
            (showNavbar || !transparent) && color,
            navPosition,
        ].filter(cssClass => cssClass);
    }

    return <div>
        <nav className={`${classes().join(' ')} navbar navbar-expand-lg`}>
            <div
                className="container-fluid"
                style={{
                    display: 'flex',
                    alignItems: ' flex-start',
                    justifyContent: 'space-between'
                }}
            >
                {children}
            </div>
        </nav>
    </div >
}


export default Navbar;