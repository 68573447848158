import React, { useEffect, useRef, useState } from 'react';
import './ProgressBar.scss';
import ProgressStepsSeller from './progressStepsSeller';
import { ProgressStepsBuyerNoNeg, ProgressStepsBuyerYesNeg } from './progressStepsBuyer';
import { useDisposition } from '../../../../context/disposition/DispositionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DispositionStatus } from 'src/services/DispositionService/types';

type ProgressBarProps = {
    workflowStep: string;
    currentProgressBar: ProgressStep;
    progressPercentage: number;
    show?: boolean
}

export interface ProgressStep {
    label: string;
    order: number;
}

export class ProgressDashboardInfo {
    workflowStep: string = '';
    progressBar: ProgressStep = { label: 'Offer', order: 1 };
    progressPercentage: number = 0;
    sentenceTop: string = '';
    whatsNextHeading: string = '';
    whatsNextCopy: string = '';
    faqs: Array<any> = [];
    column: string = '';
    column2: string = '';
}



const ProgressBar: React.FC<ProgressBarProps> = ({ currentProgressBar, progressPercentage, show = true }) => {
    if (!show) {
        return null;
    }
    const { state: { dispositionOffer } } = useDisposition();
    const isBuyer = window.location.pathname.includes('/buyers/');
    const ProgressStepsBuyer = dispositionOffer?.portalDispositionOfferDetails.length > 1 ||
        dispositionOffer?.status === DispositionStatus.NEED_HIGHEST_BEST ? ProgressStepsBuyerYesNeg : ProgressStepsBuyerNoNeg;
    const progressBarSteps = Object.values(isBuyer ? ProgressStepsBuyer : ProgressStepsSeller);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const tagsContainer = useRef(null);
    const scrollContainer = useRef(null);

    const scrollToCurrent = () => {
        if (scrollContainer.current && scrollContainer.current.scroll) {
            scrollContainer.current.scroll({
                left: scrollContainer.current.scrollLeft + (220 * (currentProgressBar.order - 2)),
                behavior: 'smooth'
            });
        }
    }

    const scrollTags = (isRight) => {
        if (scrollContainer.current) {
            scrollContainer.current.scroll({
                left: isRight ? scrollContainer.current.scrollLeft + 220 : scrollContainer.current.scrollLeft - 220,
                behavior: 'smooth'
            });
        }
    }

    useEffect(() => {
        const div = scrollContainer.current;
        if (div) {
            div.addEventListener("scroll", () => {
                setShowLeftArrow(scrollContainer.current.scrollLeft > 0);
                setShowRightArrow(scrollContainer.current?.offsetWidth + scrollContainer.current.scrollLeft <= tagsContainer.current?.offsetWidth - 2);
            });
        }
    }, []);

    useEffect(() => {
        scrollToCurrent();
        const tagOffset = scrollContainer.current?.offsetWidth;
        setShowRightArrow(tagOffset < tagsContainer.current?.offsetWidth);
    }, []);

    let style = `linear-gradient(to right, #1B6775 ${progressPercentage}%, #949494 ${progressPercentage}%)`;

    if (window.innerWidth < 1155 && progressPercentage == 10) {
        //To avoid the background color being cut by the previous step on mobile
        style = `linear-gradient(to right, #1B6775 20px, #949494 20px)`;
    }

    return (
        <div className="outer-container">
            <div className="tracker-container" ref={scrollContainer}>
                <ul className="tracker" ref={tagsContainer}>
                    {
                        progressBarSteps.filter(x => x).map((item) => {
                            if (item.order > currentProgressBar.order) {
                                return <li key={item.order} className="tracker__item">
                                    {item.label}
                                </li>
                            } else if (item.order == currentProgressBar.order) {
                                return <li key={item.order} className="tracker__item current"
                                    style={{ background: style, }}>
                                    {item.label}
                                </li>
                            } else {
                                return <li key={item.order} className="tracker__item completed">
                                    {item.label}
                                </li>
                            }
                        }
                        )
                    }
                </ul>
            </div>
            {!!showRightArrow && <div className="scrollIcon" onClick={() => scrollTags(true)}><FontAwesomeIcon icon={['fas', 'chevron-right']} /></div>}
            {!!showLeftArrow && <div className="scrollIconLeft" onClick={() => scrollTags(false)}><FontAwesomeIcon icon={['fas', 'chevron-left']} /></div>}
        </div>
    );
}

export default ProgressBar;
