import React from "react";
import { Link } from "react-router-dom";

import './NotFound.scss'

const NotFound = () => {
    return <div className="c-authform">
        <h2 className="c-authform__title">Page Not Found</h2>
        <p className="c-authform__subtitle">
            Oops! It seems that this page does not exist.
        </p>

        <div className="c-authform__forgot">
            You can navigate back to the{' '}
            <Link id="not-found-main-page" to="/" data-qa="not-found-go-to-main-link">main page</Link>
        </div>
    </div>
};

export default NotFound;