import React, { createContext, FunctionComponent, useContext } from 'react';
import TokenService from '../../services/token.service';
import jwt_decode from 'jwt-decode';
import { useUser } from 'src/context/user/UserContext';

export type Claims = {
    Customer: string;
    Email: string;
    EmailConfirmed: string;
    Platform: string;
    agent: string;
    agentId: string;
    amr: Array<string>
    aud: Array<string>
    auth_time: number
    brokerageName: string
    buyActive: string;
    client_id: string;
    driversLicenseConfirmed: string;
    email: string;
    emailConfirmed: string;
    exp: number
    firstName: string;
    idp: string;
    iss: string;
    lastName: string;
    nbf: number;
    phoneNumber: string;
    phoneNumberConfirmed: string;
    scope: Array<string>
    sellActive: string;
    sub: string;
}

const AuthenticationContext = createContext<{
    getClaims: () => Claims,
    hasEmailConfirmed: (request: () => void, cb: () => void) => void
} | undefined>(undefined);

const AuthenticationProvider: FunctionComponent = ({ children }) => {

    // for when we verify email but don't re-login so token doesn't get updated
    const { state: userInfo } = useUser();

    // takes 2 functions. Calls the first one if authenticated and email is confriemd
    // calls the second one if not
    const hasEmailConfirmed = (request: () => void, cb: () => void) => {
        const token = TokenService.getToken();
        const user = token ? jwt_decode<Claims>(token) : null;
        if (token && (user.emailConfirmed === 'True' || user.EmailConfirmed === 'True' || userInfo.EmailConfirmed === 'True')) {
            request();
        } else {
            cb();
        }
    }

    const getClaims = () => {
        const token = TokenService.getToken();
        return token ? jwt_decode<Claims>(token) : null;
    }
    return <AuthenticationContext.Provider value={{ getClaims, hasEmailConfirmed }}>{children}</AuthenticationContext.Provider>;
}

const useAuthentication = () => {
    const context = useContext(AuthenticationContext);
    if (context === undefined) {
        throw new Error('useAuthentication must be used within an AuthenticationProvider');
    }
    return context;
};

export { useAuthentication, AuthenticationProvider, AuthenticationContext };