import { newId } from "@microsoft/applicationinsights-core-js";

//Messy mapping to try and keep title images in sync
export function titleContactImage(titleContact) {
    if (!titleContact) return null;
    switch ((titleContact.fullName ?? '').toLowerCase()) {
        case 'christy keledjian':
            return require('../../../assets/titles/firstam_christy_keledjian.jpg');
        case 'michel logan':
            return require('../../../assets/titles/firstam_michel_logan.jpg');
        case 'christine russell':
            return require('../../../assets/titles/firstam_christine_russell.jpg');
        case 'janet brehm':
            return require('../../../assets/titles/firstam_janet_brehm.jpg');
        case 'philip clark':
            return require('../../../assets/titles/firstam_philip_clark.jpg');
        case 'ellen konight-sutton':
            return require('../../../assets/titles/firstam_ellen.jpg');
        case 'heather raisler':
            return require('../../../assets/titles/firstam_heather_raisler.jpg');
        case 'rebecca allsbury':
            return require('../../../assets/titles/firstam_becky_allsbury.jpg');
        case 'angela fletcher':
            return require('../../../assets/titles/firstam_angela_fletcher.jpg');
        case 'cathy tindle':
            return require('../../../assets/titles/cathy-tindle.jpg');
        case 'donna-lamorte':
            return require('../../../assets/titles/firstam_donna_lamonte.jpg');
        case 'karla mcmillan':
            return require('../../../assets/titles/firstam_karla_mcmillen.jpg');
        case 'jon hankin':
            return require('../../../assets/titles/hankin_jon_hankin.jpg');
        case 'kelly rosser':
            return require('../../../assets/titles/mmg_kelly_rosser.jpg');
        default:
            return null;
    }
}

export function illustrationPathForTitle(title) {
    switch (title) {
        case 'Hankin & Pack PLLC':
            return require('../../../assets/illustrations/title_hankin.svg');
        case 'McMichael & Gray, PC':
            return require('../../../assets/illustrations/title_mmandg.svg');
        case 'Statewide Title Services':
            return require('../../../assets/illustrations/title_statewide.svg');
        case 'First American Title':
        default:
            return require('../../../assets/illustrations/title_firstam.svg');
    }
}
