import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Alert.scss';

type AlertProp = {
    description: string;
}

const Alert: React.FC<AlertProp> = ({ description }) =>
    <div className="alert-op">
        <FontAwesomeIcon icon={['fas', 'exclamation-triangle']}/>
        {description}
    </div>

export default Alert;