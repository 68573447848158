import React from 'react';
import { useDisposition } from '../../../context/disposition/DispositionContext';
import { Modal } from 'floorplan/components/v2/Modal';
import { Button } from 'floorplan/components/Button';
import { closeModal } from '@offerpad/concrete.react/lib/Modal';
import { useModal } from '@offerpad/concrete.react/lib/Modal';
import useScreen from '@offerpad/floorplan/dist/hooks/useScreen';
import { modalWidth } from '../common/constants';
import { Breakpoints } from '../../../utils/breakpointUtil';
import styles from '../common/OfferActionModal.module.scss';

const HBSubmittedModal: React.FC = () => {
    const { state: { isOpen }, dispatch: modalDispatch } = useModal();
    const screen = useScreen();

    const { state: { dispositionOffer: { agentFirstName }, loading, error, exception },
        clearError } = useDisposition();

    const closeModalWrapper = () => {
        clearError();
        closeModal(modalDispatch);
    }

    return <Modal
        open={isOpen}
        onClose={() => closeModalWrapper()}
        hasFooter
        width={screen.width < Breakpoints.DESKTOP ? modalWidth : null}
    >
        <Modal.Header
            title='H&#38;B Submitted'
            onClose={() => closeModalWrapper()}
            border
        />
        <Modal.Content>
            {error && <div className={styles.error}>
                {exception?.response?.data?.message || 'Error something went wrong, try again'}
            </div>}
            <div className={styles.modal_content}>
                {`Thank you ${agentFirstName}! You have submitted a highest-and-best offer for your buyer.`}
            </div>
        </Modal.Content>
        <Modal.Footer border>
            <Button
                id="button-060220220907"
                brand
                type="submit"
                className="modal-new-action-button"
                progress={loading}
                onClick={() => closeModalWrapper()}
            >
                Done
            </Button>
        </Modal.Footer>
    </Modal>
}

export default HBSubmittedModal;