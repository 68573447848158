import React from 'react';
import './SurveyModal.scss'

const SurveyModal = (props) => {
    return (
        <div className="survey-modal show">
            <div className="modal-dialog-survey">
                <div className="modal-content">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default SurveyModal;