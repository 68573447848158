import React from 'react';

type SurveyLayoutProps = {
    children: React.ReactNode;
}

const SurveyLayout: React.FC<SurveyLayoutProps> = (props) => {
    return <>
        {props.children}
    </>;
}

export default SurveyLayout;
