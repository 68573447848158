import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useScreen from '@offerpad/floorplan/dist/hooks/useScreen';
import {
    useUpload, FileService, uploadFile, Actions as UploadActions,
} from '@offerpad/concrete.react/lib/File';
import Heading from '@offerpad/floorplan/dist/typography/Heading';
import PageLayout from '../../Controls/PageLayout';
import { useDisposition } from '../../../context/disposition/DispositionContext';
import { useDocuments } from '../../../context/documents/DocumentsContext';
import TokenService from '../../../services/token.service';
import { formatDate } from '../../Controls/resources/datetime';
import { getDocuments, addDocumentBlob } from '../../../context/documents/DocumentsReducer';
import { useNotifications } from '../../../context/notifications/NotificationsContext';
import DocumentCard, { DocumentCardProps } from '../../Controls/DocumentCard';
import { notify } from '../../../context/notifications/NotificationsReducer';
import { formatDocumentName, getAllMessages } from '../../Controls/resources/documentUtil';
import { useUser } from '../../../context/user/UserContext';
import { useAuthentication } from '../../../context/authentication/AuthenticationContext';
import styles from './BuyersDocuments.module.scss';
import getProgressInfoBuyer from '../../Controls/resources/progressUtilBuyer';
import ProgressBar from '../../Controls/Dashboard/ProgressBar/ProgressBar';
import { DocumentStatus, Document } from '../../../services/DocumentsService/types';
import { DispositionStatus } from 'src/services/DispositionService/types';
import { openModal, useModal } from '@offerpad/concrete.react/lib/Modal';

import { Modals } from 'src/components/Controls/ModalController/ModalController';

const TWENTY_MB = 20 * 1024 ** 2;
const columns = ['Name', 'Uploaded Date', 'Uploaded By'];
const fileService = new FileService(process.env.RAZZLE_API_MANAGEMENT_URL);

const toMB = (n: number) => Math.round(n / 1024 ** 2);

const DocumentsTable = ({ documents, status }: { documents: Array<Document>, status: DispositionStatus }) => {
    const isTablet = useScreen().width < 991;
    const { state: uploads, dispatch: uploadDispatch } = useUpload();
    const { state: user } = useUser();
    const { getClaims } = useAuthentication();

    const claims = getClaims();
    const {
        state: { deleting, loading },
        dispatch: documentsDispath,
    } = useDocuments();
    const { dispatch: notificationsDispatch } = useNotifications();
    const allowedFileTypes = ['doc', 'pdf', 'docx'];
    const { dispatch: modalDispatch } = useModal();
    if (documents.length === 0) {
        return <h3>There are no documents associated to this offer</h3>;
    }

    const isAbsoluteUrl = new RegExp('^(?:[a-z+]+:)?//', 'i');
    const [hasPendingUpload] = documents.filter(
        doc => doc.status === DocumentStatus.PendingUpload && doc.type !== 'ExecutedOfferToBuy'
    );

    const renderGrayDocumentBlurb = () => {
        switch (status) {
            case DispositionStatus.SELLER_COUNTER_SENT_DOCUMENT_CORRECTIONS:
                return (
                    <div>
                        <div>
                            <span>Requirement: </span>
                            Please provide your buyer-signed state contract
                            package to include your buyer&apos;s proof-of-funds letter if this is a
                            cash offer, or your buyer&apos;s lender prequalification letter if this
                            is a financed offer.
                        </div>
                        <div>
                            <span>File Size Limit:</span>
                            {' '}
                            20 MB
                        </div>
                    </div>
                );
            default:
                return (
                    <div>
                        <div>
                            <span>Requirement: </span>
                            Please provide the buyer-signed contract package
                            updated to reflect all terms as previously discussed. This package must
                            be uploaded as a single PDF document with all pages in vertical format.
                            Please ensure the contract package includes any/all documents as
                            referenced below:
                        </div>
                        <ul>
                            <li>Buyer-signed state contract</li>
                            <li>Buyer&apos;s proof-of-funds letter for cash offers</li>
                            <li>Buyer&apos;s lender prequalification letter for financed offers</li>
                            <li>
                                Any/all documents included in the Agent Documents section of the MLS listing for this
                                property
                            </li>
                        </ul>
                        <div>
                            <span>File Size Limit:</span>
                            {' '}
                            20 MB
                        </div>
                    </div>
                );
        }
    };

    return (
        <div className={styles.buyers_documents}>
            {!isTablet && (
                <header className={styles.header}>
                    {columns.map(column => (
                        <Heading size={5} color='#636a71'
                            key={column}
                        >
                            {column}
                        </Heading>
                    ))}
                    <div />
                </header>
            )}
            {!!hasPendingUpload && (
                <div className={styles.instructions}>
                    {renderGrayDocumentBlurb()}
                </div>
            )}
            <div>
                {documents.map(doc => {
                    if (doc.type === 'ExecutedOfferToBuy' && doc.status === DocumentStatus.PendingUpload) return null;
                    const upload = uploads.find(file => file.type === `document-${doc.id}`);
                    const onFileInputChange = async e => {
                        const file = e.target.files[0];
                        const fileType = file.name.split('.').pop();
                        if (file.size > TWENTY_MB) {
                            return notify(notificationsDispatch, {
                                message: `The file size is ${toMB(file.size)}MB, which is more than the 20MB allowed.`,
                                type: 'danger',
                            });
                        }
                        if (!allowedFileTypes.includes(fileType)) {
                            return notify(notificationsDispatch, {
                                message: `${fileType} files are not allowed`,
                                type: 'danger',
                            });
                        }
                        await uploadFile(uploadDispatch, file, doc.connectionId, fileService, `document-${doc.id}`);
                        return null;
                    };

                    const blobsExist = doc.documentBlobs.length > 0;
                    const blobExistAndCreatedBy = blobsExist && doc.documentBlobs[0]?.createdBy;
                    const sameCreatedByClaims = doc.documentBlobs[0]?.createdBy === claims.email;

                    const createdByName = sameCreatedByClaims ? `${claims.firstName} ${claims.lastName}` : 'Offerpad';
                    let uploadedBy = blobExistAndCreatedBy ? createdByName : 'TBD';
                    let uploadDate = blobsExist
                        ? formatDate(doc.documentBlobs[0].createdDateTime, undefined, undefined, 'MM/DD/YY')
                        : 'TBD';
                    const sortedDocuments = blobsExist ? doc.documentBlobs.sort((a, b) => {
                        if (!a.id) return -1;
                        if (!b.id) return 1;
                        return b.id - a.id;
                    }) : null;

                    let url = sortedDocuments?.[0]?.blobUrl;
                    const blobStorage = process.env.RAZZLE_BLOB_STORAGE;
                    // if not absoltue url, add domain to it
                    if (url && !isAbsoluteUrl.test(url)) {
                        url = `${blobStorage}/${url}`;
                    }
                    if (doc.status === DocumentStatus.PendingUpload) {
                        url = null;
                        uploadedBy = 'TBD';
                        uploadDate = 'TBD';
                    }

                    const props: DocumentCardProps = {
                        name: formatDocumentName(doc.type),
                        uploadDate,
                        url,
                        uploadedBy,
                        onFileInputChange,
                        uploadProgress: upload?.progress,
                        allowDelete: false,
                        deleting,
                        onCancelUpload: fileService.cancel,
                        onCancel:
                            upload?.progress < 100
                                ? fileService.cancel
                                : () => uploadDispatch({
                                    type: UploadActions.DELETE_UPLOAD,
                                    data: { uuid: upload.uuid },
                                }),
                        onRemove: () => uploadDispatch({
                            type: UploadActions.DELETE_UPLOAD,
                            data: {
                                uuid: upload.uuid,
                            },
                        }),
                        allowMessage: true,
                        onSubmit: msg => addDocumentBlob(
                            documentsDispath,
                            doc.id,
                            upload.blobUrl,
                            upload.file.type,
                            upload.file.size,
                            msg,
                            claims.email,
                            'DispositionOfferBuyerDocuments',
                        ),
                        submitting: loading,
                        messages: getAllMessages(user, doc),
                        showMessageHistory: true,
                        hideCircle: true,
                        submitPackageCallBack: doc.type === 'InitialOfferToBuy'
                            && status === DispositionStatus.SELLER_COUNTER_SENT_DOCUMENT_CORRECTIONS
                            ? () => openModal(modalDispatch, Modals.OFFER_ACTIVITY_CONTINUE_MODAL) : null
                    };

                    return <DocumentCard {...props} key={doc.id} />;
                })}
            </div>
        </div>
    );
};

const BuyersDocuments = () => {
    const { state: disposition, getDisposition } = useDisposition();
    const {
        state: { documents = [] },
        dispatch: documentsDispath,
    } = useDocuments();
    const { offerKey } = useParams<{ offerKey: string }>();

    const { dispositionOffer } = disposition;
    useEffect(() => {
        if (!dispositionOffer || disposition.dispositionOffer?.id?.toString() !== offerKey) {
            getDisposition(offerKey);
        }
    }, [offerKey]);
    const isLoggedIn = TokenService.getToken();
    const visualTrackerInfo = getProgressInfoBuyer(dispositionOffer);

    useEffect(() => {
        if (isLoggedIn) {
            getDocuments(documentsDispath, offerKey, 'DispositionOffer', 'DispositionOfferBuyerDocuments');
        }
    }, [isLoggedIn, offerKey]);

    if (dispositionOffer === null) {
        return null;
    }

    return (
        <PageLayout
            streetAddress='Street Address'
            headerText='Documents'
            subHeaderText=''
            listedByAgent={false}
            centerColumn={(
                <>
                    {visualTrackerInfo.workflowStep && (
                        <ProgressBar
                            workflowStep={visualTrackerInfo.workflowStep}
                            currentProgressBar={visualTrackerInfo.progressBar}
                            progressPercentage={visualTrackerInfo.progressPercentage}
                        />
                    )}
                    <DocumentsTable documents={documents} status={dispositionOffer.status} />
                </>
            )}
        />
    );
};

export default BuyersDocuments;
