import React from 'react';
import TopNavbar from './TopNavbar';
import classes from './AuthLayout.module.scss';

type AuthLayoutLayoutProps = {
    children: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutLayoutProps> = ({ children }) => {
    return <div className={classes['c-authpage__login']}>
        <div className={classes['c-authpage__login__content']}>
            {children}
        </div>
    </div>
}

export default AuthLayout;