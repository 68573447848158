import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './SurveyPage.scss'
import SurveyModal from './SurveyModal/SurveyModal';
import PostInspectionSurveyContent from './PostInspectionSurveyContent';
import { useOffer } from '../../../context/offer/OfferContext';
import { loadOffer } from '../../../context/offer/OfferReducer';
import Spinner from 'floorplan/components/Spinner';
import TokenService from '../../../services/token.service';

const PostInspectionSurveyPage = () => {
    const history = useHistory();
    const { state: { offerState, loading }, dispatch: offerDispatch } = useOffer();
    const { offerKey } = useParams<{ offerKey: string }>();

    const formatTitle = {
        homeOwnerConsultant: 'Solution Advisor',
        conciergeAgent: 'Solution Expert',
        homeMarketingConsultant: 'Offerpad Representative',
        closingConsultant: 'Transaction Manager',
    };

    const getTeamMember = (title) => {
        const member = offerState[title];
        if (member) {
            return {
                firstName: member.firstName,
                fullName: member.fullName,
                title: formatTitle[title],
                imageUrl:
                    member.imageURL ||
                    `https://ui-avatars.com/api/?bold=true&color=ffffff&background=1b6775&format=svg&name=${member.fullName}`,
            };
        } else {
            return null;
        }
    };

    const wasSurveyAlreadyTaken = () => {
        return !!offerState.postInspectionSurvey;
    };

    let acquisitionId = offerState.acquisitionId;
    let teamMembersAvailable = offerState.postInspectionSurveyReady;
    let surveyAlreadyTaken = wasSurveyAlreadyTaken();
    let loggedIn = TokenService.getToken();

    useEffect(() => {
        loadOffer(offerDispatch, offerKey, 'offer', history);
    }, [offerKey]);

    if (loading) {
        return <div className="c-homelist__listings spinner-container">
            <Spinner />
        </div>
    }

    const linkId = 'post-inspection-survey-link-8219';

    return (
        !!offerState.acquisitionId &&
        <SurveyModal>
            {surveyAlreadyTaken &&
                <div className="p-4">
                    <div className="survey-not-available">
                        <h5>
                            It looks like you already took this survey, thanks for your feedback!
                        </h5>
                        <p>
                            You can navigate back to{' '}
                            {
                                loggedIn ?
                                    <a id='post-inspection-survey-link-8219' href={`${process.env.RAZZLE_CONNECT_URL}/sell/overview?transactionId=${acquisitionId}`}>your Dashboard</a> :
                                    <a id='post-inspection-survey-anchor-8219' href="https://offerpad.com" rel="noopener">the Offerpad main page</a>
                            }.
                        </p>
                    </div>
                </div>
            }
            {!surveyAlreadyTaken && !teamMembersAvailable &&
                <div className="p-4">
                    <div className="survey-not-available">
                        <h5>
                            It looks like this survey is not accessible at the moment, please check again later.
                        </h5>
                        <p>
                            You can navigate back to{' '}
                            {
                                loggedIn ?
                                    <a id='post-inspection-survey-link-9964' href={`${process.env.RAZZLE_CONNECT_URL}/sell/overview?transactionId=${acquisitionId}`}>your Dashboard</a> :
                                    <a id='post-inspection-survey-anchor-9964' href="https://offerpad.com" rel="noopener">the Offerpad main page</a>
                            }.
                        </p>
                    </div>
                </div>
            }
            {!surveyAlreadyTaken && teamMembersAvailable &&
                <PostInspectionSurveyContent
                    acquisitionId={acquisitionId}
                    solutionTeamMember={getTeamMember('homeOwnerConsultant') || getTeamMember('conciergeAgent')}
                    transactionManager={getTeamMember('closingConsultant')}
                    offerpadRepresentative={getTeamMember('homeMarketingConsultant')}
                />
            }
        </SurveyModal>
    );
}

export default PostInspectionSurveyPage;