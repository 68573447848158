import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './HomeFilters.module.scss';
import cx from 'classnames';
import { useDisposition } from '../../../context/disposition/DispositionContext';
import { stateCodes } from '../../helpers/stateNames';
import getProgressInfoBuyer from '../../Controls/resources/progressUtilBuyer';
import { DispositionStatus, DispositionSummary } from '../../../services/DispositionService/types';

export enum StatusFilter {
    ALL = 'All',
    OPEN = 'Open',
    DECLINED = 'Declined',
    CLOSED = 'Closed'
}

export const statusMapping = {
    [DispositionStatus.SELLER_COUNTER]: StatusFilter.OPEN,
    [DispositionStatus.SELLER_COUNTER_SENT]: StatusFilter.OPEN,
    [DispositionStatus.BUYER_RECEIVED]: StatusFilter.OPEN,
    [DispositionStatus.BUYER_COUNTER]: StatusFilter.OPEN,
    [DispositionStatus.RECEIVED]: StatusFilter.OPEN,
    [DispositionStatus.DECLINED]: StatusFilter.DECLINED,
    [DispositionStatus.ACCEPTED]: StatusFilter.OPEN,
    [DispositionStatus.NEED_HIGHEST_BEST]: StatusFilter.OPEN,
    [DispositionStatus.BUYER_HIGHEST_BEST]: StatusFilter.OPEN,
    [DispositionStatus.ACCEPTANCE_PENDING_REVIEW]: StatusFilter.OPEN,
    [DispositionStatus.CONTRACT_SUBMITTED]: StatusFilter.OPEN,
    [DispositionStatus.UNDER_CONTRACT]: StatusFilter.OPEN,
    [DispositionStatus.CLOSED]: StatusFilter.CLOSED,
    [DispositionStatus.WITHDRAWN]: StatusFilter.DECLINED,
    [DispositionStatus.REJECTED]: StatusFilter.DECLINED
};

export const statusToDisplay = (offer: DispositionSummary) => getProgressInfoBuyer(offer)?.progressBar?.label || 'Unknown';

export const filterByStatus = (offer: DispositionSummary, statusFilter: StatusFilter) => {
    const status = offer.status?.replace(/ /g, '')?.toLowerCase();
    return (statusFilter === StatusFilter.ALL || statusMapping[status] === statusFilter);
};

export const filterByText = (offer: DispositionSummary, textFilter: string) => {
    const propertyAddress = offer.propertyAddress?.toLowerCase().replace(' ', '');
    const buyerFirstName = offer.buyerFirstName?.toLowerCase().replace(' ', '');
    const buyerLastName = offer.buyerLastName?.toLowerCase().replace(' ', '');
    const status = statusToDisplay(offer)?.toLowerCase().replace(' ', '');
    const filter = textFilter.toLowerCase()?.replace(' ', '');
    return (
        propertyAddress.includes(filter)
        || buyerFirstName.includes(filter)
        || buyerLastName.includes(filter)
        || status.includes(filter)
    );
};

type HomeFiltersProps = {
    statusFilter: StatusFilter;
    setStatusFilter: (filter: StatusFilter) => void;
    textFilter: string;
    setTextFilter: (filter: string) => void;
};

const HomeFilters: React.FC<HomeFiltersProps> = props => {
    const { statusFilter, setStatusFilter, textFilter, setTextFilter } = props;
    const { state: { dispositions = [] } } = useDisposition();
    const [statusFilterFocused, setStatusFilterFocused] = React.useState(false);
    const [textFilterFocused, setTextFilterFocused] = React.useState(false);
    const statusFilterRef = useRef(null);
    const textFilterRef = useRef(null);

    const handleOutsideClick = event => {
        if (statusFilterRef.current && !statusFilterRef.current.contains(event.target)) {
            setStatusFilterFocused(false);
        }
        if (textFilterRef.current && !textFilterRef.current.contains(event.target)) {
            setTextFilterFocused(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick, false);
        return () => {
            document.removeEventListener('click', handleOutsideClick, false);
        };
    }, []);

    const results = dispositions
        ?.filter(offer => filterByStatus(offer, statusFilter))
        ?.filter(offer => filterByText(offer, textFilter));

    return (
        <div className={cx(styles.homeFiltersContainer)}>
            <div className={cx(styles.homeFilters)}>
                <div className={cx(styles.statusFilter)}>
                    <input
                        id='statusFilter'
                        value={statusFilter}
                        onClick={() => setStatusFilterFocused(!statusFilterFocused)}
                        ref={statusFilterRef}
                        readOnly
                    />
                    <FontAwesomeIcon
                        icon={['fas', 'caret-down']}
                        className={cx(styles.statusFilterIcon)}
                    />
                </div>
                <div className={cx(styles.textFilter, !textFilterFocused ? styles.inactive : null)}>
                    <FontAwesomeIcon icon={['far', 'search']} />
                    <input
                        type='text'
                        id='textFilter'
                        name='textFilter'
                        value={textFilter}
                        onChange={e => setTextFilter(e.target.value)}
                        placeholder='Search'
                        onClick={() => setTextFilterFocused(!textFilterFocused)}
                        ref={textFilterRef}
                    />
                </div>
            </div>
            {
                statusFilterFocused
                && (
                    <div className={cx(styles.statusOptions)}>
                        {
                            [StatusFilter.ALL, StatusFilter.OPEN, StatusFilter.CLOSED, StatusFilter.DECLINED].map(status => (
                                <div
                                    className={cx(styles.statusOption)}
                                    onClick={() => setStatusFilter(status)}
                                    key={status}
                                    role='button'
                                    tabIndex={0}
                                >
                                    <FontAwesomeIcon
                                        icon={['fas', 'check']}
                                        className={cx(styles[statusFilter === status ? 'optionIcon' : 'noIcon'])}
                                    />
                                    <p>{status}</p>
                                </div>
                            ))
                        }
                    </div>
                )
            }
            {
                textFilterFocused
                && (
                    <div className={cx(styles.offersAutocomplete)}>
                        {
                            results.map(offer => (
                                <div
                                    className={cx(styles.offerLink)}
                                    key={offer.id}
                                >
                                    <Link
                                        to={`buyers/${offer.id}/overview`}
                                    >
                                        <p
                                            className={styles.address}
                                        >
                                            {`${offer.propertyAddress}, 
                                            ${offer.propertyCity}, 
                                            ${stateCodes?.[offer.propertyState] || ''} 
                                            ${offer.propertyZipcode}`}
                                        </p>
                                        <p
                                            className={styles.details}
                                        >
                                            Buyer:
                                            {' '}
                                            <b>{`${offer.buyerFirstName} ${offer.buyerLastName}`}</b>
                                            ,
                                            Status:
                                            {' '}
                                            <b>{statusToDisplay(offer)}</b>
                                        </p>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
};

export default HomeFilters;
