import { agentUser as c } from './contentHelpers';
import { formatDate } from '../datetime';
import { formatDocumentName } from '../documentUtil';

export class CurrentTasks {
    offerState;
    nextId;

    constructor(offerState) {
        this.offerState = offerState;
        this.nextId = 16384;
    }

    uploadDocument = (document) => {
        return {
            id: this.nextId++,
            title: `Upload ${formatDocumentName(document.type)}`,
            description:
                'Your Transaction Manager has requested that you provide us with this document. Please upload as soon as possible.',
            completionCriteria: null,
            showCompletionCriteria: false,
            buttonText: `Upload Document`,
            buttonUrl: `/offer/${document.connectionId}/documents`,
            isExternal: false,
        };
    };
    requestOffer = (newOffer = null) => {
        return {
            id: this.nextId++,
            title: newOffer ? 'Request New Offer' : 'Request your offer',
            description: newOffer && newOffer.isLost ? `Complete our 3-minute form, and we'll have everything we need to send you our cash offer. Plus, we'll look into other potential Offerpad selling options that could be beneficial. Getting you sold is our #1 goal!` :`You can request a no-obligation offer on your home! Our valuation team will evaluate the information you provide in order to ensure the most accurate and competitive offer.`,
            buttonText: newOffer ? 'Request New Offer' : 'Request offer',
        };
    };
    uploadPhotos = () => {
        return {
            id: 0,
            title: `Upload ${c('', 'your ', this.offerState)}photos`,
            description:
                "We noticed you haven't uploaded photos yet. Photos give our team a better idea of a home's value and help us to provide you with the most accurate offer possible.",
            buttonText: 'Upload photos',
        };
    };
    reviewOffer = () => {
        return {
            id: 1,
            title: 'Review your offer',
            description: `Our team has reviewed the information ${c('', 'you ', this.offerState)}provided
            regarding your ${c("seller's ", '', this.offerState)}home and
            is excited to present ${c('', 'you with ', this.offerState)}our offer.`,
            completionCriteria: '',
            showCompletionCriteria: false,
            buttonText: 'Review offer',
        };
    };
    signContract = () => {
        return {
            id: 2,
            title: `Sign ${c('', 'your ', this.offerState)}contract`,
            description: `Taking the next step is easy &mdash; we've sent you ${c('the', 'our', this.offerState)}
            purchase contract via email, which can be digitally signed${c(' by your seller', '', this.offerState)}.`,
            completionCriteria: '',
            showCompletionCriteria: false,
            // TODO: determine buttonUrl
            // buttonText: "Watch video on how it's done!",
            // buttonUrl: undefined,
        };
    };
    contactTitle = (companyName: string) => {
        return {
            id: 12,
            title: `Contact ${companyName}`,
            description: `${companyName} has notified us that they need to speak with ${c(
                'your seller',
                'you',
                this.offerState
            )}.
            Please connect with your representative at your earliest convenience.`,
            completionCriteria: `We will mark this task as completed when ${companyName}
            has informed us that they no longer need any information from ${c('your seller', 'you', this.offerState)}.`,
            showCompletionCriteria: true,
            buttonText: undefined,
            buttonUrl: undefined,
        };
    };
    titleNeedsInfo = (companyName: string) => {
        return {
            id: 3,
            title: 'Title needs information',
            description: `One of the first things <strong>${companyName}</strong> will need from
            ${c('your seller is the', 'you is a', this.offerState)}
            <em>Seller Information Sheet</em> to be completed. Please work with
            your contact directly.`,
            completionCriteria: `This task will be marked as completed when <strong>${companyName}</strong>
            informs us that the <em>Seller Information Sheet</em> has been completed.`,
            showCompletionCriteria: true,
            buttonUrl: `/${this.offerState.keyType}/${this.offerState.offerKey}/to-dos`,
            buttonText: 'View contact information',
        };
    };
    signSellerDisclosuresEmbedded = (state, sellers) => {
        return {
            id: 4,
            title: 'Complete Seller Disclosures',
            description: `Seller disclosures are required in the State of ${state}.  Please click below to fill out and sign the document.  The second seller must also sign the document in order to complete it.`,
            completionCriteria: null,
            showCompletionCriteria: false,
            buttonText: null,
            buttonUrl: `/${this.offerState.keyType}/${this.offerState.offerKey}/checklist`,
            isExternal: false,
            sellersButtonsData: sellers,
        };
    };
    checkMovingCompanyEmail = () => {
        return {
            id: 5,
            title: `Look out for an email from Bellhop`,
            description: `${c('Your seller has', 'You have', this.offerState)} selected our free local move option. You
            ${c('should', 'will', this.offerState)}
            receive an email from our preferred vendor <strong>Bellhop</strong> letting you know
            the next step for your ${c("seller's ", '', this.offerState)}move.`,
            completionCriteria: null,
            showCompletionCriteria: false,
            buttonText: undefined,
            buttonUrl: undefined,
        };
    };
    scheduleHomeInspection = (owner) => {
        const homeInspectionCopy = (associate, role) => {
            return {
                id: 6,
                title: 'Schedule the home inspection',
                description: `The next step is to schedule ${c('the', 'your', this.offerState)} inspection appointment.
                ${c('Our', 'Your', this.offerState)} ${role}, ${associate.fullName}
                ${c(
                    "will contact you regarding scheduling this appointment around your seller's schedule",
                    'will be contacting you to schedule this appointment',
                    this.offerState
                )}.`,
                completionCriteria: '',
                showCompletionCriteria: false,
                buttonText: `Contact Information for ${associate.firstName}`,
            };
        };
        const [associate, role] = !!owner
            ? [owner, 'In-Home Offerpad Representative']
            : [this.offerState.closingConsultant, 'Transaction Manager'];

        if (!!associate) {
            return homeInspectionCopy(associate, role);
        }
        return null;
    };
    signPostPossessionAddendum = () => {
        return {
            id: 7,
            title: 'Sign Post Possession Addendum',
            description: `${c(
                'Your seller has',
                "You've",
                this.offerState
            )} elected to take advantage of our Post Possession program
            ${c(
                ". We've emailed our Post Possession addendum for your seller's review and signature",
                "and we've sent you our Post Possession addendum for your review and signature",
                this.offerState
            )}.
            Please check your email.`,
            completionCriteria: '',
            showCompletionCriteria: false,
            buttonText: undefined,
            buttonUrl: undefined,
        };
    };
    signExtendedStayAddendum = () => {
        return {
            id: 18,
            title: 'Sign Extended Stay Addendum',
            description: `${c(
                'Your seller has',
                "You've",
                this.offerState
            )} elected to take advantage of our Extended Stay program
            ${c(
                ". We've emailed our Extended Stay addendum for your seller's review and signature",
                ". We've sent you our Extended Stay addendum for your review and signature",
                this.offerState
            )}.
            Please check your email.`,
            completionCriteria: '',
            showCompletionCriteria: false,
            buttonText: undefined,
            buttonUrl: undefined,
        };
    };
    signExtendedStayCancellationAddendum = () => {
        return {
            id: 19,
            title: 'Sign Extended Stay Cancellation Addendum',
            description: `${c(
                'Your seller has',
                "You've",
                this.offerState
            )} elected to opt out of the Extended Stay program
            ${c(
                ". We've emailed a contract addendum for your seller's review and signature",
                "and we've sent you a contract addendum for your review and signature",
                this.offerState
            )}.
            Please check your email.`,
            completionCriteria: '',
            showCompletionCriteria: false,
            buttonText: undefined,
            buttonUrl: undefined,
        };
    };
    signExtendedStayCOEChangeAddendum = () => {
        return {
            id: 20,
            title: 'Sign Extended Stay COE Change Addendum',
            description: `${c(
                'Your seller has',
                "You've",
                this.offerState
            )} elected to take advantage of our Extended Stay program and change the close of escrow
            ${c(
                ". We've emailed our Extended Stay addendum for your seller's review and signature",
                "and we've sent you our Extended Stay addendum for your review and signature",
                this.offerState
            )}.
            Please check your email.`,
            completionCriteria: '',
            showCompletionCriteria: false,
            buttonText: undefined,
            buttonUrl: undefined,
        };
    };
    signExtendedStayCancellationCOEChangeAddendum = () => {
        return {
            id: 21,
            title: 'Sign Extended Stay COE Change Cancellation Addendum',
            description: `${c(
                'Your seller has',
                "You've",
                this.offerState
            )} elected to opt out of the Extended Stay program and change the close of escrow
            ${c(
                ". We've emailed a contract addendum for your seller's review and signature",
                "and we've sent you a contract addendum for your review and signature",
                this.offerState
            )}.
            Please check your email.`,
            completionCriteria: '',
            showCompletionCriteria: false,
            buttonText: undefined,
            buttonUrl: undefined,
        };
    };
    scheduleInspectionReview = (closingConsultant) => {
        return {
            id: 9,
            title: `Schedule call to talk with your ${c('Offerpad ', '', this.offerState)} Transaction Manager`,
            description: `${c('The', 'Your', this.offerState)} results from your inspection are ready and
            we'd like to go over things with you. Please use the link below
            to schedule a call.`,
            completionCriteria: '',
            showCompletionCriteria: false,
            buttonText: `Schedule a call with ${closingConsultant}`,
        };
    };
    reviewAndSignCredit = (contactEmail) => {
        return {
            id: 10,
            title: 'Review & sign credit addendum',
            description: `We've emailed you a link to a credit addendum to <strong>${contactEmail}</strong>.
            Please review ${c('and have your seller sign', 'it and sign it', this.offerState)} as soon as possible.`,
            completionCriteria: '',
            showCompletionCriteria: false,
            buttonText: undefined,
            buttonUrl: undefined,
        };
    };
    reviewAndSignCreditAndRepairAddendum = (contactEmail) => {
        return {
            id: 11,
            title: 'Review & sign credit and repair addendum',
            description: `We've emailed you a link to a credit and repair
            addendum to <strong>${contactEmail}</strong>. Please review ${c(
                'and have your seller sign',
                'it and sign it',
                this.offerState
            )} as soon as possible.`,
            completionCriteria: '',
            showCompletionCriteria: false,
            buttonText: undefined,
            buttonUrl: undefined,
        };
    };
    contactMovingCompany = (movingCompany) => {
        return {
            id: 13,
            title: `Contact ${movingCompany}`,
            description: `<strong>${movingCompany}</strong> has notified us that they may need additional
            information from ${c('your seller', 'you', this.offerState)}.
            Please ${c('ask your seller to', '', this.offerState)}reach out to them at ${c(
                'their',
                'your',
                this.offerState
            )} earliest convenience.`,
            completionCriteria: `We will remove this checklist item when <strong>${movingCompany}</strong>
            has ${c('informed', 'notified', this.offerState)} us that they no longer need any information${c(
                '',
                ' from you',
                this.offerState
            )}.`,
            showCompletionCriteria: true,
            buttonText: `View ${movingCompany} Contact Info`,
            buttonUrl: `/${this.offerState.keyType}/${this.offerState.offerKey}/to-dos`,
        };
    };
    scheduleClosingAppointment = (companyName) => {
        return {
            id: 14,
            title: `Schedule Signing Appointment with ${companyName}`,
            description: `<strong>${companyName}</strong> has notified us that ${c(
                'your seller needs',
                'you need',
                this.offerState
            )} to schedule ${c('their', 'your', this.offerState)}
            signing appointment, please ${c(
                'let your seller know',
                'connect with your representative to schedule',
                this.offerState
            )}.`,
            completionCriteria: `${c(
                'We will mark this task as completed when we hear from the title company that the appointment has been scheduled',
                'We will remove checklist item when we hear from title that your appointment has been scheduled',
                this.offerState
            )}.`,
            showCompletionCriteria: true,
            buttonText: `View ${companyName} contact info`,
            buttonUrl: undefined,
            buttonFunction: undefined,
        };
    };
    signCOEAddendum = (newDate) => {
        return {
            id: 15,
            title: `Sign contract addendum`,
            description: `We have sent you an addendum to change your ${c(
                "seller's ",
                '',
                this.offerState
            )}close of escrow date to ${newDate}.
            Please check your email and ${c(
                'forward to your seller for their digital signature',
                'digitally sign the addendum at your earliest convenience',
                this.offerState
            )}.`,
            completionCriteria: '',
            showCompletionCriteria: false,
        };
    };
    checkCompletedClosingSurvey = (url) => {
        return {
            id: 16,
            title: 'Complete final survey',
            description:
                'Your feedback is valuable to our team. Please take a few minutes to tell us about your experience with Offerpad.',
            completionCriteria: null,
            showCompletionCriteria: false,
            buttonText: 'Complete Survey',
            buttonUrl: url,
            isExternal: false,
        };
    };
    completePostInspectionSurvey = () => {
        return {
            id: 17,
            title: 'Complete our Customer Experience Mini-Survey',
            description: `Please provide us with your valuable feedback so that we can continue improving our process.`,
            completionCriteria: '',
            showCompletionCriteria: false,
            buttonText: `Take the survey`,
        };
    };
    utilitiesFormNeeded = (url) => {
        return {
            id: 18,
            title: 'Provide Utilities Information',
            description: `Please take 5-minutes to tell us about the utilities and services for the home. This helps us transfer utilities quickly and also helps the next owner.`,
            completionCriteria: null,
            showCompletionCriteria: false,
            buttonText: 'Complete Form',
            buttonUrl: url,
            isExternal: false,
        };
    };
    signSellerDisclosuresEmail = () => {
        return {
            id: 19,
            title: `${c(
                'Seller disclosures to be completed',
                'Please complete your seller disclosures',
                this.offerState
            )}`,
            description: `Our team has sent you an email with a link to the seller disclosure
    form which is required on your ${c(
        "seller's ",
        '',
        this.offerState
    )}property. Please complete it as soon as possible.`,
            completionCriteria: '',
            showCompletionCriteria: false,
            buttonText: undefined,
            buttonUrl: undefined,
        };
    };
}

export class InProgressTasks {
    offerState;

    constructor(offerState) {
        this.offerState = offerState;
    }

    signSellerDisclosuresEmbedded = (state, sellers) => {
        return {
            id: 4,
            title: 'Complete Seller Disclosures',
            description: `Seller disclosures are required in the State of ${state}.  Please click below to fill out and sign the document.  The second seller must also sign the document in order to complete it.`,
            completionCriteria: null,
            showCompletionCriteria: false,
            buttonText: null,
            buttonUrl: `/${this.offerState.keyType}/${this.offerState.offerKey}/checklist`,
            isExternal: false,
            sellersButtonsData: sellers,
        };
    };
    disclosuresWaitingBuyerSignature = () => {
        return {
            id: 5,
            title: 'Complete Seller Disclosures',
            description: `Offerpad is reviewing the information you provided in the disclosure document.`,
            completionCriteria: null,
            showCompletionCriteria: false,
            buttonText: null,
            buttonUrl: undefined,
            isExternal: false,
        };
    };
}

export class CompletedTasks {
    offerState;
    nextId;

    constructor(offerState) {
        this.offerState = offerState;
        this.nextId = 16384;
    }

    uploadDocument = (document) => {
        return {
            id: this.nextId++,
            title: `Uploaded ${formatDocumentName(document.type)}`,
            completionDate: formatDate(document.modifiedDateTime, this.offerState.timeZoneId),
        };
    };

    uploadPhotos = () => {
        return {
            id: 0,
            title: 'Uploaded Photos',
            completionDate: '',
        };
    };
    reviewOffer = () => {
        return {
            id: 1,
            title: `Review ${c('', 'your ', this.offerState)}offer`,
        };
    };
    signContract = () => {
        return {
            id: 2,
            title: `Sign ${c('', 'your ', this.offerState)}contract`,
        };
    };
    titleNeedsInfo = () => {
        return {
            id: 3,
            title: 'Seller Information Sheet completed',
        };
    };
    disclosuresFormCompleted = () => {
        return {
            id: 4,
            title: 'Complete Seller Disclosures',
        };
    };
    scheduleHomeInspection = () => {
        return {
            id: 6,
            title: 'Schedule the home inspection',
        };
    };
    signPostPossessionAddendum = () => {
        return {
            id: 7,
            title: 'Extended Stay Addendum signed',
        };
    };
    signExtendedStayAddendum = () => {
        return {
            id: 18,
            title: 'Extended Stay Addendum signed',
        };
    };
    signExtendedStayCancellationAddendum = () => {
        return {
            id: 19,
            title: 'Extended Stay Cancellation Addendum signed',
        };
    };
    signExtendedStayCOEChangeAddendum = () => {
        return {
            id: 20,
            title: 'Extended Stay COE Change Addendum signed',
        };
    };
    signExtendedStayCancellationCOEChangeAddendum = () => {
        return {
            id: 21,
            title: 'Extended Stay COE Change Cancellation Addendum signed',
        };
    };
    reviewAndSignCredit = () => {
        return {
            id: 10,
            title: 'Review & sign credit addendum',
        };
    };
    reviewAndSignCreditAndRepairAddendum = () => {
        return {
            id: 11,
            title: 'Review & sign credit and repair addendum',
        };
    };
    scheduleClosingAppointment = (companyName: string) => {
        return {
            id: 14,
            title: `Schedule Signing Appointment with ${companyName}`,
        };
    };
    signCOEAddendum = (date) => {
        return {
            id: 15,
            title: `Close of Escrow Date Addendum Signed`,
            completionDate: date,
        };
    };
    closedSurveyCompleted = (date) => {
        return {
            id: 16,
            title: `Final survey completed`,
            completionDate: date,
        };
    };
    postInspectionSurveyCompleted = (date) => {
        return {
            id: 17,
            title: `Customer experience survey completed`,
            completionDate: date,
        };
    };
    utilitiesFormCompleted = (date) => {
        return {
            id: 18,
            title: `Utilities Form completed`,
            completionDate: date,
        };
    };
}
