import { FieldBinderFactory } from '@offerpad/concrete/lib/model';
import FormBinder from '@offerpad/concrete/lib/model/FormBinder';
import Validator from '@offerpad/concrete/lib/validation/Validator';
import * as rules from '@offerpad/concrete/lib/validation/rules';
import Fields from './fields';

export function create(binders: FieldBinderFactory): FormBinder {
    return new FormBinder({
        [Fields.Subject]: binders.string(
            Validator.create([
                rules.required('The subject field is required'),
            ])
        ),
        [Fields.Message]: binders.string(
            Validator.create([
                rules.required('The message  field is required')
            ])
        )
    });
}
