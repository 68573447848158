import React, { useState } from 'react';
import { useDisposition } from '../../../context/disposition/DispositionContext';
import { Modal } from 'floorplan/components/v2/Modal';
import { Button } from 'floorplan/components/Button';
import { closeModal } from '@offerpad/concrete.react/lib/Modal';
import { useModal } from '@offerpad/concrete.react/lib/Modal';
import cx from 'classnames';
import useScreen from '@offerpad/floorplan/dist/hooks/useScreen';
import { modalWidth } from '../common/constants';
import { Breakpoints } from '../../../utils/breakpointUtil';
import styles from '../common/OfferActionModal.module.scss';

const WidthdrawedOfferModal: React.FC = () => {
    const { state: { isOpen }, dispatch: modalDispatch } = useModal();
    const screen = useScreen();

    const { state: { dispositionOffer: { id }, loading, error, exception },
        declineOffer, clearError } = useDisposition();
    const [message, setMessage] = useState<string>("");

    const closeModalWrapper = () => {
        clearError();
        setMessage(null);
        closeModal(modalDispatch);
    }

    const submitMessage = () => {
        if (message) {
            declineOffer(id, message).then(() => closeModalWrapper())
        } else {
            closeModalWrapper();
        }
    }

    return <Modal
        open={isOpen}
        onClose={() => closeModalWrapper()}
        hasFooter
        width={screen.width < Breakpoints.DESKTOP ? modalWidth : null}
    >
        <Modal.Header
            title='Offer Withdrawn'
            onClose={() => closeModalWrapper()}
            border
        />
        <Modal.Content>
            {error && <div className={styles.error}>
                {exception?.response?.data?.message || 'Error something went wrong, try again'}
            </div>}
            <div className={styles.modal_content}>
            Your offer has been withdrawn. Add a message about why the offer was withdrawn.
            </div>
            <textarea
                className={cx(styles.message, message == "" ? styles.empty_message : null)}
                maxLength={200}
                rows={4}
                placeholder="Enter message (optional)"
                value={message}
                id='message'
                onChange={(e) => setMessage(e.target.value)}
            ></textarea>
        </Modal.Content>
        <Modal.Footer border>
            <Button
                id={`cancel-widthdrawed-offer`}
                secondary
                type="submit"
                className="modal-new-cancel-button"
                onClick={() => closeModalWrapper()}
            >
                Cancel
            </Button>
            <Button
                id="button-060220220907"
                brand
                type="submit"
                className="modal-new-action-button"
                progress={loading}
                onClick={() => submitMessage()}
            >
                Done
            </Button>
        </Modal.Footer>
    </Modal>
}

export default WidthdrawedOfferModal;