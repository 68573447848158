import React  from "react";
import { motion } from 'framer-motion';
import './CollapseItem.scss';

type CollapseItemProps = {
    date: string,
    title: string,
    hasContent: boolean,
    id?: string
    toggle: (string) => void,
    show: boolean;
}

const CollapseItem: React.FC<CollapseItemProps> = (props) => {
    const { date, title, id, hasContent, children, toggle, show } = props;
    const itemId = id || title;

    return <div className="card card-plain collapse-item">
        <div role="tab" id="headingOne" className="card-header">
            <a
                id="collapse-item-9245"
                data-toggle="collapse"
                href={`#${itemId}`}
                onClick={() => toggle(show ? '' : title)}
            >
                <div id="collapse-toggle">
                    <div>
                        <h5 id="date">{date}</h5>
                        <p id="title">{title}</p>
                    </div>
                    {hasContent && <i className="nc-icon nc-minimal-down"></i>}
                </div>
            </a>
        </div>
        <AnimateHeight isVisible={show}>
            {hasContent && <div>
                <div v-show="active" id={`content-${itemId}`} role="tabpanel" aria-labelledby="title" className="collapsed">
                    <div className="card-body">
                        {children}
                    </div>
                </div>
            </div>
            }
        </AnimateHeight>
    </div>
}

const AnimateHeight = ({ isVisible, children }) => {
    return (
        <motion.div
            style={{ overflow: 'hidden' }}
            initial={isVisible ? 'open' : 'collapsed'}
            animate={isVisible ? 'open' : 'collapsed'}
            inherit={false}
            variants={{
                open: {
                    height: 'auto'
                },
                collapsed: { height: 0 }
            }}
            transition={{
                ease: 'easeOut',
                duration: 0.3
            }}
        >
            <div>{children}</div>
        </motion.div>
    );
};

export default CollapseItem
