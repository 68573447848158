import React  from "react"
import Notification from "./Notification"
import { useNotifications } from '../../../context/notifications/NotificationsContext';
import './Notifications.scss'

// rough conversion from https://github.com/BinarCode/vue-notifyjs/tree/master/src, the notification package used in vue app
const Notifications = () => {
    const { state: { notifications, settings }, dispatch: _ } = useNotifications();

    return (
        <div className="notifications vue-notifyjs">
            {notifications.map(
                (notification, index) => {
                    return (
                        <Notification
                            horizontalAlign={settings.horizontalAlign}
                            verticalAlign={settings.verticalAlign}
                            message={notification.message}
                            type={notification.type}
                            timestamp={notification.timestamp}
                            showClose={settings.showClose}
                            key={notification.timestamp.getTime()}
                            index={index}
                            timeout={settings.timeout}
                        />
                    )
                }
            )}
        </div>
    )
}

export default Notifications;
