export default (buyUrl, isAgent) => {
    let sidebarLinks = [];

    const browseHomes = {
        name: 'Browse Homes',
        path: isAgent ? `${buyUrl}/buyers-agents` : buyUrl,
        absolutePath: true
    };
    sidebarLinks.push(browseHomes);

    const saveHomes = {
        name: 'Saved Homes',
        path: `${buyUrl}/saved-homes`,
        absolutePath: true
    };
    sidebarLinks.push(saveHomes);

    const saveSearches = {
        name: 'Saved Searches',
        path: `${buyUrl}/saved-searches`,
        absolutePath: true
    };
    sidebarLinks.push(saveSearches);

    return sidebarLinks;
};
