import moment from 'moment-timezone';

/* Defines a common format to display dates across the application */
export function formatDate(timestamp, timeZoneId = 'America/Phoenix', timezoneIndependent = false , format = 'MMMM D, YYYY') {
    if (timestamp === null) {
        return null;
    }
    let utcTime = moment.utc(timestamp);
    if (utcTime.toString() === 'Invalid date') {
        return null;
    }
    return timezoneIndependent ? utcTime.format(format) : utcTime.tz(timeZoneId).format(format);
}

/* Defines a common format to display times across the application */
export function formatTime(timestamp, timeZoneId = 'America/Phoenix', timezoneIndependent = false) {
    if (timestamp === null) {
        return null;
    }
    let utcTime = moment.utc(timestamp);
    if (utcTime.toString() === 'Invalid date') {
        return null;
    }
    /* Adjust timezone only for datetime */
    return timezoneIndependent ? utcTime.format('h:mm a z') : utcTime.tz(timeZoneId).format('h:mm a z');
}

export function formatTimeStamp(timestamp, timeZoneId = 'America/Phoenix', timezoneIndependent = false) {
    if (timestamp === null) {
        return null;
    }
    let utcTime = moment.utc(timestamp);
    if (utcTime.toString() === 'Invalid date') {
        return null;
    }
    /* Adjust timezone only for datetime */
    return timezoneIndependent
        ? moment(timestamp).format('YYYY-MM-DDTHH:mm:ss')
        : moment.utc(timestamp).tz(timeZoneId).format('YYYY-MM-DDTHH:mm:ss');
}

export function formatDateAndTime(signingApptDateTime, timeZoneId) {
    let retVal = formatTime(signingApptDateTime, timeZoneId);

    if (retVal.startsWith('12:00 am')) {
        return `<strong>${formatDate(signingApptDateTime, timeZoneId)}</strong>.`;
    } else {
        return `<strong>${formatDate(signingApptDateTime, timeZoneId)}</strong>
        at <strong>${retVal}</strong>.`;
    }
}

export function formatDateDirectShortPretty(date) {
    const dateVal = moment(date);
    const now = moment();
    if (dateVal.isSame(now, 'day')) {
        return 'TODAY';
    } else if (dateVal.isSame(now.add(1, 'days'), 'day')) {
        return 'TOMORROW';
    } else {
        return formatDateDirectShort(date);
    }
}

// take the date string directly from the server and convert. Helix usally returns the date in local time. Weather in local or utc moment will convert the date to local automatically
export function formatDateDirectShortYear(date) {
    return (date && moment(date).format('MM/DD/YY')) || '';
}
export function formatDateDirectShort(date) {
    return (date && moment(date).format('M/D/YY')) || '';
}
export function formatDateDirectLong(date) {
    return (date && moment(date).format('LL')) || '';
}
