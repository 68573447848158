import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import styles from './SideBarItem.module.scss';

type SideBarItemProps = {
    path: string;
    name: string;
    absolutePath?: boolean
}

const SideBarItem: React.FC<SideBarItemProps> = (props) => {
    const history = useHistory();
    const { name, path, absolutePath = false } = props;
    const active = history.location.pathname.includes(path);

    return <li className={active ? styles.active : ''}>
        {absolutePath ? <a id={`side-bar-item-${name}-anchor`} href={path}>
            <p className={styles.sideBarItemName}>{name}</p>
        </a> : <Link id={`side-bar-item-${name}`} to={path}>
            <p className={styles.sideBarItemName}>{name}</p>
        </Link>}
    </li>
}

export default SideBarItem;
