import { AuthService } from '../../../services/AuthService/auth.service';
import TokenService from '../../../services/token.service';
import { DefaultValueBinder, DefaultFieldBinderFactory } from '@offerpad/concrete/lib/model';
import { ValidationResult } from '@offerpad/concrete/lib/validation/Validator';
import email from '@offerpad/concrete/lib/validation/email';
import * as binder from './binder';
import { Button } from 'floorplan/components/v2/Button';
import { FormControl } from 'floorplan/components/v2/Form';
import Fields from './fields';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppInsights } from '../../../context/AppInsightsContext';
import { path, RouteName } from '../../../routing/Routes';
import { Link } from 'react-router-dom';
import AuthLeftPanel from './AuthLeftPanel';
import OrDivision from '../../UIComponents/OrDivision';
import classes from '../../../assets/sass/offerpad/autform.module.scss';
import { useParams } from 'react-router';
import cx from 'classnames';
import { notify } from '../../../context/notifications/NotificationsReducer';
import { useNotifications } from '../../../context/notifications/NotificationsContext';
const sellUrl = process.env.RAZZLE_SELL_URL;

export const buttonStyles = { paddingTop: '0.75rem', paddingBottom: '0.75rem' };

const Login = () => {
    const { flag, key } = useParams<{ flag: string; key: string }>();
    const search = useLocation().search;
    const preloadEmail = email(new URLSearchParams(search).get('preloadEmail')) ? new URLSearchParams(search).get('preloadEmail') : undefined;
    const history = useHistory();
    const appInsights = useAppInsights();
    const { dispatch: notificationsDispatch } = useNotifications();

    // const auth = useAuthentication();
    const valueBinder = new DefaultValueBinder();
    const fieldBinderFactory = new DefaultFieldBinderFactory(valueBinder);
    const form = binder.create(fieldBinderFactory);
    const query = new URLSearchParams(useLocation().search);
    const redirect = query.get('redirect');
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [emailVal, setEmail] = useState<string>(preloadEmail || '');
    const [password, setPassword] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    let timer = null;
    const setTimer = () => timer = setTimeout(() => setIsSubmitting(false), 5000);
    const clearTimer = () => {
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const field = form.fields[e.target.name];
        const value = e.target.value;
        clearTimer();
        setIsSubmitting(false);
        switch (e.target.name) {
            case Fields.Email:
                e.target.focus();
                setEmail(value);
                break;
            case Fields.Password:
                setPassword(value);
        }
        const result: ValidationResult = field.validator.validate(value);
        setErrors({ ...errors, [e.target.name]: result.message });
    };

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (isSubmitting) {
            return;
        }
        const model = form.bind(e.target as HTMLFormElement);
        if (!model.valid) {
            let errors = {};
            for (let field in model.fields) {
                const validation = model.fields[field].validation;
                errors[field] = validation.message;
            }
            setErrors(errors);
            return;
        }

        setIsSubmitting(true);

        let alertMessage = `There was an error while processing your request; please try again later.`;

        AuthService.login(emailVal, password)
            .then((retValue) => {
                if (retValue !== true) {
                    const data = retValue.response.data;
                    if (data && data.error_description) {
                        if (data.error_description === 'invalid_username_or_password') {
                            alertMessage = 'Unable to log in. Please verify your email and password.';
                        }
                        throw new Error(data.error_description);
                    }
                    throw new Error('Error description unavailable');
                }
                if (redirect) {
                    history.push(redirect);
                } else {
                    history.push(path(RouteName.Home));
                }
            })
            .catch((error) => {
                appInsights.trackTrace({ message: error.message });
                notify(notificationsDispatch, { 'message': alertMessage, 'type': 'danger' });
                clearTimer();
                setTimer();
            });
    };

    const isLoggedIn = TokenService.getToken();
    if (isLoggedIn) {
        history.push(path(RouteName.Home));
    }

    let refUrl = `${sellUrl}`;
    switch (flag) {
        case 'view':
            refUrl += `/offer/${key}`;
            break;
        case 'upload':
            refUrl += `/request-offer/${key}/pending-images`;
    }

    return (
        <div className={classes['c-authform__container']}>
            <AuthLeftPanel
                title="Sign into your Offerpad portal"
                subtitle="Enjoy the benefits of the portal!"
                highlight={flag}
            />
            <form className={cx(classes['c-authform'], classes['login'])} onSubmit={(e) => onSubmit(e)}>
                {/* {loginError && <Alert description={loginError} />} */}
                <fieldset>
                    <legend></legend>
                    {
                        preloadEmail && 
                        <>
                            <p className={classes['preloaded-text']}>The email address we gathered from you is <b>{preloadEmail}</b>. If this is incorrect or not your preferred email, please <a className={classes['underlined-link']} href="https://offerpad.com/contact">contact us</a>.</p>
                            <input type="hidden" value={emailVal} name={Fields.Email} readOnly={true} />
                        </>
                    }
                    {
                        !preloadEmail &&
                        <FormControl
                            type="email"
                            onChange={(e) => onChange(e)}
                            label={Fields.Email}
                            name={Fields.Email}
                            value={emailVal}
                            placeholder="Email Address"
                            id={Fields.Email}
                            hasError={!!errors[Fields.Email]}
                            errorText={errors[Fields.Email]}
                            showErrorIcon={!!errors[Fields.Email]}
                            data-qa="login-email"
                        />
                    }
                    <FormControl
                        type="password"
                        onChange={(e) => onChange(e)}
                        label={preloadEmail ? `Sign Into Your Account` : Fields.Password}
                        name={Fields.Password}
                        value={password}
                        hasError={!!errors[Fields.Password]}
                        placeholder={preloadEmail ? "Enter Password" : "Password"}
                        id={Fields.Password}
                        errorText={errors[Fields.Password]}
                        showErrorIcon={!!errors[Fields.Password]}
                        data-qa="login-password"
                        className='fs-exclude'
                    />
                    <div className={cx(classes['c-authform__link'], classes['c-authform__forgot'])}>
                        <Link id="login-forgot-password" to="/forgot-password" data-qa="login-forgot-password">
                            Forgot your password?
                        </Link>
                    </div>
                    <div className={classes['login-divider']}></div>
                </fieldset>
                <Button id="login-singn-in" fullWidth primary type="submit" style={buttonStyles} progress={isSubmitting} >
                    Sign In
                </Button>
                {
                    !preloadEmail && 
                    <OrDivision />
                }
                <div className={classes['c-authform__link']}>
                    {!flag && !preloadEmail && (
                        <>
                            Don't have an account? <Link to="/register/invite">Create one here</Link>
                        </>
                    )}
                    {flag === 'upload' && <a id="login-continue-as-guest-upload-anchor" href={refUrl}>Continue as Guest</a>}
                    {flag === 'view' && (
                        <a id="login-continue-as-guest-view-anchor" href={refUrl} target="_blank" rel="noopenner noreferrer">
                            Continue as Guest
                        </a>
                    )}
                </div>
            </form>
        </div>
    );
};

export default Login;
