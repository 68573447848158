import React from 'react'
import { useOffer } from '../../context/offer/OfferContext';
import { getFirstTeamMember } from './resources/teamUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Messaging.scss'
import { openModal } from '@offerpad/concrete.react/lib/Modal';
import { Modals } from './ModalController/ModalController';
import { useModal } from '@offerpad/concrete.react/lib/Modal';

type MessagingProps = {
    size?: string,
}

const Messaging: React.FC<MessagingProps> = (props) => {
    const { state: { offerState } } = useOffer();
    const { dispatch: modalDispatch } = useModal();
    const { size = '3x' } = props;
    const teamMember = getFirstTeamMember(offerState);
    const showMessage = teamMember && teamMember.owner;

    return showMessage ?
        <div className='envelope'>
            <FontAwesomeIcon
                id="messaging-envelope-icon-8610"
                icon={["far", "envelope"]}
                className={size}
                onClick={() => openModal(modalDispatch, Modals.MESSAGING_MODAL)}
            />
        </div>
        :
        null;
}


export default Messaging;