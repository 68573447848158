import React, { useState } from "react";
import cx from 'classnames';

import './RadioNps.scss'

export const RadioNps = (props) => {

    const [selectedOption, updateSelectedOption] = useState(props.selectedOption);

    return (
        <div>
            <div className="radio-block">
                {
                    props.options.map((option, index) => {

                        const color = selectedOption === null
                            ? `color-${option}`
                            : selectedOption === index
                                ? `color-${option}`
                                : `color-${option} color-grey`;

                        return (
                            <div key={index}>
                                <input
                                    type="radio"
                                    id={`radio-${index}`}
                                    className={cx('nps-input', color)}
                                    value={index}
                                    checked={index === selectedOption}
                                    readOnly
                                />
                                <label
                                    id={`radio-nps-update-${index}`}
                                    className="nps-label"
                                    onClick={(e) => {
                                        updateSelectedOption(index);
                                        props.onChange(index);
                                    }}>
                                    <h5 className="nps-h5">{index}</h5>
                                </label>
                            </div>

                        );
                    }
                    )
                }
            </div>
            <div className="horiflex">
                <p className="nps-p">0 - {props.minLabel}</p>
                <p className="nps-p">{props.options.length - 1} - {props.maxLabel}</p>
            </div>
        </div>
    );
}
