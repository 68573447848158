import { config, library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import { faExclamationTriangle as faExclamationTriangleReg } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faTimes as faTimesReg } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faLongArrowLeft } from '@fortawesome/pro-solid-svg-icons/faLongArrowLeft'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faList } from '@fortawesome/pro-solid-svg-icons/faList';
import { faTh } from '@fortawesome/pro-solid-svg-icons//faTh';
import { faImages } from '@fortawesome/pro-regular-svg-icons/faImages';
import { faUpload } from '@fortawesome/pro-regular-svg-icons/faUpload';
import { faCamera } from '@fortawesome/pro-regular-svg-icons/faCamera';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faPersonDolly } from '@fortawesome/pro-regular-svg-icons/faPersonDolly';
import { faCalendarDay } from '@fortawesome/pro-solid-svg-icons';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { faStar } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDoubleRight } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleRight';
import { faSort } from '@fortawesome/pro-solid-svg-icons/faSort';
import { faDownload } from '@fortawesome/pro-regular-svg-icons/faDownload';
import { faSortSizeUp } from '@fortawesome/pro-regular-svg-icons/faSortSizeUp';
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faUserHardHat } from '@fortawesome/pro-light-svg-icons/faUserHardHat'
import { faUserTie } from '@fortawesome/pro-light-svg-icons/faUserTie'
import { faHandSparkles } from '@fortawesome/pro-light-svg-icons/faHandSparkles'
import { faMegaphone } from '@fortawesome/pro-light-svg-icons/faMegaphone'
import { faPiggyBank } from '@fortawesome/pro-light-svg-icons/faPiggyBank'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus';
import { faStar as fasStar } from '@fortawesome/pro-solid-svg-icons/faStar';

library.add(
    faExclamationTriangle,
    faExclamationTriangleReg,
    faTimes,
    faLongArrowLeft,
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faExclamationCircle,
    faList,
    faTh,
    faImages,
    faUpload,
    faCamera,
    faTrash,
    faBars,
    faChevronDown,
    faChevronUp,
    faEnvelope,
    faTimesReg,
    faPersonDolly,
    faCalendarDay,
    faEye,
    faCalendar,
    faUsers,
    faStar,
    faChevronDoubleRight,
    faSort,
    faDownload,
    faSortSizeUp,
    faCircle,
    faSpinner,
    faEnvelope,
    faBars,
    faTimesReg,
    faChevronDown,
    faChevronUp,
    faUser,
    faSearch,
    faCaretDown,
    faCheck,
    faUserHardHat,
    faUserTie,
    faHandSparkles,
    faMegaphone,
    faPiggyBank,
    faPlus,
    faMinus,
    fasStar
);

config.autoAddCss = true;
