import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useOffer } from '../../../context/offer/OfferContext';
import { loadOffer } from '../../../context/offer/OfferReducer';
import Spinner from '@offerpad/floorplan/dist/components/Spinner';

const UploadPhotos = () => {
    const history = useHistory();
    const { state: { offerState, loading }, dispatch: offerDispatch } = useOffer();
    const { keyType, offerKey } = useParams<{ keyType: string, offerKey: string }>();
    const loadOfferAttempts = 10;

    useEffect(() => {
        loadOffer(offerDispatch, offerKey, keyType, history, false, loadOfferAttempts, false);
    }, [offerKey]);

    if (loading) {
        return (
            <div className='spinner'>
                <Spinner />
            </div>
        );
    }
    else {
        if (offerState?.acquisitionId) {
            window.location.href = `${process.env.RAZZLE_CONNECT_URL}/sell/overview?transactionId=${offerState.acquisitionId}`;
        } else {
            window.location.href = `${process.env.RAZZLE_CONNECT_URL}/sell/dashboard`;
        }
    }
}

export default UploadPhotos;
