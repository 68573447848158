import React from 'react';
import styles from './sellerFAQs.module.scss';

type ExternalLinkProps = {
    href: string;
    children: any;
};

const ExternalLink = (props: ExternalLinkProps) => {
    const { href, children } = props;
    return (
        <a href={href} target='_blank'
            rel='noopener noreferrer'
            className={styles.hyperlink}
        >
            {children}
        </a>
    );
};

const TwentyFourHourDisclaimer = () => (
    <p>
        *Other restrictions apply. Speak to an Offerpad representative or visit
        {' '}
        <ExternalLink href='https://my.offerpad.com/24-hour-close/'>
            https://my.offerpad.com/24-hour-close/
        </ExternalLink>
        {' '}
        for details.
    </p>
);

const sellerFAQs = {
    howImprovementsAffectOffer: {
        faq: 'How do the improvements that I made to my house affect the offer?',
        link: null,
        answer: (
            <p>
                Improvements (or renovations) to your home are an important factor in how we determine the value of your
                home and our purchase offer, so it is important you tell us about them in your offer request. Photos of
                your improvements also help; you can upload them when making an offer request or send them via email to
                {' '}
                <ExternalLink href='mailto:info@offerpad.com'>info@offerpad.com</ExternalLink>
                ; please make sure that you include your home&apos;s address in your email.
            </p>
        ),
    },

    canItradeMyHome: {
        faq: 'Can I trade-in my home to Offerpad?',
        link: null,
        answer: (
            <p>
                Yes. Because few homeowners have the financial capability to own two homes at the same time, Offerpad
                helps you time the purchase of your new home with the sale of your existing home to us. The transactions
                happen back-to-back and reduce the stress that you might otherwise experience. You can speak with one of
                our Solutions Advisors to learn more.
            </p>
        ),
    },

    shouldProvidePhotos: {
        faq: 'Should I provide photos?',
        link: null,
        answer: (
            <p>
                Yes! Our offers are not computer generated or based solely upon our powerful algorithm. Our team of real
                estate experts evaluates every property carefully and photos help them to have a more accurate
                understanding of your home&apos;s condition, features and overall value.
            </p>
        ),
    },

    howIsPriceDetermined: {
        faq: 'How is the offer price for my home determined?',
        link: null,
        answer: (
            <p>
                Your home is carefully evaluated by a team of real estate experts who specialize in your market. Using
                their knowledge, combined with Offerpad&apos;s proprietary evaluation and predictive market analysis
                technologies, Offerpad is able to provide our customers with data-driven and competitive offers.
            </p>
        ),
    },

    ifNoClosingDateAvailable: {
        faq: 'What if I don\'t know the date I would like to close?',
        link: null,
        answer: (
            <p>
                That&apos;s ok. You can simply select the closest date to your best guess. We will send you an addendum
                to adjust the closing date when you&apos;ve got your final closing date determined.
            </p>
        ),
    },

    doesMyOfferExpire: {
        faq: 'Does my offer expire?',
        link: null,
        answer: (
            <p>
                Yes. Home values will often change based upon market activity, competition and other factors. Our offers
                will usually expire in four days from the date of the offer. However, Offerpad is happy to provide you
                with an updated offer, just click on the Renew button at the top of your expired offer. Please be aware
                that our renewed offer price may be unchanged, higher or lower than your original offer depending upon
                current market conditions. In rare instances, we may conclude that we are unable to renew an offer.
            </p>
        ),
    },

    howQuicklyCanIClose: {
        faq: 'How quickly can I close?',
        link: null,
        answer: (
            <div>
                <p>
                    Need to close quickly? With Offerpad, we can close in as little as 24 hours (in limited markets)
                    after accepting an Offerpad
                    {' '}
                    <i>EXPRESS</i>
                    {' '}
                    cash offer. You pick the closing date that fits your needs!
                </p>

                <p>
                    <strong>Arizona</strong>
                    <br />
                    Homes with no HOA: 24 hours*
                    <br />
                    Homes in an HOA: 11 business days
                </p>

                <TwentyFourHourDisclaimer />

                <p>
                    <strong>Alabama</strong>
                    <br />
                    Homes with no HOA: 8 business days
                    <br />
                    Homes in an HOA: 11 business days
                </p>

                <p>
                    <strong>Colorado</strong>
                    <br />
                    Homes with no HOA: 8 business days
                    <br />
                    Homes in an HOA: 11 business days
                </p>

                <p>
                    <strong>Georgia</strong>
                    <br />
                    All homes: 15 business days
                </p>

                <p>
                    <strong>Indiana</strong>
                    <br />
                    Homes with no HOA: 12 business days
                    <br />
                    Homes in an HOA: 15 business days
                </p>

                <p>
                    <strong>Florida</strong>
                    <br />
                    All homes: 15 business days
                </p>

                <p>
                    <strong>North Carolina/South Carolina</strong>
                    <br />
                    Homes with no HOA: 8 business days
                    <br />
                    Homes in an HOA: 11 business days
                </p>

                <p>
                    <strong>Nevada</strong>
                    <br />
                    Homes with no HOA: 24 hours*
                    <br />
                    Homes in an HOA: 11 business days
                </p>

                <TwentyFourHourDisclaimer />

                <p>
                    <strong>Tennessee</strong>
                    <br />
                    Homes with no HOA: 8 business days
                    <br />
                    Homes in an HOA: 11 business days
                </p>

                <p>
                    <strong>Texas</strong>
                    <br />
                    Homes with no HOA: 8 business days
                    <br />
                    Homes in an HOA: 11 business days
                </p>

                <p>
                    <strong>Please note:</strong>
                    If the seller is not able to provide a property survey, please add
                    7-10 business days
                </p>

                <p>
                    <strong>All Markets:</strong>
                    If you need a closing date within 15 days, you must make your home available for our inspection
                    within the first three days of signing the contract—otherwise, we will be unable to accommodate
                    your request.
                </p>
            </div>
        ),
    },

    detailsOnExtendedStay: {
        faq: 'What are the specific details of the Extended Stay program?',
        link: null,
        answer: (
            <p>
                To take advantage of any of Offerpad&apos;s Extended Stay options, speak with your Offerpad
                representative to learn what the best option is for you. Offerpad&apos;s standard 3-Day Extended Stay is
                free. A refundable security deposit applies. Click here for all
                {' '}
                <ExternalLink href='https://www.offerpad.com/extended-stay/'>
                    the details and advantages of our Extended Stay program
                </ExternalLink>
                {' '}
                or talk to your Offerpad representative to learn more.
            </p>
        ),
    },

    limitsOnFreeMove: {
        faq: 'Are there limits on the free move?',
        link: null,
        answer: (
            <p>
                Your new home must be within 50 miles of the property Offerpad is purchasing. Home size is limited to
                2,800 square feet and a maximum of 13,000 pounds is allowed. If your home is larger, or your possessions
                exceed the weight limit, then you will be responsible for the difference in cost and you will be billed
                by the moving company directly. If your new home is outside of the 50-mile radius, please speak with
                your Closing Coordinator to learn more about your options. Full details can be found
                {' '}
                <ExternalLink href='https://www.offerpad.com/move-for-free/#additional-information'>here</ExternalLink>
                .
            </p>
        ),
    },

    stayingAfterClosing: {
        faq: 'Can I stay in my home after it closes?',
        link: null,
        answer: (
            <p>
                Yes! With our
                {' '}
                <ExternalLink href='https://www.offerpad.com/extended-stay/'>Extended Stay</ExternalLink>
                {' '}
                program, you can stay in your home for up to 3 days after closing and avoid the rush and hassle of
                having to close and move in one day. Combined with our Free Local Move, Offerpad can help relieve much
                of the stress associated with selling and moving.
            </p>
        ),
    },

    howToAccessExtendedStay: {
        faq: 'How do I take advantage of the Extended Stay program?',
        link: null,
        answer: (
            <p>
                To take advantage of any of Offerpad&apos;s Extended Stay options, speak with your Offerpad representative to
                learn what the best option is for you. Offerpad&apos;s standard 3-Day Extended Stay is free. A refundable
                security deposit applies. Click here for all the
                {' '}
                <ExternalLink href='https://www.offerpad.com/extended-stay/'>
                    details and advantages of our Extended Stay
                </ExternalLink>
                {' '}
                program or talk to your Offerpad representative to learn more.
            </p>
        ),
    },

    doesOPLookAtHomeInPerson: {
        faq: 'Does Offerpad look at my home in person?',
        link: null,
        answer: (
            <p>
                Yes. Offerpad will perform a free, on-site inspection of your home within 15 days of the signed
                agreement. The purpose the appointment is to verify the specifications of your home, its condition, and
                identify repairs that will need to be addressed prior to close.
            </p>
        ),
    },

    howMyOfferComparesToOthers: {
        faq: 'How does my offer price compare to values on sites like Zillow?',
        link: null,
        answer: (
            <>
                <p>
                    The home values found on sites like Zillow, Trulia, Realtor.com, and Redfin, are determined by the
                    site&apos;s individual computer-generated algorithms. Those values are limited by the data that is
                    available to them through public record. They look at the market&apos;s history; not where it&apos;s
                    going.
                </p>
                <p>
                    While our offer is not intended to be an appraisal of any kind, it is a real and actionable offer
                    and is based on careful analysis by our team of real estate experts. We take time to evaluate and
                    understand your home&apos;s unique features and condition in the context of the market&apos;s
                    inventory and future supply.
                </p>
            </>
        ),
    },

    whoInspectsHomes: {
        faq: 'Who will be inspecting my home?',
        link: null,
        answer: (
            <p>
                Either an Offerpad team member or licensed property inspector (or both) will perform the inspection. If
                specialists are needed, we will partner with licensed contractors to provide additional insight and
                expertise. We will always work with you to coordinate the scheduling of all property inspections.
            </p>
        ),
    },

    whatsOPTitleCompany: {
        faq: 'What company does Offerpad use for title and closing services?',
        link: null,
        answer: (
            <>
                <p>
                    When Offerpad purchases a home, we use First American Title in all markets except Alabama, Georgia,
                    North Carolina and South Carolina. In Alabama, we work with Statewide Title Services. In Georgia, we
                    work with the law firm of McMichael & Gray and In North Carolina and South Carolina, we work with
                    the law firm of Hankin & Pack.
                </p>
                <p>
                    The above companies ensure title is legitimate and then issue title insurance for the property. In
                    addition, they maintain the escrow account for the transaction and handle the funds for the
                    transaction, including the earnest money deposit from Offerpad, payoffs to mortgage companies, HOA
                    dues and any other transaction funds.
                </p>
            </>
        ),
    },

    areasToBeInspected: {
        faq: 'What areas of my house will be looked at?',
        link: null,
        answer: (
            <p>
                Offerpad will need access to your entire home during our inspection. When your inspection is scheduled,
                we will provide you with a guide that will help you prepare for the inspection and provide you with an
                overview of the most common items we evaluate during the inspection process.
            </p>
        ),
    },

    whatIfRepairsAreNeeded: {
        faq: 'What happens if repairs are needed?',
        link: null,
        answer: (
            <div>
                <p>If our inspection reveals needed repairs, you&apos;ll have the following options:</p>
                <ul className={styles.list}>
                    <li>
                        <strong>Provide Offerpad with a credit.</strong>
                        {' '}
                        Due to our volume and in-house resources,
                        we&apos;re able to keep required repair credits to a low and reasonable price point. To move
                        forward with this option, you would sign an addendum to the contract. This solution means no
                        money out of pocket, or work done by you. This is the most popular option by our sellers.
                    </li>
                    <li>
                        <strong>Make the repairs before closing.</strong>
                        {' '}
                        You can hire a licensed contractor to make all the required repairs before closing.
                        Offerpad will provide you a list of required repairs, and it would be up to you to
                        ensure all items are completed to current building codes. Offerpad will
                        need proof of the work being completed, contractor name and license number, before and
                        after pictures, receipts, and visible approval by Offerpad during the final walk-through.
                    </li>
                    <li>
                        <strong>Decline to make any requested repairs or issue a credit.</strong>
                        {' '}
                        Offerpad can then
                        choose to cancel the contract or may determine that it still wants to move forward with the
                        purchase of the home. If Offerpad elects to cancel the contract, there is no penalty to either
                        party. Keep in mind, these repairs will most likely have to be addressed regardless if you sell
                        to us or try to sell traditionally. A traditional buyer will typically want these same issues
                        fixed (or credited) before they agree to purchase your home.
                    </li>
                </ul>
                <p>
                    <strong>Please note:</strong>
                    {' '}
                    In some circumstances, the repairs required on a home may be
                    significant and require substantial funds to complete or special expertise to manage. In these
                    cases, rather than add unnecessary stress to our customers, we will offer the option of a credit
                    only.
                </p>
            </div>
        ),
    },

    whenWillWeSignClosing: {
        faq: 'When will we sign the closing documents?',
        link: null,
        answer: (
            <p>
                The title company or attorney&apos;s office handling the transaction will schedule a convenient time for
                you to sign the closing documents. Typically, the closing takes place at the title company or
                attorney&apos;s office although you may also request a mobile notary come to you. The date this happens
                is called the closing date or close of escrow. On your closing date, the sale is recorded on public
                record and the funds are distributed. The timing of this varies depending upon the state you live in and
                the time of day you sign your documents.
            </p>
        ),
    },

    isFlexRightForMe: {
        faq: 'Is listing with Offerpad FLEX vs. a traditional listing right for me?',
        link: null,
        answer: (
            <div>
                <p>
                    It is if you want to list your home the easy way, with time and money-saving services you just
                    won&apos;t get listing the traditional way, including:
                </p>
                <ul>
                    <li>
                        A backup cash offer so you can list with confidence and certainty knowing you already have an
                        offer in your pocket!
                    </li>
                    <li>Free show-ready services to make your home look its best.</li>
                    <li>
                        No-fee, interest-free home improvement advances through Offerpad&apos;s renovation division
                        allow you to make updates before putting your house on the market.
                    </li>
                    <li>
                        A dedicated local Offerpad real estate agent and team of listing experts to help you maximize
                        the value of your home.
                    </li>
                    <li>
                        National brand recognition and marketing power of Offerpad to get your home noticed by more
                        buyers.
                    </li>
                </ul>
                <p>
                    Save more with Offerpad, too, when you bundle selling your home with buying and financing a new one
                    with us. Ask your FLEX Solutions Expert for details!
                </p>
            </div>
        ),
    },

    areThereFlexFees: {
        faq: 'Do I have to pay any fees?',
        link: null,
        answer: (
            <p>
                When listing with us, you will be responsible for covering the typical transaction costs involved in
                selling a home on the open market, including seller concessions, holding costs, maintenance fees, taxes
                and other expenses. We also charge an agent commission based on our ability to work with you to meet
                your unique needs in listing and selling your home for maximum value.
            </p>
        ),
    },

    whatIfIWantLowerOrHigher: {
        faq: 'What if I want to list my house for lower or higher than your range?',
        link: null,
        answer: (
            <p>
                Your initial list range is calculated based upon your offer price. Once you connect with our Solution
                Experts, they will perform a comprehensive market evaluation of your home, taking into account its value
                after any free show-ready services we provide or any home improvements that are agreed upon. Once your
                home is ready to hit the market, your Solutions Expert will work with you to determine the best starting
                price to list for. We&apos;ll also recommend any adjustments as needed while it is listed to ensure your
                home remains competitively priced and you meet your selling goals.
            </p>
        ),
    },

    flexFreeServices: {
        faq: 'What free services are included to get my home ‘show-ready’?',
        link: null,
        answer: (
            <div>
                <p>We&apos;ll get your home ready for open-houses and showings with free services including:</p>
                <ul>
                    <li>Carpet cleaning</li>
                    <li>Deep house cleaning</li>
                    <li>Handyman services</li>
                    <li>Landscape cleanup</li>
                    <li>Pool services</li>
                </ul>
            </div>
        ),
    },
};

export default sellerFAQs;
