import OfferState from '../../../context/offer/types';
import ProgressStepsSeller, { Faq } from '../Dashboard/ProgressBar/progressStepsSeller';
import moment from 'moment';
import { formatDateDirectShortYear, formatTime } from './datetime';
import { formatter } from './currency';
import { ProgressStep } from '../Dashboard/ProgressBar/ProgressBar';
import sellerFAQs from './sellerFAQs';

export class ProgressDashboardInfo {
    workflowStep: string = '';
    progressBar: ProgressStep = { label: 'Offer', order: 1 };
    progressPercentage: number = 0;
    sentenceTop: string = '';
    whatsNextHeading: string = '';
    whatsNextCopy: string = '';
    faqs: Array<Faq> = [];
    column: string = '';
    column2: string = '';
}

export default function getProgressInfoSeller(offerState: Partial<OfferState>) {
    const dashboardInfo = new ProgressDashboardInfo();
    const currentState = offerState.currentStep;
    const progressBar = ProgressStepsSeller;

    const createId = (suffix) => `id='progress-bar-${suffix}'`;

    //Fallback status
    dashboardInfo.workflowStep = null;
    dashboardInfo.progressBar = null;
    dashboardInfo.progressPercentage = 0;
    dashboardInfo.sentenceTop = offerState.listedByAgent
        ? `This transaction status is currently being updated!`
        : `Your transaction status is currently being updated!`;
    dashboardInfo.whatsNextCopy = offerState.listedByAgent
        ? `Your process status is currently being updated. Please check in with your <a ${createId(
              'status-updating'
          )} href="${offerState.teamPageLink}">Transaction Manager</a> to receive an update.`
        : `Your process status is currently being updated. Please check in with your <a ${createId(
              'status-updating'
          )} href="${offerState.teamPageLink}">Transaction Manager</a> to receive an update.`;

    switch (currentState) {
        case 'Interest':
            dashboardInfo.workflowStep = currentState;
            dashboardInfo.progressBar = progressBar.Offer;
            dashboardInfo.progressPercentage = 8;
            dashboardInfo.sentenceTop = `Thanks for visiting your Offerpad Portal!`;
            dashboardInfo.whatsNextCopy = `You can request a no-obligation offer on your home! Our valuation team will evaluate the information you provide in order to ensure the most accurate and competitive offer.`;
            break;
        case 'NewRequest':
        case 'OfferReview':
            dashboardInfo.workflowStep = currentState;
            dashboardInfo.progressBar = progressBar.Offer;
            dashboardInfo.progressPercentage = 33;
            dashboardInfo.sentenceTop = offerState.listedByAgent
                ? `We are hard at work evaluating your seller's home`
                : `We are hard at work evaluating your home`;
            dashboardInfo.whatsNextCopy = offerState.listedByAgent
                ? `When we have completed our review of your seller's home's value, we will send you a link to our offer via email.`
                : `When we have completed our review of your home's value, we will send you a link to our offer via email.`;
            dashboardInfo.faqs = [
                sellerFAQs.howImprovementsAffectOffer,
                sellerFAQs.canItradeMyHome,
                sellerFAQs.shouldProvidePhotos,
            ];
            break;
        case 'OfferInProgress':
            dashboardInfo.workflowStep = currentState;
            dashboardInfo.progressBar = progressBar.Offer;
            dashboardInfo.progressPercentage = 33;
            dashboardInfo.sentenceTop = offerState.listedByAgent
                ? `We are hard at work evaluating your seller's home`
                : `We are hard at work evaluating your home`;
            dashboardInfo.whatsNextCopy = offerState.listedByAgent
                ? `When we have completed our review of your seller's home's value, we will send you a link to our offer via email.`
                : `When we have completed our review of your home's value, we will send you a link to our offer via email.`;
            dashboardInfo.faqs = [sellerFAQs.howImprovementsAffectOffer, sellerFAQs.canItradeMyHome];
            break;
        case 'PresentOffer':
            dashboardInfo.workflowStep = currentState;
            dashboardInfo.progressBar = progressBar.Offer;
            dashboardInfo.progressPercentage = 33;
            dashboardInfo.sentenceTop = offerState.listedByAgent
                ? `We are hard at work evaluating your seller's home`
                : `We are hard at work evaluating your home`;
            dashboardInfo.whatsNextCopy = offerState.listedByAgent
                ? `When we have completed our review of your seller's home's value, we will send you a link to our offer via email.`
                : `When we have completed our review of your home's value, we will send you a link to our offer via email.`;
            dashboardInfo.faqs = [sellerFAQs.howImprovementsAffectOffer, sellerFAQs.canItradeMyHome];
            break;

        case 'RenewalRequest':
            dashboardInfo.workflowStep = currentState;
            dashboardInfo.progressBar = progressBar.Offer;
            dashboardInfo.progressPercentage = 33;
            dashboardInfo.sentenceTop = offerState.listedByAgent
                ? `We're working on your seller's renewal offer!`
                : `We're working on your renewal request!`;
            dashboardInfo.whatsNextCopy = offerState.listedByAgent
                ? `We're working on your seller's updated offer. Check your email in the next few days!`
                : `We're working on your updated offer. Check your email in the next few days!`;
            dashboardInfo.faqs = [
                sellerFAQs.howImprovementsAffectOffer,
                sellerFAQs.canItradeMyHome,
                sellerFAQs.shouldProvidePhotos,
            ];
            break;

        case 'OfferSent':
            dashboardInfo.workflowStep = currentState;
            dashboardInfo.progressBar = progressBar.Offer;
            dashboardInfo.progressPercentage = 66;
            if (offerState.expired) {
                dashboardInfo.sentenceTop = offerState.listedByAgent
                    ? `Your seller's offer expired on ${formatDateDirectShortYear(
                          offerState?.expirationDateTime
                      )}. Please renew the offer.`
                    : `Your offer expired on ${formatDateDirectShortYear(
                          offerState?.expirationDateTime
                      )}. Please renew your offer.`;
                dashboardInfo.whatsNextCopy = offerState.listedByAgent
                    ? `Your seller's offer expired on ${formatDateDirectShortYear(
                          offerState?.expirationDateTime
                      )}. Renew the no&#8209;obligation
                offer on your seller's home! Our valuation team will evaluate the information you provide in order to ensure the
                most accurate and competitive offer.`
                    : `Your offer expired on ${formatDateDirectShortYear(
                          offerState?.expirationDateTime
                      )}. Renew your no&#8209;obligation
                    offer on your home! Our valuation team will evaluate the information you provide in order to ensure the
                    most accurate and competitive offer.`;
                dashboardInfo.column2 = `We need to chat about the conditions on this one`;
                dashboardInfo.faqs = [sellerFAQs.howIsPriceDetermined, sellerFAQs.ifNoClosingDateAvailable];
            } else {
                dashboardInfo.sentenceTop = offerState.listedByAgent
                    ? `We've carefully reviewed your seller's home's information and our offer is ready! `
                    : `We've carefully reviewed your home's information and your offer is ready! `;
                dashboardInfo.whatsNextCopy = offerState.listedByAgent
                    ? `When your seller is ready to move forward, just click on the View Purchase Agreement button in the offer and  we'll email you a link to the official purchase agreement.`
                    : `When you're ready to move forward, just click on the View Purchase Agreement button in the offer and  we'll email you a link to the official purchase agreement, which you will be able to sign, digitally.`;
                dashboardInfo.column2 = `We need to chat about the conditions on this one`;
                dashboardInfo.faqs = [
                    sellerFAQs.doesMyOfferExpire,
                    sellerFAQs.howIsPriceDetermined,
                    sellerFAQs.ifNoClosingDateAvailable,
                ];
            }
            break;

        case 'ContractInProgress':
            dashboardInfo.workflowStep = currentState;
            dashboardInfo.progressBar = progressBar.Contract;
            dashboardInfo.progressPercentage = 33;
            dashboardInfo.sentenceTop = `We are working on the purchase contract`;
            dashboardInfo.whatsNextCopy = offerState.listedByAgent
                ? `You will receive an email with a link to our purchase contract for your seller's review and signature. Please keep an eye on your inbox.`
                : `You will receive an email with a link to our purchase contract for your review and signature. Please keep an eye on your inbox.`;
            dashboardInfo.faqs = [
                sellerFAQs.howQuicklyCanIClose,
                sellerFAQs.detailsOnExtendedStay,
                sellerFAQs.limitsOnFreeMove,
                sellerFAQs.stayingAfterClosing,
            ];
            break;

        case 'ContractSent':
            dashboardInfo.workflowStep = currentState;
            dashboardInfo.progressBar = progressBar.Contract;
            dashboardInfo.progressPercentage = 66;
            dashboardInfo.sentenceTop = `We've sent our the purchase agreement to ${offerState.contactEmail}.`;
            dashboardInfo.whatsNextCopy = offerState.listedByAgent
                ? `Let's make things official. To take the next step, your seller(s) will simply need to digitally sign the agreement we sent via email.`
                : `Let's make things official. To take the next step, you (and any other sellers) simply need to digitally sign the agreement we sent via email.`;
            dashboardInfo.faqs = [
                sellerFAQs.howQuicklyCanIClose,
                sellerFAQs.howToAccessExtendedStay,
                sellerFAQs.limitsOnFreeMove,
                sellerFAQs.howMyOfferComparesToOthers,
            ];
            break;

        case 'ContractSigned':
            dashboardInfo.workflowStep = currentState;
            dashboardInfo.progressBar = progressBar.Contract;
            dashboardInfo.progressPercentage = 100;
            dashboardInfo.sentenceTop = `We are officially under contract, thanks for choosing Offerpad!`;
            dashboardInfo.whatsNextCopy = offerState.listedByAgent
                ? `An Offerpad team member will connect with you to arrange a convenient time for an Offerpad team member and a third-party inspector to visit your seller's home for our in-person inspection.`
                : `An Offerpad team member will connect with you to arrange a convenient time for an Offerpad team member and a third-party inspector to visit your home in-person.`;
            dashboardInfo.faqs = [
                sellerFAQs.doesOPLookAtHomeInPerson,
                sellerFAQs.whoInspectsHomes,
                sellerFAQs.whatsOPTitleCompany,
            ];
            break;

        case 'EscrowOpened':
            if (offerState.offerExtended) {
                dashboardInfo.workflowStep = currentState;
                dashboardInfo.progressBar = progressBar.Inspection;
                dashboardInfo.progressPercentage = 10;
                dashboardInfo.sentenceTop = `We are officially under contract & we have deposited our earnest money with ${offerState.titleCompany}!`;
                dashboardInfo.whatsNextCopy = offerState.listedByAgent
                    ? `An Offerpad team member will connect with you to arrange a convenient time for an Offerpad team member and a third-party inspector to visit your seller's home for our in-person inspection.`
                    : `An Offerpad team member will connect with you to arrange a convenient time for an Offerpad team member and a third-party inspector to visit your home in-person.`;
                dashboardInfo.faqs = [
                    sellerFAQs.doesOPLookAtHomeInPerson,
                    sellerFAQs.whoInspectsHomes,
                    sellerFAQs.whatsOPTitleCompany,
                ];
            } else {
                dashboardInfo.workflowStep = null;
                dashboardInfo.progressBar = null;
                dashboardInfo.progressPercentage = 0;
                dashboardInfo.sentenceTop = offerState.listedByAgent
                    ? `This transaction status is currently being updated!`
                    : `Your transaction status is currently being updated!`;
                dashboardInfo.whatsNextCopy = offerState.listedByAgent
                    ? `Your process status is currently being updated. Please check in with your <a ${createId(
                          'status-updating-bis'
                      )} href="${offerState.teamPageLink}">Transaction Manager</a> to receive an update.`
                    : `Your process status is currently being updated. Please check in with your <a ${createId(
                          'status-updating-bis'
                      )} href="${offerState.teamPageLink}">Transaction Manager</a> to receive an update.`;
            }
            break;

        case 'InspectionOnHold':
            dashboardInfo.workflowStep = currentState;
            dashboardInfo.progressBar = progressBar.Inspection;
            dashboardInfo.progressPercentage = 10;
            dashboardInfo.sentenceTop = offerState.listedByAgent
                ? `We are working on scheduling your seller's inspection appointment.`
                : `We are working on scheduling your inspection appointment.`;
            dashboardInfo.whatsNextCopy = offerState.listedByAgent
                ? `Please connect with your <a ${createId('inspection-schedule')} href="${
                      offerState.teamPageLink
                  }">Transaction Manager</a> to pick a date that works for your seller's in-home inspection!`
                : `Please connect with your <a ${createId('inspection-schedule')} href="${
                      offerState.teamPageLink
                  }">Transaction Manager</a> to pick a date that works for your in-home inspection!`;
            dashboardInfo.column2 = `Link to teams page`;
            break;
        case 'InspectionScheduled':
            if (offerState.inspectionOnHold) {
                dashboardInfo.workflowStep = currentState;
                dashboardInfo.progressBar = progressBar.Inspection;
                dashboardInfo.progressPercentage = 10;
                dashboardInfo.sentenceTop = offerState.listedByAgent
                    ? `We are working on scheduling your seller's inspection appointment.`
                    : `We are working on scheduling your inspection appointment.`;
                dashboardInfo.whatsNextCopy = offerState.listedByAgent
                    ? `Please connect with your <a ${createId('inspection-schedule-bis')} href="${
                          offerState.teamPageLink
                      }">Transaction Manager</a> to pick a date that works for your seller's in-home inspection!`
                    : `Please connect with your <a ${createId('inspection-schedule-bis')} href="${
                          offerState.teamPageLink
                      }">Transaction Manager</a> to pick a date that works for your in-home inspection!`;
                dashboardInfo.faqs = [
                    sellerFAQs.whoInspectsHomes,
                    sellerFAQs.areasToBeInspected,
                    sellerFAQs.whatIfRepairsAreNeeded,
                ];
            } else if (offerState.inspectionScheduled) {
                const timeZone = offerState.timeZoneId ? offerState.timeZoneId : 'America/Phoenix';
                dashboardInfo.workflowStep = currentState;
                dashboardInfo.progressBar = progressBar.Inspection;
                dashboardInfo.progressPercentage = 40;
                dashboardInfo.sentenceTop = offerState.listedByAgent
                    ? `Your seller's inspection appointment has been scheduled!`
                    : `Mark your calendar, your inspection appointment has been scheduled!`;
                dashboardInfo.whatsNextCopy = offerState.listedByAgent
                    ? `The Offerpad representative and a third-party inspector will be at your seller's home on <strong>${moment(
                          offerState.inspectionScheduledDateTime
                      ).format('MMMM D, YYYY')} at ${formatTime(
                          offerState.inspectionScheduledDateTime,
                          timeZone
                      )}</strong>. The Offerpad Representative will be at your seller's home for about and the third-party inspector will be there between 2-3 hours.`
                    : `The Offerpad representative and a third-party inspector will be at your home on <strong>${moment(
                          offerState.inspectionScheduledDateTime
                      ).format('MMMM D, YYYY')} at ${formatTime(
                          offerState.inspectionScheduledDateTime,
                          timeZone
                      )}</strong>. The Offerpad Representative will be at your home for about 45 minutes and the third-party inspector will be there between 2-3 hours.`;
                dashboardInfo.faqs = [
                    sellerFAQs.whoInspectsHomes,
                    sellerFAQs.areasToBeInspected,
                    sellerFAQs.whatIfRepairsAreNeeded,
                ];
            } else {
                dashboardInfo.workflowStep = currentState;
                dashboardInfo.progressBar = progressBar.Inspection;
                dashboardInfo.progressPercentage = 50;
                dashboardInfo.sentenceTop = offerState.listedByAgent
                    ? `Your seller's inspection appointment is complete and we're working on the results.`
                    : `Your inspection appointment is complete and we're working on the results.`;
                dashboardInfo.whatsNextCopy = `It may take a few days for our evaluation team to complete their review of the inspection report. Your Transaction Manager will arrange a short call to discuss the results with you.`;
                dashboardInfo.faqs = [
                    sellerFAQs.whoInspectsHomes,
                    sellerFAQs.areasToBeInspected,
                    sellerFAQs.whatIfRepairsAreNeeded,
                ];
            }
            break;

        case 'InspectionCompleted':
        case 'OkInspection':
            if (offerState.creditAddendum) {
                let creditAmount = 0;
                offerState.contracts.map((c) => {
                    if (c.category == 'CreditAddendum') {
                        creditAmount = c.additionalCreditToBuyer;
                    }
                });
                dashboardInfo.workflowStep = currentState;
                dashboardInfo.progressBar = progressBar.Inspection;
                dashboardInfo.progressPercentage = 80;
                dashboardInfo.sentenceTop = offerState.listedByAgent
                    ? `We've finished our evaluation of your seller's home's inspection!`
                    : `We've finished our evaluation of your home's inspection! `;
                dashboardInfo.whatsNextCopy = offerState.listedByAgent
                    ? `We've emailed you a link to our Credit Addendum. In lieu of repairs to your seller's home, they can elect to provide Offerpad with a credit of <strong> ${formatter.format(
                          creditAmount
                      )}</strong>. To move forward, please have your seller(s) sign the addendum.`
                    : `We've emailed you a link to our Credit Addendum. In lieu of repairs to your home, you can elect to provide Offerpad with a credit of <strong>${formatter.format(
                          creditAmount
                      )}</strong>. Please sign the addendum to move forward.`;
                dashboardInfo.faqs = [
                    sellerFAQs.whatIfRepairsAreNeeded,
                    sellerFAQs.stayingAfterClosing,
                    sellerFAQs.whenWillWeSignClosing,
                ];
            } else if (offerState.creditAndRepairAddendum) {
                let creditAmount = 0;
                offerState.contracts.map((c) => {
                    if (c.category == 'CreditAndRepairAddendum') {
                        creditAmount = c.additionalCreditToBuyer;
                    }
                });
                dashboardInfo.workflowStep = currentState;
                dashboardInfo.progressBar = progressBar.Inspection;
                dashboardInfo.progressPercentage = 80;
                dashboardInfo.sentenceTop = offerState.listedByAgent
                    ? `We've finished our evaluation of your seller's home's inspection!`
                    : `We've finished our evaluation of your home's inspection!`;
                dashboardInfo.whatsNextCopy = offerState.listedByAgent
                    ? `We've emailed you a link to our Credit and Repair Addendum. Your seller has a choice of providing Offerpad with a credit in the amount of <strong>${formatter.format(
                          creditAmount
                      )}</strong> or having certain items repaired. To move forward, please have your seller(s) sign the agreement to move forward.`
                    : `We've emailed you a link to our Credit and Repair Addendum. You have a choice of providing Offerpad with a credit in the amount of <strong>${formatter.format(
                          creditAmount
                      )}</strong> or having certain items repaired. Please sign the agreement to move forward.`;
                dashboardInfo.faqs = [
                    sellerFAQs.whatIfRepairsAreNeeded,
                    sellerFAQs.stayingAfterClosing,
                    sellerFAQs.whenWillWeSignClosing,
                ];
            } else if (offerState.officialInspectionCompleted) {
                dashboardInfo.workflowStep = currentState;
                dashboardInfo.progressBar = progressBar.Inspection;
                dashboardInfo.progressPercentage = 60;
                dashboardInfo.sentenceTop = offerState.listedByAgent
                    ? `Your seller's  home inspection results are ready.`
                    : `Your home inspection results are ready.`;
                dashboardInfo.whatsNextCopy = offerState.listedByAgent
                    ? `The third-party home inspection report has been emailed to you. Offerpad doesn't typically ask for every item that has been identified on this report. To review what credit or repair items we are asking for, please schedule a time to chat with your ${createId(
                          'repairs-chat-schedule'
                      )} href="${offerState.closingConsultantLink}" target="_blank">Transaction Manager</a>.`
                    : `The third-party home inspection report has been emailed to you. Offerpad doesn't typically ask for every item that has been identified on this report. To review what credit or repair items we are asking for, please schedule a time to chat with your <a ${createId(
                          'repairs-chat-schedule'
                      )} href="${offerState.closingConsultantLink}" target="_blank">Transaction Manager</a>.`;
                dashboardInfo.faqs = [
                    sellerFAQs.whatIfRepairsAreNeeded,
                    sellerFAQs.stayingAfterClosing,
                    sellerFAQs.whenWillWeSignClosing,
                ];
            } else {
                dashboardInfo.workflowStep = currentState;
                dashboardInfo.progressBar = progressBar.Inspection;
                dashboardInfo.progressPercentage = 30;
                dashboardInfo.sentenceTop = offerState.listedByAgent
                    ? `We're working on your seller's inspection results.`
                    : `We're working on your inspection results.`;
                dashboardInfo.whatsNextCopy = offerState.listedByAgent
                    ? `Our team is currently reviewing the results of our third-party home inspection. This can take a few days and we will reach out as soon as we have an update. Once the evaluation team has completed their review of the inspection report, your Transaction Manager will arrange a short call to discuss the results with you.`
                    : `Our team is currently reviewing the results of our third-party home inspection. This can take a few days and we will reach out as soon as we have an update. Once the evaluation team has completed their review of the inspection report, your Transaction Manager will arrange a short call to discuss the results with you.`;
                dashboardInfo.faqs = [
                    sellerFAQs.whatIfRepairsAreNeeded,
                    sellerFAQs.stayingAfterClosing,
                    sellerFAQs.whenWillWeSignClosing,
                ];
            }
            break;

        case 'PurchaseInProgress':
        case 'WalkThroughScheduled':
            if (offerState.clearedToClose && offerState.signingApptDateTime) {
                dashboardInfo.workflowStep = currentState;
                dashboardInfo.progressBar = progressBar.Closing;
                dashboardInfo.progressPercentage = 33;
                dashboardInfo.sentenceTop = offerState.listedByAgent
                    ? `Your seller's closing appointment is scheduled and we are cleared to close!`
                    : `Your closing appointment is scheduled and we are cleared to close!`;
                dashboardInfo.whatsNextCopy = offerState.listedByAgent
                    ? `We've reached the home stretch. ${
                          offerState.titleCompany
                      } has notified us that your seller's transaction is cleared to close and their signing appointment has been scheduled for ${moment(
                          offerState.signingApptDateTime
                      ).format('MMMM D, YYYY')}. The walkthrough has been scheduled for sometime on ${moment(
                          offerState.walkthroughScheduledDate
                      ).format('MMMM D, YYYY')}.`
                    : `We've reached the home stretch. ${
                          offerState.titleCompany
                      } has notified us that your transaction is cleared to close and your signing appointment has been scheduled for ${moment(
                          offerState.signingApptDateTime
                      ).format('MMMM D, YYYY')}. Your walkthrough has been scheduled for sometime on ${moment(
                          offerState.walkthroughScheduledDate
                      ).format('MMMM D, YYYY')}.`;
            } else if (offerState.clearedToClose && !offerState.signingApptDateTime) {
                dashboardInfo.workflowStep = currentState;
                dashboardInfo.progressBar = progressBar.PrepareForClosing;
                dashboardInfo.progressPercentage = 40;
                dashboardInfo.sentenceTop = offerState.listedByAgent
                    ? `${offerState.titleCompany} has notified us that your seller's transaction is cleared to close!`
                    : `${offerState.titleCompany} has notified us that your transaction is cleared to close!`;
                dashboardInfo.whatsNextCopy = offerState.listedByAgent
                    ? `${offerState.titleCompany} has notified us that your transaction is cleared to close. Please connect with ${offerState.titleContactFullName} at ${offerState.titleContactPhoneNumber} to schedule your seller's signing appointment.`
                    : `${offerState.titleCompany} has notified us that your transaction is cleared to close. Please connect with ${offerState.titleContactFullName} at ${offerState.titleContactPhoneNumber} to schedule your signing appointment.`;
            } else if (!offerState.clearedToClose && offerState.signingApptDateTime) {
                dashboardInfo.workflowStep = currentState;
                dashboardInfo.progressBar = progressBar.PrepareForClosing;
                dashboardInfo.progressPercentage = 66;
                dashboardInfo.sentenceTop = offerState.listedByAgent
                    ? `Your seller's  closing appointment has been scheduled!`
                    : `Your closing appointment has been scheduled!`;
                dashboardInfo.whatsNextCopy = offerState.listedByAgent
                    ? `We are awaiting a final notification from ${
                          offerState.titleCompany
                      } that your seller is cleared to close. Your seller's signing appointment with ${
                          offerState.titleCompany
                      } has been scheduled for ${moment(offerState.signingApptDateTime).format(
                          'MMMM D, YYYY'
                      )} and their walkthrough has been scheduled for sometime on ${moment(
                          offerState.walkthroughScheduledDate
                      ).format('MMMM D, YYYY')}.`
                    : `We are awaiting a final notification from ${
                          offerState.titleCompany
                      } that you are cleared to close. Your signing appointment with ${
                          offerState.titleCompany
                      } has been scheduled for ${moment(offerState.signingApptDateTime).format(
                          'MMMM D, YYYY'
                      )}. Your walkthrough has been scheduled for sometime on ${moment(
                          offerState.walkthroughScheduledDate
                      ).format('MMMM D, YYYY')}.`;
            } else if (
                !offerState.clearedToClose &&
                !offerState.signingApptDateTime &&
                offerState.creditAndRepairSkipped &&
                offerState.escrowCloseDate
            ) {
                dashboardInfo.workflowStep = currentState;
                dashboardInfo.progressBar = progressBar.PrepareForClosing;
                dashboardInfo.progressPercentage = 33;
                dashboardInfo.sentenceTop = `Let's prepare for closing!`;
                dashboardInfo.whatsNextCopy = offerState.listedByAgent
                    ? `Offerpad has finished our evaluation of the condition of your seller's home and determined we will not be requesting either repairs to be made or credit.  Their scheduled closing date of ${moment(
                          offerState.escrowCloseDate
                      ).format(
                          'MMMM D, YYYY'
                      )} is just around the corner!  Need to make any changes?  Get in touch with us—we're always here to help.`
                    : `Offerpad has finished our evaluation of the condition of your home and determined we will not be requesting either repairs to be made or credit.  Your scheduled closing date of ${moment(
                          offerState.escrowCloseDate
                      ).format(
                          'MMMM D, YYYY'
                      )} is just around the corner!  Need to make any changes?  Get in touch with us—we're always here to help.`;
            } else if (
                !offerState.clearedToClose &&
                !offerState.signingApptDateTime &&
                !offerState.creditAndRepairSkipped &&
                offerState.escrowCloseDate
            ) {
                dashboardInfo.workflowStep = currentState;
                dashboardInfo.progressBar = progressBar.PrepareForClosing;
                dashboardInfo.progressPercentage = 33;
                dashboardInfo.sentenceTop = `Let's prepare for closing!`;
                dashboardInfo.whatsNextCopy = offerState.listedByAgent
                    ? `It’s time for your seller to start preparing for their closing, scheduled for ${moment(
                          offerState.escrowCloseDate
                      ).format(
                          'MMMM D, YYYY'
                      )}. Need to change your seller's closing date? No problem! Contact your Transaction Manager to discuss your options.`
                    : `It’s time to start preparing for your closing, scheduled for ${moment(
                          offerState.escrowCloseDate
                      ).format(
                          'MMMM D, YYYY'
                      )}. Need to change your closing date? No problem! Contact your Transaction Manager to discuss your options.`;
            }
            break;

        case 'WalkThroughCompleted':
            dashboardInfo.workflowStep = currentState;
            dashboardInfo.progressBar = progressBar.Closing;
            dashboardInfo.progressPercentage = 70;
            dashboardInfo.sentenceTop = offerState.listedByAgent
                ? 'Walk-through completed! We are nearing the finish line!'
                : 'Walk-through completed! We are nearing the finish line!';
            dashboardInfo.whatsNextCopy = `Our final walkthrough has been completed on ${moment(
                offerState.walkthroughCompletedDate
            ).format('MMMM D, YYYY')}.`;
            dashboardInfo.column2 = `We need to chat about the conditions on this one`;
            break;

        case 'Closed':
            dashboardInfo.workflowStep = currentState;
            dashboardInfo.progressBar = progressBar.Closing;
            dashboardInfo.progressPercentage = 100;
            if (offerState.isLost) {
                dashboardInfo.faqs = [
                    sellerFAQs.howImprovementsAffectOffer,
                    sellerFAQs.canItradeMyHome,
                    sellerFAQs.shouldProvidePhotos,
                ];
                dashboardInfo.sentenceTop = offerState.listedByAgent
                    ? `Thanks for visiting the Offerpad Portal. Your request for this property is no longer active. Please request a new offer on your home!`
                    : `Thanks for visiting the Offerpad Portal. Your request for this property is no longer active. Please request a new offer on your home!`;
                dashboardInfo.whatsNextCopy = offerState.listedByAgent
                    ? `Your previous request for this property is no longer active. You can request a new no-obligation offer on your home! Our valuation team will evaluate the information you provide in order to ensure the most accurate and competitive offer. Feel free to reach out to your <a ${createId(
                          'lost-offer-contact-team'
                      )} href='/offer/${
                          offerState.offerKey
                      }/your-team'>Transaction Manager</a> with any questions or concerns.`
                    : `Your previous request for this property is no longer active. You can request a new no-obligation offer on your home! Our valuation team will evaluate the information you provide in order to ensure the most accurate and competitive offer. Feel free to reach out to your <a ${createId(
                          'lost-offer-contact-team'
                      )} href='/offer/${
                          offerState.offerKey
                      }/your-team'>Transaction Manager</a> with any questions or concerns.`;
            } else if (!offerState.postPossessionAddendum) {
                dashboardInfo.sentenceTop = offerState.listedByAgent
                    ? `Your seller sold their house to Offerpad!`
                    : `You sold your house to Offerpad!`;
                dashboardInfo.whatsNextCopy = offerState.listedByAgent
                    ? `Learning from our customer's feedback is one of the most important parts of the process for our team.  Please click here to complete our <a ${createId(
                          'open-exit-survey'
                      )} href="${
                          offerState.closedSurveyUrl
                      }" target="_blank" rel="noreferrer noopener">exit survey</a>.`
                    : `Learning from our customer's feedback is one of the most important parts of the process for our team.  Please click here to complete our <a ${createId(
                          'open-exit-survey'
                      )} href="${
                          offerState.closedSurveyUrl
                      }" target="_blank" rel="noreferrer noopener">exit survey</a>.`;
                dashboardInfo.faqs = [
                    sellerFAQs.howImprovementsAffectOffer,
                    sellerFAQs.canItradeMyHome,
                    sellerFAQs.shouldProvidePhotos,
                ];
            } else {
                dashboardInfo.sentenceTop = offerState.listedByAgent
                    ? `Your seller sold their house to Offerpad!`
                    : `You sold your house to Offerpad!`;
                dashboardInfo.whatsNextCopy = offerState.listedByAgent
                    ? `On behalf of the entire team, we would like to thank you for choosing Offerpad and giving us the opportunity to serve you!  You selected Extended Stay, this "no-stress" option gives you until ${moment(
                          offerState.postPossessionMoveOutDate
                      ).format('MMMM D, YYYY')} to move! Please click here to complete our <a ${createId(
                          'open-exit-survey'
                      )} href="${
                          offerState.closedSurveyUrl
                      }" target="_blank" rel="noreferrer noopener">exit survey</a>.`
                    : `On behalf of the entire team, we would like to thank you for choosing Offerpad and giving us the opportunity to serve you!  You selected Extended Stay, this "no-stress" option gives you until ${moment(
                          offerState.postPossessionMoveOutDate
                      ).format('MMMM D, YYYY')} to move! Please click here to complete our <a ${createId(
                          'open-exit-survey'
                      )} href="${
                          offerState.closedSurveyUrl
                      }" target="_blank" rel="noreferrer noopener">exit survey</a>.`;
            }
            break;
        default:
            dashboardInfo.workflowStep = null;
            dashboardInfo.progressBar = null;
            dashboardInfo.progressPercentage = 0;
            dashboardInfo.sentenceTop = offerState.listedByAgent
                ? `This transaction status is currently being updated!`
                : `Your transaction status is currently being updated!`;
            dashboardInfo.whatsNextCopy = offerState.listedByAgent
                ? `Your process status is currently being updated. Please check in with your <a ${createId(
                      'status-update-fallback'
                  )} href="${offerState.teamPageLink}">Transaction Manager</a> to receive an update.`
                : `Your process status is currently being updated. Please check in with your <a ${createId(
                      'status-update-fallback'
                  )} href="${offerState.teamPageLink}">Transaction Manager</a> to receive an update.`;
            break;
    }
    return dashboardInfo;
}

export const getFlexProgressInfo = (): Partial<ProgressDashboardInfo> => {
    const dashboardInfo = new ProgressDashboardInfo();
    dashboardInfo.whatsNextHeading = "What's Next?";
    dashboardInfo.whatsNextCopy = `Offerpad has innovative solutions to help you sell your home. While you’re waiting
    to connect with your Solutions Expert, <a id="explore-benefits" target="_blank" href="https://www.offerpad.com/flex">explore the benefits</a> of our FLEX solution here.`;
    dashboardInfo.faqs = [
        sellerFAQs.isFlexRightForMe,
        sellerFAQs.whatIfIWantLowerOrHigher,
        sellerFAQs.areThereFlexFees,
        sellerFAQs.flexFreeServices,
    ];
    return dashboardInfo;
};
