import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Wizard, WizardStep, useStepController } from './Wizard/Wizard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOffer } from '../../../context/offer/OfferContext';
import { ConnectionType } from '../../../services/FormsService/models/ConnectionType';
import { FormType } from '../../../services/FormsService/models/FormType';
import * as FormService from '../../../services/FormsService/forms.service';
import { FormRadioGrid } from 'floorplan/components/Form';
import { FormControl } from 'floorplan/components/v2/Form';
import { Button } from 'floorplan/components/v2/Button';
import { Rating, RatingView } from 'react-simple-star-rating';
import { RadioNps } from '../../UIComponents/RadioNps/RadioNps';
import TokenService from '../../../services/token.service';
import cx from 'classnames';

import './SurveyContent.scss';

const PostInspectionSurveyContent = (props) => {
    const { state: { offerState }, dispatch: offerDispatch } = useOffer();
    const stepController = useStepController();
    const history = useHistory();
    const [formSucceeded, updateFormSucceeded] = useState(false);
    const [formFailed, updateFormFailed] = useState(false);
    const [submitting, updateSubmitting] = useState(false);

    const [form, updateForm] = useState({
        solutionTeamMemberFullName: props.solutionTeamMember.fullName,
        solutionTeamMemberKnowledgeable: '',
        solutionTeamMemberResponsiveness: '',
        solutionTeamMemberStarRating: null,
        solutionTeamMemberComments: '',
        transactionManagerFullName: props.transactionManager.fullName,
        transactionManagerKnowledgeable: '',
        transactionManagerResponsiveness: '',
        transactionManagerComments: '',
        offerpadRating: null,
        offerpadComments: '',
        ... !!props.offerpadRepresentative ? {
            offerpadRepresentativeFullName: props.offerpadRepresentative.fullName,
            offerpadRepresentativeArrival: '',
            offerpadRepresentativeFriendliness: '',
            offerpadRepresentativeExplainedProcess: '',
            offerpadRepresentativeStarRating: null,
            offerpadRepresentativeComments: '',
        }
        :{}
    });

    const [formErrors, updateFormErrors] = useState({
        solutionTeamMemberKnowledgeable: false,
        solutionTeamMemberResponsiveness: false,
        solutionTeamMemberStarRating: false,
        transactionManagerKnowledgeable: false,
        transactionManagerResponsiveness: false,
        offerpadRating: false,
        ... !!props.offerpadRepresentative ? {
            offerpadRepresentativeArrival: false,
            offerpadRepresentativeFriendliness: false,
            offerpadRepresentativeExplainedProcess: false,
            offerpadRepresentativeStarRating: false,
        }
        :{}
    });

    const HMCFlag = !!props.offerpadRepresentative;

    const updateField = (field, value) => {
        updateForm({ ...form, [field]: value });
        updateFormErrors({ ...formErrors, [field]: false });
    };

    const validate = (field) => {
        if (form[field] || form[field] === 0) {
            stepController.incrementStep();
        }
        else {
            updateFormErrors({ ...formErrors, [field]: true });
        }
    };

    const friendliness = [
        { label: '- Very friendly', value: 5 },
        { label: '- Friendly', value: 4 },
        { label: '- Somewhat friendly', value: 3 },
        { label: '- Not always friendly', value: 2 },
        { label: '- Not friendly at all', value: 1 },
    ];

    const explainedProcess = [
        { label: '- Very clearly', value: 5 },
        { label: '- Clearly', value: 4 },
        { label: '- Somewhat clearly', value: 3 },
        { label: '- Not always clearly', value: 2 },
        { label: '- Not clearly at all', value: 1 },
    ];

    const setKnowledgeText = (text) => {
        let t = (text ?? '').toLowerCase();
        switch (t) {
            case 'yes':
            case 'not applicable':
                return 'knowledgeable';
            case 'partially':
                return t + ' knowledgeable';
            default:
                return t;
        }
    };

    const setResponsiveText = (text) => {
        let t = (text ?? '').toLowerCase();
        switch (t) {
            case 'not applicable':
                return 'responsive';
            default:
                return t;
        }
    };

    const setFriendlinessText = (rating) => {
        switch (rating) {
            case 5:
                return 'very friendly';
            case 2:
                return 'not very friendly';
            case 1:
                return 'not friendly at all';
            default:
                return 'friendly';
        }
    };

    const setExplainedProcessText = (rating) => {
        switch (rating) {
            case 4:
                return 'explained the process well';
            case 3:
                return 'somewhat explained the process';
            case 2:
                return 'did not explain the process very well';
            case 1:
                return 'did not explain the process';
            default:
                return 'explained the process very well';
        }
    };

    const setStarText = (rating) => {
        return rating + (rating === 1 ? ' star' : ' stars');
    };

    const navigateAway = () => {
        const loggedIn = TokenService.getToken();
        if (loggedIn) {
            window.location.href = `${process.env.RAZZLE_CONNECT_URL}/sell/overview?transactionId=${props.acquisitionId}`
        } else {
            window.location.href = `https://www.offerpad.com/`;
        }
    };

    const submitForm = () => {
        if (submitting) {
            return;
        }
        updateSubmitting(true);
        FormService.submitForm({
            connectionId: props.acquisitionId,
            connectionType: ConnectionType.HelixEntity,
            formType: FormType.PostInspectionSurvey,
            items: Object.keys(form).map((key) => {
                return {
                    name: key,
                    value: form[key],
                };
            }),
        })
            .then(
                () => {
                    updateFormSucceeded(true);
                },
                () => {
                    updateFormFailed(true);
                })
            .catch(() => {
                updateFormFailed(true);
            })
            .finally(() => {
                updateSubmitting(false);
                stepController.incrementStep();
            });
    };

    const NavigationButtons = () => {
        return (
            <div className={cx({ 'btn-wrapper': true, 'd-none': submitting })} >
                {
                    (!formSucceeded && !formFailed && stepController.currentStep > 0) ?
                        <button
                            id="post-inspection-survey-previous"
                            className="btn-survey-previous"
                            onClick={stepController.decrementStep}
                        >
                            <FontAwesomeIcon icon={['fas', 'long-arrow-left']} />
                            {' Back'}
                        </button>
                        :
                        <button id="post-inspection-navigate-away" className="btn-survey-previous" onClick={navigateAway}>
                            <FontAwesomeIcon icon={['fas', 'times']} />
                            {' Close'}
                        </button>
                }
            </div>
        )
    };

    return (
        <div>
            <NavigationButtons />
            <Wizard stepController={stepController} >
                {/*
                Initial section
                */}
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar">
                            {
                                HMCFlag ?
                                    <div className="tri-image">
                                        <div className="text-center">
                                            <img src={props.solutionTeamMember.imageUrl} className="rep" />
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <img src={props.transactionManager.imageUrl} className="rep" />
                                            <img src={props.offerpadRepresentative.imageUrl} className="rep" />
                                        </div>
                                    </div>
                                    :
                                    <div className="tri-image">
                                        <div className="text-center">
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <img src={props.solutionTeamMember.imageUrl} className="rep" />
                                            <img src={props.transactionManager.imageUrl} className="rep" />
                                        </div>
                                    </div>
                            }
                            <div className="text-center pt-4">
                                <img src={require('@assets/op-logo.svg')} className="logo" />
                            </div>
                        </div>
                        <div className="modal-main">
                            <p className="d-none d-md-block">Thank you for helping us to be better</p>
                            <h1 className="d-none d-md-block">Welcome</h1>
                            <div className="highlight">
                                Offerpad is all about building a great experience for our customers. We'd like to check-in
                                and see how things are going so far.
                            </div>
                            <div className="btn-wrapper pt-4">
                                <Button id="post-inspection-survey-begin" large onClick={stepController.incrementStep}>
                                    Let's Begin
                                </Button>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                <WizardStep>
                    <div className="p-4">
                        <div className="survey-intro">
                            <h3>First, let's see how our people are doing so far!</h3>
                            <div className="d-flex justify-content-center">
                                <img src={props.solutionTeamMember.imageUrl} className="rep" />
                                <img src={props.transactionManager.imageUrl} className="rep" />
                                {HMCFlag && <img src={props.offerpadRepresentative.imageUrl} className="rep" />}
                            </div>
                            <p className="pt-4">
                                {
                                    HMCFlag ?
                                        <React.Fragment>
                                            Let's see how <b>{props.solutionTeamMember.firstName}</b>,{' '}
                                            <b>{props.transactionManager.firstName}</b>{' and  '}
                                            <b>{props.offerpadRepresentative.firstName}</b>{' '}
                                            are taking care of you!
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            Let's see how <b>{props.solutionTeamMember.firstName}</b>{' and '}
                                            <b>{props.transactionManager.firstName}</b>{' '}
                                            are taking care of you!
                                        </React.Fragment>
                                }
                            </p>
                            <div className="btn-wrapper pt-4 d-flex justify-content-center">
                                <Button id="post-inspection-survey-9981" large onClick={stepController.incrementStep}>
                                    Tell us how they're doing!
                                </Button>
                            </div>
                        </div>
                    </div>
                </WizardStep>

                {/*
                Solution Team Member section (Solution Advisor or Solution Expert)
                */}
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar">
                            <div className="text-center">
                                <img src={props.solutionTeamMember.imageUrl} alt="" className="rep large" />
                            </div>
                        </div>
                        <div className="modal-main">
                            <p>This is your {props.solutionTeamMember.title}</p>
                            <h3>{props.solutionTeamMember.fullName}</h3>
                            <div className="highlight">
                                Was {props.solutionTeamMember.firstName} knowledgeable about Offerpad and able to
                                answer all of your questions?
                            </div>
                            <div>
                                <FormControl
                                    id='solutionTeamMemberKnowledgeable'
                                    className='simple-radios'
                                    name='solutionTeamMemberKnowledgeable'
                                    value={form.solutionTeamMemberKnowledgeable}
                                    hasError={formErrors.solutionTeamMemberKnowledgeable}
                                    errorText={formErrors.solutionTeamMemberKnowledgeable ? 'Please make a selection' : ''}
                                    onChange={e => updateField('solutionTeamMemberKnowledgeable', e.target.value)}
                                >
                                    <FormRadioGrid
                                        columns={1}
                                        bp='desktop'
                                    >
                                        <FormRadioGrid.Option
                                            id='solutionTeamMemberKnowledgeable1'
                                            key='solutionTeamMemberKnowledgeable1'
                                            label='Yes'
                                            value='Yes'
                                            checked={form.solutionTeamMemberKnowledgeable === 'Yes'}
                                        />
                                        <FormRadioGrid.Option
                                            id='solutionTeamMemberKnowledgeable2'
                                            key='solutionTeamMemberKnowledgeable2'
                                            label='Partially'
                                            value='Partially'
                                            checked={form.solutionTeamMemberKnowledgeable === 'Partially'}
                                        />
                                        <FormRadioGrid.Option
                                            id='solutionTeamMemberKnowledgeable3'
                                            key='solutionTeamMemberKnowledgeable3'
                                            label='Not Knowledgeable'
                                            value='Not Knowledgeable'
                                            checked={form.solutionTeamMemberKnowledgeable === 'Not Knowledgeable'}
                                        />
                                        <FormRadioGrid.Option
                                            id='solutionTeamMemberKnowledgeable4'
                                            key='solutionTeamMemberKnowledgeable4'
                                            label='Not applicable'
                                            value='Not applicable'
                                            checked={form.solutionTeamMemberKnowledgeable === 'Not applicable'}
                                        />
                                    </FormRadioGrid>
                                </FormControl>
                                <div className="btn-wrapper pt-2">
                                    <Button
                                        id="post-inspection-0072"
                                        large
                                        disabled={!form.solutionTeamMemberKnowledgeable}
                                        onClick={() => validate('solutionTeamMemberKnowledgeable')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar">
                            <div className="text-center">
                                <img src={props.solutionTeamMember.imageUrl} alt="" className="rep large" />
                            </div>
                        </div>
                        <div className="modal-main">
                            <p>Correspondence with</p>
                            <h3>{props.solutionTeamMember.fullName}</h3>
                            <div className="highlight">
                                Was {props.solutionTeamMember.firstName} quick to respond to any messages or emails?
                            </div>
                            <div>
                                <FormControl
                                    id='solutionTeamMemberResponsiveness'
                                    className='simple-radios'
                                    name='solutionTeamMemberResponsiveness'
                                    value={form.solutionTeamMemberResponsiveness}
                                    hasError={formErrors.solutionTeamMemberResponsiveness}
                                    errorText={formErrors.solutionTeamMemberResponsiveness ? 'Please make a selection' : ''}
                                    onChange={e => updateField('solutionTeamMemberResponsiveness', e.target.value)}
                                >
                                    <FormRadioGrid
                                        columns={1}
                                        bp='desktop'
                                    >
                                        <FormRadioGrid.Option
                                            id='solutionTeamMemberResponsiveness1'
                                            key='solutionTeamMemberResponsiveness1'
                                            label='Very responsive'
                                            value='Very responsive'
                                            checked={form.solutionTeamMemberResponsiveness === 'Very responsive'}
                                        />
                                        <FormRadioGrid.Option
                                            id='solutionTeamMemberResponsiveness2'
                                            key='solutionTeamMemberResponsiveness2'
                                            label='Mostly responsive'
                                            value='Mostly responsive'
                                            checked={form.solutionTeamMemberResponsiveness === 'Mostly responsive'}
                                        />
                                        <FormRadioGrid.Option
                                            id='solutionTeamMemberResponsiveness3'
                                            key='solutionTeamMemberResponsiveness3'
                                            label='Not responsive at all'
                                            value='Not responsive at all'
                                            checked={form.solutionTeamMemberResponsiveness === 'Not responsive at all'}
                                        />
                                        <FormRadioGrid.Option
                                            id='solutionTeamMemberResponsiveness4'
                                            key='solutionTeamMemberResponsiveness4'
                                            label='Not applicable'
                                            value='Not applicable'
                                            checked={form.solutionTeamMemberResponsiveness === 'Not applicable'}
                                        />
                                    </FormRadioGrid>
                                </FormControl>
                                <div className="btn-wrapper pt-2">
                                    <Button
                                        id="post-inspection-0288"
                                        large
                                        disabled={!form.solutionTeamMemberResponsiveness}
                                        onClick={() => validate('solutionTeamMemberResponsiveness')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar">
                            <div className="text-center">
                                <img src={props.solutionTeamMember.imageUrl} alt="" className="rep large" />
                            </div>
                        </div>
                        <div className="modal-main">
                            <p>Your rating for</p>
                            <h3>{props.solutionTeamMember.fullName}</h3>
                            <div className="highlight">
                                Please give {props.solutionTeamMember.firstName} a star rating.
                            </div>
                            <div>
                                <Rating
                                    ratingValue={form.solutionTeamMemberStarRating || 0}
                                    onClick={rating => updateField('solutionTeamMemberStarRating', rating)}
                                    size={50}
                                />
                                <div className="btn-wrapper pt-5">
                                    <Button
                                        id="post-inspection-1122"
                                        large
                                        disabled={!form.solutionTeamMemberStarRating}
                                        onClick={() => validate('solutionTeamMemberStarRating')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar">
                            <div className="text-center">
                                <img src={props.solutionTeamMember.imageUrl} alt="" className="rep large" />
                                <h3>{props.solutionTeamMember.fullName}</h3>
                                <div className="mx-auto">
                                    <div>Your rating:</div>
                                    <RatingView
                                        stars={form.solutionTeamMemberStarRating || 0}
                                        ratingValue={form.solutionTeamMemberStarRating || 0}
                                        size={30}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-main">
                            {
                                form.solutionTeamMemberStarRating <= 3 ?
                                    <div className="highlight">
                                        Hmm, looks like {props.solutionTeamMember.firstName} could have done better. Would you
                                        like to tell us more? (optional)
                                    </div> :
                                    <div className="highlight">
                                        Wow! We love {props.solutionTeamMember.firstName} too! Was there anything they did that
                                        exceeded your expectations? (optional)
                                    </div>
                            }
                            <div>
                                <FormControl
                                    id='solutionTeamMemberComments'
                                    name='solutionTeamMemberComments'
                                    placeholder="Tell us more"
                                    value={form.solutionTeamMemberComments}
                                    onChange={(e) =>
                                        updateForm({
                                            ...form, solutionTeamMemberComments: e.target.value
                                        })}

                                >
                                    <textarea
                                        name="solutionTeamMemberComments"
                                        rows={4}
                                    ></textarea>
                                </FormControl>
                                <div className="btn-wrapper pt-4">
                                    <Button
                                        id="post-inspection-3984"
                                        large
                                        onClick={stepController.incrementStep}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar">
                            <div className="text-center">
                                <img src={props.solutionTeamMember.imageUrl} alt="" className="rep large" />
                            </div>
                            <div className="text-center rep-next">
                                <img src={props.transactionManager.imageUrl} alt="" className="rep small" />
                                <span>Up next</span>
                            </div>
                            {
                                HMCFlag &&
                                <div className="text-center rep-next">
                                    <img src={props.offerpadRepresentative.imageUrl} alt="" className="rep small" />
                                    <span>And then</span>
                                </div>
                            }
                        </div>
                        <div className="pt-3 pl-3 pr-3 d-flex flex-column">
                            <p className="pt-1 pb-4">Review Recap</p>
                            <div className="highlight">
                                {props.solutionTeamMember.fullName}{' '}
                                was{' '}
                                {setKnowledgeText(form.solutionTeamMemberKnowledgeable)}{' '}
                                and{' '}
                                {setResponsiveText(form.solutionTeamMemberResponsiveness)}{' '}
                                and awarded{' '}
                                {setStarText(form.solutionTeamMemberStarRating)}.
                            </div>
                            <div className="col d-flex align-items-center new-justify-content review-recap">
                                <div className="btn-wrapper mr-2 mb-2">
                                    <Button
                                        id="post-inspection-9587"
                                        large
                                        onClick={stepController.incrementStep}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>

                {/*
                Transaction Manager section
                */}
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar">
                            <div className="text-center">
                                <img src={props.transactionManager.imageUrl} alt="" className="rep large" />
                            </div>
                        </div>
                        <div className="modal-main">
                            <p>This is your Transaction Manager</p>
                            <h3>{props.transactionManager.fullName}</h3>
                            <div className="highlight">
                                So far, is {props.transactionManager.firstName} knowledgeable about the Offerpad
                                process and able to answer all of your questions?
                            </div>
                            <div>
                                <FormControl
                                    id='transactionManagerKnowledgeable'
                                    className='simple-radios'
                                    name='transactionManagerKnowledgeable'
                                    value={form.transactionManagerKnowledgeable}
                                    hasError={formErrors.transactionManagerKnowledgeable}
                                    errorText={formErrors.transactionManagerKnowledgeable ? 'Please make a selection' : ''}
                                    onChange={e => updateField('transactionManagerKnowledgeable', e.target.value)}
                                >
                                    <FormRadioGrid
                                        columns={1}
                                        bp='desktop'
                                    >
                                        <FormRadioGrid.Option
                                            id='transactionManagerKnowledgeable1'
                                            key='transactionManagerKnowledgeable1'
                                            label='Yes'
                                            value='Yes'
                                            checked={form.transactionManagerKnowledgeable === 'Yes'}
                                        />
                                        <FormRadioGrid.Option
                                            id='transactionManagerKnowledgeable2'
                                            key='transactionManagerKnowledgeable2'
                                            label='Partially'
                                            value='Partially'
                                            checked={form.transactionManagerKnowledgeable === 'Partially'}
                                        />
                                        <FormRadioGrid.Option
                                            id='transactionManagerKnowledgeable3'
                                            key='transactionManagerKnowledgeable3'
                                            label='Not Knowledgeable'
                                            value='Not Knowledgeable'
                                            checked={form.transactionManagerKnowledgeable === 'Not Knowledgeable'}
                                        />
                                        <FormRadioGrid.Option
                                            id='transactionManagerKnowledgeable4'
                                            key='transactionManagerKnowledgeable4'
                                            label='Not applicable'
                                            value='Not applicable'
                                            checked={form.transactionManagerKnowledgeable === 'Not applicable'}
                                        />
                                    </FormRadioGrid>
                                </FormControl>
                                <div className="btn-wrapper">
                                    <Button
                                        id="post-inspection-1581"
                                        large
                                        disabled={!form.transactionManagerKnowledgeable}
                                        onClick={() => validate('transactionManagerKnowledgeable')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar">
                            <div className="text-center">
                                <img src={props.transactionManager.imageUrl} alt="" className="rep large" />
                            </div>
                        </div>
                        <div className="modal-main">
                            <p>Correspondence with</p>
                            <h3>{props.transactionManager.fullName}</h3>
                            <div className="highlight">
                                Now let's find out how quickly {props.transactionManager.firstName} took care of you.
                                Was {props.transactionManager.firstName} quick to respond to any messages or emails?
                            </div>
                            <div>
                                <FormControl
                                    id='transactionManagerResponsiveness'
                                    className='simple-radios'
                                    name='transactionManagerResponsiveness'
                                    value={form.transactionManagerResponsiveness}
                                    hasError={formErrors.transactionManagerResponsiveness}
                                    errorText={formErrors.transactionManagerResponsiveness ? 'Please make a selection' : ''}
                                    onChange={e => updateField('transactionManagerResponsiveness', e.target.value)}
                                >
                                    <FormRadioGrid
                                        columns={1}
                                        bp='desktop'
                                    >
                                        <FormRadioGrid.Option
                                            id='transactionManagerResponsiveness1'
                                            key='transactionManagerResponsiveness1'
                                            label='Very responsive'
                                            value='Very responsive'
                                            checked={form.transactionManagerResponsiveness === 'Very responsive'}
                                        />
                                        <FormRadioGrid.Option
                                            id='transactionManagerResponsiveness2'
                                            key='transactionManagerResponsiveness2'
                                            label='Mostly responsive'
                                            value='Mostly responsive'
                                            checked={form.transactionManagerResponsiveness === 'Mostly responsive'}
                                        />
                                        <FormRadioGrid.Option
                                            id='transactionManagerResponsiveness3'
                                            key='transactionManagerResponsiveness3'
                                            label='Not responsive at all'
                                            value='Not responsive at all'
                                            checked={form.transactionManagerResponsiveness === 'Not responsive at all'}
                                        />
                                        <FormRadioGrid.Option
                                            id='transactionManagerResponsiveness4'
                                            key='transactionManagerResponsiveness4'
                                            label='Not applicable'
                                            value='Not applicable'
                                            checked={form.transactionManagerResponsiveness === 'Not applicable'}
                                        />
                                    </FormRadioGrid>
                                </FormControl>
                                <div className="btn-wrapper">
                                    <Button
                                        id="post-inspection-5324"
                                        large
                                        disabled={!form.transactionManagerResponsiveness}
                                        onClick={() => validate('transactionManagerResponsiveness')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar">
                            <div className="text-center">
                                <img src={props.transactionManager.imageUrl} alt="" className="rep large" />
                                <h3>{props.transactionManager.fullName}</h3>
                            </div>
                        </div>
                        <div className="modal-main">
                            <div className="highlight">
                                We know{' '}
                                {props.transactionManager.firstName}{' '}
                                is still helping you through your sale to Offerpad,
                                but if you want to tell us something about the service provided by{' '}
                                {props.transactionManager.firstName}, so far, please enter your feedback below.
                            </div>
                            <div>
                                <FormControl
                                    id='transactionManagerComments'
                                    name='transactionManagerComments'
                                    placeholder="Tell us more"
                                    value={form.transactionManagerComments}
                                    onChange={(e) =>
                                        updateForm({
                                            ...form, transactionManagerComments: e.target.value
                                        })}

                                >
                                    <textarea
                                        name="transactionManagerComments"
                                        rows={4}
                                    ></textarea>
                                </FormControl>
                                <div className="btn-wrapper pt-4">
                                    <Button
                                        id="post-inspection-9957"
                                        large
                                        onClick={stepController.incrementStep}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar">
                            <div className="text-center">
                                <img src={props.transactionManager.imageUrl} alt="" className="rep large" />
                            </div>
                            {
                                HMCFlag &&
                                <div className="text-center rep-next">
                                    <img src={props.offerpadRepresentative.imageUrl} alt="" className="rep small" />
                                    <span>Up next</span>
                                </div>
                            }
                            <div className="text-center rep-next">
                                <img src={props.solutionTeamMember.imageUrl} alt="" className="rep small" />
                                <span className="complete-text">Completed</span>
                                <FontAwesomeIcon icon={['fas', 'check-circle']} className="complete-icon" />
                            </div>
                        </div>
                        <div className="pt-3 pl-3 pr-3 d-flex flex-column">
                            <p className="pt-1 pb-4">Review Recap</p>
                            <div className="highlight">
                                So far,{' '}
                                {props.transactionManager.fullName}{' '}
                                is{' '}
                                {setKnowledgeText(form.transactionManagerKnowledgeable)}{' '}
                                and{' '}
                                {setResponsiveText(form.transactionManagerResponsiveness)}.
                            </div>
                            <div className="col d-flex align-items-end new-justify-content review-recap">
                                <div className="btn-wrapper mr-2 mb-2">
                                    <Button
                                        id="post-inspection-0168"
                                        large
                                        onClick={stepController.incrementStep}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>

                {/*
                Offerpad Representative section
                */}

                {
                    HMCFlag &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-sidebar">
                                <div className="text-center">
                                    <img src={props.offerpadRepresentative.imageUrl} alt="" className="rep large" />
                                </div>
                            </div>
                            <div className="modal-main">
                                <p>Offerpad Representative</p>
                                <h3>{props.offerpadRepresentative.fullName}</h3>
                                <div className="highlight">
                                    {props.offerpadRepresentative.firstName} is the Offerpad Representative that recently
                                    visited your home. Did {props.offerpadRepresentative.firstName} arrive on time?
                                </div>
                                <div>
                                    <FormControl
                                        id='offerpadRepresentativeArrival'
                                        className='simple-radios'
                                        name='offerpadRepresentativeArrival'
                                        value={form.offerpadRepresentativeArrival}
                                        hasError={formErrors.offerpadRepresentativeArrival}
                                        errorText={formErrors.offerpadRepresentativeArrival ? 'Please make a selection' : ''}
                                        onChange={e => updateField('offerpadRepresentativeArrival', e.target.value)}
                                    >
                                        <FormRadioGrid
                                            columns={1}
                                            bp='desktop'
                                        >
                                            <FormRadioGrid.Option
                                                id='offerpadRepresentativeArrival1'
                                                key='offerpadRepresentativeArrival1'
                                                label='Right on time'
                                                value='Right on time'
                                                checked={form.offerpadRepresentativeArrival === 'Right on time'}
                                            />
                                            <FormRadioGrid.Option
                                                id='offerpadRepresentativeArrival2'
                                                key='offerpadRepresentativeArrival2'
                                                label='Just a little late'
                                                value='Just a little late'
                                                checked={form.offerpadRepresentativeArrival === 'Just a little late'}
                                            />
                                            <FormRadioGrid.Option
                                                id='offerpadRepresentativeArrival3'
                                                key='offerpadRepresentativeArrival3'
                                                label='Around 15 minutes late'
                                                value='Around 15 minutes late'
                                                checked={form.offerpadRepresentativeArrival === 'Around 15 minutes late'}
                                            />
                                            <FormRadioGrid.Option
                                                id='offerpadRepresentativeArrival4'
                                                key='offerpadRepresentativeArrival4'
                                                label='30+ minutes late'
                                                value='30+ minutes late'
                                                checked={form.offerpadRepresentativeArrival === '30+ minutes late'}
                                            />
                                        </FormRadioGrid>
                                    </FormControl>
                                    <div className="btn-wrapper">
                                        <Button
                                            id="post-inspection-0661"
                                            large
                                            disabled={!form.offerpadRepresentativeArrival}
                                            onClick={() => validate('offerpadRepresentativeArrival')}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }

                {
                    HMCFlag &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-sidebar">
                                <div className="text-center">
                                    <img src={props.offerpadRepresentative.imageUrl} alt="" className="rep large" />
                                </div>
                            </div>
                            <div className="modal-main">
                                <p>Friendliness of</p>
                                <h3>{props.offerpadRepresentative.fullName}</h3>
                                <div className="highlight">
                                    Friendliness is important to Offerpad. Was{' '}
                                    {props.offerpadRepresentative.firstName}{' '}
                                    friendly?
                                </div>
                                <div>
                                    <FormControl
                                        id='offerpadRepresentativeFriendliness'
                                        className='simple-radios'
                                        name='offerpadRepresentativeFriendliness'
                                        value={form.offerpadRepresentativeFriendliness}
                                        hasError={formErrors.offerpadRepresentativeFriendliness}
                                        errorText={formErrors.offerpadRepresentativeFriendliness ? 'Please make a selection' : ''}
                                        onChange={e => updateField('offerpadRepresentativeFriendliness', e.target.value)}
                                    >
                                        <select
                                            className="op-select-dropdown"
                                            style={{ width: '250px' }}
                                            placeholder="Select your rating"
                                        >
                                            <option hidden value='' label="Select your rating">
                                                Select your rating
                                            </option>
                                            {
                                                friendliness.map(x =>
                                                    <option value={x.value} label={`${x.value} ${x.label}`} key={x.value}>
                                                        {`${x.value} ${x.label}`}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </FormControl>
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="post-inspection-2471"
                                            large
                                            disabled={!form.offerpadRepresentativeFriendliness}
                                            onClick={() => validate('offerpadRepresentativeFriendliness')}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }


                {
                    HMCFlag &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-sidebar">
                                <div className="text-center">
                                    <img src={props.offerpadRepresentative.imageUrl} alt="" className="rep large" />
                                </div>
                            </div>
                            <div className="modal-main">
                                <p>Clarity of</p>
                                <h3>{props.offerpadRepresentative.fullName}</h3>
                                <div className="highlight">
                                    Did {props.offerpadRepresentative.firstName}{' '}
                                    clearly explain the inspection process and
                                    next steps in the sale of your home to Offerpad?
                                </div>
                                <div>
                                    <FormControl
                                        id='offerpadRepresentativeExplainedProcess'
                                        className='simple-radios'
                                        name='offerpadRepresentativeExplainedProcess'
                                        value={form.offerpadRepresentativeExplainedProcess}
                                        hasError={formErrors.offerpadRepresentativeExplainedProcess}
                                        errorText={formErrors.offerpadRepresentativeExplainedProcess ? 'Please make a selection' : ''}
                                        onChange={e => updateField('offerpadRepresentativeExplainedProcess', e.target.value)}
                                    >
                                        <select
                                            className="op-select-dropdown"
                                            style={{ width: '250px' }}
                                            placeholder="Select your rating"
                                        >
                                            <option hidden value='' label="Select your rating">
                                                Select your rating
                                            </option>
                                            {
                                                explainedProcess.map(x =>
                                                    <option value={x.value} label={`${x.value} ${x.label}`} key={x.value}>
                                                        {`${x.value} ${x.label}`}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </FormControl>
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="post-inspection-7442"
                                            large
                                            disabled={!form.offerpadRepresentativeExplainedProcess}
                                            onClick={() => validate('offerpadRepresentativeExplainedProcess')}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }


                {
                    HMCFlag &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-sidebar">
                                <div className="text-center">
                                    <img src={props.offerpadRepresentative.imageUrl} alt="" className="rep large" />
                                </div>
                            </div>
                            <div className="modal-main">
                                <p>Your rating for</p>
                                <h3>{props.offerpadRepresentative.fullName}</h3>
                                <div className="highlight">
                                    Please give {props.offerpadRepresentative.firstName} a star rating.
                                </div>
                                <div>
                                    <Rating
                                        ratingValue={form.offerpadRepresentativeStarRating || 0}
                                        onClick={rating => updateField('offerpadRepresentativeStarRating', rating)}
                                        size={50}
                                    />
                                    <div className="btn-wrapper pt-5">
                                        <Button
                                            id="post-inspection-7614"
                                            large
                                            disabled={!form.offerpadRepresentativeStarRating}
                                            onClick={() => validate('offerpadRepresentativeStarRating')}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }


                {
                    HMCFlag &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-sidebar">
                                <div className="text-center">
                                    <img src={props.offerpadRepresentative.imageUrl} alt="" className="rep large" />
                                    <h3>{props.offerpadRepresentative.fullName}</h3>
                                    <div className="mx-auto">
                                        <div>Your rating:</div>
                                        <RatingView
                                            stars={form.offerpadRepresentativeStarRating || 0}
                                            ratingValue={form.offerpadRepresentativeStarRating || 0}
                                            size={30}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-main">
                                {
                                    form.offerpadRepresentativeStarRating <= 3 ?
                                        <div className="highlight">
                                            Hmm, looks like {props.offerpadRepresentative.firstName} could have done better. Would
                                            you like to tell us more? (optional)
                                        </div> :
                                        <div className="highlight">
                                            Wow! We love {props.offerpadRepresentative.firstName} too! Was there anything they did that exceeded your expectations? (optional)
                                        </div>
                                }
                                <div>
                                    <FormControl
                                        id='offerpadRepresentativeComments'
                                        name='offerpadRepresentativeComments'
                                        placeholder="Tell us more"
                                        value={form.offerpadRepresentativeComments}
                                        onChange={(e) =>
                                            updateForm({
                                                ...form, offerpadRepresentativeComments: e.target.value
                                            })}
                                    >
                                        <textarea
                                            name="offerpadRepresentativeComments"
                                            rows={4}
                                        ></textarea>
                                    </FormControl>
                                    <div className="btn-wrapper pt-4">
                                        <Button
                                            id="post-inspection-7266"
                                            large
                                            onClick={stepController.incrementStep}>
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }


                {
                    HMCFlag &&
                    <WizardStep>
                        <div className="step-wrapper">
                            <div className="modal-sidebar">
                                <div className="text-center">
                                    <img src={props.offerpadRepresentative.imageUrl} alt="" className="rep large" />
                                </div>
                                <div className="text-center rep-next">
                                    <img src={props.transactionManager.imageUrl} alt="" className="rep small" />
                                    <span className="complete-text">Completed</span>
                                    <FontAwesomeIcon icon={['fas', 'check-circle']} className="complete-icon" />
                                </div>
                                <div className="text-center rep-next">
                                    <img src={props.solutionTeamMember.imageUrl} alt="" className="rep small" />
                                    <span className="complete-text">Completed</span>
                                    <FontAwesomeIcon icon={['fas', 'check-circle']} className="complete-icon" />
                                </div>
                            </div>
                            <div className="pt-3 pl-3 pr-3 d-flex flex-column">
                                <p className="pt-1 pb-4">Review Recap</p>
                                <div className="highlight">
                                    {props.offerpadRepresentative.fullName}{' '}
                                    was{' '}
                                    {form.offerpadRepresentativeArrival?.toLowerCase()};{' '}
                                    was{' '}
                                    {setFriendlinessText(form.offerpadRepresentativeFriendliness)};{' '}
                                    {setExplainedProcessText(form.offerpadRepresentativeExplainedProcess)};{' '}
                                    and was awarded{' '}
                                    {setStarText(form.offerpadRepresentativeStarRating)}.
                                </div>
                                <div className="col d-flex align-items-end new-justify-content review-recap">
                                    <div className="btn-wrapper mb-2">
                                        <Button
                                            id="post-inspection-7945"
                                            large
                                            onClick={stepController.incrementStep}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WizardStep>
                }

                {/*
                Offerpad overall section
                */}
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar">
                            <div className="text-center">
                                {
                                    HMCFlag ?
                                        <div className="tri-image">
                                            <div className="text-center">
                                                <img src={props.solutionTeamMember.imageUrl} className="rep" width="200px" />
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <img src={props.transactionManager.imageUrl} className="rep" />
                                                <img src={props.offerpadRepresentative.imageUrl} className="rep" />
                                            </div>
                                        </div>
                                        :
                                        <div className="tri-image">
                                            <div className="d-flex justify-content-center">
                                                <img src={props.solutionTeamMember.imageUrl} className="rep" />
                                                <img src={props.transactionManager.imageUrl} className="rep" />
                                            </div>
                                        </div>
                                }
                                <h3>Overall Experience</h3>
                                {
                                    (form.offerpadRating || form.offerpadRating === 0) &&
                                    <div className="mx-auto">
                                        <div>Your rating:</div>
                                        <span className="rating-number">{form.offerpadRating}</span>
                                    </div>
                                }

                            </div>
                        </div>
                        <div className="pt-5 pl-3 pr-2 d-flex flex-column">
                            <h2>Your Overall Experience</h2>
                            <div className="highlight">
                                Based on your experience so far, how likely are you to recommend Offerpad to friends and
                                family?
                            </div>
                            <div className="pt-2">
                                <RadioNps
                                    name="offerpadRating"
                                    options={[
                                        'grey',
                                        'grey',
                                        'grey',
                                        'grey',
                                        'grey',
                                        'grey',
                                        'grey',
                                        'grey',
                                        'grey',
                                        'grey',
                                        'grey',
                                    ]}
                                    minLabel="Not likely"
                                    maxLabel="Very likely"
                                    selectedOption={form.offerpadRating}
                                    onChange={value => updateField('offerpadRating', value)}
                                />
                            </div>
                            <div className="pt-2 align-self-center">
                                <div className="btn-wrapper pt-5">
                                    <Button
                                        id="post-inspection-continue-6530"
                                        large
                                        disabled={form.offerpadRating === null}
                                        onClick={() => validate('offerpadRating')}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>
                <WizardStep>
                    <div className="step-wrapper">
                        <div className="modal-sidebar mr-3">
                            <div className="text-center">
                                <img src={require('@assets/op-logo.svg')} style={{ width: '200px' }} className="logo" alt="" />
                            </div>
                        </div>
                        <div className="modal-main">
                            {
                                [10, 9].includes(form.offerpadRating) &&
                                <div className="highlight">
                                    Glad to hear things are going well so far. Is there anything you want to share with us? (optional)
                                </div>
                            }
                            {
                                [8, 7].includes(form.offerpadRating) &&
                                <div className="highlight">
                                    It seems like things could be going a bit better, can you tell us more? (optional)
                                </div>
                            }
                            {
                                [6, 5, 4, 3, 2, 1, 0].includes(form.offerpadRating) &&
                                <div className="highlight">
                                    It seems like things aren’t going well, can you tell us more? (optional)
                                </div>
                            }
                            <div className="pt-3">
                                <FormControl
                                    id='offerpadComments'
                                    name='offerpadComments'
                                    placeholder="Tell us more"
                                    value={form.offerpadComments}
                                    onChange={(e) =>
                                        updateForm({
                                            ...form, offerpadComments: e.target.value
                                        })}
                                >
                                    <textarea
                                        name="offerpadComments"
                                        rows={4}
                                    ></textarea>
                                </FormControl>
                                <div className="btn-wrapper pt-5 new-pull-right">
                                    <Button
                                        id="post-inspection-submit-form"
                                        large
                                        disabled={submitting}
                                        onClick={submitForm}
                                    >
                                        {(submitting ? 'Submitting...' : 'Submit survey')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardStep>

                {/*
                Feedback after form submission
                */}
                <WizardStep>
                    <div className="p-2">
                        <div className="survey-end">
                            {
                                formSucceeded &&
                                <h3>
                                    Thank you for taking our mini-survey. Your feedback is valuable and appreciated!
                                </h3>
                            }
                            {
                                formFailed &&
                                <h3>
                                    We encountered an issue while submitting your survey. Please try again later.
                                </h3>
                            }
                            <div className="d-flex justify-content-center">
                                <img src={require('@assets/illustrations/survey_finished_icon.svg')} />
                            </div>
                        </div>
                    </div>
                </WizardStep>
            </Wizard>
        </div>
    );
}

export default PostInspectionSurveyContent;
