import { FieldBinderFactory } from '@offerpad/concrete/lib/model';
import FormBinder from '@offerpad/concrete/lib/model/FormBinder';
import Validator from '@offerpad/concrete/lib/validation/Validator';
import * as rules from '@offerpad/concrete/lib/validation/rules';
import Fields from './fields';

export function create(binders: FieldBinderFactory): FormBinder {
    return new FormBinder({
        [Fields.OldPassword]: binders.string(
            Validator.create([
                rules.required('The current password field is required'),
            ])
        ),
        [Fields.NewPassword]: binders.string(
            Validator.create([
                rules.required('The new password field is required'),
                rules.password('The new password is not valid')
            ])
        )
    });
}
