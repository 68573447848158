import React, { useReducer, createContext, useContext } from 'react';
import { sidebarReducer, initialState, State, Dispatch } from './SidebarReducer';

const SidebarContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

type SidebarProviderProps = { children: React.ReactNode };

const SidebarProvider: React.FC<SidebarProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(sidebarReducer, initialState);
    const value = { state, dispatch };

    return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};

const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (context === undefined) {
        throw new Error('useSidebar must be used within an SidebarProvider');
    }
    return context;
};

export { useSidebar, SidebarContext, SidebarProvider };
