import React, { useEffect, useState } from "react";
import styles from './NoOffers.module.scss'
import { Button } from 'floorplan/components/v2/Button';
import * as rules from '@offerpad/concrete/lib/validation/rules';
import Validator from '@offerpad/concrete/lib/validation/Validator';
import { FormControl } from 'floorplan/components/v2/Form';
import { useUser } from '../../../context/user/UserContext';
import { updateInfo } from "../../../context/user/UserReducer";

const NoOffers = () => {
    const { state: user, dispatch } = useUser();
    const required = Validator.create([rules.required('Brokerage name is required')]);
    const [brokerageName, setBrokerageName] = useState<string>(user.brokerageName);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setBrokerageName(value);
    }

    useEffect(() => {
        setBrokerageName(user.brokerageName)
    }, [user.brokerageName])

    const onSubmit = () => {
        updateInfo(dispatch, { ...user, brokerageName: brokerageName, agent: true }).then((success) => {
            if (success) {
                window.location.href = `${process.env.RAZZLE_BUY_URL}/near`;
            }
        })
    }

    return <div className={styles.no_offers}>
        <h3 className={styles.header}>You do not have any properties you are buying.</h3>
        <div className={styles.browse_homes}>
            <p>{user.error && 'Something wrong happend, try again'}</p>
            <p>Add your Brokerage name to begin browsing homes and submitting offers for your buyers.</p>
            <FormControl
                onChange={(e) => onChange(e)}
                label='Brokerage Name'
                name='BrokerageName'
                id='BrokerageName'
                value={brokerageName}
                placeholder="Enter Brokerage Name"
                data-qa="brokerage-name"
            />
            <Button
                id='browse-homes'
                disabled={!required.validate(brokerageName).valid || user.loading}
                progress={user.loading}
                onClick={onSubmit}
                fullWidth
            >
                Browse Homes
            </Button>
        </div>
    </div>
}

export default NoOffers