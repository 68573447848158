import apiClient from '../../clients/apiClient'

const googleMapsEndpoint = () => {
    apiClient.defaults.baseURL = 'https://maps.googleapis.com/maps/api/';
    return apiClient;
};

export const getStreetView = async (normalizedAddress: string): Promise<string> => {
    if (normalizedAddress) {
        const response = await googleMapsEndpoint().get(`streetview/metadata?size=800x300&location=${normalizedAddress}&fov=80&key=AIzaSyCZ-Epxl_Hb3W6N2K6HlP1usXio6P3DeZk`);
        if (response.data.status === 'OK') {
            return `${apiClient.defaults.baseURL}streetview?size=800x300&location=${normalizedAddress}&fov=80&key=AIzaSyCZ-Epxl_Hb3W6N2K6HlP1usXio6P3DeZk`;
        }
    }
}
