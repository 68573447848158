import React, { useEffect, useRef } from 'react';
import SideBarItem from './SideBarItem';
import PerfectScrollbar from 'perfect-scrollbar';
import { useOffer } from '../../../context/offer/OfferContext';
import { useSidebar } from '../../../context/sidebar/SidebarContext';
import { displaySidebar } from '../../../context/sidebar/SidebarReducer';
import useScreen from '@offerpad/floorplan/dist/hooks/useScreen';
import Heading from '@offerpad/floorplan/dist/typography/Heading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUser } from '../../../context/user/UserContext';
import { useHistory, useParams } from 'react-router-dom';
import { getFirstTeamMember } from '../../Controls/resources/teamUtil';
import { path, RouteName } from '../../../routing/Routes';
import { openModal, useModal } from '@offerpad/concrete.react/lib/Modal';
import { Modals } from '../../Controls/ModalController/ModalController';
import styles from './SideBar.module.scss';
import '@assets/sass/offerpad/paper/plugins/_plugin-perfect-scrollbar.scss';

type SideBarProps = {
    backgroundColor?: 'white' | 'brown' | 'black';
    activeColor?: 'primary' | 'info' | 'success' | 'warning' | 'danger';
    sidebarLinksSell?: Array<any>;
    sidebarLinksBuy?: Array<any>;
    sidebarLinksBuyAgent?: Array<any>;
};

const BuySectionHeader = () => (
    <li>
        <div className={styles.nav__heading}>
            <Heading size={4} weight='bold'>
                Buy
            </Heading>
        </div>
    </li>
);

const SellSectionHeader = () => (
    <li>
        <div className={styles.nav__heading}>
            <Heading size={4} weight='bold'>
                Sell
            </Heading>
        </div>
    </li>
);

const SideBar: React.FC<SideBarProps> = props => {
    const {
        backgroundColor = 'white',
        activeColor = 'success',
        sidebarLinksSell = [],
        sidebarLinksBuy = [],
        sidebarLinksBuyAgent = [],
    } = props;

    const sidebarScrollArea = useRef(null);
    const history = useHistory();
    const {
        state: { offerState },
    } = useOffer();
    const { state: user } = useUser();
    const { state: sidebar, dispatch: sidebarDispatch } = useSidebar();
    const { keyType } = useParams<{ keyType: string; offerKey: string }>();
    const hasLink = !offerState.offerInProgress && !offerState.offerSent && !offerState.offerExpired;
    const screen = useScreen();
    const isTablet = screen.width < 991; // this is the breakpoint in paper
    const isBuyer = window.location.pathname.includes('/buyers/');

    useEffect(() => {
        if (sidebarScrollArea && offerState.thereIsOffer) {
            const ps = new PerfectScrollbar(sidebarScrollArea?.current);
            ps.update();
        }
    }, [sidebarScrollArea, offerState.thereIsOffer]);

    return (
        <div
            className={styles.sidebar}
            data-color={backgroundColor}
            style={{ transform: sidebar.showSidebar ? 'translate3d(0,0,0)' : '' }}
            data-active-color={activeColor}
        >
            <div className={styles.logo}>
                {isTablet && (
                    <FontAwesomeIcon
                        id='side-bar-times-logo'
                        icon={['far', 'times']}
                        className={`${styles.times_logo}`}
                        onClick={() => displaySidebar(sidebarDispatch, false)}
                    />
                )}
                <a id='side-bar-normal-logo' className='simple-text logo-normal text-center'
                    href='/home'
                >
                    <img src={require('@assets/op-logo.svg')} className='img-fluid'
                        alt=''
                    />
                </a>
            </div>
            <div className={styles.logo_line} />
            <div
                className={styles.dashboard_button}
                onClick={() => {
                    history.push(isBuyer ? path(RouteName.AgentHome) : path(RouteName.Login));
                }}
            >
                <FontAwesomeIcon className={styles.dashboard_button_icon} icon={'chevron-left'} />
                <p className={styles.dashboard_button_text}>Back to Dashboard</p>
            </div>
            <div className={styles.dashboard_line} />
            {(keyType === 'request' || keyType === 'offer') && (
                <ul className={styles.nav} ref={sidebarScrollArea}>
                    <SellSectionHeader />
                    {sidebarLinksSell
                        .filter(x => x.name !== 'Status' || hasLink)
                        .map(sidebarLink => (
                            <SideBarItem key={sidebarLink.name} name={sidebarLink.name}
                                path={sidebarLink.path}
                            />
                        ))}
                </ul>
            )}
            {isBuyer && (
                <ul className={styles.nav} ref={sidebarScrollArea}>
                    <BuySectionHeader />
                    {sidebarLinksBuyAgent
                        .filter(x => x.name !== 'Status' || hasLink)
                        .map(sidebarLink => (
                            <SideBarItem
                                key={sidebarLink.name}
                                name={sidebarLink.name}
                                path={sidebarLink.path}
                                absolutePath={sidebarLink.absolutePath}
                            />
                        ))}
                    <div className={styles.section_line} />
                    {sidebarLinksBuy
                        .filter(x => x.name !== 'Status' || hasLink)
                        .map(sidebarLink => (
                            <SideBarItem
                                key={sidebarLink.name}
                                name={sidebarLink.name}
                                path={sidebarLink.path}
                                absolutePath={sidebarLink.absolutePath}
                            />
                        ))}
                    {isTablet && <UserNav />}
                </ul>
            )}
            {!isBuyer && user.agent === 'False' && (
                <ul className={styles.nav} ref={sidebarScrollArea}>
                    <BuySectionHeader />
                    {sidebarLinksBuy
                        .filter(x => x.name !== 'Status' || hasLink)
                        .map(sidebarLink => (
                            <SideBarItem
                                key={sidebarLink.name}
                                name={sidebarLink.name}
                                path={sidebarLink.path}
                                absolutePath={sidebarLink.absolutePath}
                            />
                        ))}
                    {isTablet && <UserNav />}
                </ul>
            )}
        </div>
    );
};

const UserNav = () => {
    const { state: user } = useUser();
    const {
        state: { offerState },
    } = useOffer();
    const { dispatch: modalDispatch } = useModal();
    const teamMember = getFirstTeamMember(offerState);
    const showMessage = teamMember && teamMember.owner;
    const avatarUrl = user.firstName && user.lastName
        ? `https://ui-avatars.com/api/?bold=true&color=ec7625&background=ffffff&format=svg&name=${user.firstName}+${user.lastName}`
        : '';
    const history = useHistory();

    return (
        <>
            <hr style={{ margin: '1rem 1.5rem' }} />
            <li id='side-bar-goto-profile' onClick={() => history.push('/profile')}>
                <div className={styles.user_nav_item}>
                    <div className={'user-avatar sm-size'}>
                        <img src={avatarUrl} className='avatar'
                            alt=''
                        />
                    </div>
                    <p>Profile</p>
                </div>
            </li>
            {showMessage && (
                <li id='side-bar-alert-7128' onClick={() => openModal(modalDispatch, Modals.MESSAGING_MODAL)}>
                    <div className={styles.user_nav_item}>
                        <div className={styles.user_nav_item_envelope}>
                            <FontAwesomeIcon icon={['far', 'envelope']} className={'fa-2x'} />
                        </div>
                        <p>Message</p>
                    </div>
                </li>
            )}
        </>
    );
};

export default SideBar;
