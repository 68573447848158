import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import './Gallery.scss'

export const Gallery = ({
    photos,
    indexOnOpen,
    closeModal
}) => {
    const [currentIndex, setCurrentIndex] = useState(indexOnOpen);

    const filterIndex = (index) => {
        if (index < 0) {
            return 0;
        }
        if (index > photos.length - 1) {
            return photos.length - 1;
        }
        else { /* index in range */
            return index;
        }
    }

    return (
        <div className="gallery-container">
            {
                currentIndex > 0 &&
                <button
                    id="gallery-button-prev"
                    type="button"
                    className="gallery-button gallery-prev"
                    onClick={() => setCurrentIndex(filterIndex(currentIndex - 1))}
                    data-qa="gallery-prev"
                >
                    <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                </button>
            }
            {
                currentIndex < photos.length - 1 &&
                <button
                    id="gallery-button-next"
                    type="button"
                    className="gallery-button gallery-next"
                    onClick={() => setCurrentIndex(filterIndex(currentIndex + 1))}
                    data-qa="gallery-next"
                >
                    <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                </button>
            }
            <button
                id="gallery-button-close"
                type="button"
                className="gallery-button gallery-close"
                onClick={closeModal}
                data-qa="gallery-close"
            >
                <FontAwesomeIcon icon={['fas', 'times']} />
            </button>
            <div
                className="gallery-photo"
                style={{ backgroundImage: `url("${(photos[currentIndex] as any).preview}")` }}
            />
        </div>
    );
}
