import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { closeModal } from '@offerpad/concrete.react/lib/Modal';
import { useModal } from '@offerpad/concrete.react/lib/Modal';
import { Modal } from 'floorplan/components/v2/Modal'
import { Button } from 'floorplan/components/Button'
import EventBody from "./EventBody";
import './CalendarEventModal.scss';

export type EventModalContent = {
    title: string,
    illustrationPath: string,
    description: string
}

export type CalendarEventModalProps = {
    modalContent: EventModalContent;
}

const CalendarEventModal: React.FC<CalendarEventModalProps> = (props) => {
    const {title, illustrationPath, description} = props.modalContent;
    const { state: { isOpen }, dispatch: modalDispatch } = useModal();

    return (
        <Modal
        open={isOpen}
        onClose={() => closeModal(modalDispatch)}
        width={'700px'}
        hasFooter
    >
        <Modal.Header
            title={title}
            onClose={() => closeModal(modalDispatch)}
            border={false}
        />
        <Modal.Content>
            <EventBody
                illustrationPath={illustrationPath}
                description={description}
            />
        </Modal.Content>
        <Modal.Footer>
            <Button
                id="submit-offer-brokerage-name"
                data-qa="submit-offer-brokerage-name"
                onClick={() => closeModal(modalDispatch)}
            >
                Close
            </Button>
        </Modal.Footer>
    </Modal>
)
}

export default CalendarEventModal;