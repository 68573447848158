export enum Actions {
    REMOVE_NOTIFICATION = 'removeNotification',
    NOTIFY = 'notify',
    CLEAR = 'clear',
}

export type Action = { type: Actions; data?: any };
export type State = {
    notifications: Array<any>
    settings: {
        overlap: boolean,
        verticalAlign: string,
        horizontalAlign: string,
        timeout: 5000,
        closeOnClick: boolean,
        showClose: boolean
    },
};
export type Dispatch = (action: Action) => void;

export type Notification = {
    message: string,
    type: string,
    timestamp?: Date,
};

export const initialState: State = {
    notifications: [],
    settings: {
        overlap: false,
        verticalAlign: 'top',
        horizontalAlign: 'center',
        timeout: 5000,
        closeOnClick: true,
        showClose: true
    },
};

export const notificationsReducer = (state: State, action: Action) => {
    const notifications = [...state.notifications];
    switch (action.type) {
        case Actions.REMOVE_NOTIFICATION:
            notifications.splice(notifications.findIndex(n => n.timestamp === action.data), 1);
            return { ...state, notifications };
        case Actions.NOTIFY:
            action.data.timestamp = new Date();
            action.data.timestamp.setMilliseconds(action.data.timestamp.getMilliseconds() + state.notifications.length);
            notifications.push(action.data);
            return { ...state, notifications };
        case Actions.CLEAR:
            return { ...state, notifications: [] };
        default:
            return state;
    }
};

export const notify = (dispatch: Dispatch, notification: Notification) => dispatch({ type: Actions.NOTIFY, data: notification });
export const removeNotification = (dispatch: Dispatch, timestamp: Date) => dispatch(
    { type: Actions.REMOVE_NOTIFICATION, data: timestamp }
);
export const clear = (dispatch: Dispatch) => dispatch({ type: Actions.CLEAR });
