import * as DispositionService from '../../services/DispositionService/disposition.service'
import { Disposition, DispositionOffer, DispositionStatus, DispositionSummary } from '../../services/DispositionService/types';

export enum Actions {
    GET_DISPOSITION_COMPLETE = 'getDispositionComplete',
    GET_DISPOSITIONS_COMPLETE = 'getDispositionsComplete',
    COUNTER_OFFER_COMPLETE = 'counterOfferComplete',
    ACCEPT_OFFER_COMPLETE = 'acceptCounterOffer',
    DECLINE_OFFER_COMLETE = 'declineOfferComplete',
    SET_NEW_COUNTER_OFFER = 'setNewCounterOffer',
    REQUEST_BEGIN = 'requestBegin',
    REQUEST_ERROR = 'requestError',
    CLEAR_ERROR = 'clearError'
}
export type Action = { type: Actions; data?: any };
export type State = {
    dispositionOffer: Disposition,
    dispositions: Array<DispositionSummary>
    loading: boolean,
    error: boolean,
    exception: any,
    newCounterOffer?: Partial<DispositionOffer>;
}

export type Dispatch = (action: Action) => void;

export const initialState: State = {
    dispositionOffer: null,
    dispositions: null,
    loading: false,
    error: false,
    exception: null,
    newCounterOffer: {}
}

export const DispositionReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case Actions.REQUEST_BEGIN:
            return { ...state, loading: true, error: false }
        case Actions.GET_DISPOSITION_COMPLETE:
            if (action.data.status) {
                action.data.status = action.data.status?.replace(/ /g, '')?.toLowerCase();
            }
            return { ...state, loading: false, dispositionOffer: action.data, error: false };
        case Actions.GET_DISPOSITIONS_COMPLETE:
            // when there are no offers, we get a 204 (no content), and .data is actually empty string ''. We want an array
            action.data = action.data || [];
            return { ...state, loading: false, dispositions: action.data, error: false };
        case Actions.ACCEPT_OFFER_COMPLETE:
            return {
                ...state, loading: false,
                dispositionOffer: {
                    ...state.dispositionOffer,
                    status: DispositionStatus.ACCEPTED
                },
                error: false
            };
        case Actions.COUNTER_OFFER_COMPLETE:
            const newCounterOffer = state.newCounterOffer as DispositionOffer;
            newCounterOffer.sentBy = 'Buyer';
            return {
                ...state, loading: false,
                dispositionOffer: {
                    ...state.dispositionOffer,
                    portalDispositionOfferDetails: [...state.dispositionOffer.portalDispositionOfferDetails, newCounterOffer],
                    status: DispositionStatus.BUYER_COUNTER,
                },
                newCounterOffer: {},
                error: false
            };
        case Actions.DECLINE_OFFER_COMLETE:
            return {
                ...state, loading: false, dispositionOffer: {
                    ...state.dispositionOffer,
                    status: DispositionStatus.DECLINED
                }, error: false
            };
        case Actions.REQUEST_ERROR:
            return { ...state, loading: false, error: true, exception: action.data };
        case Actions.SET_NEW_COUNTER_OFFER:
            return { ...state, loading: false, error: true, newCounterOffer: action.data };
        case Actions.CLEAR_ERROR:
            return { ...state, loading: false, error: false, exception: null, };
        default:
            return state;
    }
}

export const getDisposition = async (dispatch: Dispatch, offerId: string) => {
    dispatch({ type: Actions.REQUEST_BEGIN });
    try {
        const response = await DispositionService.getDisposition(offerId);
        dispatch({ type: Actions.GET_DISPOSITION_COMPLETE, data: response.data })
    } catch (e) {
        dispatch({ type: Actions.REQUEST_ERROR });
    }
}

export const getDispositions = async (dispatch: Dispatch) => {
    dispatch({ type: Actions.REQUEST_BEGIN });
    try {
        const response = await DispositionService.getDispositions();
        dispatch({ type: Actions.GET_DISPOSITIONS_COMPLETE, data: response.data })
        return response.data;
    } catch (e) {
        dispatch({ type: Actions.REQUEST_ERROR });
    }
}

export const counterOffer = async (dispatch: Dispatch, offerId: string, offer: DispositionOffer) => {
    dispatch({ type: Actions.REQUEST_BEGIN });
    try {
        const response = await DispositionService.counterOffer(offerId, offer);
        dispatch({ type: Actions.COUNTER_OFFER_COMPLETE })
        return true;
    } catch (e) {
        dispatch({ type: Actions.REQUEST_ERROR });
        return false;
    }
}

export const acceptOffer = async (dispatch: Dispatch, offerId: string) => {
    dispatch({ type: Actions.REQUEST_BEGIN });
    try {
        const response = await DispositionService.acceptOffer(offerId);
        dispatch({ type: Actions.ACCEPT_OFFER_COMPLETE, data: response.data })
        return true;
    } catch (e) {
        dispatch({ type: Actions.REQUEST_ERROR, data: e });
        return false;
    }
}

export const declineOffer = async (dispatch: Dispatch, offerId: string, message: string) => {
    dispatch({ type: Actions.REQUEST_BEGIN });
    try {
        const response = await DispositionService.declineOffer(offerId, message);
        dispatch({ type: Actions.DECLINE_OFFER_COMLETE, data: response.data })
        return true;
    } catch (e) {
        dispatch({ type: Actions.REQUEST_ERROR });
        return false;
    }
}

export const setNewCounterOffer = (dispatch: Dispatch, offer: Partial<DispositionOffer>) => dispatch({ type: Actions.SET_NEW_COUNTER_OFFER, data: offer })