import React, { useState } from "react";
import './DashboardWhatsNext.scss';
import Heading from '@offerpad/floorplan/dist/typography/Heading'
import { NavTabs } from 'floorplan/components/NavTabs';
import { Faq } from "../ProgressBar/progressStepsSeller";
import { LogoHouse } from 'floorplan/components/Logo';
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FaqList from 'floorplan/components/FaqList/FaqList';

type DashboardWhatsNextProps = {
    whatsNextHeading: string;
    whatsNextCopy: string;
    faqs: Array<Faq>;
}

const DashboardWhatsNext: React.FC<DashboardWhatsNextProps> = ({ whatsNextHeading, whatsNextCopy, faqs }) => {

    const [whatsNextActive, setWhatsNextActive] = useState(true);
    const [faqsActive, setFaqsActive] = useState(false);

    const activateTab = (tab) => {
        if (tab == 'whatsNext') {
            setFaqsActive(false);
            setWhatsNextActive(true);
        } else {
            setWhatsNextActive(false);
            setFaqsActive(true);
        }
    };

    const tabClicked = (e, tab) => {
        e.preventDefault();
        activateTab(tab);
    }

    return <div className='dashboard-whatsnext-wrapper op-card'>
        <div className="whatsNext-heading ">
            <NavTabs key="0">
                <NavTabs.Item id="dashboard-whats-next" href="#" className={whatsNextActive ? 'is-active' : ''} onClick={(e) => { tabClicked(e, 'whatsNext') }}>
                    <Heading size={4} weight="bold">
                        {whatsNextHeading != '' ? whatsNextHeading : `What's Next?`}
                    </Heading>
                </NavTabs.Item>
                {
                    faqs && faqs.length > 0 &&
                    <NavTabs.Item id="dashboard-whats-next-faqs" href="#" className={faqsActive ? 'is-active' : ''} onClick={(e) => { tabClicked(e, 'faqs') }}>
                        <Heading size={4} weight="bold">
                            FAQ's
                        </Heading>
                    </NavTabs.Item>
                }

            </NavTabs>
            <div className={whatsNextActive ? 'nav-content is-active' : 'nav-content'}>
                {whatsNextCopy &&
                    <div className="whatsnext-content">
                        <span className="offerpad-icon"><LogoHouse title="Offerpad House Icon" titleId="preloader-icon" /></span>
                        <p dangerouslySetInnerHTML={{ __html: whatsNextCopy }} />
                    </div>
                }
            </div>
            <div className={faqsActive ? 'nav-content is-active' : 'nav-content'}>
                {
                    faqs && faqs.length > 0 &&
                    <ul className="faqs-list">
                        {
                            faqs.map((faq, i) => {
                                if (faq.answer) {
                                    return <FaqList.Item id={`faq-${i}`} key={i} question={faq.faq} answer={faq.answer} />
                                }

                                return <li key={i}>
                                    <a id={`faq-${i}`} href={faq.link} target="_blank">
                                        {faq.faq}
                                        <FontAwesomeIcon className="move-icon" icon={faPlus} />
                                    </a>
                                </li>
                            })
                        }
                    </ul>
                }
            </div>
        </div>
    </div>
}

export default DashboardWhatsNext;