import { useEffect } from 'react';

const Home = () => {
    useEffect(() => {
        window.location.href = `${process.env.RAZZLE_CONNECT_URL}/sell/dashboard`
    }, []);

    return null;
}

export default Home;
