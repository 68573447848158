import React, { FunctionComponent, useState } from 'react';
import useScreen, { ScreenMode } from '@offerpad/floorplan/dist/hooks/useScreen';
import Heading from '@offerpad/floorplan/dist/typography/Heading';
import classes from '../../../assets/sass/offerpad/autform.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import SliderControl from './SliderControl';

interface ILeftPanel {
    title: string;
    subtitle: string;
    highlight?: string;
}

const content = [
    {
        id: '0',
        icon: ['far', 'chevron-double-right'],
        text: 'Check the progress and status of your transaction',
    },
    {
        id: 'view',
        icon: ['far', 'eye'],
        text: 'View your offer',
    },
    {
        id: 'upload',
        icon: ['far', 'upload'],
        text: 'Upload and view your photos and documents',
    },
    {
        id: '3',
        icon: ['far', 'calendar'],
        text: 'Schedule appointments',
    },
    {
        id: '4',
        icon: ['far', 'users'],
        text: 'Access your team and important contact information',
    },
    {
        id: '5',
        icon: ['far', 'star'],
        text: 'Save your favorite homes',
    },
];

// 

const swipeWidth = 48;

const AuthLeftPanel: FunctionComponent<ILeftPanel> = (props) => {
    const { title, subtitle, highlight } = props;
    const screen = useScreen();
    const [index, setIndex] = useState(0);
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const [moveFlag, setMoveFlag] = useState(false);

    function handleTouchStart(e) {
        setTouchStart(e.targetTouches[0].clientX);
    }

    function handleTouchMove(e) {
        setMoveFlag(true);
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchEnd() {
        if (!moveFlag) return;
        if (Math.abs(touchEnd - touchStart) >= swipeWidth) {
            setIndex(
                (index) => (touchStart -touchEnd > swipeWidth ? ++index : --index + content.length) % content.length
            );
        }
        setMoveFlag(false);
    }

    const getClassName = (highlightValue: string) => {
        if (screen.mode === ScreenMode.Mobile) {
            return classes['c-authform__infopanel__option'];
        }
        switch (highlight) {
            case 'upload':
            case 'view':
                return cx(
                    classes['c-authform__infopanel__option'],
                    highlightValue === highlight ? classes.highlight : ''
                );
            default:
                return classes['c-authform__infopanel__option'];
        }
    };

    const generateMobileContent = function (): Array<unknown> {
        return content.map((object) => (
            <div
                key={object.id}
                className={cx(classes['c-authform__infopanel__option'], classes['option-component'])}
                style={{ height: '3.25rem' }}
            >
                <div>
                    <FontAwesomeIcon icon={object.icon as any} style={{ width: '1.0625rem' }} />
                </div>
                <div>{object.text}</div>
            </div>
        ));
    };

    return (
        <section
            className={cx(classes['c-authform__infopanel'], ' u-teal-darker')}
            onTouchStart={() => handleTouchStart(event)}
            onTouchMove={() => handleTouchMove(event)}
            onTouchEnd={handleTouchEnd}
        >
            <header>
                <Heading size={3} color="white" weight="bolder">
                    {title}
                </Heading>
                <Heading size={4} color="white">
                    {subtitle}
                </Heading>
            </header>
            <div>
                {screen.mode === ScreenMode.Mobile && (
                    <React.Fragment>
                        {generateMobileContent()[index]}
                        <div style={{ marginTop: '1.5rem' }}></div>
                    </React.Fragment>
                )}
                {screen.mode === ScreenMode.Mobile && (
                    <SliderControl
                        states={content.length}
                        radius={0.1875}
                        color="white"
                        selectedColor="#eb7724"
                        currentState={index}
                        setState={setIndex}
                        width="40%"
                    />
                )}
                {screen.mode >= ScreenMode.Tablet &&
                    content.map((object) => (
                        <div key={object.id} className={getClassName(object.id)}>
                            <FontAwesomeIcon icon={object.icon as any} style={{ width: '1.0625rem' }} /> {object.text}
                        </div>
                    ))}
            </div>
        </section>
    );
};

export default AuthLeftPanel;
