import React from "react";
import { useDisposition } from "../../../../context/disposition/DispositionContext";
import { DispositionStatus } from "../../../../services/DispositionService/types";
import { Link, useParams } from "react-router-dom";
import { Card } from 'floorplan/components/Card';
import styles from './WithdrawnRejectedCard.module.scss'

type WithdrawnRejectedCardProps = {
}

const WithdrawnRejectedCard: React.FC<WithdrawnRejectedCardProps> = () => {
    const { offerKey } = useParams<{ keyType: string; offerKey: string }>();
    const { state: { dispositionOffer } } = useDisposition();
    const withdrawn = dispositionOffer.declinedBy === 'Buyer'

    return (
        <Card className={styles.withdrawn_rejected_card}>
            <div className={styles.card_content}>
                <div className={styles.heading_section} >
                    <h1>
                        {`Your offer has been ${withdrawn ? 'withdrawn' : 'declined'}.`}
                    </h1>
                </div>
                <hr />
                <div className={styles.message_section} >
                    <h1>Message</h1>
                    <p>{dispositionOffer.declinedNotes || 'N/A'}</p>
                </div>
                <hr />
                <div className={styles.status_section} >
                    <h1>Status</h1>
                    <Link to={`/buyers/${offerKey}/offer-activity`}>
                        {`View ${withdrawn ? 'withdrawn' : 'declined'} offer`}
                    </Link>
                </div>

            </div>
        </Card>
    );
}

export default WithdrawnRejectedCard;