import Cookies from 'js-cookie'
const TOKEN_KEY = 'authToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const EXPIRES_IN_KEY = 'expiresIn';

Cookies.withAttributes({ sameSite: 'strict' })

const getDomainWithoutSubdomain = url => {
    const urlParts = new URL(url).hostname.split('.')

    return urlParts
        .slice(0)
        .slice(-(urlParts.length === 4 ? 3 : 2))
        .join('.')
}

const TokenService = {
    getToken() {
        return Cookies.get(TOKEN_KEY, { domain: getDomainWithoutSubdomain(window.location.href) });
    },

    saveToken(accessToken) {
        Cookies.set(TOKEN_KEY, accessToken, { domain: getDomainWithoutSubdomain(window.location.href) });
    },

    removeToken() {
        Cookies.remove(TOKEN_KEY, { domain: getDomainWithoutSubdomain(window.location.href) });
    },

    getRefreshToken() {
        return Cookies.get(REFRESH_TOKEN_KEY, { domain: getDomainWithoutSubdomain(window.location.href) });
    },

    saveRefreshToken(refreshToken) {
        Cookies.set(REFRESH_TOKEN_KEY, refreshToken, { domain: getDomainWithoutSubdomain(window.location.href) });
    },

    removeRefreshToken() {
        Cookies.remove(REFRESH_TOKEN_KEY, { domain: getDomainWithoutSubdomain(window.location.href) });
    },

    getExpiresIn() {
        return Cookies.get(EXPIRES_IN_KEY, { domain: getDomainWithoutSubdomain(window.location.href) });
    },

    saveExpiresIn(expiresIn) {
        Cookies.set(EXPIRES_IN_KEY, expiresIn, { domain: getDomainWithoutSubdomain(window.location.href) });
    },

    removeExpiresIn() {
        Cookies.remove(EXPIRES_IN_KEY, { domain: getDomainWithoutSubdomain(window.location.href) });
    },

    clearAll() {
        this.removeToken();
        this.removeRefreshToken();
        this.removeExpiresIn();
    },
};

export default TokenService;
