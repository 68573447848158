import React from 'react';
import Routes from './routing/Routes';
import { AuthenticationProvider } from './context/authentication/AuthenticationContext';
import { OfferProvider } from './context/offer/OfferContext';
import { SidebarProvider } from './context/sidebar/SidebarContext';
import { UserProvider } from './context/user/UserContext';
import { NotificationsProvider } from './context/notifications/NotificationsContext';
import Notifications from './components/UIComponents/Notifications/Notifications';
import { RequestsProvider } from './context/request/RequestsContext';
import { DocumentsProvider } from './context/documents/DocumentsContext';
import { AppInsightsProvider } from './context/AppInsightsContext';
import { UploadProvider } from '@offerpad/concrete.react/lib/File/UploadContext';
import { ContractsProvider } from './context/contracts/ContractsContext';
import { ModalProvider } from '@offerpad/concrete.react/lib/Modal';
import { Lab, LabService, BrowserLabStorage } from '@offerpad/concrete.react/lib/Lab';
import { Experiments } from './experiments';
import { DispositionProvider } from './context/disposition/DispositionContext';
import ModalController from './components/Controls/ModalController/ModalController';
import './App.scss';
import './fontAwesomeSetup.ts';

const labService = new LabService(
    new BrowserLabStorage(),
    process.env.RAZZLE_API_MANAGEMENT_URL,
    process.env.NODE_ENV !== 'production'
);

const App = () => (
    <UserProvider>
        <AuthenticationProvider>
            <OfferProvider>
                <UploadProvider>
                    <ModalProvider>
                        <SidebarProvider>
                            <AppInsightsProvider>
                                <NotificationsProvider>
                                    <RequestsProvider>
                                        <ContractsProvider>
                                            <DocumentsProvider>
                                                <DispositionProvider>
                                                    <Lab service={labService} experiments={Experiments}>
                                                        <Notifications />
                                                        <ModalController />
                                                        <Routes />
                                                    </Lab>
                                                </DispositionProvider>
                                            </DocumentsProvider>
                                        </ContractsProvider>
                                    </RequestsProvider>
                                </NotificationsProvider>
                            </AppInsightsProvider>
                        </SidebarProvider>
                    </ModalProvider>
                </UploadProvider>
            </OfferProvider>
        </AuthenticationProvider>
    </UserProvider>
);

export default App;
