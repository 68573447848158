import React, { useEffect } from 'react';
import './DashboardTodos.scss';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useOffer } from "../../../../context/offer/OfferContext";
import buildSellerCheckList from "../../resources/checklistUtilSeller";
import { useContracts } from '../../../../context/contracts/ContractsContext';
import { useDocuments } from '../../../../context/documents/DocumentsContext';
import { getContracts } from '../../../../context/contracts/ContractsReducer';
import TokenService from "../../../../services/token.service";
import buildBuyerCheckList from "../../resources/checklistUtilBuyer";
import { useDisposition } from "../../../../context/disposition/DispositionContext";
import { Button } from 'floorplan/components/v2/Button';

const DashboardTodos = () => {
    const { state: { offerState } } = useOffer();
    const { state: { dispositionOffer } } = useDisposition();
    const { state: { disclosureInfo, loaded }, dispatch: contractsDispath } = useContracts();
    const { state: { documents } } = useDocuments();
    const history = useHistory();
    const isLoggedIn = TokenService.getToken();
    const isBuyer = window.location.pathname.includes('/buyers/');

    useEffect(() => {
        if (offerState?.thereIsOffer && isLoggedIn) {
            getContracts(contractsDispath, offerState.acquisitionId, offerState.market)
        }
    }, [offerState.thereIsOffer, isLoggedIn]);

    useEffect(() => {
        if (isBuyer) {
            checkList = buildBuyerCheckList(dispositionOffer);
        }
    }, [dispositionOffer])

    let checkList;
    if (isBuyer) {
        checkList = buildBuyerCheckList(dispositionOffer);
    }
    else {
        checkList = buildSellerCheckList(offerState, disclosureInfo, documents);
    }

    const loadLink = (item, isFinish) => {
        if (!item.buttonUrl) return <div className="noLink">{item.title}</div>;
        if (item.buttonUrl.includes('http')) return <a id="dashboard-todos-finish-anchor" target="_blank" className={isFinish ? 'finish-btn' : ''} href={item.buttonUrl}>{item.title}</a>
        return <Link id={`dashboard-todos-item-0784-${item.id}`} to={item.buttonUrl} className={isFinish ? 'finish-btn' : ''}>{item.title}</Link>
    }

    if (!isBuyer && (!offerState.thereIsOffer || !loaded)) {
        return null;
    }

    return <div className="op-card todos">
        <div>
            <div className="todos-title">Your To-Dos</div>
            <p>Here are your current action items:</p>
        </div>
        {
            checkList === null ||
                checkList.current === null ||
                checkList.current.length == 0 ?
                <div className="placeholder">
                    <img src={require('@assets/no-todos.svg')} />
                    <p>You do not have any current tasks assigned.</p>
                </div>
                :
                checkList.current.map((item, i) => {
                    if (i > 2) return;
                    return (
                        <div key={item.id} className="todo-item">
                            <div className="noLink">{item.title}</div>
                            {item.buttonUrl && !item.buttonUrl?.includes('/your-team') && loadLink({ ...item, title: 'Start' }, true)}
                        </div>
                    )
                })
        }
        {
            !isBuyer &&
            <Button ghost id="dashboard-to-dos-view-all" className="view-btn" onClick={() => history.push('./checklist')}>View All To-Dos</Button>
        }
    </div>

}


export default DashboardTodos;
