import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env['RAZZLE_INSTRUMENTATION_KEY'],
        enableAutoRouteTracking: true,
    },
});

appInsights.loadAppInsights();
appInsights.trackPageView();
appInsights.addTelemetryInitializer((telemetryItem) => {
    telemetryItem.tags['ai.cloud.role'] = 'Portal';
});

export default appInsights;