import * as React from 'react';
import Home from '../components/Views/Home';
import { Switch, generatePath, BrowserRouter } from 'react-router-dom';
import AuthLayout from '../components/Views/Layout/AuthLayout';
import AuthLayoutLogin from '../components/Views/Layout/AuthLayoutLogin';
import DashboardLayout from '../components/Views/Layout/DashboardLayout';
import SurveyLayout from '../components/Views/Layout/SurveyLayout';
import ChangePassword from '../components/Views/ChangePassword/ChangePassword';
import Login from '../components/Views/Login/Login';
import Register from '../components/Views/Register/Register';
import NotFound from '../components/Views/NotFound/NotFound';
import ForgotPassword from '../components/Views/ForgotPassword/ForgotPassword';
import ResetPassword from '../components/Views/ResetPassword/ResetPassword';
import Profile from '../components/Views/Profile/Profile';
import Status from '../components/Views/Status';
import Checklist from '../components/Views/Checklist';
import { Helmet } from "react-helmet";
import { Gate } from './Gate';
import PostInspectionSurveyPage from '../components/Views/Surveys/PostInspectionSurveyPage';
import ExitSurveyPage from '../components/Views/Surveys/ExitSurveyPage';
import ResendEmailVerification from '../components/Views/ResendEmailVerification/ResendEmailVerification';
import Calendar from '../components/Views/Calendar';
import VerifyEmail from '../components/Views/VerifyEmail/VerifyEmail';
import Dashboard from '../components/Views/Dashboard';
import Documents from '../components/Views/Documents';
import YourTeam from '../components/Views/YourTeam'
import UploadPhotos from '../components/Views/UploadPhotos/UploadPhotos'
import UtilitiesFormPage from '../components/Views/Surveys/UtilitiesFormPage';
import HomeLayout from '../components/Views/Layout/HomeLayout';
import OfferActivity from '../components/Views/OfferActivity';
import BuyersDocuments from '../components/Views/BuyersDocuments';
import BuyersTodos from '../components/Views/BuyersTodos';
import AgentHome from 'src/components/Views/Home/AgentHome';

export enum RouteName {
    Base,
    Login,
    LoginWithKey,
    Registration,
    ForgotPassword,
    ResetPassword,
    ChangePassword,
    ResendEmailVerification,
    VerifyEmail,
    Home,
    AgentHome,
    NotFound,
    Profile,
    PhotoUploads,
    Todo,
    Calendar,
    Team,
    Documents,
    Status,
    Dashboard,
    SurveyPostInspection,
    SurveyClosed,
    FormUtilities,
    LoginWithEmail,
    OfferActivity,
    BuyersDocuments,
    BuyersOverview,
    BuyersTodos
}

export enum RouteLayout {
    Auth,
    Dashboard,
    Survey
}

export interface RouteEntry {
    name: RouteName;
    path: string;
    Component: React.ComponentType;
    Layout: typeof AuthLayout | typeof DashboardLayout | typeof SurveyLayout;
    protected: boolean;
    meta: { title: string }
}

const routes: RouteEntry[] = [
    //#region AUTHENTICATION LAYOUT ROUTES
    {
        name: RouteName.Base,
        path: '/',
        Component: () => <>Test</>,
        Layout: AuthLayout,
        protected: false,
        meta: { title: 'Offerpad Portal' }
    },
    {
        name: RouteName.Login,
        path: '/login',
        Component: Login,
        Layout: AuthLayoutLogin,
        protected: false,
        meta: { title: 'Offerpad Portal | Login' }
    },
    {
        name: RouteName.LoginWithKey,
        path: '/login/:flag/:key',
        Component: Login,
        Layout: AuthLayoutLogin,
        protected: false,
        meta: { title: 'Offerpad Portal | Login' }
    },
    {
        name: RouteName.Registration,
        path: '/register/invite',
        Component: Register,
        Layout: AuthLayoutLogin,
        protected: false,
        meta: { title: 'Offerpad Portal | Register' }
    },
    {
        name: RouteName.ForgotPassword,
        path: '/forgot-password',
        Component: ForgotPassword,
        Layout: AuthLayout,
        protected: false,
        meta: { title: 'Offerpad Portal | Forgot Password' }
    },
    {
        name: RouteName.ResetPassword,
        path: '/reset-password',
        Component: ResetPassword,
        Layout: AuthLayout,
        protected: false,
        meta: { title: 'Offerpad Portal | Reset Password' }
    },
    {
        name: RouteName.ChangePassword,
        path: '/change-password',
        Component: ChangePassword,
        Layout: AuthLayout,
        protected: false,
        meta: { title: 'Offerpad Portal | Change Password' }
    },
    {
        name: RouteName.ResendEmailVerification,
        path: '/resend-email-verification',
        Component: ResendEmailVerification,
        Layout: AuthLayout,
        protected: true,
        meta: { title: 'Offerpad Portal | Resend Email Verification' }
    },
    {
        name: RouteName.VerifyEmail,
        path: '/verify-email',
        Component: VerifyEmail,
        Layout: AuthLayout,
        protected: false,
        meta: { title: 'Offerpad Portal | Verify Email' }
    },
    {
        name: RouteName.Home,
        path: '/home',
        Component: Home,
        Layout: HomeLayout,
        protected: true,
        meta: { title: 'Offerpad Portal | Select Property' }
    },
    {
        name: RouteName.AgentHome,
        path: '/agent-home',
        Component: AgentHome,
        Layout: HomeLayout,
        protected: true,
        meta: { title: 'Offerpad Portal | Select Property' }
    },
    {
        name: RouteName.Profile,
        path: '/profile',
        Component: Profile,
        Layout: AuthLayout,
        protected: true,
        meta: { title: 'Offerpad Portal | Account Settings' }
    },
    //#endregion AUTHENTICATION LAYOUT ROUTES
    //#region BUYERS AGENT DASHBOARD LAYOUT ROUTES
    {
        name: RouteName.OfferActivity,
        path: '/buyers/:offerKey/offer-activity',
        Component: OfferActivity,
        Layout: DashboardLayout,
        protected: true,
        meta: { title: 'Offerpad Portal | Offer Activity' }
    },
    {
        name: RouteName.BuyersDocuments,
        path: '/buyers/:offerKey/documents',
        Component: BuyersDocuments,
        Layout: DashboardLayout,
        protected: true,
        meta: { title: 'Offerpad Portal | Documents' }
    },
    {
        name: RouteName.BuyersOverview,
        path: '/buyers/:offerKey/overview',
        Component: Dashboard,
        Layout: DashboardLayout,
        protected: true,
        meta: { title: 'Offerpad Portal | Overview' }
    },
    {
        name: RouteName.BuyersTodos,
        path: '/buyers/:offerKey/to-dos',
        Component: BuyersTodos,
        Layout: DashboardLayout,
        protected: true,
        meta: { title: 'Offerpad Portal | To-Dos' }
    },
    //#endregion BUYERS AGENT DASHBOARD LAYOUT ROUTES
    //#region DASHBOARD LAYOUT ROUTES
    {
        name: RouteName.PhotoUploads,
        path: '/:keyType/:offerKey/upload-photos',
        Component: UploadPhotos,
        Layout: DashboardLayout,
        protected: true,
        meta: { title: 'Offerpad Portal | Upload Photos' }
    },
    {
        name: RouteName.Todo,
        path: '/:keyType/:offerKey/checklist',
        Component: Checklist,
        Layout: DashboardLayout,
        protected: true,
        meta: { title: 'Offerpad Portal | Your Checklist' }
    },
    {
        name: RouteName.Calendar,
        path: '/:keyType/:offerKey/calendar',
        Component: Calendar,
        Layout: DashboardLayout,
        protected: true,
        meta: { title: 'Offerpad Portal | Calendar' }
    },
    {
        name: RouteName.Team,
        path: '/:keyType/:offerKey/your-team',
        Component: YourTeam,
        Layout: DashboardLayout,
        protected: true,
        meta: { title: 'Offerpad Portal | Your Team' }
    },
    {
        name: RouteName.Documents,
        path: '/:keyType/:offerKey/documents',
        Component: Documents,
        Layout: DashboardLayout,
        protected: true,
        meta: { title: 'Offerpad Portal | Your Documents' }
    },
    //Status component redirects to Dashboard
    //This is a temporary fix, to avoid any errors while the Open offer in Portal link is made on helix
    {
        name: RouteName.Status,
        path: '/:keyType/:offerKey/status',
        Component: Status,
        Layout: DashboardLayout,
        protected: true,
        meta: { title: 'Offerpad Portal | Your Dashboard' }
    },
    {
        name: RouteName.Dashboard,
        path: '/:keyType/:offerKey/dashboard',
        Component: Dashboard,
        Layout: DashboardLayout,
        protected: true,
        meta: { title: 'Offerpad Portal | Your Dashboard' }
    },
    //#endregion DASHBOARD LAYOUT ROUTES
    //#region SURVEY LAYOUT ROUTES
    {
        name: RouteName.FormUtilities,
        path: '/:keyType/:offerKey/form/utilities',
        Component: UtilitiesFormPage,
        Layout: SurveyLayout,
        protected: false,
        meta: { title: 'Offerpad Portal | Utilities' }
    },
    {
        name: RouteName.SurveyPostInspection,
        path: '/:keyType/:offerKey/survey/post-inspection',
        Component: PostInspectionSurveyPage,
        Layout: SurveyLayout,
        protected: false,
        meta: { title: 'Offerpad Portal | Post Inspection Survey' }
    },
    {
        name: RouteName.SurveyClosed,
        path: '/:keyType/:offerKey/survey/closed',
        Component: ExitSurveyPage,
        Layout: SurveyLayout,
        protected: false,
        meta: { title: 'Offerpad Portal | Closed Survey' }
    },
    //#endregion SURVEY LAYOUT ROUTES
    {
        name: RouteName.NotFound,
        path: '*',
        Component: NotFound,
        Layout: AuthLayout,
        protected: false,
        meta: { title: 'Offerpad Portal | Page Not Found' }
    }
];

const Routes = () => {
    return <BrowserRouter>
        <Switch>
            {routes.map(route =>
                <Gate key={route.name} exact path={route.path} protected={route.protected}>
                    <route.Layout>
                        <Helmet>
                            <title>{route.meta.title}</title>
                        </Helmet>
                        <route.Component />
                    </route.Layout>
                </Gate>
            )}
        </Switch>
    </BrowserRouter>
}

export const path = (name: RouteName, params?: Record<string, string | number | boolean>): string => {
    const route = routes.find(r => r.name === name);
    if (route != null) {
        try {
            const result = generatePath(route.path, params);

            if (result != null) {
                return result;
            }
        } catch {
            // tslint:disable-line no-empty
        }
    }

    return routes.find(r => r.name === RouteName.NotFound)?.path;
}

export default Routes;