import { AuthService } from '../../../services/AuthService/auth.service';
import TokenService from '../../../services/token.service';
import { DefaultValueBinder, DefaultFieldBinderFactory } from '@offerpad/concrete/lib/model';
import { ValidationResult } from '@offerpad/concrete/lib/validation/Validator';
import Alert from '../../UIComponents/Alert';
import * as binder from './binder';
import Button from '../../UIComponents/Button';
import FormGroupInput from "../../UIComponents/Inputs/FormGroupInput";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useNotifications } from '../../../context/notifications/NotificationsContext';
import { notify } from '../../../context/notifications/NotificationsReducer';
import { useAppInsights } from '../../../context/AppInsightsContext';
import { path, RouteName } from '../../../routing/Routes';
import { Button as FloorPlanButton } from 'floorplan/components/Button';
import Modal from 'floorplan/components/Modal/Modal';

const ResetPassword = () => {
    const history = useHistory();
    const appInsights = useAppInsights();
    const valueBinder = new DefaultValueBinder();
    const fieldBinderFactory = new DefaultFieldBinderFactory(valueBinder);
    const form = binder.create(fieldBinderFactory);

    const [errors, setErrors] = useState<Record<string, string>>({})
    const [resetPassErr, setResetPassErr] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get('email');
    const code = queryParams.get('code');
    const ref = queryParams.get('ref');
    const { dispatch: notificationsDispatch } = useNotifications();
    const [redirectModal, setRedirectModal] = useState<boolean>(false);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const field = form.fields[e.target.name];
        const value = e.target.value;
        const result: ValidationResult = field.validator.validate(value);
        setErrors({ ...errors, [e.target.name]: result.message })
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const model = form.bind(e.target as HTMLFormElement);
        if (model.valid) {
            const password = model.fields["Password"].value;

            let alertMessage = `There was a problem while attempting to reset your password.`;

            try {
                setIsSubmitting(true);
                let response = await AuthService.resetPassword({ 'code': code, 'email': email, 'newPassword': password });
                if (response.status === 200) {
                    if (ref && ref === 'buy') {
                        setRedirectModal(true);
                    } else {
                        notify(notificationsDispatch, { 'message': 'Your password was successfully updated. Please login', 'type': 'success' });
                        history.push(path(RouteName.Login));
                    }
                }
            } catch (error) {
                const errorMessage = error.message;
                appInsights.trackTrace({ message: errorMessage });
                setResetPassErr(alertMessage);
            } finally {
                setIsSubmitting(false);
            }
        } else {
            let errors = {};
            for (let field in model.fields) {
                const validation = model.fields[field].validation;
                errors[field] = validation.message;
            }
            setErrors(errors);
        }
    }

    const isLoggedIn = TokenService.getToken();
    if (isLoggedIn) {
        history.push(path(RouteName.Home));
    }

    return <div className="c-authform">
        <form onSubmit={(e) => onSubmit(e)}>
            <h2 className="c-authform__title">Reset Password</h2>
            <p className="c-authform__subtitle">
                Let’s get you a new password.
            </p>
            {resetPassErr && <Alert description={resetPassErr} />}
            <fieldset>
                <legend></legend>
                <FormGroupInput
                    onChange={(e) => onChange(e)}
                    label="Email"
                    name="Email"
                    disabled={true}
                    value={email}
                    type='email'
                />
                <FormGroupInput
                    onChange={(e) => onChange(e)}
                    label="New Password"
                    name={"Password"}
                    error={errors["Password"]}
                    type='Password'
                />
            </fieldset>
            <Button
                nativeType="submit"
                round
                block
                className="c-authform__submit"
                data-qa="reset-password-submit"
                disabled={isSubmitting}
            >
                {isSubmitting ? 'Resetting Password...' : 'Update my password'}
            </Button>
            <div className="c-authform__forgot">
                Passwords must be at least six characters long and include digits, lower and upper case letters, and special characters.
            </div>
        </form>
        <Modal
            isOpen={redirectModal}
            onClose={() => {
                setRedirectModal(false)
                window.location.href = process.env.RAZZLE_BUY_URL;
            }}
        >
            <p style={{ whiteSpace: 'normal' }}>
                Your password was successfully updated. Please continue to login
            </p>
            <FloorPlanButton
                id="reset-password-continue"
                onClick={() => {
                    setRedirectModal(false)
                    window.location.href = process.env.RAZZLE_BUY_URL;
                }}
            >
                Continue
            </FloorPlanButton>
        </Modal>
    </div>

};

export default ResetPassword;