import React, { useReducer, createContext, useContext } from 'react';
import { ContractsReducer, initialState, State, Dispatch } from './ContractsReducer';

const ContractsContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

const ContractsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ContractsReducer, initialState);
    const value = { state, dispatch };

    return <ContractsContext.Provider value={value}>{children}</ContractsContext.Provider>;
};

const useContracts = () => {
    const context = useContext(ContractsContext);
    if (context === undefined) {
        throw new Error('useContracts must be used within an ContractsProvider');
    }
    return context;
};

export { useContracts, ContractsProvider, ContractsContext };
