import React, { useState } from 'react';
import { formatDateDirectShortYear } from '../../resources/datetime';
import { formatter } from '../../resources/currency';
import './DashboardStatus.scss';
import DashboardStatusCard from '../DashboardStatusCard';
import moment from 'moment';
import Heading from '@offerpad/floorplan/dist/typography/Heading';
import { AnimateHeight } from 'floorplan/components/ViewDetails/ViewDetails';
import useScreen from '@offerpad/floorplan/dist/hooks/useScreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { renewMyOffer } from '../../../../context/offer/OfferReducer';
import { useHistory } from 'react-router';
import { openModal } from '@offerpad/concrete.react/lib/Modal';
import { useModal } from '@offerpad/concrete.react/lib/Modal';
import { Modals } from '../../ModalController/ModalController';
import { Disposition } from 'src/services/DispositionService/types';
import OfferState from 'src/context/offer/types';

type DashboardStatusProp = {
    offer: Disposition | OfferState;
    offerDispatch: any;
    className?: string;
    isBuyer?: boolean;
};

const DashboardStatus: React.FC<DashboardStatusProp> = ({ offer, offerDispatch, className = '', isBuyer = false }) => {
    const screen = useScreen();
    const isDropdown = screen.width < 768; // this is the breakpoint in the grid
    const [showStatuses, setShowStatuses] = useState(true);

    return (
        <div className={`dashboard-status-wrapper ${className}`}>
            <div className="status-heading">
                <Heading size={4} weight="bold" color="#333333">
                    Transaction Status
                </Heading>
                {isDropdown && (
                    <FontAwesomeIcon
                        id="dashboard-status-show-status-icon"
                        icon={showStatuses ? 'chevron-up' : 'chevron-down'}
                        className="chevron_logo"
                        onClick={() => setShowStatuses(!showStatuses)}
                    />
                )}
            </div>
            <AnimateHeight isVisible={showStatuses || !isDropdown}>
                {!isBuyer && (
                    <div className="dashboard-status">
                        <DateOpenedStatusCard offer={offer} />
                        <DateClosedStatusCard offer={offer} />
                        <DaysToCloseStatusCard offer={offer} />
                        <OfferAmountStatusCard offer={offer} offerDispatch={offerDispatch} />
                    </div>
                )}
                {isBuyer && (
                    <div className="dashboard-status">
                        <StatusChangeDateCard offer={offer} />
                        <ListPriceCard offer={offer} />
                        <ProposedClossingDateCard offer={offer} />
                        <OfferStatusCard offer={offer} />
                    </div>
                )}
            </AnimateHeight>
        </div>
    );
};

const DateOpenedStatusCard = ({ offer }) => {
    const title = offer.contractSignedDate ? 'Date Opened' : 'Date Requested';
    const value = offer.isLost ? 'TBD' : (offer.contractSignedDate ? formatDateDirectShortYear(offer.contractSignedDate) : formatDateDirectShortYear(offer.requestSubmittedDate)) || 'TBD';
    return <DashboardStatusCard
        title={title}
        value={value} />;
};

const DateClosedStatusCard = ({ offer }) => {
    const { dispatch: modalDispatch } = useModal();

    let cardData = { title: 'Close of Escrow Date', value: null, color: null, linkTitle: null, linkClick: null };
    if (offer) {
        if (!offer.escrowCloseDate) {
            cardData.value = 'TBD';
        } else {
            cardData.value = formatDateDirectShortYear(offer.escrowCloseDate) || 'TBD';
            const daysToClose = daysToCloseOfEscrow(offer);
            if (daysToClose && daysToClose >= 0 && offer.closingConsultant) {
                cardData.linkTitle = 'Request Date Change';
                cardData.linkClick = () => openModal(modalDispatch, Modals.CHANGE_COE_MODAL);
            }
        }
        if (offer.isAcquired) {
            cardData.title = 'Sold to Offerpad';
        }
    }
    return <DashboardStatusCard {...cardData} />;
};

const DaysToCloseStatusCard = ({ offer }) => {
    let cardData = { title: 'Days Left Until Close', value: 'TBD', color: null };
    if (offer && offer.contractSignedDate) {
        let diff = daysToCloseOfEscrow(offer);
        cardData.value = `${diff}`;
        if (diff === null) {
            cardData.value = 'TBD';
            cardData.color = null;
        }
        if (diff <= 7) {
            cardData.color = null;
        }
        if (diff < 1) {
            cardData.value = '0';
        }
        if (offer.isAcquired) {
            cardData.color = null;
            cardData.value = 'Closed';
        }
    }
    return <DashboardStatusCard {...cardData} />;
};

const OfferAmountStatusCard = ({ offer, offerDispatch }) => {
    const { dispatch: modalDispatch } = useModal();
    let cardData = {
        title: offer.offerViewed ? 'Offer Amount' : 'Offer Ready',
        value: 'Requested!',
        status: 'pending',
        link: null,
        linkTitle: null,
        linkClick: null,
    };


    if (offer.keyType === 'interest') {
        cardData.title = 'Offer Amount';
        cardData.value = 'TBD';
    }

    if (offer) {
        if (offer.isLost) {
            cardData = {
                title: 'Offer Amount',
                value: null,
                status: 'pending',
                link: sellUrl,
                linkTitle: 'Request New Offer',
                linkClick: null,
            };
            return <DashboardStatusCard {...cardData} />
        }
        let expirationDate = offer.expirationDateTime;
        let hasOffer = offer.price && offer.offerExtendedDate && !offer.offerInProgress;
        let expired = offer.offerExpired;
        if (hasOffer) {
            cardData.status = 'active';
            cardData.value = offer.offerViewed ? formatter.format(Math.round(offer.price)) : null;
            cardData.link = `${sellUrl}/offer/${offer.offerKey}`;
            cardData.linkTitle = 'Review offer';
        }
        if (hasOffer && expired && expirationDate) {
            cardData.status = 'expired';
            cardData.title = offer.isLost ? 'Offer Ammount' : `EXPIRED ${formatDateDirectShortYear(expirationDate)}`;
            cardData.linkTitle = offer.isLost ? 'Request New Offer' : 'Renew your offer';
            // @ts-ignore
            cardData.linkClick = () => renewMyOffer(offer.offerKey, offerDispatch, modalDispatch);
            cardData.link = null;
        }
        if (offer.currentStep === 'RenewalRequest') {
            cardData.title = '';
            cardData.value = 'Renewal in Progress';
            cardData.link = null;
            cardData.linkTitle = null;
            cardData.linkClick = null;
        }
    }
    return <DashboardStatusCard {...cardData} />;
};

const StatusChangeDateCard = ({ offer }) => {
    const title = 'Status Change Date';
    const value = offer.statusUpdatedDatetime ? formatDateDirectShortYear(offer.statusUpdatedDatetime) : 'TBD';
    return <DashboardStatusCard title={title} value={value} />;
};

const ListPriceCard = ({ offer }) => {
    const title = 'List Price';
    const value = offer.listPrice ? formatter.format(Math.round(offer.listPrice)) : 'TBD';
    return <DashboardStatusCard title={title} value={value} />;
};

const ProposedClossingDateCard = ({ offer }) => {
    const title = 'Proposed Closing Date';
    const closingDates = offer.portalDispositionOfferDetails
        .map((x) => x?.proposedClosingDatetime)
        .sort((a, b) => {
            return a?.localeCompare(b?.toLowerCase());
        });
    let closingDate = closingDates.length > 0 ? closingDates[closingDates.length - 1] : 'TBD';
    const value = closingDate ? formatDateDirectShortYear(closingDate) : 'TBD';
    return <DashboardStatusCard title={title} value={value} />;
};

const OfferStatusCard = ({ offer }) => {
    const history = useHistory();
    const title = 'Offer Status';
    const linkTitle = 'View Submitted Offer';
    const linkClick = () => history.push(`/buyers/${offer.id}/offer-activity`);
    return <DashboardStatusCard title={title} linkClick={linkClick} linkTitle={linkTitle} />;
};

const daysToCloseOfEscrow = (offer) => {
    if (offer.isLost) {
        return 'TBD';
    }
    const d = offer.escrowCloseDate;
    const closeDate = d && moment(d).startOf('day');
    const today = d && moment().startOf('day');
    const diff = (closeDate && closeDate.diff(today, 'days')) || null;
    return diff;
};

const sellUrl = process.env.RAZZLE_SELL_URL;

export default DashboardStatus;
