import React, { useState } from "react";
import './Wizard.scss'

export const Wizard = ({
    children,
    stepController
}) => {

    let currentStep = stepController.currentStep;
    const enabledChildren = children.filter(x => x);

    /* Range check */
    if (currentStep < 0 || currentStep > enabledChildren.length - 1) {
        currentStep = 0;
    }

    return (
        <div className="wizard-container">
            <div className="card card-wizard active survey-form" >
                <div className="card-body">
                    <div className="tab-content">
                        {enabledChildren[currentStep]}
                    </div>
                </div>
            </div>
        </div>
    );
}

export const WizardStep = ({ children }) => {
    return (
        <div className="tab-pane fade active show">
            {children}
        </div>
    )
}

export const useStepController = () => {
    const [currentStep, setCurrentStep] = useState(0);
    return { 
        currentStep: currentStep,
        incrementStep: () => setCurrentStep(currentStep + 1),
        decrementStep: () => setCurrentStep(currentStep - 1),
    };
};
