export enum DispositionStatus {
    SELLER_COUNTER = 'sellercounter',
    SELLER_COUNTER_SENT = 'sellercountersent',
    SELLER_COUNTER_SENT_DOCUMENT_CORRECTIONS = 'sellercountersentwithdocumentcorrections',
    BUYER_RECEIVED = 'buyerreceived',
    BUYER_COUNTER = 'buyercounter',
    RECEIVED = 'received',
    DECLINED = 'declined',
    ACCEPTED = 'accepted',
    NEED_HIGHEST_BEST = 'needhighestandbest',
    BUYER_HIGHEST_BEST = 'buyerhighestandbest',
    ACCEPTANCE_PENDING_REVIEW = 'acceptancependingreview',
    CONTRACT_SUBMITTED = 'contractsubmitted',
    UNDER_CONTRACT = 'undercontract',
    CLOSED = 'closed',
    WITHDRAWN = 'withdrawn',
    REJECTED = 'rejected'
}

export enum DetailType {
    OFFER_RECEIVED = 'OfferReceived',
    COUNTER_OFFER = 'Counteroffer',
    HIGHEST_AND_BET_RECEIVED = 'HighestAndBestReceived'
}

export interface DispositionSummary {
    id: number,
    status: DispositionStatus,
    statusUpdatedDatetime: Date,
    propertyAddress: string,
    propertyCity: string,
    propertyState: string,
    propertyZipcode: string,
    defaultImageUrl: string,
    buyerFirstName: string,
    buyerLastName: string
}

export interface Disposition extends DispositionSummary {
    listPrice: number,
    financialType: string,
    portalDispositionOfferDetails: Array<DispositionOffer>,
    agentFirstName: string,
    agentLastName: string,
    agentEmail: string,
    propertyCity: string,
    propertyState: string,
    propertyZipcode: string,
    declinedNotes: string,
    declinedBy: string,
    declinedReason: string,
    agentRepresentationProgramTier: string
}

export interface DispositionOffer {
    offerPrice: number,
    requestedConcessions: number,
    earnestMoney: number,
    proposedClosingDatetime: Date,
    message?: string,
    sentBy: string,
    createdDatetime: Date,
    detailType: DetailType
}

