import { Disposition, DispositionStatus } from '../../../services/DispositionService/types';

export default function buildBuyerCheckList(dispositionOffer: Disposition) {
    let taskCount = 0;
    const checkList = {
        current: [],
        inProgress: [],
        completed: [],
    };

    if (!dispositionOffer) {
        return checkList;
    }

    if (dispositionOffer.status === DispositionStatus.SELLER_COUNTER_SENT) {
        checkList.current.push({
            id: taskCount++,
            title: 'Review Counter',
            buttonUrl: `/buyers/${dispositionOffer.id}/offer-activity`,
        });
    }

    if (dispositionOffer.status === DispositionStatus.ACCEPTED) {
        checkList.current.push({
            id: taskCount++,
            title: 'Submit Contract Package',
            buttonUrl: `/buyers/${dispositionOffer.id}/documents`,
        });
    }

    if (dispositionOffer.status === DispositionStatus.NEED_HIGHEST_BEST) {
        checkList.current.push({
            id: taskCount++,
            title: 'Submit Highest-and-best',
            buttonUrl: `/buyers/${dispositionOffer.id}/offer-activity`,
        });
    }

    if (dispositionOffer.status === DispositionStatus.SELLER_COUNTER_SENT_DOCUMENT_CORRECTIONS) {
        checkList.current.push({
            id: taskCount++,
            title: 'Correct Contract Package',
            buttonUrl: `/buyers/${dispositionOffer.id}/documents`,
        });
    }

    return checkList;
}
