import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Spinner from '@offerpad/floorplan/dist/components/Spinner';
import { path, RouteName } from '../../../routing/Routes';
import { useUser } from '../../../context/user/UserContext';
import Heading from '@offerpad/floorplan/dist/typography/Heading';
import { notify } from '../../../context/notifications/NotificationsReducer';
import { useNotifications } from '../../../context/notifications/NotificationsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Home.module.scss';
import cx from 'classnames';
import { useDisposition } from '../../../context/disposition/DispositionContext';
import PropertyTile, { dispositionToProperty, buyer } from './PropertyTile';
import { useAuthentication } from '../../../context/authentication/AuthenticationContext';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import NoOffers from './NoOffers';
import HomeFilters, { filterByStatus, filterByText, StatusFilter } from './HomeFilters';

const AgentHome = () => {
    const { state: user } = useUser();
    const { hasEmailConfirmed } = useAuthentication();
    const { state: { loading: loadingDispositions, error, dispositions }, getDispositions } = useDisposition();
    const { dispatch: notificationsDispatch } = useNotifications();
    const history = useHistory();

    const limit = 8;
    const [offset, setOffSet] = useState<number>(0);
    const [statusFilter, setStatusFilter] = useState<StatusFilter>(StatusFilter.ALL);
    const [textFilter, setTextFilter] = useState<string>('');

    useEffect(() => {
        hasEmailConfirmed(
            () => getDispositions(),
            () => history.push(path(RouteName.ResendEmailVerification))
        );
    }, []);

    useEffect(() => {
        if (error) {
            notify(notificationsDispatch, {
                message: 'There was a problem with your request. Please try again.',
                type: 'danger'
            })
        }
    }, [error]);

    const filteredDispositions = dispositions
        ? dispositions
            .filter(offer => filterByStatus(offer, statusFilter))
            .filter(offer => filterByText(offer, textFilter))
        : [];

    const offersByAddressFiltered = Object.values(groupBy(filteredDispositions, 'defaultImageUrl'));
    const offersByAddress = Object.values(groupBy(dispositions, 'defaultImageUrl'));
    const paginationNumbers = Array.from({ length: Math.ceil(length / limit) }, (_, i) => i + 1);

    return (
        <div className={styles['Homes']}>
            <div className={styles['Homes__container']}>
                <div className={cx(styles['Homes__header'])}>
                    <div>
                        <h2>Agent Buy Dashboard</h2>
                    </div>
                    {(textFilter !== '' || statusFilter !== StatusFilter.ALL || offersByAddressFiltered.length > 0) && (
                        <div className={styles['right']}>
                            <div className={cx(styles['homeAmount'])}>
                                Showing {`${offset + 1}-${Math.min(offset + limit, offersByAddressFiltered.length)}`} | Page{' '}
                                {Math.ceil((offset + 1) / limit)} of {Math.ceil(offersByAddressFiltered.length / limit)}
                            </div>                                
                            <div className={styles['desktop-filters']}>
                                <div className="u-margin-xs" />
                                <HomeFilters
                                    statusFilter={statusFilter}
                                    setStatusFilter={setStatusFilter}
                                    textFilter={textFilter}
                                    setTextFilter={setTextFilter}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {(textFilter !== '' || statusFilter !== StatusFilter.ALL || offersByAddressFiltered.length > 0) && <div className={styles['Homes__subheader']}>
                    <Heading size={4} weight="bold">{`Hello, ${user.firstName}`}</Heading>
                    <p>Please select the property you would like to view in your account.</p>                    
                    <div className={styles['mobile-filters']}>
                        <div className="u-spacer-xs" />
                        <HomeFilters
                            statusFilter={statusFilter}
                            setStatusFilter={setStatusFilter}
                            textFilter={textFilter}
                            setTextFilter={setTextFilter}
                        />
                    </div>
                </div>}
                <div className={cx(styles['c-homelist'])}>
                    {loadingDispositions && offersByAddressFiltered.length === 0 && <div className={styles['spinner']}>
                        <Spinner />
                    </div>}
                    {
                        (offersByAddressFiltered.length === 0 && offersByAddress.length != 0) ?
                            <>
                                <div className="u-spacer-m" />
                                <p>Your search did not match any offers.</p>
                            </>
                            :
                            (offersByAddress.length === 0 && dispositions !== null && <NoOffers />)
                    }
                    <div className={styles['c-homelist__listings']}>
                        {
                            offersByAddressFiltered
                                .slice(offset, offset + limit)
                                .map((dispositions, key) => {
                                    if (dispositions.length === 1) {
                                        const disposition = head(dispositions);
                                        return <PropertyTile
                                            link={`buyers/${disposition.id}/overview`}
                                            property={dispositionToProperty(disposition)}
                                            imageUrl={disposition.defaultImageUrl}
                                            status={""}
                                            key={key}
                                        />
                                    } else if (dispositions.length > 1) {
                                        const buyers: Array<buyer> = dispositions.map(disposition => ({
                                            firstName: disposition.buyerFirstName,
                                            lastName: disposition.buyerLastName,
                                            link: `buyers/${disposition.id}/overview`,
                                            status: disposition.status
                                        }))
                                        const disposition = head(dispositions);
                                        return <PropertyTile
                                            link=""
                                            property={dispositionToProperty(disposition)}
                                            imageUrl={disposition.defaultImageUrl}
                                            status={""}
                                            key={key}
                                            buyers={buyers}
                                        />
                                    }
                                })
                        }
                    </div>
                </div>
                {offersByAddressFiltered.length > 0 && (
                    <div className={styles['Homes__pages']}>
                        {
                            <div className={cx(styles['homeAmount'])}>
                                Showing {`${offset + 1}-${Math.min(offset + limit, offersByAddressFiltered.length)}`} | Page{' '}
                                {Math.ceil((offset + 1) / limit)} of {Math.ceil(offersByAddressFiltered.length / limit)}
                            </div>
                        }
                        <div className={styles['Homes__pages-numbers']}>
                            <div
                                id="homes-offer-set-0181"
                                className={cx(styles['arrowBtn'], styles[offset <= 0 && 'arrowBtn__disabled'])}
                                onClick={() => {
                                    if (offset > 0) {
                                        setOffSet(offset - limit);
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                                Prev
                            </div>
                            <div className={styles['Homes__pages-numbers']}>
                                {paginationNumbers.map((page) => {
                                    return (
                                        <div
                                            id="homes-offer-set-0714"
                                            key={`page${page}`}
                                            onClick={() => {
                                                setOffSet((page - 1) * limit);
                                            }}
                                            style={{
                                                textDecoration:
                                                    Math.ceil((offset + 1) / limit) === page ? 'underline' : 'none',
                                            }}
                                        >
                                            {page}
                                        </div>
                                    );
                                })}
                            </div>
                            <div
                                id="homes-offer-set-2653"
                                className={cx(
                                    styles['arrowBtn'],
                                    styles[offset + limit >= offersByAddressFiltered.length && 'arrowBtn__disabled']
                                )}
                                onClick={() => {
                                    if (offset + limit < offersByAddressFiltered.length) {
                                        setOffSet(offset + limit);
                                    }
                                }}
                            >
                                Next
                                <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default AgentHome;
