import React from "react";
import classes from '../../assets/sass/offerpad/autform.module.scss';

const OrDivision = () => {
    const line = <hr className={classes['hr-style']}/>;
    return <div className={classes['or-division-container']}>
        {line} <div>OR</div> {line}
    </div>
}


export default OrDivision;