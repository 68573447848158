import React, { useEffect } from 'react';
import { useHistory, useLocation, Link } from "react-router-dom";
import { useNotifications } from '../../../context/notifications/NotificationsContext';
import { notify } from '../../../context/notifications/NotificationsReducer';
import { useUser } from '../../../context/user/UserContext';
import { getInfo } from '../../../context/user/UserReducer';
import { path, RouteName } from '../../../routing/Routes';
import AuthService from '../../../services/AuthService/auth.service';
import TokenService from '../../../services/token.service';
import Spinner from 'floorplan/components/Spinner';
import "./VerifyEmail.scss";

const VerifyEmail = () => {
    const { state: user, dispatch: userDispatch } = useUser();
    const history = useHistory();
    const { dispatch: notificationsDispatch } = useNotifications();
    const params = new URLSearchParams(useLocation().search);
    const message = (params.get('message') != '') ? params.get('message') : 'Thank you for verifying your email.';

    const isLoggedIn = TokenService.getToken();

    useEffect(() => {
        if (isLoggedIn) {
            getInfo(userDispatch, isLoggedIn);
        } else if (!params.get('message')) {
            // if there is no message , default to success
            notify(notificationsDispatch, {
                'message': `Thank you for verifying your email. Please login.`,
                'type': 'success'
            });
            if(params.get('preloadEmail')) {
                history.push(`/login/?preloadEmail=${params.get('preloadEmail')}`);
            }else {
                history.push(path(RouteName.Login));
            }

        }
    }, []);

    const isVerified = user['EmailConfirmed'] === 'True' && user['emailConfirmed'] === 'True';

    if (isVerified) {
        notify(notificationsDispatch, {
            'message': `Thank you for verifying your email. Please login.`,
            'type': 'success'
        });
        AuthService.logout();
        if(params.get('preloadEmail')) {
            history.push(`/login/?preloadEmail=${params.get('preloadEmail')}`);
        }else {
            history.push(path(RouteName.Login));
        }
    }

    const dataQa = "verify-email-resend-verification-link";

    return (
        <div className="c-authform">
            <h2 className="c-authform__title">Email Verification</h2>
            <p className="c-authform__subtitle">{message}</p>
            {user.loading &&
                <div className='spinner'>
                    <Spinner />
                </div>
            }
            {!isVerified &&
                <Link
                    id={dataQa}
                    data-qa={dataQa}
                    to="/resend-email-verification"
                >
                    Resend verification
                </Link>
            }
        </div>);

}

export default VerifyEmail;
