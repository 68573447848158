import React, { useState } from "react";
import { useDisposition } from "../../../context/disposition/DispositionContext";
import { Modal } from 'floorplan/components/v2/Modal';
import { Button } from 'floorplan/components/v2/Button';
import { FormControl } from 'floorplan/components/v2/Form';
import Paragraph from '@offerpad/floorplan/dist/typography/Paragraph/Paragraph';
import useScreen from '@offerpad/floorplan/dist/hooks/useScreen';
import { useModal } from '@offerpad/concrete.react/lib/Modal';
import { closeModal } from '@offerpad/concrete.react/lib/Modal';
import { Breakpoints } from "../../../utils/breakpointUtil";
import cx from 'classnames';
import styles from './OfferRoundMessageModal.module.scss';

type OfferRoundMessageModalProps = {
    message: string;
    isCounter?: boolean;
}

const OfferRoundMessageModal: React.FC<OfferRoundMessageModalProps> = (props) => {
    const { message, isCounter } = props;
    const { state: { newCounterOffer }, setNewCounterOffer } = useDisposition();
    const { state: { isOpen }, dispatch: modalDispatch } = useModal();
    const screen = useScreen();
    const [counterMessage, setCounterMessage] = useState<string>(newCounterOffer.message);

    const closeModalWrapper = () => {
        closeModal(modalDispatch);
        setCounterMessage(null);
    }

    return (
        <div className={cx(styles.message_modal, 'message-modal-inputs')}>
            <Modal
                hasFooter
                open={isOpen}
                onClose={closeModalWrapper}
                mobileBottom={screen.width < Breakpoints.DESKTOP}
            >
                <Modal.Header
                    title={isCounter ? 'Add Message' : 'View Message'}
                    onClose={closeModalWrapper}
                    border
                />
                <Modal.Content>
                    {
                        isCounter ?
                            <FormControl
                                id="offer-round-msg-edit"
                                name="offer-round-msg-edit"
                                onChange={e => setCounterMessage(e.target.value)}
                                placeholder="Enter message"
                                value={counterMessage || message}
                            >
                                <textarea
                                    id="offer-round-msg-edit"
                                    data-testid="offer-round-msg-edit"
                                    name="buyerMessage"
                                    maxLength={200}
                                    rows={4}
                                ></textarea>
                            </FormControl>
                            :
                            <>
                                <div className="u-spacer-m" />
                                <Paragraph
                                    size={'large'}
                                    id="offer-round-msg-display"
                                    className="offer-round-msg-display"
                                    data-testid="offer-round-msg-display"
                                >
                                    {message}
                                </Paragraph>
                                <div className="u-spacer-m" />
                            </>
                    }
                </Modal.Content>
                <Modal.Footer border>
                    {
                        isCounter ?
                            <>
                                <Button
                                    id="cancel-dispo-offer-msg"
                                    secondary
                                    type="submit"
                                    className="modal-new-cancel-button"
                                    onClick={closeModalWrapper}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    id="save-dispo-offer-msg"
                                    brand
                                    type="submit"
                                    className="modal-new-action-button"
                                    onClick={() => {
                                        setNewCounterOffer({ ...newCounterOffer, message: counterMessage });
                                        closeModalWrapper();
                                    }}
                                    disabled={!counterMessage && !message}
                                >
                                    Save
                                </Button>
                            </>
                            :
                            <Button
                                id="done-viewing-dispo-offer-msg"
                                brand
                                type="submit"
                                className="modal-new-action-button"
                                onClick={closeModalWrapper}
                            >
                                Done
                            </Button>
                    }
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default OfferRoundMessageModal;