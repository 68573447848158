export enum Actions {
    DISPLAY_SIDE_BAR = 'display sidebar',
    SET_ACTIVE_LINK = 'set active link',
}

export type Action = { type: Actions; data?: any };

export type State = {
    showSidebar: boolean,
    sidebarLinks: Array<string>,
    activeLink: string;
};

export type Dispatch = (action: Action) => void;

export const initialState: State = {
    showSidebar: false,
    sidebarLinks: [],
    activeLink: '',
}

export const sidebarReducer = (state: State, action: Action) => {
    let newState = { ...state };
    switch (action.type) {
        case Actions.DISPLAY_SIDE_BAR:
            newState.showSidebar = action.data;
            break;
        case Actions.SET_ACTIVE_LINK:
            newState.activeLink = action.data;
    }
    return newState;
};

export const displaySidebar = (dispatch: Dispatch, value: boolean) => dispatch({ type: Actions.DISPLAY_SIDE_BAR, data: value });