import React from 'react';
import './CurrentTaskCard.scss';
import { Link } from 'react-router-dom';
import UserAvatar from './UserAvatar';
import cx from 'classnames';
import { Button } from 'floorplan/components/v2/Button';

const CurrentTaskCard = (item) => {
    const task = item.task;
    const isExternal: boolean = task.buttonUrl?.includes('http');

    return (
        <div className="op-card c-taskcard" style={{ flexDirection: 'column' }}>
            <div>
                <h4 className="c-taskcard__title"><UserAvatar clickable={false} size="sm" />{task.title}</h4>
                <p className="c-taskcard__description" dangerouslySetInnerHTML={{ __html: task.description }}></p>
                {task.showCompletionCriteria && <p className="c-taskcard__description" dangerouslySetInnerHTML={{ __html: task.completionCriteria }}></p>}
            </div>
            {task.buttonText && (
                <div className="c-taskcard__action">
                    {!task.buttonUrl && (
                        <Button
                            id="task-no-link"
                            data-qa="task-no-link"
                            className="c-taskcard__actionlink"
                            fullWidth
                        >{task.buttonText}</Button>
                    )}
                    {task.buttonUrl && isExternal && (
                        <Button
                            href={task.buttonUrl}
                            target="_blank"
                            id="link-task-button-external"
                            data-qa="link-task-button-external"
                            rel="noreferrer noopener"
                            fullWidth
                        >{task.buttonText}</Button>
                    )}
                    {task.buttonUrl && !isExternal && (
                        <Link to={task.buttonUrl}>
                            <Button
                                id="link-task-button-url"
                                data-qa="link-task-button-url"
                                fullWidth
                            >{task.buttonText}</Button>
                        </Link>
                    )}
                </div>
            )}
            {task.sellersButtonsData?.map((seller, index) => (
                !task.sellersButtonsData[index].signed &&
                <div key={index}>
                    <div
                        className="c-taskcard__text-above-button"
                        dangerouslySetInnerHTML={{ __html: `<h3><b>${seller.signerName.split(' ')[0]}</b> (Seller ${index + 1})</h3>` }}
                    ></div>
                    <div
                        id="current-task-card-open-contract-seller"
                        className={cx({ 'c-taskcard__actionlink': true, 'c-taskcard__disabled': index - 1 >= 0 && !task.sellersButtonsData[index - 1].signed })}
                        dangerouslySetInnerHTML={{ __html: index === task.sellersButtonsData.length - 1 ? 'Sign to Complete' : 'Fill Out and Sign' }}
                        onClick={() => item.openContract(seller)}
                    ></div>
                </div>
            ))}
        </div>
    );
};

export default CurrentTaskCard;
