import * as ContractsService from '../../services/ContractsService/contracts.service';

export enum Actions {
    LOAD_CONTRACTS = 'loadContracts',
    LOAD_CONTRACTS_COMPLETE = 'loadContractsComplete',
    ERROR = 'error',
}
export type Action = { type: Actions; data?: any };
export type State = {
    contracts: Array<Object>;
    disclosureInfo: Object;
    loading: boolean;
    error: boolean;
    errorMessage: string;
    loaded: boolean;
};

const markets = [
    'South Charlotte',
    'Las Vegas',
    'San Antonio',
    'Houston',
    'Dallas',
    'Austin',
    'Columbia',
    'Indianapolis',
    'St Louis',
    'Columbus',
    'Riverside',
    'Sacramento',
    'San Bernardino'
];

export type Dispatch = (action: Action) => void;

export const initialState: State = {
    contracts: [],
    disclosureInfo: {
        propertyDisclosure: null,
        sellersForDisclosure: [],
        marketRequiresDisclosures: false,
    },
    loading: false,
    error: false,
    errorMessage: '',
    loaded: false,
};

export const ContractsReducer = (state: State, action: Action) => {
    switch (action.type) {
        case Actions.LOAD_CONTRACTS:
            return { ...state, loading: true };
        case Actions.LOAD_CONTRACTS_COMPLETE:
            return {
                ...state,
                contracts: action.data.contracts,
                disclosureInfo: {
                    propertyDisclosure: action.data.disclosureInfo.propertyDisclosure,
                    sellersForDisclosure: action.data.disclosureInfo.sellersForDisclosure,
                    marketRequiresDisclosures: action.data.disclosureInfo.marketRequiresDisclosures,
                },
                loading: false,
                error: false,
                loaded: true
            };
        case Actions.ERROR:
            const message = action.data.message;
            return { ...state, error: true, loaded: true, errorMessage: message };
        default:
            return state;
    }
};

export const getContracts = async (dispatch: Dispatch, acquisitionId: Number, market: string) => {

    dispatch({ type: Actions.LOAD_CONTRACTS });

    if (!acquisitionId) {
        dispatch({ type: Actions.LOAD_CONTRACTS_COMPLETE, data: { ...initialState, loaded: true } });
        return;
    }

    try {
        const acquisitionContractsResponse = await ContractsService.getAcquisitionContracts(acquisitionId);
        const acquisitionContracts = await acquisitionContractsResponse.data;
        const propertyDisclosure = acquisitionContracts.find(x =>
            x.category === 'PropertyDisclosure' && (
                x.status === 'Sent' ||
                x.status === 'Delivered' ||
                x.status === 'Completed'
                ));
                const marketRequiresDisclosures = markets.includes(market);
        
                let sellers = [];
                if (!!propertyDisclosure && propertyDisclosure.sentEmbedded) {
            const propertyDisclosureInfoResponse = await ContractsService.getContractIncludeSignatures(propertyDisclosure.contractId);
            const propertyDisclosureInfo = await propertyDisclosureInfoResponse.data;
            sellers = getSellersFromPropertyDisclosure(propertyDisclosureInfo);
            sellers = await addSignUrlToSellers(sellers);
        }

        const data = {
            contracts: acquisitionContracts,
            disclosureInfo: {
                propertyDisclosure: propertyDisclosure,
                sellersForDisclosure: sellers,
                marketRequiresDisclosures: marketRequiresDisclosures,
            },
            loading: false,
            error: false,
            errorMessage: '',
            loaded: true,
        };
        dispatch({ type: Actions.LOAD_CONTRACTS_COMPLETE, data: data });
    } catch (error) {
        dispatch({ type: Actions.ERROR, data: { message: error } });
    }
};

const getSellersFromPropertyDisclosure = (propertyDisclosure) => {
    return propertyDisclosure?.signaturesInfo?.filter(x => x.signerRole.startsWith('Seller'));
}

const addSignUrlToSellers = async (sellers) => {
    const sellersWithUrl = [];
    for (let i = 0; i < sellers.length; i++) {
        let signUrl;
        let docuSignClientId;
        try {
            const signUrlResponse = await ContractsService.getContractEmbeddedSignUrl(sellers[i].signatureId);
            signUrl = signUrlResponse?.data.embeddedSignUrl;
            docuSignClientId = signUrlResponse?.data.clientId;
        }
        catch (e) {
            const message = e.response?.data?.message;
            if (
                message === 'This request has already been signed' ||
                message === 'This request cannot be signed yet'
            ) {
                signUrl = null;
                docuSignClientId = null;
            }
            else {
                return [];
            }
        }
        sellersWithUrl.push({
            ...sellers[i],
            signUrl: signUrl,
            docuSignClientId: docuSignClientId,
            signed: sellers[i].signedAt !== '0001-01-01T00:00:00'
        });
    }
    return sellersWithUrl;
};