import { Disposition, DispositionStatus } from '../../../services/DispositionService/types';
import { ProgressStep } from '../Dashboard/ProgressBar/ProgressBar';
import { Faq, ProgressStepsBuyerYesNeg } from '../Dashboard/ProgressBar/progressStepsBuyer';

export class ProgressDashboardInfo {
    workflowStep: string = '';
    progressBar: ProgressStep = { label: 'Offer', order: 1 };
    progressPercentage: number = 0;
    sentenceTop: string = '';
    whatsNextHeading: string = '';
    whatsNextCopy: string = '';
    faqs: Array<Faq> = [];
    column: string = '';
    column2: string = '';
}

export default function getProgressInfoBuyer(dispoOffer: Partial<Disposition>) {
    const dashboardInfo = new ProgressDashboardInfo();
    const currentStatus = dispoOffer?.status;
    const progressBar = ProgressStepsBuyerYesNeg;

    switch (currentStatus) {
        case DispositionStatus.RECEIVED:
        case DispositionStatus.BUYER_RECEIVED:
            dashboardInfo.workflowStep = currentStatus;
            dashboardInfo.progressBar = progressBar.OfferInReview;
            dashboardInfo.progressPercentage = 33;
            dashboardInfo.whatsNextCopy = 'Your offer has been submitted and is in review. You will receive an '
            + 'email from Offerpad with additional instructions once Offerpad\'s review is complete. '
            + 'This typically takes 24 hours or less!';
            break;
        case DispositionStatus.SELLER_COUNTER:
            dashboardInfo.workflowStep = currentStatus;
            dashboardInfo.progressBar = progressBar.OfferNegotiations;
            dashboardInfo.progressPercentage = 33;
            dashboardInfo.whatsNextCopy = 'Your offer has been submitted and is in review. You will receive an '
            + 'email from Offerpad with additional instructions once Offerpad\'s review is complete. '
            + 'This typically takes 24 hours or less!';
            break;
        case DispositionStatus.SELLER_COUNTER_SENT:
            dashboardInfo.workflowStep = currentStatus;
            dashboardInfo.progressBar = progressBar.OfferNegotiations;
            dashboardInfo.progressPercentage = 33;
            dashboardInfo.whatsNextCopy = 'Your initial offer was reviewed and Offerpad has countered your '
            + 'offer. You can now view and reply to this counter. Check the Your To-Dos section for more '
            + 'information.';
            break;
        case DispositionStatus.SELLER_COUNTER_SENT_DOCUMENT_CORRECTIONS:
            dashboardInfo.workflowStep = currentStatus;
            dashboardInfo.progressBar = progressBar.OfferNegotiations;
            dashboardInfo.progressPercentage = 33;
            dashboardInfo.whatsNextCopy = 'After reviewing the contract package that was submitted, Offerpad '
            + 'is going to need you to make a few corrections. Check your To-Dos section for more information.';
            break;
        case DispositionStatus.BUYER_COUNTER:
            dashboardInfo.workflowStep = currentStatus;
            dashboardInfo.progressBar = progressBar.OfferNegotiations;
            dashboardInfo.progressPercentage = 33;
            dashboardInfo.whatsNextCopy = 'Your counter has been submitted and is in review. You will receive '
            + 'an email from Offerpad with additional instructions once Offerpad\'s review is complete. This '
            + 'typically takes 24 hours or less!';
            break;
        case DispositionStatus.NEED_HIGHEST_BEST:
            dashboardInfo.workflowStep = currentStatus;
            dashboardInfo.progressBar = progressBar.OfferNegotiations;
            dashboardInfo.progressPercentage = 53;
            dashboardInfo.whatsNextCopy = 'Your offer was reviewed and Offerpad has requested your '
            + 'highest-and-best. You can now review this request and submit your highest-and-best. '
            + 'Check the Your To-Dos section for more information.';
            break;
        case DispositionStatus.BUYER_HIGHEST_BEST:
            dashboardInfo.workflowStep = currentStatus;
            dashboardInfo.progressBar = progressBar.OfferNegotiations;
            dashboardInfo.progressPercentage = 73;
            dashboardInfo.whatsNextCopy = 'Your highest-and-best offer has been submitted and is in review. '
            + 'You will receive an email from Offerpad with additional instructions once Offerpad\'s review '
            + 'is complete.';
            break;
        case DispositionStatus.ACCEPTANCE_PENDING_REVIEW:
            dashboardInfo.workflowStep = currentStatus;
            dashboardInfo.progressBar = progressBar.AcceptanceReview;
            dashboardInfo.progressPercentage = 33;
            dashboardInfo.whatsNextCopy = 'Your offer acceptance has been submitted and is in review. '
            + 'You will receive an email from Offerpad with additional instructions once Offerpad\'s '
            + 'review is complete. This typically takes 24 hours or less!';
            break;
        case DispositionStatus.ACCEPTED:
            dashboardInfo.workflowStep = currentStatus;
            dashboardInfo.progressBar = progressBar.SubmitContract;
            dashboardInfo.progressPercentage = 33;
            dashboardInfo.whatsNextCopy = 'Congratulations! Your offer has been accepted! You can now '
            + 'submit your buyer-signed contract package. Check the Your To-Dos section for more information.';
            break;
        case DispositionStatus.CONTRACT_SUBMITTED:
            dashboardInfo.workflowStep = currentStatus;
            dashboardInfo.progressBar = progressBar.ContractInReview;
            dashboardInfo.progressPercentage = 33;
            dashboardInfo.whatsNextCopy = 'Your buyer-signed contract package has been submitted and is in '
            + 'review. You will receive an email from Offerpad with additional instructions once Offerpad\'s '
            + 'review is complete. This typically takes 24 hours or less!';
            break;
        case DispositionStatus.UNDER_CONTRACT:
            dashboardInfo.workflowStep = currentStatus;
            dashboardInfo.progressBar = progressBar.UnderContract;
            dashboardInfo.progressPercentage = 33;
            dashboardInfo.whatsNextCopy = 'Congratulations! You are currently under contract on this '
            + 'home! Offerpad\'s closing team will be reach out to you to help guide you through '
            + 'the closing process!';
            break;
        case DispositionStatus.CLOSED:
            dashboardInfo.workflowStep = currentStatus;
            dashboardInfo.progressBar = progressBar.Closed;
            dashboardInfo.progressPercentage = 100;
            dashboardInfo.whatsNextCopy = 'You have nothing coming up next for this home. Thank you '
            + 'for working with Offerpad!';
            break;
        default:
            dashboardInfo.workflowStep = null;
            dashboardInfo.progressBar = null;
            dashboardInfo.progressPercentage = 0;
            dashboardInfo.whatsNextCopy = 'There\'s no current steps for you to take!';
            break;
    }
    return dashboardInfo;
}
