import React from "react";
import './EventBody.scss';

type EventBodyProps = {
    illustrationPath: string,
    description: string,
}

const EventBody: React.FC<EventBodyProps> = (props) => {
    const { illustrationPath, description } = props;

    return <div>
        <div data-testid="event-card" className="c-eventcard">
            <div className="c-eventcard__middle">
                {illustrationPath && <img
                    width="5rem"
                    className="c-eventcard__image"
                    src={illustrationPath}
                />}
                {description && <p
                    style={{ textAlign: 'left' }}
                    className="c-eventcard__desc"
                    dangerouslySetInnerHTML={{ __html: description }}
                />}
            </div>

        </div>
    </div>
}

export default EventBody;