import React, { useEffect } from 'react'
import PageLayout from '../../Controls/PageLayout'
import { useDisposition } from '../../../context/disposition/DispositionContext'
import { useParams } from 'react-router-dom';
import OfferHistory from '../../Controls/OfferHistory';
import useScreen from '@offerpad/floorplan/dist/hooks/useScreen';
import styles from './OfferActivity.module.scss'
import getProgressInfoBuyer from '../../Controls/resources/progressUtilBuyer';
import ProgressBar from '../../Controls/Dashboard/ProgressBar/ProgressBar';
import { Alert } from 'floorplan/components/Alert';
import { MessageAlert, AgentRepresentationProgramTierMax } from "src/services/DispositionService/constants";

const OfferActivity = () => {
    const { state: disposition, getDisposition } = useDisposition();
    const { offerKey } = useParams<{ offerKey: string }>();
    const screen = useScreen();
    const isTablet = screen.width < 991

    useEffect(() => {
        if (disposition.dispositionOffer === null || disposition.dispositionOffer?.id?.toString() !== offerKey) {
            getDisposition(offerKey)
        }
    }, [offerKey])

    const { dispositionOffer } = disposition;
    const visualTrackerInfo = getProgressInfoBuyer(dispositionOffer);
    let latestMessageAlert = '';

    if (dispositionOffer === null) {
        return <></>
    }

    dispositionOffer.portalDispositionOfferDetails.map((offer, index) => {
        latestMessageAlert = offer.message;

        if (dispositionOffer.status == 'buyercounter') {
            latestMessageAlert = ''
        }
    })
    let submitMessageAlert = MessageAlert;
    let showMessageAlert = dispositionOffer.agentRepresentationProgramTier === AgentRepresentationProgramTierMax;
    return <PageLayout
        streetAddress={'Street Address'}
        headerText="Offer Activity"
        subHeaderText={``}
        listedByAgent={false}
        centerColumn={<div className={styles.offer_activity}>
            {visualTrackerInfo.workflowStep &&
                <ProgressBar
                    workflowStep={visualTrackerInfo.workflowStep}
                    currentProgressBar={visualTrackerInfo.progressBar}
                    progressPercentage={visualTrackerInfo.progressPercentage}
                />}
            {latestMessageAlert && showMessageAlert ?
                <div> <Alert kind="danger">
                    <b>Message from Offerpad:</b><hr />
                    {latestMessageAlert}
                </Alert>
                    <Alert kind="warning">
                        {submitMessageAlert}
                    </Alert></div>
                : (latestMessageAlert ?
                    <Alert kind="danger">
                        <b>Message from Offerpad:</b><hr />
                        {latestMessageAlert}
                    </Alert>
                    : (showMessageAlert ?
                        <Alert kind="warning">
                            <b>Message from Offerpad:</b><hr />
                            {submitMessageAlert}
                        </Alert> : null))}
            <OfferHistory
                isCompact={isTablet}
            />
            <div className={styles.offer_details}>
                <div className={styles.title}>Offer Details</div>
                <div className={styles.detail_content}>
                    <div>
                        <div className={styles.detail_title}>Financing Type</div>
                        <div className={styles.detail_name}>{dispositionOffer.financialType}</div>
                    </div>
                    <div>
                        <div className={styles.detail_title}>Buyer's Name</div>
                        <div className={styles.detail_name}>{dispositionOffer.buyerFirstName} {dispositionOffer.buyerLastName}</div>
                    </div>
                    <div>
                        <div className={styles.detail_title}>Agent's Name</div>
                        <div className={styles.detail_name}>{dispositionOffer.agentFirstName} {dispositionOffer.agentLastName}</div>
                    </div>
                    <div>
                        <div className={styles.detail_title}>Agent's Email</div>
                        <div className={styles.detail_name}>{dispositionOffer.agentEmail}</div>
                    </div>
                </div>
            </div>
        </div>
        }
    />
}

export default OfferActivity;
