import React from "react";
import { Modal } from 'floorplan/components/v2/Modal'
import { useModal } from '@offerpad/concrete.react/lib/Modal';
import { closeModal } from '@offerpad/concrete.react/lib/Modal';
import { Gallery } from "../../UIComponents/Gallery/Gallery";
import "react-datepicker/dist/react-datepicker.css";

export type PhotoGalleryModalProps = {
    files: Array<any>,
    index: number
}

const PhotoGalleryModal: React.FC<PhotoGalleryModalProps> = (props) => {
    const {files, index} = props;
    const { state: { isOpen }, dispatch: modalDispatch } = useModal();

    return (
        <Modal
            open={isOpen}
            onClose={() => closeModal(modalDispatch)}
            width={'0'}
        >
            <Gallery
                photos={files}
                indexOnOpen={index}
                closeModal={() => closeModal(modalDispatch)}
            />
        </Modal>
    )
}

export default PhotoGalleryModal;