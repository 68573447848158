import React, { useEffect, useState, useRef } from "react"
import { useNotifications } from '../../../context/notifications/NotificationsContext';
import { removeNotification } from '../../../context/notifications/NotificationsReducer';

type NotificationProps = {
    showClose: boolean,
    message: string,
    timestamp: Date,
    index: number;
    timeout: number;
    verticalAlign?: string,
    horizontalAlign?: string,
    type?: string;
}
// rough conversion from https://github.com/BinarCode/vue-notifyjs/tree/master/src, the notification package used in vue app
const Notification: React.FC<NotificationProps> = (props) => {
    const { showClose, message, timestamp, index, timeout,
        verticalAlign = 'top',
        horizontalAlign = 'center',
        type = 'info'
    } = props;

    const { state: _, dispatch: notificationsDispatch } = useNotifications();

    const [elmHeight, setElmHeight] = useState(0);
    const ref = useRef(null);

    useEffect(() => {
        setElmHeight(ref?.current.clientHeight)
        setTimeout(() => removeNotification(notificationsDispatch, timestamp), timeout)
    }, [])

    const top = index * (elmHeight + 20) + 20;
    return (
        <div
            id="notifications-remove-2591"
            onClick={() => removeNotification(notificationsDispatch, timestamp)}
            data-notify="container"
            className={[
                "alert open ",
                verticalAlign,
                horizontalAlign,
                `alert-${type}`
            ].filter(cls => cls).join(' ')}
            role="alert"
            data-notify-position="top-center"
            ref={ref}
            style={{ top: top }}
        >
            {showClose && (
                <button
                    id="notifiations-close-7679"
                    type="button"
                    aria-hidden="true"
                    className="close col-xs-1"
                    data-notify="dismiss"
                    onClick={close}
                >
                    ×
                </button>
            )}

            <span data-notify="message">
                {message}
            </span>
        </div>
    )
}

export default Notification;
