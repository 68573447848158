import * as User from '../../services/UserService/user.service'

export enum Actions {
    GET_INFO = 'getInfo',
    GET_INFO_COMPLETE = 'getInfoComplete',
    GET_INFO_ERROR = 'getInfoError',
    UPDATE_INFO = 'updateInfo'
}

export type Action = { type: Actions; data?: any };
export type State = {
    customer: string,
    email: string,
    emailConfirmed: string,
    EmailConfirmed: string,
    platform: string,
    agent: string,
    agentId: string,
    driversLicenseConfirmed: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    phoneNumberConfirmed: string,
    sub: string,
    error: boolean,
    loading: boolean,
    loginToken: string,
    sellActive: string,
    buyActive: string,
    brokerageName: string
};
export type Dispatch = (action: Action) => void;

export const initialState: State = {
    customer: '',
    email: '',
    emailConfirmed: '',
    EmailConfirmed: '',
    platform: '',
    agent: '',
    agentId: '',
    driversLicenseConfirmed: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    phoneNumberConfirmed: '',
    sub: '',
    error: false,
    loading: false,
    loginToken: '',
    sellActive: '',
    buyActive: '',
    brokerageName: ''
}


export const userReducer = (state: State, action: Action) => {
    switch (action.type) {
        case Actions.GET_INFO:
            return { ...state, loading: true };
        case Actions.GET_INFO_COMPLETE:
            const data = action.data;
            return { ...state, ...data, loading: false, error: false };
        case Actions.GET_INFO_ERROR:
            return { ...state, error: true, loading: false };
        case Actions.UPDATE_INFO:
            return { ...state, loading: true, error: false };
        default:
            return state;
    }
};

export const getInfo = async (dispatch: Dispatch, loginToken: string) => {
    dispatch({ type: Actions.GET_INFO })
    try {
        const userInfo = await User.getInfo()
        dispatch({ type: Actions.GET_INFO_COMPLETE, data: { ...userInfo, loginToken } })
    } catch {
        dispatch({ type: Actions.GET_INFO_ERROR })
    }
}

export const updateInfo = async (dispatch: Dispatch, user) : Promise<boolean> => {
    dispatch({ type: Actions.UPDATE_INFO })
    try {
        const userInfo = await User.updateProfile(user)
        dispatch({ type: Actions.GET_INFO_COMPLETE, data: userInfo })
        return true;
    } catch {
        dispatch({ type: Actions.GET_INFO_ERROR })
        return false;
    }
}
