import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useScreen, { ScreenMode } from '@offerpad/floorplan/dist/hooks/useScreen';
import { Link } from 'react-router-dom';
import { AuthService } from '../../../services/AuthService/auth.service';
import { DefaultValueBinder, DefaultFieldBinderFactory } from '@offerpad/concrete/lib/model';
import { ValidationResult } from '@offerpad/concrete/lib/validation/Validator';
import { FormControl } from 'floorplan/components/v2/Form';
import * as binder from './binder';
import { useNotifications } from '../../../context/notifications/NotificationsContext';
import { notify } from '../../../context/notifications/NotificationsReducer';
import Fields from './fields';
import { Button } from 'floorplan/components/v2/Button';
import { useAppInsights } from '../../../context/AppInsightsContext';
import { path, RouteName } from '../../../routing/Routes';
import TokenService from '../../../services/token.service';
import AuthLeftPanel from '../Login/AuthLeftPanel';
import OrDivision from '../../UIComponents/OrDivision';
import classes from '../../../assets/sass/offerpad/autform.module.scss';
import './Register.scss';
import cx from 'classnames';

import { buttonStyles } from '../Login/Login';

const processError = (errorMessage: string, data: any) => {
    if (errorMessage && errorMessage.search('is already taken') === -1) {
        return ` ${data.email} is already in use.`;
    }
    return 'There was a problem while creating your account.';
};

const postProcessFields = (model: any, email: string) => {
    if (email) {
        model.fields.email = {
            value: email,
            validation: { valid: true, rule: '', message: '' },
        };
    }
    model.valid = true;
    for (let field in model.fields) {
        model.valid = model.valid && model.fields[field].validation.valid;
    }
}

const Register = () => {
    const history = useHistory();
    const screen = useScreen();

    if (TokenService.getToken()) {
        history.push(path(RouteName.Home));
    }

    const params = new URLSearchParams(useLocation().search);

    const getInitialValue = (field: string) => {
        const value = params.get(field);
        return value ? value : '';
    };

    const appInsights = useAppInsights();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPasswordMessage, setShowPasswordMessage] = useState(false);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const { dispatch: notificationsDispatch } = useNotifications();

    const [firstName, setFirstName] = useState(getInitialValue('firstName'));
    const [lastName, setLastName] = useState(getInitialValue('lastName'));
    const [email, setEmail] = useState(getInitialValue('email'));
    const [emailFrosen] = useState(!!email);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const valueBinder = new DefaultValueBinder();
    const fieldBinderFactory = new DefaultFieldBinderFactory(valueBinder);
    const form = binder.create(fieldBinderFactory);

    const onSubmitHandler = async (e: React.FormEvent) => {
        e.preventDefault();

        const model = form.bind(e.target as HTMLFormElement);

        postProcessFields(model, email);

        if (model.valid) {
            const passwordValue = model.fields[Fields.Password].value;
            if (passwordValue !== model.fields[Fields.ConfirmPassword].value) {
                return;
            }
            const data = {
                firstName: model.fields[Fields.FirstName].value,
                lastName: model.fields[Fields.LastName].value,
                email: model.fields[Fields.Email].value,
                password: passwordValue,
                agent: false,
                agentId: null,
                phoneNumber: '',
            };
            try {
                setIsSubmitting(true);
                const response = await AuthService.register(data);
                if (response.status === 200) {
                    notify(notificationsDispatch, {
                        message: 'Almost done! Please check your email to confirm your account.',
                        type: 'success',
                    });
                    history.push(path(RouteName.Login));
                }
            } catch (error) {
                const errorMessage = error.message;
                appInsights.trackTrace({ message: errorMessage });
                setIsSubmitting(false);
                notify(notificationsDispatch, { message: processError(errorMessage, data), type: 'danger' });
            }
        } else {
            const foundErrors = {};
            Object.keys(model.fields).forEach((field) => {
                const { validation } = model.fields[field];
                foundErrors[field] = validation.message;
            });
            setErrors({ ...foundErrors });
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const field = form.fields[e.target.name];
        const { value } = e.target;
        switch (e.target.name) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'password':
                setPassword(value);
                setShowPasswordMessage(confirmPassword !== '' && confirmPassword !== value);
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                setShowPasswordMessage(password !== value);
                break;
            case 'email':
                setEmail(value);
                break;
            default:
        }
        const result: ValidationResult = field.validator.validate(value);
        setErrors({ ...errors, [e.target.name]: result.message });
    };

    const dataQa = 'register-forgot-password';

    return (
        <div className={classes['c-authform__container']}>
            <AuthLeftPanel title="Create your Offerpad portal account" subtitle="Enjoy the benefits of the portal!" />
            <form className={cx(classes['c-authform'], classes.register)} onSubmit={(e) => onSubmitHandler(e)}>
                <fieldset>
                    <legend />
                    {screen.mode > ScreenMode.Mobile && (
                        <div className={classes['formatted-name']}>
                            <FormControl
                                onChange={(e) => onChange(e)}
                                label="First Name"
                                name={Fields.FirstName}
                                id={Fields.FirstName}
                                value={firstName}
                                errorText={errors[Fields.FirstName]}
                                placeholder="First Name"
                                hasError={!!errors[Fields.FirstName]}
                                showErrorIcon={!!errors[Fields.FirstName]}
                                className={classes['name-component']}
                                data-qa="register-firstname"
                            />
                            <FormControl
                                onChange={(e) => onChange(e)}
                                label="Last Name"
                                name={Fields.LastName}
                                id={Fields.LastName}
                                value={lastName}
                                errorText={errors[Fields.LastName]}
                                placeholder="Last Name"
                                hasError={!!errors[Fields.LastName]}
                                showErrorIcon={!!errors[Fields.LastName]}
                                className={classes['name-component']}
                                data-qa="register-lastname"
                            />
                        </div>
                    )}
                    {screen.mode <= ScreenMode.Mobile && (
                        <>
                            <FormControl
                                onChange={(e) => onChange(e)}
                                label="First Name"
                                name={Fields.FirstName}
                                id={Fields.FirstName}
                                value={firstName}
                                errorText={errors[Fields.FirstName]}
                                placeholder="First Name"
                                hasError={!!errors[Fields.FirstName]}
                                showErrorIcon={!!errors[Fields.FirstName]}
                                data-qa="register-firstname"
                            />
                            <FormControl
                                onChange={(e) => onChange(e)}
                                label="Last Name"
                                name={Fields.LastName}
                                id={Fields.LastName}
                                value={lastName}
                                errorText={errors[Fields.LastName]}
                                placeholder="Last Name"
                                hasError={!!errors[Fields.LastName]}
                                showErrorIcon={!!errors[Fields.LastName]}
                                data-qa="register-lastname"
                            />
                        </>
                    )}
                    <FormControl
                        type="email"
                        onChange={(e) => onChange(e)}
                        label="Email Address"
                        name={Fields.Email}
                        id={Fields.Email}
                        disabled={emailFrosen}
                        value={email}
                        errorText={errors[Fields.Email]}
                        placeholder="Email Address"
                        hasError={!!errors[Fields.Email]}
                        showErrorIcon={!!errors[Fields.Email]}
                        data-qa="register-email"
                    />
                    {showPasswordMessage ? (
                        <div className="password-error-message">Passwords do not match!</div>
                    ) : (
                        <div />
                    )}
                    <FormControl
                        type="password"
                        onChange={(e) => onChange(e)}
                        label="Create a password"
                        name={Fields.Password}
                        id={Fields.Password}
                        value={password}
                        errorText={errors[Fields.Password]}
                        placeholder="Password"
                        hasError={!!errors[Fields.Password]}
                        showErrorIcon={!!errors[Fields.Password]}
                        data-qa="register-password"
                        className='fs-exclude'
                    />
                    <div className="password-copy">
                        Passwords must be at least six characters long and include digits, lower and upper case letters
                        and special characters.
                    </div>
                    <FormControl
                        type="password"
                        onChange={(e) => onChange(e)}
                        label="Confirm your password"
                        name={Fields.ConfirmPassword}
                        id={Fields.ConfirmPassword}
                        value={confirmPassword}
                        errorText={errors[Fields.ConfirmPassword]}
                        placeholder="Re-enter password"
                        hasError={!!errors[Fields.ConfirmPassword]}
                        showErrorIcon={!!errors[Fields.ConfirmPassword]}
                        data-qa="register-re-enter-password"
                        className='fs-exclude'
                    />
                </fieldset>
                <Button id="register-already-have-account" fullWidth primary type="submit" style={buttonStyles}>
                    {isSubmitting ? 'Creating your account...' : 'Create my account'}
                </Button>
                <OrDivision />
                <div className="regisration-form-divider" />
                <div className={cx(classes['c-authform__link'], classes['c-authform__new'])}>
                    Have an account already?
                    {' '}
                    <Link id={dataQa} to="/login" data-qa={dataQa}>
                        Sign in here
                    </Link>
                </div>
            </form>
        </div>
    );
};

export default Register;
