import React from 'react';
import { useModal } from '@offerpad/concrete.react/lib/Modal';
import CalendarEventModal from '../../Modals/CalendarEventModal';
import ChangeCOEModal from '../../Modals/ChangeCOEModal';
import MessagingModal from '../../Modals/MessagingModal';
import AcceptOfferModal from '../../Modals/AcceptOfferModal/AcceptOfferModal';
import CounterSubmittedModal from '../../Modals/CounterSubmittedModal/CounterSubmittedModal';
import HBSubmittedModal from '../../Modals/HBSubmittedModal/HBSubmittedModal';
import OfferAcceptedModal from '../../Modals/OfferAcceptedModal/OfferAcceptedModal';
import RejectedOfferModal from '../../Modals/RejectedOfferModal/RejectedOfferModal';
import RejectOfferModal from '../../Modals/RejectOfferModal/RejectOfferModal';
import WidthdrawedOfferModal from '../../Modals/WidthdrawedOfferModal/WidthdrawedOfferModal';
import WidthdrawOfferModal from '../../Modals/WidthdrawOfferModal/WidthdrawOfferModal';
import OfferRenewalModal from '../../Modals/OfferRenewalModal';
import OfferRoundMessageModal from '../../Modals/OfferRoundMessageModal/OfferRoundMessageModal';
import PhotoGalleryModal from '../../Modals/PhotoGalleryModal';
import OfferActivityContinueModal from 'src/components/Modals/OfferActivityContinueModal';
import MessageHistoryModal from '../../Modals/MessageHistoryModal/MessageHistoryModal';
import './ModalController.scss';

export enum Modals {
    CHANGE_COE_MODAL = 'CHANGE_COE_MODAL',
    OFFER_RENEWAL_MODAL = 'OFFER_RENEWAL_MODAL',
    PHOTO_GALLERY_MODAL = 'PHOTO_GALLERY_MODAL',
    MESSAGING_MODAL = 'MESSAGING_MODAL',
    CALENDAR_EVENT_MODAL = 'CALENDAR_EVENT_MODAL',
    OFFER_ROUND_MESSAGE_MODAL = 'OFFER_ROUND_MESSAGE_MODAL',
    ACCEPT_OFFER_MODAL = 'ACCEPT_OFFER_MODAL',
    OFFER_ACCEPTED_MODAL = 'OFFER_ACCEPTED_MODAL',
    WITHDRAW_OFFER_MODAL = 'WITHDRAW_OFFER_MODAL',
    REJECT_OFFER_MODAL = 'REJECT_OFFER_MODAL',
    WITHDRAWED_OFFER_MODAL = 'WITHDRAWED_OFFER_MODAL',
    REJECTED_OFFER_MODAL = 'REJECTED_OFFER_MODAL',
    COUNTER_SUBMITTED_MODAL = 'COUNTER_SUBMITTED_MODAL',
    HB_SUBMITTED_MODAL = 'HB_SUBMITTED_MODAL',
    OFFER_ACTIVITY_CONTINUE_MODAL = 'OFFER_ACTIVITY_CONTINUE_MODAL',
    MESSAGE_HISTORY_MODAL = 'MESSAGE_HITORY_MODAL',
}

const MODAL_COMPONENTS = {
    [Modals.CHANGE_COE_MODAL]: ChangeCOEModal,
    [Modals.OFFER_RENEWAL_MODAL]: OfferRenewalModal,
    [Modals.PHOTO_GALLERY_MODAL]: PhotoGalleryModal,
    [Modals.MESSAGING_MODAL]: MessagingModal,
    [Modals.CALENDAR_EVENT_MODAL]: CalendarEventModal,
    [Modals.OFFER_ROUND_MESSAGE_MODAL]: OfferRoundMessageModal,
    [Modals.ACCEPT_OFFER_MODAL]: AcceptOfferModal,
    [Modals.OFFER_ACCEPTED_MODAL]: OfferAcceptedModal,
    [Modals.WITHDRAW_OFFER_MODAL]: WidthdrawOfferModal,
    [Modals.REJECT_OFFER_MODAL]: RejectOfferModal,
    [Modals.WITHDRAWED_OFFER_MODAL]: WidthdrawedOfferModal,
    [Modals.REJECTED_OFFER_MODAL]: RejectedOfferModal,
    [Modals.COUNTER_SUBMITTED_MODAL]: CounterSubmittedModal,
    [Modals.HB_SUBMITTED_MODAL]: HBSubmittedModal,
    [Modals.OFFER_ACTIVITY_CONTINUE_MODAL]: OfferActivityContinueModal,
    [Modals.MESSAGE_HISTORY_MODAL]: MessageHistoryModal, 
}

const ModalController: React.FC = () => {
    const { state: { modalType, modalProps } } = useModal();

    if (!modalType || !MODAL_COMPONENTS) {
        return null;
    }

    const ModalComponent = MODAL_COMPONENTS[modalType];

    return <ModalComponent {...modalProps} />;
};

export default ModalController;
