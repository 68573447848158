import { State } from "src/context/user/UserReducer";
import { Document, DocumentMessage } from "src/services/DocumentsService/types";

export const formatDocumentName = (documentName) => documentName.replace(/([a-z0-9])([A-Z])/g, '$1 $2')

export const columns = [
    'Name',
    'Assigned Date',
    'Uploaded Date',
    'Uploaded By'
]

export const sortDocuments = (column, documents) => {
    documents = documents.slice();
    switch (columns[column]) {
        case 'Name':
            return documents.sort((doc1, doc2) => {
                return doc1.type > doc2.type ? 1 : -1;
            })
        case 'Assigned Date':
            return documents.sort((doc1, doc2) => {
                return doc1.createdDateTime > doc2.createdDateTime ? -1 : 1;
            })
        case 'Uploaded Date':
            return documents.sort((doc1, doc2) => {
                const doc1Blob = doc1.documentBlobs.length > 0 ? doc1.documentBlobs[0] : null
                const doc2Blob = doc2.documentBlobs.length > 0 ? doc2.documentBlobs[0] : null
                if (doc1Blob && doc2Blob) {
                    return doc1Blob.createdDateTime > doc2Blob.createdDateTime ? -1 : 1;
                }

                if (doc1Blob && !doc2Blob) {
                    return -1;
                } else {
                    return 1;
                }
            })
        case 'Uploaded By':
            return documents.sort((doc1, doc2) => {
                const doc1Blob = doc1.documentBlobs.length > 0 ? doc1.documentBlobs[0] : null
                const doc2Blob = doc2.documentBlobs.length > 0 ? doc2.documentBlobs[0] : null
                if (doc1Blob && doc2Blob) {
                    return doc1Blob.uploadedType > doc2Blob.uploadedType ? -1 : 1;
                }

                if (doc1Blob && !doc2Blob) {
                    return -1;
                } else {
                    return 1;
                }
            })
    }
}

export const createInspectionDocument = (inspectionDocumentUrl, inspectionSubmittedDateTime, id) => {
    return {
        id: id,
        type: "3rd Party Inspection Report",
        status: 'Approved',
        group: "SellerDocuments",
        connectionId: 'null',
        connectionType: "",
        createdDateTime: null,
        createdBy: "Offerpad",
        modifiedDateTime: null,
        modifiedBy: "Offerpad",
        documentBlobs: [{
            id: 0,
            blobUrl: inspectionDocumentUrl,
            annotation: '',
            size: 0,
            mimeType: '',
            uploadedType: '',
            createdDateTime: new Date(inspectionSubmittedDateTime),
            createdBy: 'Offerpad'
        }],
        documentNotes: []
    }
}

export const createOfferStateDocuments = (id, name, contractid, completedDateTime) => {
    return {
        id: id,
        type: name,
        status: 'Approved',
        group: "OfferStateDocuments",
        connectionId: contractid,
        connectionType: "",
        createdDateTime: new Date(completedDateTime),
        createdBy: "Offerpad",
        modifiedDateTime: new Date(completedDateTime),
        modifiedBy: "Offerpad",
        documentBlobs: [{
            id: 0,
            blobUrl: '',
            annotation: '',
            size: 0,
            mimeType: '',
            uploadedType: '',
            createdDateTime: new Date(completedDateTime),
            createdBy: 'Offerpad'
        }],
        documentNotes: []
    }
}

export const getAllMessages = (user: any, document: Document) => {
    const notes: DocumentMessage[] = document?.documentNotes
        ?.filter(x => x.note)
        ?.map(x => {
            return {
                messageContent: x.note,
                createdDateTime: x.createdDateTime,
                createdBy: getDisplayName(user, x.createdBy)
            };
        })
    const blobs: DocumentMessage[] = document?.documentBlobs
        ?.filter(x => x.annotation)
        ?.map(x => {
            return {
                messageContent: x.annotation,
                createdDateTime: x.createdDateTime,
                createdBy: getDisplayName(user, x.createdBy)
            };
        })
    const allDocumentMessages: DocumentMessage[] = [...notes, ...blobs]?.sort(
        (a, b) => new Date(b.createdDateTime).getTime() - new Date(a.createdDateTime).getTime()
    );
    /* return Offerpad's and buyer's messages merged into a single list and sorted by time */
    return allDocumentMessages;
}

export const getDisplayName = (user: State, createdBy: string): string =>
    createdBy === 'InfrastructureDocumentsService' ? 'Offerpad' : `${user.firstName} ${user.lastName}`
