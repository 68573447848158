const disabledDates = (disabledDatesString, returnAsStrings = false) => {
    let disabledDates = [];

    const parsedDates = JSON.parse(disabledDatesString);

    if (parsedDates) {
        parsedDates.map((x) => {
            disabledDates = disabledDates.concat(
                x.value.map((date) => {
                    if (returnAsStrings) {
                        return date;
                    }
                    return new Date(date);
                })
            );
        });
    }

    return disabledDates;
};

export default disabledDates;
