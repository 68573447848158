export interface Faq {
    faq: string;
    link: string;
}

const PreNegotiationSteps = {
    SubmitOffer: {
        label: 'Submit Offer',
        order: 1
    },
    OfferInReview: {
        label: 'Offer In Review',
        order: 2
    }
};

const NegotiationStep = {
    OfferNegotiations: {
        label: 'Offer Negotiations',
        order: 3
    }
};

const PostNegotiationSteps = {
    AcceptanceReview: {
        label: 'Acceptance Review',
        order: 4
    },
    SubmitContract: {
        label: 'Submit Contract',
        order: 5
    },
    ContractInReview: {
        label: 'Contract In-Review',
        order: 6
    },
    UnderContract : {
        label: 'Under Contract',
        order: 7
    },
    Closed: {
        label: 'Closed',
        order: 8
    }
};

export const ProgressStepsBuyerNoNeg = {...PreNegotiationSteps, ...PostNegotiationSteps};
export const ProgressStepsBuyerYesNeg = {...PreNegotiationSteps, ...NegotiationStep, ...PostNegotiationSteps}
