export default (key) => {
    let sidebarLinks = [];

    const overview = {
        name: 'Overview',
        path: `/buyers/${key}/overview`,
    };
    sidebarLinks.push(overview);

    // const todos = {
    //     name: 'To-Dos',
    //     path: `/buyers/${key}/to-dos`,
    // };
    // sidebarLinks.push(todos);

    const offeractivity = {
        name: 'Offer Activity',
        path: `/buyers/${key}/offer-activity`,
    };
    sidebarLinks.push(offeractivity);

    const documents = {
        name: 'Documents',
        path: `/buyers/${key}/documents`,
    };
    sidebarLinks.push(documents);

    return sidebarLinks;
};
