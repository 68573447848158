import React from 'react';
import Messaging from '../Controls/Messaging';
import UserAvatar from '../Controls/UserAvatar';
import Heading from '@offerpad/floorplan/dist/typography/Heading';
import './ViewHeader.scss';

export type ViewHeaderProps = {
    address: string,
    header: string,
    subheader?: string,
    description?: string,
}

const ViewHeader: React.FC<ViewHeaderProps> = (props) => {
    const { header, subheader = null, description = null } = props;

    return <div className="ui-header">
        <div className="c-viewheader">
            <h2>{header}</h2>
            {subheader && <Heading size={4} weight='bold'>{subheader}</Heading>}

            {description && <p dangerouslySetInnerHTML={{ __html: description }}></p>}
        </div>
        <div className="c-header-toolbar">
            {/* <AddressDropdown /> */}
            <Messaging size="3x"/>
            <UserAvatar size="md" />
        </div>
    </div>
}

export default ViewHeader;
