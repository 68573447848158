import React from 'react';
import { useParams, Redirect } from 'react-router-dom';

const Status = () => {

    const { keyType, offerKey } = useParams<{ keyType: string, offerKey: string }>();

    return <Redirect to={`/${keyType}/${offerKey}/dashboard`}  />
}

export default Status;