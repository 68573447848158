import { FieldBinderFactory } from '@offerpad/concrete/lib/model';
import FormBinder from '@offerpad/concrete/lib/model/FormBinder';
import Validator from '@offerpad/concrete/lib/validation/Validator';
import * as rules from '@offerpad/concrete/lib/validation/rules';
import Fields from './fields';

export function create(binders: FieldBinderFactory, validateBrokerageName: boolean): FormBinder {
    let formBinder = {
        [Fields.FirstName]: binders.string(Validator.create([rules.required('The first name is required')])),
        [Fields.LastName]: binders.string(Validator.create([rules.required('The last name field is required')])),
        [Fields.PhoneNumber]: binders.string(
            Validator.create([
                rules.required('The phone-number field is required'),
                rules.phone('The phone-number field is invalid'),
            ])
        )
    };

    if (validateBrokerageName) {
        formBinder[Fields.BrokerageName] = binders.string(Validator.create([
            rules.required('Brokerage Name field is required')
        ]));
    }

    return new FormBinder(formBinder);
}
