import React, { useEffect } from 'react';
import PageLayout from '../Controls/PageLayout';
import { useOffer } from '../../context/offer/OfferContext';
import { loadOffer } from '../../context/offer/OfferReducer';
import { useParams, useHistory } from 'react-router-dom';
import buildSellerCheckList from '../Controls/resources/checklistUtilSeller';
import CurrentTaskCard from '../Controls/CurrentTaskCard';
import CompletedTaskCard from '../Controls/CompletedTaskCard';
import './Checklist.scss';
import { useDocuments } from '../../context/documents/DocumentsContext';
import { getDocuments } from '../../context/documents/DocumentsReducer';
import TokenService from '../../services/token.service';
import ProgressBar from '../Controls/Dashboard/ProgressBar/ProgressBar';
import HelloSign from 'hellosign-embedded';
import { useContracts } from '../../context/contracts/ContractsContext';
import { getContracts } from '../../context/contracts/ContractsReducer';
import moment from 'moment-timezone';
import getProgressInfoSeller from '../Controls/resources/progressUtilSeller';

const Checklist = () => {
    const { state: { offerState }, dispatch: offerDispatch } = useOffer();
    const { state: { disclosureInfo }, dispatch: contractsDispath } = useContracts();
    const { keyType, offerKey } = useParams<{ keyType: string, offerKey: string }>();
    const history = useHistory();
    const client = new HelloSign();
    const { state: { documents }, dispatch: documentsDispath } = useDocuments();
    const visualTrackerInfo = getProgressInfoSeller(offerState);

    const isLoggedIn = TokenService.getToken();

    client.on('close', () => getContracts(contractsDispath, offerState.acquisitionId, offerState.market));

    const openContract = (seller) => {
        if (seller.signUrl && seller.docuSignClientId) {
            client.open(seller.signUrl, {
                clientId: seller.docuSignClientId,
                skipDomainVerification: true
            });
        }
    };

    const sortCompletedTasks = (completedTasks) => {
        /* Reverse the order to have the most recent on top */
        const completed = completedTasks.reverse();
        /* Pull out from the list the tasks that have completion date */
        const completedWithDate = [];
        for (let i = 0; i < completed.length; i++) {
            if (completed[i].completionDate) {
                completedWithDate.push(completed[i]);
                completed[i] = null;
            }
        }
        /* Sort by date the tasks that do have a completion date and reinsert them in the list
        (preserving the spots of the other tasks) */
        const completedWithDateSorted =
            completedWithDate.sort((a, b) =>
                moment(a.completionDate, "MMMM D, YYYY").diff(moment(b.completionDate, "MMM DD, YYYY"), 'seconds')
            );
        for (let i = 0; i < completed.length && completedWithDateSorted.length > 0; i++) {
            if (completed[i] === null) {
                completed[i] = completedWithDateSorted.pop();
            }
        }
        return completed;
    };

    useEffect(() => {
        if (offerState.offerKey != offerKey || offerState.keyType != keyType || !offerState.thereIsOffer) {
            loadOffer(offerDispatch, offerKey, keyType, history);
        }
    }, [keyType, offerKey]);

    useEffect(() => {
        if (offerState.thereIsOffer && isLoggedIn) {
            getDocuments(documentsDispath, offerState.offerKey, "Offer", "SellerDocuments")
        }
    }, [offerState.thereIsOffer, isLoggedIn])

    const checkList = buildSellerCheckList(offerState, disclosureInfo, documents);
    useEffect(() => {
        if (offerState.thereIsOffer && isLoggedIn) {
            getContracts(contractsDispath, offerState.acquisitionId, offerState.market)
        }
    }, [offerState.thereIsOffer, isLoggedIn])


    if (checkList === null) {
        return null;
    }

    const completed = sortCompletedTasks(checkList.completed);

    const leftColumn = (
        <div className="c-checklist">
            {checkList.current.length === 0 && (
                <span className="c-checklist__card no-task-msg">
                    You do not have any current tasks assigned.
                </span>
            )}
            {checkList.current.map((item) => (
                <div key={item.id} className="c-checklist__card">
                    <CurrentTaskCard task={item} openContract={openContract} />
                </div>
            ))}
        </div>
    );

    const centerColumn = (
        <div className="c-checklist">
            {checkList.inProgress.length === 0 && (
                <span className="c-checklist__card no-task-msg">
                    No items are currently in progress.
                </span>
            )}
            {checkList.inProgress.map(item => (
                <div key={item.id} className="c-checklist__card">
                    <CurrentTaskCard task={item} openContract={openContract} />
                </div>
            ))}
        </div>
    );

    const rightColumn = (
        <>
            {checkList.completed.length === 0 && <span className="no-task-msg">You have no completed tasks yet.</span>}
            {completed.reverse().map((item) => (
                <div key={item.id}>
                    <CompletedTaskCard task={item} />
                </div>
            ))}
        </>
    );

    return (
        <PageLayout
            streetAddress={offerState.streetAddress}
            headerText="Checklist of action items"
            subHeaderText={`Welcome back, ${offerState.firstName}`}
            headerDescription={visualTrackerInfo.sentenceTop}
            listedByAgent={offerState.listedByAgent}
            leftColumnHeader={`Current Task${checkList.current.length > 1 ? 's' : ''}`}
            rightColumnHeader={`Completed Task${checkList.completed.length > 1 ? 's' : ''}`}
            centerColumn={
                <div className='dashboard'>
                    {
                        visualTrackerInfo.workflowStep &&
                        <ProgressBar show={!offerState.isLost} workflowStep={visualTrackerInfo.workflowStep} currentProgressBar={visualTrackerInfo.progressBar} progressPercentage={visualTrackerInfo.progressPercentage} />
                    }
                    <div className="row mobile-flex">
                        <div className="col-lg-4">
                            <h3 className="c-viewsubhead">To-Do</h3>
                            {leftColumn}
                        </div>
                        <div className="col-lg-4">
                            <h3 className="c-viewsubhead">In Progress</h3>
                            {centerColumn}
                        </div>
                        <div className="col-lg-4">
                            <h3 className="c-viewsubhead">Completed</h3>
                            {rightColumn}
                        </div>
                    </div>
                </div>}
        />
    );
};

export default Checklist;
