import React, { useEffect } from 'react';
import PageLayout from '../Controls/PageLayout';
import { useOffer } from '../../context/offer/OfferContext';
import { loadOffer } from '../../context/offer/OfferReducer';
import { useParams, useHistory } from 'react-router-dom';
import getTeamObject from '../Controls/resources/teamUtil';
import TeamMember from '../Controls/TeamMember';
import classes from './YourTeam.module.scss';
import ProgressBar from '../Controls/Dashboard/ProgressBar/ProgressBar';
import getProgressInfoSeller from '../Controls/resources/progressUtilSeller';

const YourTeam = () => {
    const { state: { offerState }, dispatch: offerDispatch } = useOffer();
    const { keyType, offerKey } = useParams<{ keyType: string, offerKey: string }>();
    const history = useHistory();
    const visualTrackerInfo = getProgressInfoSeller(offerState);

    const { offerpadNumber, teamMembers } = getTeamObject(offerState);
    const content = teamMembers &&
        <div className={classes['c-yourteam']}>
            {teamMembers.filter(member=>member!=null).map(member => <div className={classes['c-yourteam__card']} key={member.role}> <TeamMember teamMember={member} offerpadNumber={offerpadNumber} />
            </div>)}
        </div>

    useEffect(() => {
        if (offerState.offerKey != offerKey || offerState.keyType != keyType || !offerState.thereIsOffer) {
            loadOffer(offerDispatch, offerKey, keyType, history);
        }
    }, [keyType, offerKey]);

    return (
        <PageLayout
        headerDescription={visualTrackerInfo.sentenceTop}
        subHeaderText={`Welcome back, ${offerState.firstName}`}
            streetAddress={offerState.streetAddress}
            headerText="The team working for you"
            listedByAgent={offerState.listedByAgent}
            centerColumn={
                <>
                    {
                        visualTrackerInfo.workflowStep &&
                        <ProgressBar show={!offerState.isLost} workflowStep={visualTrackerInfo.workflowStep} currentProgressBar={visualTrackerInfo.progressBar} progressPercentage={visualTrackerInfo.progressPercentage} />
                    }
                    {content}
                </>
            }
        />
    );
}

export default YourTeam;
