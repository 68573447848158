import React from 'react';
import { Modal } from 'floorplan/components/v2/Modal';
import { Button } from 'floorplan/components/Button';
import { closeModal } from '@offerpad/concrete.react/lib/Modal';
import { useModal } from '@offerpad/concrete.react/lib/Modal';
import useScreen from '@offerpad/floorplan/dist/hooks/useScreen';
import { modalWidth } from 'src/components/Modals/common/constants';
import { Breakpoints } from 'src/utils/breakpointUtil';
import styles from '../common/OfferActionModal.module.scss';
import { useDisposition } from 'src/context/disposition/DispositionContext';

const OfferActivityContinueModal: React.FC = () => {
    const { state: { isOpen }, dispatch: modalDispatch } = useModal();
    const screen = useScreen();

    const { state: { dispositionOffer } } = useDisposition();

    const closeModalWrapper = () => {
        // we want location.href so the dispositionOffer gets refetched with new status
        // this will get rid of the Seller Counter with Document Corrections status and let someone counter
        window.location.href = (`/buyers/${dispositionOffer.id}/offer-activity`);
        closeModal(modalDispatch);
    }

    return <Modal
        open={isOpen}
        onClose={() => closeModalWrapper()}
        hasFooter
        width={screen.width < Breakpoints.DESKTOP ? modalWidth : null}
    >
        <Modal.Header
            title='Package updated'
            onClose={() => closeModalWrapper()}
            border
        />
        <Modal.Content>
            <div className={styles.modal_content}>
                Thank you for uploading your corrected offer package.
                Please continue to the Offer Activity page to complete submitting this package/counter to Offerpad.
            </div>
        </Modal.Content>
        <Modal.Footer border>
            <Button
                id="go-there"
                brand
                type="submit"
                className="modal-new-action-button"
                onClick={() => closeModalWrapper()}
            >
                Go there
            </Button>
        </Modal.Footer>
    </Modal>
}

export default OfferActivityContinueModal;