import React, { useState } from "react";
import { Grid, GridColumn } from '@offerpad/floorplan/dist/layouts/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';
import DateTimePicker from './DateTimePicker';
import { DetailType, DispositionOffer } from '../../../services/DispositionService/types';
import styles from './OfferRound.module.scss'
import { formatDate } from "../resources/datetime";
import { useDisposition } from "../../../context/disposition/DispositionContext";
import { Moment } from "moment-timezone";
import cx from 'classnames';
import { Modals } from "../ModalController/ModalController";
import { useModal } from '@offerpad/concrete.react/lib/Modal';
import { openModal } from '@offerpad/concrete.react/lib/Modal';

export type OfferRoundProps = {
    offers: Array<DispositionOffer>;
    isCompact?: boolean;
    showCounterInputs?: boolean;
    highestAndBestCounter?: boolean;
    round: number;
    isNew?: boolean;
}

const OfferRound: React.FC<OfferRoundProps> = (props) => {
    const { isCompact, ...rest } = props;
    return isCompact ?
        <Compact  {...rest} /> :
        <Extended {...rest} />
}

const blockedDates = date => {
    return date.day() !== 0 && date.day() !== 6 && date.diff(new Date(), 'days') >= 0;
}

const Compact: React.FC<OfferRoundProps> = (props) => {
    const { offers, showCounterInputs, isNew, highestAndBestCounter } = props;
    const { state: { newCounterOffer }, setNewCounterOffer } = useDisposition();
    const { dispatch: modalDispatch } = useModal();

    return <Grid className={styles.grid_compact}>
        {offers.map((offer, index) =>
            <GridColumn size={offers.length > 1 || showCounterInputs ? "6" : "12"} className={styles.column} key={index}>
                <div className={styles.flexbox}>
                    {getSentBy(offer)}
                    {offers.length - 1 === index && isNew && !showCounterInputs && <NewLogo />}
                </div>
                <div className={styles.flexbox}>
                    {formatDate(offer.proposedClosingDatetime, null, true, 'ddd, MMM D')}
                </div>
                <div className={styles.flexbox}>
                    <NumberFormat
                        thousandSeparator={true}
                        prefix={'$'}
                        value={offer.offerPrice}
                        displayType={'text'}
                    />
                </div>
                <div className={styles.flexbox}>
                    <NumberFormat
                        thousandSeparator={true}
                        prefix={'$'}
                        value={offer.requestedConcessions || 0}
                        displayType={'text'}
                    />
                </div>
                <div className={styles.flexbox}>
                    <NumberFormat
                        thousandSeparator={true}
                        prefix={'$'}
                        value={offer.earnestMoney}
                        displayType={'text'}
                    />
                </div>
                <div
                    className={cx(
                        styles.flexbox,
                        offer.message ? styles.message_opener : styles.message_opener_disabled
                    )}
                    onClick={() => {
                        openModal(
                            modalDispatch,
                            Modals.OFFER_ROUND_MESSAGE_MODAL,
                            {
                                message: offer.message,
                                isCounter: false
                            })
                    }}
                >
                    View Message
                </div>
            </GridColumn>
        )}
        {showCounterInputs && offers.length < 2 &&
            <GridColumn size="6" className={styles.column}>
                <div className={styles.flexbox}>
                    {showCounterInputs && offers.length < 2 &&
                        <>
                            {highestAndBestCounter ? <>
                                <FontAwesomeIcon icon={['fas', 'star']} className={styles.logo} />
                                H&amp;B
                                <NewLogo />
                            </> :
                                <>
                                    <FontAwesomeIcon icon={['fas', 'user']} className={styles.logo} />
                                    Buyer
                                    <NewLogo />
                                </>}
                        </>
                    }
                </div>
                <div className={styles.flexbox}>
                    <DateTimePicker
                        blockedDates={blockedDates}
                        onChange={(e: Moment) => setNewCounterOffer({ ...newCounterOffer, proposedClosingDatetime: e.toDate() })}
                        value={newCounterOffer.proposedClosingDatetime}
                    />
                    <FontAwesomeIcon icon={['far', 'calendar']} className={styles.calendar} />
                </div>
                <div className={styles.flexbox}>
                    <NumberFormat
                        className={styles.inputs}
                        thousandSeparator={true}
                        prefix={'$'}
                        allowNegative={false}
                        isNumericString={true}
                        value={newCounterOffer.offerPrice}
                        onValueChange={(e) => setNewCounterOffer({ ...newCounterOffer, offerPrice: e.floatValue })}
                    />
                </div>
                <div className={styles.flexbox}>
                    <NumberFormat
                        className={styles.inputs}
                        thousandSeparator={true}
                        prefix={'$'}
                        allowNegative={false}
                        isNumericString={true}
                        value={newCounterOffer.requestedConcessions}
                        onValueChange={(e) => setNewCounterOffer({ ...newCounterOffer, requestedConcessions: e.floatValue })}
                    />
                </div>
                <div className={styles.flexbox}>
                    <NumberFormat
                        className={styles.inputs}
                        thousandSeparator={true}
                        prefix={'$'}
                        allowNegative={false}
                        isNumericString={true}
                        value={newCounterOffer.earnestMoney}
                        onValueChange={(e) => setNewCounterOffer({ ...newCounterOffer, earnestMoney: e.floatValue })}
                    />
                </div>
                <div
                    className={cx(styles.flexbox, styles.message_opener)}
                    onClick={() => {
                        openModal(
                            modalDispatch,
                            Modals.OFFER_ROUND_MESSAGE_MODAL,
                            {
                                message: newCounterOffer.message,
                                isCounter: true
                            })
                    }}>
                    Add Message
                </div>
            </GridColumn>}
    </Grid>;
}

const Extended: React.FC<OfferRoundProps> = (props) => {
    const { offers, showCounterInputs, round, isNew, highestAndBestCounter } = props;
    const { state: { newCounterOffer }, setNewCounterOffer } = useDisposition();
    const { dispatch: modalDispatch } = useModal();

    return (
        <>
            <Grid className={styles.grid_extended}>
                <GridColumn size="12" className={styles.round}>
                    Round {round} {isNew && <NewLogo />}
                </GridColumn>
                <Grid className={styles.header}>
                    {offers.map((offer, index) => <GridColumn size={offers.length > 1 || showCounterInputs ? "6" : "12"} key={`header-${index}`}>
                        <div className={styles.flexbox}>
                            {getSentBy(offer)}
                        </div>
                    </GridColumn>)}
                    {showCounterInputs && offers.length < 2 &&
                        <GridColumn size="6">
                            <div className={styles.flexbox}>
                                {highestAndBestCounter ? <>
                                    <FontAwesomeIcon icon={['fas', 'star']} className={styles.logo} />
                                    H&amp;B
                                </> :
                                    <>
                                        <FontAwesomeIcon icon={['fas', 'user']} className={styles.logo} />
                                        Buyer
                                    </>}
                            </div>
                        </GridColumn>
                    }
                </Grid>
                <Grid className={styles.body} >
                    {offers.map((offer, index) =>
                        <GridColumn size={offers.length > 1 || showCounterInputs ? "6" : "12"} className={styles.column} id={`extended_round-${round}`} key={`body-${index}`}>
                            <div className={styles.flexbox}>
                                {formatDate(offer.proposedClosingDatetime, null, true, 'ddd, MMM D')}
                            </div>
                            <div className={styles.flexbox}>
                                <NumberFormat
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    value={offer.offerPrice}
                                    displayType={'text'}
                                />
                            </div>
                            <div className={styles.flexbox}>
                                <NumberFormat
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    value={offer.requestedConcessions || 0}
                                    displayType={'text'}
                                />
                            </div>
                            <div className={styles.flexbox}>
                                <NumberFormat
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    value={offer.earnestMoney}
                                    displayType={'text'}
                                />
                            </div>
                            <div
                                className={cx(
                                    styles.flexbox,
                                    offer.message ? styles.message_opener : styles.message_opener_disabled
                                )}
                                onClick={() => {
                                    openModal(
                                        modalDispatch,
                                        Modals.OFFER_ROUND_MESSAGE_MODAL,
                                        {
                                            message: offer.message,
                                            isCounter: false
                                        })
                                }}
                            >
                                View Message
                            </div>
                        </GridColumn>)}
                    {showCounterInputs && offers.length < 2 &&
                        < GridColumn size="6" className={styles.column}>
                            <div className={styles.flexbox}>
                                <DateTimePicker
                                    blockedDates={blockedDates}
                                    onChange={(e: Moment) => setNewCounterOffer({ ...newCounterOffer, proposedClosingDatetime: e.toDate() })}
                                    className={styles.rdt}
                                    value={newCounterOffer.proposedClosingDatetime}
                                />
                                <FontAwesomeIcon icon={['far', 'calendar']} className={styles.calendar} />
                            </div>
                            <div className={styles.flexbox}>
                                <NumberFormat
                                    className={styles.inputs}
                                    thousandSeparator={true}
                                    onValueChange={(e) => setNewCounterOffer({ ...newCounterOffer, offerPrice: e.floatValue })}
                                    prefix={'$'}
                                    allowNegative={false}
                                    isNumericString={true}
                                    placeholder="Enter Price"
                                    value={newCounterOffer.offerPrice}
                                />
                            </div>
                            <div className={styles.flexbox}>
                                <NumberFormat
                                    className={styles.inputs}
                                    thousandSeparator={true}
                                    onValueChange={(e) => setNewCounterOffer({ ...newCounterOffer, requestedConcessions: e.floatValue })}
                                    prefix={'$'}
                                    allowNegative={false}
                                    isNumericString={true}
                                    placeholder="$0"
                                    value={newCounterOffer.requestedConcessions}
                                />
                            </div>
                            <div className={styles.flexbox}>
                                <NumberFormat
                                    className={styles.inputs}
                                    thousandSeparator={true}
                                    onValueChange={(e) => setNewCounterOffer({ ...newCounterOffer, earnestMoney: e.floatValue })}
                                    prefix={'$'}
                                    allowNegative={false}
                                    isNumericString={true}
                                    placeholder="Enter Value"
                                    value={newCounterOffer.earnestMoney}
                                />
                            </div>
                            <div
                                className={cx(styles.flexbox, styles.message_opener)}
                                onClick={() => {
                                    openModal(
                                        modalDispatch,
                                        Modals.OFFER_ROUND_MESSAGE_MODAL,
                                        {
                                            message: newCounterOffer.message,
                                            isCounter: true
                                        })
                                }}
                            >
                                Add Message
                            </div>
                        </GridColumn>
                    }
                </Grid>
            </Grid >
        </>
    );
}

const getSentBy = (offer: DispositionOffer) => {
    if (offer.detailType === DetailType.HIGHEST_AND_BET_RECEIVED) {
        return <>
            <FontAwesomeIcon icon={['fas', 'star']} className={styles.logo} />
            H&amp;B
        </>
    }

    if (offer.sentBy === "Buyer") {
        return <>
            <FontAwesomeIcon icon={['fas', 'user']} className={styles.logo} />
            Buyer
        </>
    } else {
        return <>
            <OfferpadLogo />
            Offerpad
        </>
    }
}

const OfferpadLogo = () => <svg
    className={styles.logo}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-labelledby="preloader-icon"
    viewBox="8 0 100 100"
>
    <title id="preloader-icon">Offerpad House Icon</title>
    <path
        d="M72 5.16a17.74 17.74 0 0 0-25 0L7.21 44.96A24.66 24.66 0 0 0 0 62.39v39.14a17.75 17.75 0 0 0 17.56 17.54h83.87A17.63 17.63 0 0 0 119 101.5V62.38a24.64 24.64 0 0 0-7.21-17.42z"
        fill="currentColor"
    />
</svg>

const NewLogo = () => <div className={styles.new_logo}>
    NEW
</div>

export default OfferRound;
