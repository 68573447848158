import { ReactElement } from "react";
export interface Faq {
    faq: string;
    link: string;
    answer?: string | ReactElement ;
}

const ProgressStepsSeller = {
    Offer: {
        label: 'Offer',
        order: 1
    },
    Contract: {
        label: 'Contract',
        order: 2
    },
    Inspection: {
        label: 'Inspection',
        order: 3
    },
    PrepareForClosing: {
        label: 'Prepare for Closing',
        order: 4
    },
    Closing: {
        label: 'Closing',
        order: 5
    }
};

export default ProgressStepsSeller;
