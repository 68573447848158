import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './SurveyPage.scss'
import SurveyModal from './SurveyModal/SurveyModal';
import ExitSurveyContent from './ExitSurveyContent';
import { useOffer } from '../../../context/offer/OfferContext';
import { loadOffer } from '../../../context/offer/OfferReducer';
import { buildTeamMember } from '../../../components/Controls/resources/teamUtil';

import Spinner from 'floorplan/components/Spinner';
import TokenService from '../../../services/token.service';

const ExitSurveyPage = () => {
    const history = useHistory();
    const { state: { offerState, loading }, dispatch: offerDispatch } = useOffer();
    const { offerKey } = useParams<{ offerKey: string }>();
    const COMPANY_TYPE_TITLE = 'COMPANY_TYPE_TITLE';
    const COMPANY_TYPE_MOVING = 'COMPANY_TYPE_MOVING';

    const getImageUrl = (company, type) => {
        switch ((company.companyName ?? '').toLowerCase()) {
            /* Moving companies */
            case 'dircks':
                return require('@assets/movers/dircks_image.jpg');
            case 'bellhop':
            case 'bellhops':
                return require('@assets/movers/bellhop_image.jpg');
            /* Title companies */
            case 'first american title':
                return require('@assets/first-american-logo.png');
            case 'endpoint closing':
                return require('@assets/endpoint-logo.png');
            case 'hankin & pack pllc':
                return require('@assets/hankin-pack-logo.png');
            case 'mcmichael & gray, pc':
                return require('@assets/mc-michael-and-gray-logo.png');
            case 'statewide title services':
                return require('@assets/statewide-title-logo.png');
            default:
                if (type === COMPANY_TYPE_TITLE) {
                    return require('@assets/first-american-logo.png');
                } else { /* type === 'MOVING' */
                    return require('@assets/movers/bellhop_image.jpg');
                }
        }
    }

    const acquisitionId = offerState.acquisitionId;
    const exitSurveyReady = offerState.exitSurveyReady;
    const surveyAlreadyTaken =!!offerState.exitSurvey;
    const loggedIn = TokenService.getToken();

    let movingCompany = buildTeamMember({ ROLE: 'Moving Company Contact', FIELD: 'movingCompanyContact' }, offerState);
    if (movingCompany) {
        movingCompany.imageURL = getImageUrl(movingCompany, COMPANY_TYPE_MOVING);
    }

    let transactionManager = buildTeamMember({ ROLE: 'Transaction Manager', FIELD: 'closingConsultant', OWNER: 'ClosingConsultant', }, offerState);
    if (transactionManager) {
        transactionManager.imageURL = transactionManager.imageURL ||
            `https://ui-avatars.com/api/?bold=true&color=ffffff&background=1b6775&format=svg&name=${transactionManager.fullName}`;
    }

    let titleCompany = buildTeamMember({ ROLE: 'Title Contact', FIELD: 'titleContact' }, offerState);
    if (titleCompany) {
        titleCompany.imageURL = getImageUrl(titleCompany, COMPANY_TYPE_TITLE);
    }


    useEffect(() => {
        loadOffer(offerDispatch, offerKey, 'offer', history);
    }, [offerKey]);

    if (loading) {
        return <div className="c-homelist__listings spinner-container">
            <Spinner />
        </div>
    }

    return (
        !!offerState.acquisitionId &&
        <SurveyModal>
            {surveyAlreadyTaken &&
                <div className="p-4">
                    <div className="survey-not-available">
                        <h5>
                            It looks like you already took this survey, thanks for your feedback!
                        </h5>
                        <p>
                            You can navigate back to{' '}
                            {
                                loggedIn ?
                                    <a id='exit-survey-page-link-2890'  href={`${process.env.RAZZLE_CONNECT_URL}/sell/overview?transactionId=${acquisitionId}`}>your Dashboard</a> :
                                    <a id='exit-survey-page-anchor-2890' href="https://offerpad.com" rel="noopener">the Offerpad main page</a>
                            }.
                        </p>
                    </div>
                </div>
            }
            {!surveyAlreadyTaken && !exitSurveyReady &&
                <div className="p-4">
                    <div className="survey-not-available">
                        <h5>
                            It looks like this survey is not accessible at the moment, please check again later.
                        </h5>
                        <p>
                            You can navigate back to{' '}
                            {
                                loggedIn ?
                                    <a id='exit-survey-page-link-5296' href={`${process.env.RAZZLE_CONNECT_URL}/sell/overview?transactionId=${acquisitionId}`}>your Dashboard</a> :
                                    <a id='exit-survey-page-anchor-5296' href="https://offerpad.com" rel="noopener">the Offerpad main page</a>
                            }.
                        </p>
                    </div>
                </div>
            }
            {!surveyAlreadyTaken && exitSurveyReady &&
                <ExitSurveyContent
                    acquisitionId={acquisitionId}
                    movingCompany={movingCompany}
                    transactionManager={transactionManager}
                    titleCompany={titleCompany}
                />
            }
        </SurveyModal>
    );
}

export default ExitSurveyPage;
