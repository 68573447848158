import React, { useRef, useState } from 'react';
import moment from 'moment';
import { DocumentMessage } from 'src/services/DocumentsService/types';
import { openModal } from '@offerpad/concrete.react/lib/Modal';
import { useModal } from '@offerpad/concrete.react/lib/Modal';
import { Modals } from './ModalController/ModalController';
import Modal from 'floorplan/components/Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'floorplan/components/v2/Button';
import cx from 'classnames';
import axios from 'axios';
import styles from './DocumentCard.module.scss';

const _document = document;

const DocumentCard: React.FC<DocumentCardProps> = (props) => {
    const inputFile = useRef(null);
    const [message, setMessage] = useState<string>("");
    const [modal, setModal] = useState<boolean>(false)
    const { dispatch: modalDispatch } = useModal();

    const latestMessageSection = (className: string, classNameSuffix: string) => {
        return (
            props.messages?.length > 0 &&
            props.messages[0].messageContent &&
            <>
                <div className={className}><span>Latest Message: </span>
                    {props.messages[0].messageContent}
                </div>
                <p className={styles[`message_date_${classNameSuffix}`]}>{moment(props.messages[0].createdDateTime).format("MM/DD/YY, h:m A")}</p>
            </>
        )
    };

    const messageHistorySection = (classNameSuffix: string) => {
        return (
            props.showMessageHistory &&
            props.messages?.length > 0 &&
            <>
                {classNameSuffix === 'mobile' && <div className="u-spacer-xs" />}
                <div
                    className={styles[`view_messages_${classNameSuffix}`]}
                    onClick={() => {
                        openModal(modalDispatch, Modals.MESSAGE_HISTORY_MODAL, { messages: props.messages })
                    }}
                >
                    View message history
                </div>
                {classNameSuffix === 'mobile' && <div className="u-spacer-xs" />}
            </>
        )
    };

    const downloadDocument = async (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e.preventDefault();
        const link = _document.createElement('a');
        const response = await axios.get(props.url);
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        link.href = URL.createObjectURL(blob);
        link.download = props.url.split("/").pop();
        link.click();
        URL.revokeObjectURL(link.href);
    }
    if (props.url) {
        return <div className={styles.document_card} data-testid={props.name}>
            <div className={cx(styles.row)}>
                <div className={styles.desktop_field}>
                    <FontAwesomeIcon className={styles.checkIcon} icon={['fas', 'check-circle']} />
                    <a id={`desktop-name-${props.name}`} href={props.url} target="_blank" className={styles.documentLink}>
                        {props.name}
                    </a>
                </div>
                {props.assignedDate && <div className={styles.desktop_field} >{props.assignedDate}</div>}
                <div className={styles.desktop_field} >{props.uploadDate}</div>
                <div className={styles.desktop_field} >{props.uploadedBy}</div>
                <div className={styles.mobileDocument}>
                    <FontAwesomeIcon className={styles.checkIcon} icon={['fas', 'check-circle']} />
                    <div>
                        <a id={`mobile-name-${props.name}`} href={props.url} target="_blank" className={styles.documentLink}>
                            {props.name}
                        </a>
                        <div className="u-spacer-xs" />
                        {props.assignedDate && <div><span>Assigned Date: </span>
                            {props.assignedDate}
                        </div>}
                        <div><span>Upload Date:</span> {props.uploadDate}</div>
                        <div><span>Uploaded By:</span> {props.uploadedBy}</div>
                        <div className="u-spacer-xs" />
                        {latestMessageSection('', 'mobile')}
                        {messageHistorySection('mobile')}
                    </div>
                </div>
                <div className={styles.document_icons}>
                    {props.allowDelete && <FontAwesomeIcon
                        id="document-delete"
                        className={styles.icons}
                        icon={['far', 'trash']}
                        onClick={() => setModal(true)}
                        data-testid="delete-icon"
                    />}
                    <FontAwesomeIcon
                        id="documents-download-document"
                        className={styles.icons}
                        icon={['far', 'download']}
                        onClick={(e) => downloadDocument(e)} />
                </div>
            </div>
            <div className={styles.desktopDetails}>
                {
                    props.messages?.length > 0 &&
                    <FontAwesomeIcon className={styles.checkIcon} style={{ visibility: 'hidden' }} icon={['fas', 'check-circle']} />
                }
                <div>
                    {latestMessageSection(styles.message_desktop, 'desktop')}
                    {messageHistorySection('desktop')}
                </div>
            </div>

            <Modal
                isOpen={modal}
                onClose={() => setModal(false)}
                className={styles.modal}
            >
                <h1>Delete Document</h1>
                <p style={{ whiteSpace: 'normal' }}>
                    Are you sure you want to delete this document? You will not be able to undo this action.
                </p>
                <div className={styles.footer}>
                    <Button
                        id="documents-modal-new-cancel"
                        ghost
                        large
                        className={styles.ghost}
                        onClick={() => setModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        id="documents-modal-new-delete-blob"
                        data-testid="documents-modal-new-delete-blob"
                        brand
                        large
                        onClick={() => props.onDelete().then(() => setModal(false))}
                        progress={props.deleting}
                    >
                        Delete
                    </Button>
                </div>
            </Modal>
        </div>
    }

    return <div className={styles.document_card} data-testid={props.name}>
        {props.uploadProgress >= 0 && <div className={styles.progressBarContainer}>
            <div className={styles.progressBar} style={{ width: `${props.uploadProgress}%` }}>
            </div>
        </div>}
        <div className={styles.mobileUpload}>
            {props.uploadProgress == 100 && props.allowMessage ?
                <>
                    <FontAwesomeIcon className={styles.checkIcon} icon={['fas', 'check-circle']} />
                    <a id='documents-blob-url-anchor' href={'#'} target="_blank" className={styles.documentLink}>
                        {props.name}
                    </a>
                </>
                :
                <>
                    {!props.hideCircle && <FontAwesomeIcon className={styles.circleIcon} icon={['far', 'circle']} />}
                    <div className={styles.documentName}>
                        {props.name}
                    </div>
                </>
            }
        </div>
        <div className={styles.row}>
            <div className={cx(styles.desktop_field)}>
                {props.uploadProgress == 100 && props.allowMessage ?
                    <>
                        <FontAwesomeIcon className={styles.checkIcon} icon={['fas', 'check-circle']} />
                        <a id='documents-blob-url-anchor' href={'#'} target="_blank" className={styles.documentLink}>
                            {props.name}
                        </a>
                    </>
                    :
                    <>
                        {!props.hideCircle && <FontAwesomeIcon className={styles.circleIcon} icon={['far', 'circle']} />}
                        <div className={styles.documentName}>
                            {props.name}
                        </div>
                    </>
                }
            </div>
            {(props.uploadProgress == 100 && props.allowMessage) ||
                <>
                    {props.assignedDate && <div className={styles.desktop_field}>{props.assignedDate}</div>}
                    <div className={styles.desktop_field}>{props.uploadDate}</div>
                    <div className={styles.desktop_field}>{props.uploadedBy}</div>
                    <div className={styles.documentUploading}>
                        {props.uploadProgress >= 0 ? <>
                            <FontAwesomeIcon
                                className={styles.icons}
                                icon={['fas', 'times']}
                                onClick={() => props.onCancelUpload()}
                                id='cancel-upload-icon'
                                data-testid='cancel-upload-icon'
                            />
                            <div className={styles.uploading}>
                                Uploading - {props.uploadProgress}%
                            </div> </> : <>
                            <Button
                                id="upload-document"
                                data-testid="upload-document"
                                brand
                                onClick={() => { inputFile?.current?.click() }}>
                                Upload
                            </Button>
                            <input
                                type='file'
                                id='file-input'
                                data-testid="file-input"
                                ref={inputFile}
                                style={{ display: 'none' }}
                                onChange={(e) => { props.onFileInputChange(e); inputFile.current.value = null; }}
                                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                        </>}
                    </div></>}
        </div>
        <div className={styles.pendingUploadDetails}>
            <div>
                {latestMessageSection(styles.message_pending, 'pending')}
                {messageHistorySection('pending')}
            </div>
        </div>
        {props.uploadProgress >= 0 && props.allowMessage &&
            <textarea
                className={cx(styles.message, message == "" ? styles.empty_message : null)}
                maxLength={200}
                rows={4}
                placeholder="Enter message (optional)"
                value={message}
                id='message'
                data-testid='message'
                onChange={(e) => setMessage(e.target.value)}
            ></textarea>
        }
        {props.uploadProgress >= 0 && props.allowMessage &&
            <div className={styles.footer}>
                <Button ghost id="cancel-btn" className={styles.ghost} onClick={() => props.onCancel()} >
                    Cancel
                </Button>
                <Button secondary id="remove-btn" onClick={() => props.onRemove()} disabled={props.uploadProgress != 100}>
                    Remove
                </Button>
                <Button primary id="submit-btn" data-testid="submit-btn" onClick={() => setModal(true)} disabled={props.uploadProgress != 100}>
                    Submit
                </Button>
            </div>
        }
        <Modal
            isOpen={modal}
            onClose={() => setModal(false)}
            className={styles.modal}
        >
            <h1>Submit Package</h1>
            <hr></hr>
            <p style={{ whiteSpace: 'normal' }}>
                Are you sure you want to submit the {props.name}? You will not be able to undo this action.
            </p>
            <hr></hr>
            <div className={styles.footer}>
                <Button
                    id="confirmation-cancel"
                    ghost
                    large
                    className={styles.ghost}
                    onClick={() => setModal(false)}
                >
                    Cancel
                </Button>
                <Button
                    id="confirmation-submit"
                    data-testid="confirmation-submit"
                    brand
                    large
                    onClick={(e) => props.onSubmit(message).then(() => {
                        setModal(false)
                        if (typeof props.submitPackageCallBack === 'function') {
                            props.submitPackageCallBack();
                        }
                    })}
                    progress={props.submitting}
                >
                    Submit Package
                </Button>
            </div>
        </Modal>
    </div>
}

export type DocumentCardProps = {
    uploadDate: string,
    uploadedBy: string,
    name: string,
    url?: string,
    messages?: DocumentMessage[],
    showMessageHistory?: boolean,
    allowDelete?: boolean,
    uploadProgress?: number,
    assignedDate?: string,
    allowMessage?: boolean,
    hideCircle?: boolean,
    deleting?: boolean,
    submitting?: boolean,
    submitPackageCallBack?: () => void,
    onCancelUpload?: () => void,
    onDelete?: () => Promise<void>,
    onCancel?: () => void,
    onRemove?: () => void,
    onSubmit?: (message: string) => Promise<void>,
    onFileInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export default DocumentCard;
