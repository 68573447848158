const apiManagementUrl = process.env.RAZZLE_API_MANAGEMENT_URL;
import { AxiosResponse } from 'axios';
import { getAuthHeaders } from '../AuthService/auth.service';
import { Request, RequestSummary } from './types';
import apiClient from '../../clients/apiClient'

const customerEndpoint = () => {
    apiClient.defaults.baseURL = apiManagementUrl + '/api/customer/v2/';
    return apiClient;
};

export const loadAll = (): Promise<AxiosResponse<Array<RequestSummary>>> => customerEndpoint().get('/requests', getAuthHeaders());
export const loadDetails = (key: string, noCache: boolean = false): Promise<AxiosResponse<Request>> => customerEndpoint().get(`/requests/${key}`, { clearCacheEntry: noCache });
export const getImageUploadStatus = (key: string) => customerEndpoint().get(`/request/${key}/image-upload-status`);
export const setImageUploadStatus = (key: string, payload: any) => customerEndpoint().post(`/request/${key}/image-upload-status`, payload);
export const renewOffer = (offerKey: string) => customerEndpoint().post(`/offers/${offerKey}/renew`);
export const deleteImage = (id: number, requestKey: string) => customerEndpoint().delete(`/blobs/${id}/request/${requestKey}`, getAuthHeaders());
export const getUtilitiesFormFlag = (market: string) => customerEndpoint().get(`/settings/featureflag?key=EnableUtilityServicesTasks&market=${market}`);
