import React, { FunctionComponent } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import TokenService from '../services/token.service';
import { path, RouteName } from './Routes';

interface IGate extends RouteProps {
    protected: boolean;
}

export const Gate: FunctionComponent<IGate> = (props) => {
    // const auth = useAuthentication();
    // authContext is never getting updated, so new state isn't getting set properly once you're logged in
    // TODO : auth service together and token service together
    // Initialize store default values from cookies, and have auth post calls update the cookies and stores
    // rather than pulling values from tokenService which just pull values from cookies, we can pull it here
    // Auth calls need to update the store and cookies
    const isLoggedIn = TokenService.getToken();

    if (props.protected) {
        if (!isLoggedIn) {
            const loginRedirect = `?redirect=${props.location.pathname}`;
            return <Redirect to={{
                pathname: path(RouteName.Login),
                search: loginRedirect
            }} exact />
        }
    }

    if (props.path === '/') {
        const pathname = isLoggedIn ? path(RouteName.Home) : path(RouteName.Login);
        return <Redirect to={{
            pathname: pathname
        }} />
    }

    return <Route {...props} />
}