import React from 'react';
import { Button } from 'floorplan/components/v2/Button';
import { useDisposition } from '../../../context/disposition/DispositionContext';
import Validator from '@offerpad/concrete/lib/validation/Validator';
import * as rules from '@offerpad/concrete/lib/validation/rules';
import { DispositionOffer, DispositionStatus } from 'src/services/DispositionService/types';
import { openModal } from '@offerpad/concrete.react/lib/Modal';
import { useModal } from '@offerpad/concrete.react/lib/Modal';
import { Modals } from '../ModalController/ModalController';
import styles from './OfferHistoryFooter.module.scss';
import { useHistory } from 'react-router';
import { DetailType } from 'src/services/DispositionService/types';

type OfferHistoryFooterProps = {
	isCounter: boolean;
	onCancel: () => void;
	onCounter: () => void;
}

const DispositionOfferValidators: Partial<Record<keyof DispositionOffer, Validator>> = {
	offerPrice: Validator.create([rules.required()]),
	earnestMoney: Validator.create([rules.required()]),
	proposedClosingDatetime: Validator.create([rules.required()]),
};

const OfferHistoryFooter: React.FC<OfferHistoryFooterProps> = (props) => {
	const { isCounter, onCancel, onCounter } = props;
	const history = useHistory();
	const {
		state: {
			newCounterOffer,
			dispositionOffer: { status, id },
			loading,
		},
		counterOffer,
	} = useDisposition();

	const { dispatch: modalDispatch } = useModal();
	const validCounterOffer = Object.keys(DispositionOfferValidators)
		.every((key) => DispositionOfferValidators[key].validate(newCounterOffer[key]).valid);

	const submitCounterOffer = () => {
		if (!newCounterOffer.requestedConcessions) {
			newCounterOffer.requestedConcessions = 0;
		}

		if(status === DispositionStatus.NEED_HIGHEST_BEST) {
			newCounterOffer.detailType = DetailType.HIGHEST_AND_BET_RECEIVED;
		}

		counterOffer(id, newCounterOffer as DispositionOffer).then(() => {
			onCancel(); // get out of counter mode
			if (status === DispositionStatus.NEED_HIGHEST_BEST) {
				openModal(modalDispatch, Modals.HB_SUBMITTED_MODAL);
			} else {
				openModal(modalDispatch, Modals.COUNTER_SUBMITTED_MODAL);
			}
		});
	};

	const getButtons = (dispositionStatus: DispositionStatus) => {

		if (isCounter && dispositionStatus === DispositionStatus.NEED_HIGHEST_BEST) {
			return (
				<>
					<Button ghost id="cancel-btn" onClick={onCancel}>
						Cancel
					</Button>
					<Button
						secondary
						id="withdraw-btn"
						className={`${styles.counter}`}
						onClick={() => openModal(modalDispatch, Modals.WITHDRAW_OFFER_MODAL)}
					>
						Withdraw
					</Button>
					<Button primary id="submit-counter-btn" onClick={submitCounterOffer} disabled={!validCounterOffer} progress={loading}>
						Submit H&amp;B
					</Button>
				</>
			);
		}

		if (isCounter) {
			return (
				<>
					<Button ghost id="cancel-btn" onClick={onCancel}>
						Cancel
					</Button>
					<Button secondary id="submit-counter-btn" onClick={submitCounterOffer} disabled={!validCounterOffer} progress={loading}>
						Submit Counter
					</Button>
				</>
			);
		}

		switch (dispositionStatus) {
            case DispositionStatus.BUYER_RECEIVED:
            case DispositionStatus.BUYER_COUNTER:
            case DispositionStatus.RECEIVED:
            case DispositionStatus.ACCEPTED:
            case DispositionStatus.BUYER_HIGHEST_BEST:
            case DispositionStatus.ACCEPTANCE_PENDING_REVIEW:
            case DispositionStatus.CONTRACT_SUBMITTED:
			case DispositionStatus.SELLER_COUNTER:
				return (
					<Button
						primary
						id="withdraw-btn"
						onClick={() => openModal(modalDispatch, Modals.WITHDRAW_OFFER_MODAL)}
					>
						Withdraw
					</Button>
				);

			case DispositionStatus.SELLER_COUNTER_SENT:
				return (
					<>
						<Button
							ghost
							id="reject-btn"
							className={`${styles.reject}`}
							onClick={() => openModal(modalDispatch, Modals.REJECT_OFFER_MODAL)}
						>
							Reject
						</Button>
						<Button secondary id="counter-btn" onClick={onCounter} className={`${styles.counter}`}>
							Counter
						</Button>
						<Button
							primary
							id="accept-btn"
							onClick={() => openModal(modalDispatch, Modals.ACCEPT_OFFER_MODAL)}
						>
							Accept
						</Button>
					</>
				);
			case DispositionStatus.SELLER_COUNTER_SENT_DOCUMENT_CORRECTIONS:
					return (
						<>
						<Button
							ghost
							id="reject-btn"
							className={`${styles.reject}`}
							onClick={() => openModal(modalDispatch, Modals.REJECT_OFFER_MODAL)}
						>
							Reject
						</Button>
						<Button
							primary
							id="correct-contract-btn"
							onClick={() => history.push(`/buyers/${id}/documents`)}
						>
							Correct Contract
						</Button>
					</>
					)
			case DispositionStatus.NEED_HIGHEST_BEST:
				return (
					<>
						<Button
						secondary
						id="withdraw-btn"
						onClick={() => openModal(modalDispatch, Modals.WITHDRAW_OFFER_MODAL)}
						className={`${styles.counter}`}
					>
						Withdraw
					</Button>
					<Button primary id="counter-btn" onClick={onCounter} className={`${styles.counter}`}>
							Add H&amp;B
						</Button>
					</>
				);

			default:
return null;
		}
	};

return (
	<div className={styles.footer}>
		{getButtons(status)}
	</div>
);
};

export default OfferHistoryFooter;
