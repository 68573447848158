import { formatDate, formatDateAndTime, formatTime, formatTimeStamp } from './datetime';
import { illustrationPathForTitle } from './titleContacts';
import { agentUser as c } from './content/contentHelpers';
import moment from 'moment-timezone';

export function generateModalContent(event, offerState) {
    const timeZoneId = offerState.timeZoneId;
    const escrowCloseDate = offerState.escrowCloseDate;

    let moveDate = '';
    switch (event) {
        case 'Close of Escrow':
            return {
                title: `Your ${c("client's ", '', offerState)}close of escrow date has been scheduled`,
                description: `Close of escrow is scheduled for <strong>${formatDate(
                    escrowCloseDate,
                    timeZoneId,
                    true
                )}</strong>. Please know that your ${c("client's ", '', offerState)}date
                                is flexible! If ${c('your client needs', 'you need', offerState)} to modify it,
                                reach out to your Transaction Manager.`,
                illustrationPath: require('../../../assets/illustrations/sold_to_offerpad.svg'),
            };
        case 'Inspection Appointment':
            const inspectionScheduledDateTime = offerState.inspectionScheduledDateTime;
            return {
                title: `${c('Insepection', 'Your inspection', offerState)} date has been scheduled`,
                illustrationPath: require('../../../assets/illustrations/inspection-scheduled.svg'),
                description: `Your ${c("client's home ", '', offerState)}inspection has been scheduled for <strong>${formatDate(
                    inspectionScheduledDateTime,
                    timeZoneId
                )}</strong>
                at <strong>${formatTime(inspectionScheduledDateTime, timeZoneId)}</strong>.
                We are looking forward to ${c(
                    "learn more about your client's home",
                    'meeting with you and learning more about your home',
                    offerState
                )}.`,
            };
        case 'Moving Day':
            const movingCompany = offerState.movingCompany;
            moveDate = offerState.moveDate;
            return {
                title: `Your ${c("client's ", '', offerState)}moving date has been scheduled`,
                description: `${movingCompany} has told us that your ${c("client's ", '', offerState)}moving
                                    date is scheduled for <strong>${formatDate(
                    moveDate,
                    timeZoneId
                )}</strong>. Woo-hoo!`,
                illustrationPath: illustrationPathForMovingCompany(movingCompany),
            };
        case 'Move Out Deadline':
            return {
                title: `Last day to move out`,
                description: `Just a reminder: our contract requires ${c('your client', 'you', offerState)} to
                                have ${c('their', 'your', offerState)} possessions and furniture moved out
                                from the home on this date by midnight.`,
                illustrationPath: require('../../../assets/illustrations/couple_moving.svg'),
            };
        case 'Final Walk-through':
            return {
                title: c(`Final walk-through`, `Just wanted to let you know`, offerState),
                description: `On this date, Offerpad will perform the final walk-through of your ${c("client's ", '', offerState)}
                home. We may be there at any time and will use the key in the lockbox to gain entry.`,
                illustrationPath: require('../../../assets/illustrations/offerpad_representative.svg'),
            };
        case 'Move Out Deadline ES':
            return {
                title: `Last day to move out`,
                description: `Just a reminder, your ${c("client's ", '', offerState)}extended stay period ends on this date. ${c(
                    'They',
                    'You',
                    offerState
                )} are required
                to have ${c('their', 'your', offerState)} furniture and possessions moved out by <strong>5:00 pm
                ${moment().tz(timeZoneId).format('z')}</strong>.${c(
                    '',
                    ' We hope this additional time has helped make moving easier for you!',
                    offerState
                )}`,
                illustrationPath: require('../../../assets/illustrations/couple_moving.svg'),
            };
        case 'Closing Appointment':
            const signingApptDateTime = offerState.signingApptDateTime;
            const titleCompanyCapitalized = offerState.titleCompanyCapitalized;
            return {
                title: `Your ${c("client's ", '', offerState)}closing appointment has been scheduled`,
                description: `${titleCompanyCapitalized} has informed us that your ${c("client's ", '', offerState)}signing appointment
                is scheduled for ${formatDateAndTime(signingApptDateTime, timeZoneId)}`,
                illustrationPath: illustrationPathForTitle(titleCompanyCapitalized),
            };
        case 'Virtual Walk-Through':
            const virtualWalkThroughDateTime = offerState.virtualWalkThroughScheduledDateTime;
            return {
                title: `Virtual walk-through`,
                description: `You have scheduled ${c('a', 'your', offerState)} virtual walk-through ${c(
                    'of your seller’s home',
                    'with an Offerpad team member',
                    offerState
                )} on ${formatDateAndTime(
                    virtualWalkThroughDateTime,
                    timeZoneId
                )} We are looking forward to seeing your home virtually.`,
                illustrationPath: require('../../../assets/illustrations/virtual-walkthrough.svg'),
            };
        case 'Offer Presentation':
            const presentOfferApptDateTime = offerState.presentOfferApptDateTime;
            return {
                title: `Review your ${c("seller's ", '', offerState)}offer`,
                description: `You are scheduled to discuss your ${c("seller's ", '', offerState)}offer on ${formatDateAndTime(
                    presentOfferApptDateTime,
                    timeZoneId
                )} We are looking forward to talking with you.`,
                illustrationPath: require('../../../assets/illustrations/offerpad_representative.svg'),
            };
        default:
            break;
    }
    return {};
}

export default function getCalendarEvents(offerState) {

    if (!offerState.thereIsOffer) {
        return [];
    }

    const escrowCloseDate = offerState.escrowCloseDate;
    const inspectionScheduledDateTime = offerState.inspectionScheduledDateTime;
    const timeZoneId = offerState.timeZoneId;
    const moveDate = offerState.moveDate;
    const walkthroughScheduledDate = offerState.walkthroughScheduledDate;
    const signingApptDateTime = offerState.signingApptDateTime;
    const postPossessionMoveOutDate = offerState.postPossessionMoveOutDate;
    const noPostPossessionMoveOutDate = offerState.noPostPossessionMoveOutDate;
    const contractSignedDate = offerState.contractSignedDate;
    const virtualWalkThroughDateTime = offerState.virtualWalkThroughScheduledDateTime;
    const presentOfferApptDateTime = offerState.presentOfferApptDateTime;
    const offerSent = offerState.offerSent;
    const offerExpired = offerState.offerExpired;
    const offerExpiredDate = offerState.expirationDateTime;

    let calendarEvents = [];

    if (offerExpired) {
        return calendarEvents;
    }

    //Virtual Walk-Through
    if (virtualWalkThroughDateTime) {
        calendarEvents.push({
            start: formatTimeStamp(virtualWalkThroughDateTime, timeZoneId),
            title: 'Virtual Walk-Through',
            extendedProps: {
                eventHtml: `
                    <div>${formatTime(virtualWalkThroughDateTime, timeZoneId)}</div>
                    <div>Virtual <br/> Walk-Through</div>`,
                modalContent: generateModalContent('Virtual Walk-Through', offerState),
            },
        });
    }

    //Offer Presentation Appt
    if (presentOfferApptDateTime) {
        calendarEvents.push({
            start: formatTimeStamp(presentOfferApptDateTime, timeZoneId),
            title: 'Offer Presentation Appointment',
            extendedProps: {
                eventHtml: `
                    <div>${formatTime(presentOfferApptDateTime, timeZoneId)}</div>
                    <div>Offer Presentation <br/> Appointment</div>`,
                modalContent: generateModalContent('Offer Presentation', offerState),
            },
        });
    }

    //offer expired
    if (offerSent && !offerExpired && offerExpiredDate) {
        calendarEvents.push({
            start: formatTimeStamp(offerExpiredDate, timeZoneId),
            title: 'Offer Will Expire',
            extendedProps: {
                eventHtml: `<div>Offer Will Expire</div>`,
                icon: 'fa-file-contract',
                modalContent: {},
            },
        });
    }

    // Contract Signed
    if (contractSignedDate) {
        calendarEvents.push({
            start: formatTimeStamp(contractSignedDate, timeZoneId),
            title: 'Contract Signed',
            extendedProps: {
                eventHtml: `<div>Contract Signed</div>`,
                icon: 'fa-file-contract',
                modalContent: {},
            },
        });
    }

    // Close of Escrow
    if (escrowCloseDate) {
        calendarEvents.push({
            start: formatTimeStamp(escrowCloseDate, timeZoneId, true),
            title: 'Close of Escrow',
            extendedProps: {
                eventHtml: `<div>Close of Escrow</div>`,
                icon: 'fa-user-tie',
                modalContent: generateModalContent('Close of Escrow', offerState),
            },
        });
    }

    // Inspection Appointment
    if (inspectionScheduledDateTime && offerState.currentStep !== 'InspectionOnHold') {
        calendarEvents.push({
            start: formatTimeStamp(inspectionScheduledDateTime, timeZoneId),
            title: 'Inspection Appointment',
            extendedProps: {
                eventHtml: `<div>${formatTime(inspectionScheduledDateTime, timeZoneId)}</div><div>Inspection</div>
                            <div>Appointment</div>`,
                icon: 'fa-search-location',
                modalContent: generateModalContent('Inspection Appointment', offerState),
            },
        });
    }

    // Moving Day
    if (moveDate) {
        calendarEvents.push({
            start: formatTimeStamp(moveDate, timeZoneId),
            title: 'Moving Day',
            extendedProps: {
                eventHtml: `<div>Moving Day - ${offerState.movingCompany}</div>`,
                icon: 'fa-truck',
                modalContent: generateModalContent('Moving Day', offerState),
            },
        });
    }

    // Move Out Deadline (no post possession or extended stay)
    if (
        postPossessionMoveOutDate === null &&
        escrowCloseDate !== null &&
        noPostPossessionMoveOutDate &&
        noPostPossessionMoveOutDate !== 'Invalid date'
    ) {
        calendarEvents.push({
            /* Without exteneded stay, this is the day before escrow closes */
            start: formatTimeStamp(noPostPossessionMoveOutDate, timeZoneId, true),
            title: 'Move Out Deadline',
            extendedProps: {
                eventHtml: `<div>Move Out Deadline</div>`,
                icon: 'fa-box-all',
                modalContent: generateModalContent('Move Out Deadline', offerState),
            },
        });
    }

    // Your walkthrough will happen on this date
    if (walkthroughScheduledDate) {
        calendarEvents.push({
            start: formatTimeStamp(walkthroughScheduledDate, timeZoneId),
            title: 'Final Walk-through',
            extendedProps: {
                eventHtml: `<div>Final</div><div>Walk-through</div>`,
                icon: 'fa-walking',
                modalContent: generateModalContent('Final Walk-through', offerState),
            },
        });
    }

    // Move Out Deadline (post possession)
    if (postPossessionMoveOutDate) {
        calendarEvents.push({
            start: formatTimeStamp(postPossessionMoveOutDate, timeZoneId),
            title: 'Move Out Deadline',
            color: 'white',
            extendedProps: {
                eventHtml: `<div>Move Out Deadline</div>`,
                icon: 'fa-box-all',
                modalContent: generateModalContent('Move Out Deadline ES', offerState),
            },
        });
    }

    // Closing Appointment at Title
    if (signingApptDateTime) {
        const time = formatTime(signingApptDateTime, timeZoneId);
        calendarEvents.push({
            start: formatTimeStamp(signingApptDateTime, timeZoneId),
            title: 'Closing Appointment',
            extendedProps: {
                eventHtml: `<div>${time.startsWith('12:00 am') ? '' : time
                    }</div><div>Closing</div><div>Appointment</div>`,
                icon: 'fa-file-signature',
                modalContent: generateModalContent('Closing Appointment', offerState),
            },
        });
    }

    return calendarEvents.sort((a, b) => moment(a.start).diff(moment(b.start), 'seconds'));
}

const illustrationPathForMovingCompany = (movingCompany) => {
    switch ((movingCompany ?? '').toLowerCase()) {
        case 'bellhop':
        case 'bellhops':
            return require('../../../assets/movers/bellhop_movers.svg');
        default:
            return require('../../../assets/movers/generic_movers.svg');
    }
};
