import React, { useState } from 'react'
import { useNotifications } from '../../../context/notifications/NotificationsContext';
import { useOffer } from '../../../context/offer/OfferContext';
import { Modal } from 'floorplan/components/v2/Modal'
import { Button } from 'floorplan/components/Button'
import { FormControl } from 'floorplan/components/Form'
import Fields from './fields';
import { DefaultValueBinder, DefaultFieldBinderFactory } from '@offerpad/concrete/lib/model';
import { ValidationResult } from '@offerpad/concrete/lib/validation/Validator';
import * as binder from './binder';
import * as ClientRequest from '../../../services/ClientRequest/clientRequest.service';
import { notify } from '../../../context/notifications/NotificationsReducer';
import { getFirstTeamMember } from '../../Controls/resources/teamUtil';
import { useModal } from '@offerpad/concrete.react/lib/Modal';
import { closeModal } from '@offerpad/concrete.react/lib/Modal';

const MessagingModal: React.FC = () => {
    const { state: { offerState } } = useOffer();
    const { dispatch: notificationsDispatch } = useNotifications();
    const { state: { isOpen }, dispatch: modalDispatch } = useModal();
    const teamMember = getFirstTeamMember(offerState);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [errors, setErrors] = useState<Record<string, string>>({})

    const valueBinder = new DefaultValueBinder();
    const fieldBinderFactory = new DefaultFieldBinderFactory(valueBinder);
    const form = binder.create(fieldBinderFactory);

    const onChange = (e: React.ChangeEvent<any>) => {
        const field = form.fields[e.target.name];
        const value = e.target.value;
        const result: ValidationResult = field.validator.validate(value);
        setErrors({ ...errors, [e.target.name]: result.message })
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const model = form.bind(e.target as HTMLFormElement);
        if (model.valid) {
            setSubmitting(true);
            ClientRequest.sendMessage({
                acquisitionId: offerState.acquisitionId,
                subject: model.fields[Fields.Subject].value,
                message: model.fields[Fields.Message].value,
                ownerRelationship: teamMember && teamMember.owner,
            })
                .then(() => {
                    notify(notificationsDispatch, {
                        message: `Your message has been sent. We'll be in touch.`,
                        type: 'success',
                    });
                    closeModal(modalDispatch);
                })
                .catch(() => {
                    notify(notificationsDispatch, {
                        message: `Oops! There was an issue sending your message. Please try again later.`,
                        type: 'danger',
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                });

        } else {
            let errors = {};
            for (let field in model.fields) {
                const validation = model.fields[field].validation;
                errors[field] = validation.message;
            }
            setErrors(errors);
        }
    }

    return (
        <Modal
            open={isOpen}
            onClose={() => closeModal(modalDispatch)}
            mobileFull
            hasFooter
        >
            <Modal.Header
                title={'Send us a message'}
                onClose={() => closeModal(modalDispatch)}
                border={false}
            />
            <form data-testid="message-form" className="message-form" onSubmit={(e) => onSubmit(e)}>
                <Modal.Content>
                    <div className="form-group">
                        <FormControl
                            label={Fields.Subject}
                            id={Fields.Subject}
                            name={Fields.Subject}
                            onChange={e => onChange(e)}
                            placeholder="Enter subject"
                            errorText={errors[Fields.Subject]}
                            hasError={!!errors[Fields.Subject]}
                        />
                    </div>
                    <div className="form-group">
                        <FormControl
                            label={Fields.Message}
                            id={Fields.Message}
                            name={Fields.Message}
                            onChange={e => onChange(e)}
                            placeholder="Enter message"
                            errorText={errors[Fields.Message]}
                            hasError={!!errors[Fields.Message]}
                        >
                            <textarea
                                name={Fields.Message}
                                rows={4}
                                onChange={(e) => onChange(e)}
                            />
                        </FormControl>
                    </div>
                </Modal.Content>
                <Modal.Footer>
                    <Button
                        id="submit-offer-brokerage-name"
                        data-qa="submit-offer-brokerage-name"
                        type='submit'
                        large
                    >
                        {submitting ? 'Sending...' : 'Send'}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default MessagingModal;