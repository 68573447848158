import React, { useState } from 'react';
import * as binder from './binder';
import Fields from './fields';
import { Button } from 'floorplan/components/v2/Button';
import { FormControl } from 'floorplan/components/v2/Form';
import { useAppInsights } from '../../../context/AppInsightsContext';
import './Profile.scss';
import { DefaultFieldBinderFactory, DefaultValueBinder } from '@offerpad/concrete/lib/model';
import { ValidationResult } from '@offerpad/concrete/lib/validation/Validator';
import { useHistory } from 'react-router';
import { RouteName, path } from '../../../routing/Routes';
import { useUser } from '../../../context/user/UserContext';
import { updateInfo } from '../../../context/user/UserReducer';


const Profile = () => {
    const history = useHistory();
    const appInsights = useAppInsights();
    const { state: user, dispatch: userDispatch } = useUser();

    const valueBinder = new DefaultValueBinder();
    const fieldBinderFactory = new DefaultFieldBinderFactory(valueBinder);
    const form = binder.create(fieldBinderFactory, user.agent == 'True');
    const [errors, setErrors] = useState<Record<string, string>>({});

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            case Fields.FirstName:
                user.firstName = e.target.value;
                break;
            case Fields.LastName:
                user.lastName = e.target.value;
                break;
            case Fields.PhoneNumber:
                user.phoneNumber = e.target.value;
                break;
            case Fields.BrokerageName:
                user.brokerageName = e.target.value;
                break;

        }
        const field = form.fields[e.target.name];
        const value = e.target.value;
        const result: ValidationResult = field.validator.validate(value);
        setErrors({ ...errors, [e.target.name]: result.message })
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const model = form.bind(e.target as HTMLFormElement);
        if (model.valid) {
            updateInfo(userDispatch, user).then(() => {
                history.push(path(RouteName.Home));
            }).catch((error) => {
                appInsights.trackTrace({ message: error.message });
            });
        } else {
            let errors = {};
            for (let field in model.fields) {
                const validation = model.fields[field].validation;
                errors[field] = validation.message;
            }
            setErrors(errors);
        }
    }

    if (!user.email) {
        return null;
    }

    return (
        <div className="c-authform register-wrapper">
            <form onSubmit={(e) => onSubmit(e)}>
                <h2 className="c-authform__title">Your profile</h2>
                <p className="c-authform__subtitle">Update your information here.</p>
                <fieldset>
                    <legend></legend>
                    <FormControl
                        id={Fields.Email}
                        onChange={(e) => onChange(e)}
                        label="Email"
                        disabled={true}
                        name={Fields.Email}
                        value={user.email}
                        error={errors[Fields.Email]}
                    />
                    <FormControl
                        id={Fields.FirstName}
                        onChange={(e) => onChange(e)}
                        label="First name"
                        name={Fields.FirstName}
                        value={user.firstName || ""}
                        error={errors[Fields.FirstName]}
                    />
                    <FormControl
                        id={Fields.LastName}
                        onChange={(e) => onChange(e)}
                        label="Last name"
                        name={Fields.LastName}
                        value={user.lastName || ""}
                        error={errors[Fields.LastName]}
                    />
                    {user.agent == 'True' && 
                        <FormControl
                            id={Fields.BrokerageName}
                            onChange={(e) => onChange(e)}
                            label="Brokerage Name"
                            name={Fields.BrokerageName}
                            value={user.brokerageName || ""}
                            error={errors[Fields.BrokerageName]}
                        />
                    } 
                    <FormControl
                        id={Fields.PhoneNumber}
                        onChange={(e) => onChange(e)}
                        label="Phone"
                        name={Fields.PhoneNumber}
                        value={user.phoneNumber || ""}
                        error={errors[Fields.PhoneNumber]}
                    />
                </fieldset>
                <Button
                    id={'reset-password-submit'}
                    fullWidth
                    data-qa="reset-password-submit"
                    disabled={user.loading}
                    type={'submit'}
                >
                    {user.loading ? 'Updating profle...' : 'Update profile'}
                </Button>
            </form>
        </div>
    );
}

export default Profile;