class OfferState {
    preTitleCurrentSteps = [
        'ContractInProgress',
        'ContractSent',
        'ContractSigned',
        'Counteroffer',
        'NewRequest',
        'OfferAccepted',
        'OfferInProgress',
        'OfferPresented',
        'OfferReview',
        'OfferSent',
        'RenewalRequest',
    ];

    markets = [
        'South Charlotte',
        'Las Vegas',
        'San Antonio',
        'Houston',
        'Dallas',
        'Austin',
        'Columbia',
        'Indianapolis',
        'St Louis',
    ];

    postPossessionAddenda = [
        'PostPossessionAddendum',
        'PostPossessionCOEChangeAddendum',
        'PostPossesionAddendum',
        'PostPossesionCOEChangeAddendum',
    ];

    documentName = {
        BuyerCreditToSeller: 'Buyer Credit to Seller',
        CloseOfEscrowAddendum: 'Close of Escrow Addendum',
        CreditAddendum: 'Credit Addendum',
        CreditAndRepairAddendum: 'Credit and Repair Addendum',
        ExtendedStayAddendum: 'Extended Stay Addendum',
        ExtendedStayCancellationAddendum: 'Extended Stay Cancellation Addendum',
        ExtendedStayCancellationCOEChangeAddendum: 'Extended Stay COE Change Cancellation Addendum',
        ExtendedStayCOEChangeAddendum: 'Extended Stay COE Change Addendum',
        GeneralAddendum: 'General Addendum',
        PostPossesionAddendum: 'Post Possesion Addendum',
        PostPossesionCOEChangeAddendum: 'Post Possesion COE Change Addendum',
        PostPossessionAddendum: 'Post Possesion Addendum',
        PostPossessionCOEChangeAddendum: 'Post Possesion COE Change Addendum',
        PropertyDisclosure: 'Property Disclosure',
        PurchaseContract: 'Purchase Contract',
    };

    // Adding all the fields so that the 'type' exists in offerState. You know typescript
    accepted = false;
    acceptedDateTime: string = null;
    acquisitionId: number = null;
    checklistPageLink: string = null;
    calendarEndDate: string = null;
    calendarStartDate: string = null;
    city: string = null;
    cityStateZipCode: string = null;
    clearedToClose: boolean = false;
    closedSurveyUrl: string = null;
    closeOfEscrowAddedndumCompleted: Object = null;
    closeOfEscrowAddedndumCompletedDateTime: string = null;
    closeOfEscrowAddedndumSent: boolean = false;
    closeOfEscrowAddedndumSentSignByDateTime: string = null;
    closeOfEscrowAddendumSigned: boolean = false;
    closingConsultant: Object;
    closingConsultantFullName: string = null;
    closingConsultantLink: string = null;
    conciergeAgent: ConciergeAgent = null;
    contactEmail: string = null;
    contracts: Array<any> = [];
    contractSigned: boolean = false;
    contractSignedDate: string = null;
    contractSignedDateTime: string = null;
    creditAddendum: Object = null;
    creditAddendumAmount: string = null;
    creditAddendumAmountIsNaN: boolean = false;
    creditAddendumCompleted: Object = null;
    creditAddendumCompletedDateTime: string = null;
    creditAddendumSent: boolean = false;
    creditAndRepairAddendum: Object = null;
    creditAndRepairAddendumAmount: string = null;
    creditAndRepairAddendumAmountIsNaN: boolean = false;
    creditAndRepairAddendumCompleted: boolean = false;
    creditAndRepairAddendumCompletedDateTime: string = null;
    creditAndRepairAddendumSent: boolean = false;
    creditAndRepairSkipped: boolean = false;
    currentStep: string = null;
    dateTimePicker: string = null;
    documents: Array<any> = [];
    donotContactTitleJustYet: boolean = false;
    emailFromMovingCompanyExpected: boolean = false;
    entityEvents: Array<any> = [];
    escrowCloseDate: string = null;
    events: Array<any> = [];
    exitSurvey: string = null;
    exitSurveyReady: boolean = false;
    expirationDateTime: string = null;
    expired: boolean = false;
    extendedStayAddendum: Object = null;
    extendedStayAddendumSigned: boolean = false;
    extendedStayCancellationAddendum: Object = null;
    extendedStayCancellationAddendumSigned: boolean = false;
    extendedStayCancellationCOEChangeAddendum: Object = null;
    extendedStayCancellationCOEChangeAddendumSigned: boolean = false;
    extendedStayCancellationCOEChangeContractCompletedDateTime: string = null;
    extendedStayCancellationContractCompletedDateTime: string = null;
    extendedStayCOEChangeAddendum: Object = null;
    extendedStayCOEChangeAddendumSigned: boolean = false;
    extendedStayCOEChangeContractCompletedDateTime: string = null;
    extendedStayContractCompletedDateTime: string = null;
    firstName: string = null;
    freeMove: boolean = false;
    hasContactedTitle: boolean = false;
    hasNotContactedTitle: boolean = false;
    hasToReviewOffer: boolean = false;
    homeInspectionCreatedDateTime: string = null;
    homeInspectionScheduled: boolean = false;
    homeMarketingConsultant: Object = null;
    homeOwnerConsultant: Object = null;
    imageRequestStatus: Object = null;
    images: Array<any> = [];
    inspectionDocumentUrl: string = null;
    inspectionOnHold: boolean = false;
    inspectionScheduled: boolean = false;
    inspectionScheduledDateTime: string = null;
    inspectionSubmittedDateTime: string = null;
    isAcquired: boolean = false;
    isConcierge: boolean = false;
    isLost: boolean = false;
    isTimeToContactTitle: boolean = false;
    itsTimeToContactTitle: boolean = false;
    keyType: string = null;
    lastName: string = null;
    listedByAgent: boolean = false;
    market: string = null;
    moveDate: string = null;
    movingCompany: string = null;
    movingCompanyContact: Object;
    movingCompanyNeedsAdditionalInfoFromSeller: boolean = false;
    needsToContactMovingCompany: boolean = false;
    needsToScheduleHomeInspection: boolean = false;
    needsToScheduleInspectionReview: boolean = false;
    needsToScheduleSigningAppointment: boolean = false;
    needsToSignCloseOfEscrowAddendum: boolean = false;
    needsToSignContract: boolean = false;
    needsTosignExtendedStayAddendum: boolean = false;
    needsTosignExtendedStayCancellationAddendum: boolean = false;
    needsTosignExtendedStayCancellationCOEChangeAddendum: boolean = false;
    needsTosignExtendedStayCOEChangeAddendum: boolean = false;
    needsTosignPostPossessionAddendum: boolean = false;
    noPostPossessionMoveOutDate: string = null;
    normalizedAddress: string = null;
    offerExpired: boolean = false;
    offerExtended: boolean = false;
    offerExtendedDate: string = null;
    offerInProgress: boolean = false;
    offerKey: string = null;
    offerpadContactPhoneNumber: string = null;
    offerSent: boolean = false;
    offerViewed: boolean = false;
    officialInspectionCompleted: object = null;
    pathCalendar: string = null;
    pathUploadPhotos: string = null;
    pathYourTeam: string = null;
    pathDocuments: string = null;
    pathChecklist: string = null;
    postInspectionSurvey: Object = null;
    postInspectionSurveyLink: string = null;
    postInspectionSurveyReady: boolean = false;
    postPossessionAddendum: Object = null;
    postPossessionAddendumSigned: boolean = false;
    postPossessionContractCompletedDateTime: string = null;
    postPossessionMoveOutDate: string = null;
    presentOfferApptDateTime: string = null;
    price: number = null;
    propertyDisclosure: Object = null;
    propertyDisclosureCompleted: boolean = false;
    propertyDisclosureRequired: boolean = false;
    propertyImages: Array<any> = [];
    request: any = null;
    requestKey: string = null;
    requestSubmittedDate: string = null;
    sellerDisclosuresRequired: boolean = false;
    sellerInformationSheetReceived: boolean = false;
    showUtilitiesForm: boolean = false;
    signingAppointmentScheduled: boolean = false;
    signingApptDateTime: string = null;
    solutionsAdvisorGeneric: Object = null;
    state: string = null;
    streetAddress: string = null;
    teamPageLink: string = null;
    thereIsOffer: boolean = false;
    timeZoneId: string = null;
    titleClosingDate: string = null;
    titleCompany: string = null;
    titleCompanyCapitalized: string = null;
    titleContact: Object = null;
    titleContactFullName: string = null;
    titleContactPhoneNumber: string = null;
    titleNeedsInformation: boolean = false;
    titleWaitingOnSeller: boolean = false;
    underContract: boolean = false;
    uploadPhotos: boolean = false;
    uploadPhotosCompleted: boolean = false;
    uploadPhotosCompletionCriteria: Object = null;
    utilitiesForm: Object = null;
    utilitiesFormLink: string = null;
    virtualWalkThroughScheduledDateTime: string = null;
    walkthroughCompletedDate: string = null;
    walkthroughScheduledDate: string = null;
    zipCode: string = null;
    propertySpec: any = null;
}

class ConciergeAgent {
    email: string = null;
    firstName: string = null;
    fullName: string = null;
    id: number = 0;
    imageURL: string = null;
    lastName: string = null;
    phoneExtension: string = null;
    phoneNumber: string = null;
    schedulingLink: string = null;
}

export default OfferState;
